import { FC, useEffect, useMemo, useState } from 'react';

import commonStyles from '../common.module.scss';
import { GitHubCard } from '../components/GitHubCard/GitHubCard';
import githubCardStyles from '../components/GitHubCard/GitHubCard.module.scss';

import styles from './InstallJitApp.module.scss';

import { Loading } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useTenantContext } from 'context';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';

interface Props {
  onContinueButtonClick: () => void;
  isRedirectedBackFromGHInstallation: boolean;
  onInstallClick: () => void;
}

export const InstallJitApp: FC<Props> = ({ onContinueButtonClick, isRedirectedBackFromGHInstallation, onInstallClick }) => {
  const { githubInstallation, isLoadingInstallation } = useTenantContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isRedirectedBackFromGHInstallation) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, constants.LOADER_TIMEOUT);
    }
  }, [isRedirectedBackFromGHInstallation]);

  const onInstallButtonClick = async () => {
    setIsLoading(true);
    await onInstallClick();
  };

  const shouldShowLoader = useMemo(() => (isLoadingInstallation || isRedirectedBackFromGHInstallation) && isLoading, [isLoadingInstallation, isRedirectedBackFromGHInstallation, isLoading]);

  const installButton = (
    <JitButton analytics={{ action: 'clicked-install-github-app' }} isLoading={isLoading} onClick={onInstallButtonClick} variant='contained'>
      <JitText color='inherit' noWrap text='gettingStartedWizard.installJitApp.card.buttonText' />
    </JitButton>
  );

  const loadingLabel = (
    <div className={styles.loadingLabel}>
      <div className={styles.loadingIcon}>
        <JitIcon icon={Loading} size={12} />
      </div>

      <JitText bold color={colors.basicLight} text='gettingStartedWizard.installJitApp.card.loading' />
    </div>
  );

  const installSuccessLabel = (
    <JitText bold color={colors.successGreen} text='gettingStartedWizard.installJitApp.card.success' />
  );

  const getCardContent = () => {
    if (githubInstallation) return installSuccessLabel;
    if (shouldShowLoader) return loadingLabel;
    return installButton;
  };

  return (
    <DialogContentWrapper
      actionButtons={[{
        label: 'gettingStartedWizard.installJitApp.buttonText',
        disabled: !githubInstallation,
        handleClick: onContinueButtonClick,
      }]}
    >
      <div className={commonStyles.dotJitTextBox}>

        <JitText bold text='gettingStartedWizard.installJitApp.title' />

        <GitHubCard>
          <div className={githubCardStyles.cardLeftSide}>
            <JitText bold size='l' text='gettingStartedWizard.installJitApp.card.title' />

            <JitText muted text='gettingStartedWizard.installJitApp.card.description' />

            <JitExternalLink data-testid='whyPermissions' href={config.docsWhyJitNeedsPermissionLink} text='gettingStartedWizard.installJitApp.card.learnMore' />
          </div>

          <div className={githubCardStyles.cardRightSide}>
            <div className={styles.cardContentWrapper}>
              {getCardContent()}
            </div>
          </div>
        </GitHubCard>
      </div>
    </DialogContentWrapper>
  );
};
