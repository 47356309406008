import { FC, useEffect } from 'react';

import styles from './LoginPage.module.scss';
import { centerStyle, descriptionStyle, frameStyle, jitIconStyle, loginStyle, textStyle } from './LoginPage.styles';

import { JitFavicon } from 'assets';
import { JitText } from 'components/JitText/JitText';
import { useNotAuthenticatedAnalyticsClient } from 'context/AnalyticsContext/hooks/useNotAuthenticatedAnalyticsClient';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { Interval } from 'types/types';
import { setIntervalImmediately } from 'utils';

let hideElementsInterval: Interval;

const getFronteggContainer = () => {
  const fronteggContainer: Element | undefined = document.querySelector('#frontegg-login-box-container-default') || undefined;
  const fronteggContainerShadowRoot: ShadowRoot | undefined = fronteggContainer?.shadowRoot || undefined;
  return fronteggContainerShadowRoot;
};

const hidePoweredByFronteggAndSignUp = () => {
  hideElementsInterval = setIntervalImmediately(() => {
    const container = getFronteggContainer();
    const poweredByFrontegg: HTMLElement | undefined = container?.querySelector('[data-test-id="powered-by-logo"]') || undefined;
    const signUpElement: HTMLElement | undefined = container?.querySelector('h6') || undefined;

    if (poweredByFrontegg && signUpElement) {
      signUpElement.style.display = 'none';
      poweredByFrontegg.style.display = 'none';
      clearInterval(hideElementsInterval);
    }
  }, 10, 100);
};

export const LoginPage: FC = () => {
  const { sendPageViewedEventNotAuthenticated } = useNotAuthenticatedAnalyticsClient();
  hidePoweredByFronteggAndSignUp();

  useEffect(() => {
    sendPageViewedEventNotAuthenticated('login', '/login');
  }, [sendPageViewedEventNotAuthenticated]);

  return (
    <div style={{
      ...loginStyle,
      backgroundColor: colors.generalSystemsBG,
    }}
    >
      <div className={styles.gradientBlock} />

      <JitFavicon
        fill={colors.jitPink}
        style={jitIconStyle}
      />

      <div style={centerStyle}>
        <div style={{ ...textStyle,
          fontSize: '3.3em' }}
        >
          <div>
            {i18n.t('pages.login.theOnly')}

            <span style={{
              ...frameStyle,
              margin: '0 10px',
              borderColor: colors.blue02,
            }}
            >
              {i18n.t('pages.login.open')}
            </span>

            <span style={{
              ...frameStyle,
              borderColor: colors.jitPink,
            }}
            >
              {i18n.t('pages.login.devSecOps')}
            </span>
          </div>

          <span style={{
            ...textStyle,
            margin: '0 10px',
          }}
          >
            {i18n.t('pages.login.orchestrationPlatform')}
          </span>

        </div>

        <div style={descriptionStyle}>
          <JitText lineHeight={1.7} muted size='xl' text='pages.login.description' />
        </div>
      </div>
    </div>
  );
};
