import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useHandlePrUpdatesWebSocketNotification } from 'context/PullRequestsContext/hooks';
import { PullRequestsContext } from 'context/PullRequestsContext/PullRequestsContext';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { usePRService } from 'services/PrService/usePrService';
import { WebSocketNotificationTopics } from 'types/enums';
import { IPullRequestAnalysisPercentage, IPullRequestsAnalysis } from 'types/interfaces/PullRequests';

export const PullRequestsProvider: FC = ({ children }) => {
  const [prAnalysis, setPrAnalysis] = useState<IPullRequestsAnalysis>();
  const [isLoading, setIsLoading] = useState(false);
  const [prPercentage, setPrPercentage] = useState<IPullRequestAnalysisPercentage>();

  const { fetchPullRequestsAnalysis, fetchPullRequestPercentageAnalysis } = usePRService();

  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handlePrUpdatesWebSocketNotification } = useHandlePrUpdatesWebSocketNotification({ setPrAnalysis });

  const getPullRequestsAnalysis = useCallback(async () => {
    setIsLoading(true);
    const response = await fetchPullRequestsAnalysis();
    const prPercentageResponse = await fetchPullRequestPercentageAnalysis();
    setPrAnalysis(response);
    setPrPercentage(prPercentageResponse);
    setIsLoading(false);
  }, [fetchPullRequestPercentageAnalysis, fetchPullRequestsAnalysis]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.PrUpdates, handlePrUpdatesWebSocketNotification);
  }, [handlePrUpdatesWebSocketNotification, websocketSubscribe]);

  const value = useMemo(() => ({
    prPercentage,
    prAnalysis,
    isLoading,
    getPullRequestsAnalysis,
  }), [
    prPercentage,
    prAnalysis,
    isLoading,
    getPullRequestsAnalysis,
  ]);
  return (
    <PullRequestsContext.Provider value={value}>
      {children}
    </PullRequestsContext.Provider>
  );
};
