import { constants } from 'globalConstants';

export const ROLES = {
  ADMIN: 'admin',
  MEMBER: 'member',
  DEVELOPER: 'developer',
};

export const PERMISSIONS = {
  // Old format - DO NOT WRITE NEW PERMISSIONS IN THIS FORMAT
  READ_PIPELINE: 'jit.read.pipeline',
  READ_RESOURCE: 'jit.read.resource',
  READ_PIPELINES: 'jit.read.pipelines',
  READ_ACTIONS: 'jit.read.actions',
  READ_TEAMS: 'jit.read.teams',
  READ_INSIGHTS: 'jit.read.insights',
  READ_SNAPSHOT: 'jit.read.performanceSnapshot',
  READ_FINDINGS: 'jit.read.findings',
  READ_INTEGRATIONS: 'jit.read.integrations',
  READ_PLAN: 'jit.read.plan',
  READ_OVERVIEW: 'jit.read.overview',

  // New format
  READ_SBOM: 'jit.sbom.read',
  READ_SECURITY_MAPPING: 'jit.securityMapping.read',
  WRITE_FINDINGS: 'jit.findings.write',
  WRITE_IGNORE_RULES: 'jit.ignoreRules.write',
  WRITE_TICKETS: 'jit.ticketing.write',
};

const { FINDINGS, INTEGRATIONS, PIPELINES, ACTIONS, PLAN, PLANS, TEAMS, RESOURCE, OVERVIEW, SBOM, SECURITY_MAPPING,
  insights: { BASE_ROUTE: BASE_INSIGHTS_ROUTE, DEPLOYMENTS, PULL_REQUESTS, PERFORMANCE, SNAPSHOT, SNAPSHOT_NOT_FOUND, SECURITY_IMPACT } } = constants.routes;
const { PIPELINE_ID, SNAPSHOT_ID, PLAN_SLUG, RESOURCE_ID, TEAM_ID } = constants.routes.pathParameters;

export const ROUTES_PERMISSIONS = {
  [`/${OVERVIEW}`]: PERMISSIONS.READ_OVERVIEW,
  [`/${PIPELINES}`]: PERMISSIONS.READ_PIPELINES,
  [`/${PIPELINES}/:${PIPELINE_ID}`]: PERMISSIONS.READ_PIPELINE,
  [`/${ACTIONS}`]: PERMISSIONS.READ_ACTIONS,
  [`/${TEAMS}`]: PERMISSIONS.READ_TEAMS,
  [`/${TEAMS}/:${TEAM_ID}/${RESOURCE}/:${RESOURCE_ID}`]: PERMISSIONS.READ_TEAMS,
  [`/${RESOURCE}/:${RESOURCE_ID}`]: PERMISSIONS.READ_RESOURCE,
  [`/${TEAMS}/:${TEAM_ID}/${RESOURCE}/:${RESOURCE_ID}`]: PERMISSIONS.READ_TEAMS,
  [`/${TEAMS}/:${TEAM_ID}`]: PERMISSIONS.READ_TEAMS,
  [`/${BASE_INSIGHTS_ROUTE}/${SECURITY_IMPACT}`]: PERMISSIONS.READ_INSIGHTS,
  [`/${BASE_INSIGHTS_ROUTE}/${DEPLOYMENTS}`]: PERMISSIONS.READ_INSIGHTS,
  [`/${BASE_INSIGHTS_ROUTE}/${PULL_REQUESTS}`]: PERMISSIONS.READ_INSIGHTS,
  [`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}`]: PERMISSIONS.READ_INSIGHTS,
  [`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}/${SNAPSHOT}/:${SNAPSHOT_ID}`]: PERMISSIONS.READ_SNAPSHOT,
  [`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}/${SNAPSHOT_NOT_FOUND}`]: PERMISSIONS.READ_SNAPSHOT,
  [`/${FINDINGS}`]: PERMISSIONS.READ_FINDINGS,
  [`/${INTEGRATIONS}`]: PERMISSIONS.READ_INTEGRATIONS,
  '*': PERMISSIONS.READ_PLAN,
  [`/${PLAN}`]: PERMISSIONS.READ_PLAN,
  [`/${PLAN}/:${PLAN_SLUG}`]: PERMISSIONS.READ_PLAN,
  [`/${PLANS}`]: PERMISSIONS.READ_PLAN,
  [`/${PLANS}/:${PLAN_SLUG}`]: PERMISSIONS.READ_PLAN,
  [`/${SBOM}`]: PERMISSIONS.READ_PLAN,
  [`/${SECURITY_MAPPING}`]: PERMISSIONS.READ_SECURITY_MAPPING,
};
