import FormControl, { FormControlProps } from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectProps } from '@mui/material/Select';
import { FC, useCallback } from 'react';

import { StyledCheckbox, StyledMenuItem, StyledOutlinedInput } from './JitDropdown.styles';
import { menuProps } from './utils/constants';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitReadOnlyTooltip } from 'components/JitTooltip/JitReadOnlyTooltip/JitReadOnlyTooltip';
import { useThemeContext } from 'context';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { JitDropdownVariant } from 'types/enums';
import { ISelectItemOption, ISelectChangeEvent, ISvg } from 'types/interfaces';

interface Props {
  setCheckedFilters: (filters: string[]) => void;
  checkedFilters: string[];
  filterOptions?: string[] | ISelectItemOption[];
  placeholder?: string;
  optionsDividerIndex?: number;
  formControlProps?: FormControlProps;
  selectProps?: SelectProps;
  icon?: FC<ISvg>;
  variant?: JitDropdownVariant;
  className?: string;
  maxHeight?: number;
  disabled?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  isReadOnly?: boolean;
}

export const JitDropdown: FC<Props> = ({
  formControlProps = {},
  selectProps = {},
  checkedFilters = [],
  setCheckedFilters,
  placeholder,
  filterOptions,
  optionsDividerIndex,
  icon,
  variant,
  className,
  maxHeight,
  disabled,
  onOpen,
  onClose,
  isReadOnly,
}) => {
  const { t } = i18n;
  const { theme } = useThemeContext();

  const handleChange = (e: ISelectChangeEvent) => {
    let { value } = e.target;
    if (typeof value === 'string') value = value.split(',');
    if (variant === JitDropdownVariant.checkbox) {
      setCheckedFilters(value);
      return;
    }
    const filter = value.filter((newFilter: string) => !checkedFilters.includes(newFilter));
    setCheckedFilters(filter);
  };

  const selectValue = useCallback((selected: (string | ISelectItemOption)[]) => {
    if (placeholder && selected.length === 0) {
      return <JitText color={colors.darkGray} size='m' text={placeholder} />;
    }
    return selected.map((filter: string | ISelectItemOption) => {
      // @ts-ignore
      const filterName = typeof filterOptions?.[0] === 'string' ? filter : filterOptions?.find((option: Filter) => option.key === filter)?.name || filter;
      return t(filterName);
    }).join(', ');
  }, [filterOptions, placeholder, t]);

  const iconComponent = icon && <JitIcon className='position-absolute' icon={icon} style={{ left: 10 }} />;

  // update menuProps with maxHeight if provided
  const menuPropsWithMaxHeight = {
    ...menuProps,
    MenuListProps: {
      ...menuProps.MenuListProps,
      style: {
        ...menuProps.MenuListProps.style,
        ...(maxHeight && { maxHeight }),
      },
    },
  };

  const dropdownElement = (
    <FormControl className=' min-wp-200' {...formControlProps}>

      <Select
        className={className}
        data-testid='dropdown'
        disabled={isReadOnly || disabled}
        displayEmpty
        fullWidth
        input={<StyledOutlinedInput startAdornment={iconComponent} />}
        MenuProps={menuPropsWithMaxHeight}
        multiple
        // @ts-ignore
        onChange={handleChange}
        onClose={onClose}
        onOpen={onOpen}
        // @ts-ignore
        renderValue={selectValue}
        value={checkedFilters}
        {...selectProps}
      >
        {filterOptions?.map((filter, index) => {
          const isFilterString = typeof filter === 'string';
          const filterValue = isFilterString ? filter : filter.key;
          const filterName = i18n.t(isFilterString ? filter : filter.name);
          const isChecked = checkedFilters.includes(filterValue);
          const shouldShowDivider = optionsDividerIndex != null && index === optionsDividerIndex;
          return (
            <StyledMenuItem
              key={filterValue}
              autoFocus={false}
              data-testid={`menuItem-${index}`}
              sx={shouldShowDivider ? { borderBottom: `1px solid ${colors.gray}` } : {}}
              value={filterValue}
            >
              {variant === JitDropdownVariant.checkbox && <StyledCheckbox checked={isChecked} />}

              <ListItemText
                className='fw-bold me-3 ms-2'
                primary={filterName}
                primaryTypographyProps={{
                  fontSize: theme.typography.fontSize,
                  fontWeight: 500,
                }}
              />
            </StyledMenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  return isReadOnly ? (
    <JitReadOnlyTooltip>
      {dropdownElement}
    </JitReadOnlyTooltip>
  ) : dropdownElement;
};

