
import { createTheme } from '@mui/material';

import { baseTheme } from 'themes/baseTheme';

// @ts-ignore
// Todo: find a way to remove ts-ignore: https://mui.com/customization/theming/#custom-variables
export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: 'dark',
    primary: {
      main: '#485BFF',
      light: '#B8BEEF',
      dark: '#9AA3F4',
    },
    secondary: {
      main: '#5CCCC7',
      light: '#5CCCC7',
      dark: '#5CCCC7',
    },
    error: {
      main: '#CE4747',
      light: '#CE4747',
      dark: '#CE4747',
    },
    warning: {
      main: '#EE8355',
      light: '#EE8355',
      dark: '#EE8355',
    },
    info: {
      main: '#B8BEEF',
      light: '#B8BEEF',
      dark: '#B8BEEF',
    },
    success: {
      main: '#5CA47A',
      light: '#5CA47A',
      dark: '#5CA47A',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#838383',
      disabled: '#353535',
    },
    divider: '#555555',
    background: {
      default: '#1C1E23',
      paper: '#131313',
    },
  },
});
