import { UIEvent } from 'react';

export const calcShouldFetchMore = (event: UIEvent<HTMLDivElement>, scrollPositionThreshold: number): boolean => {
  // We want to fetch more data when we are close to the end of the table (start seeing the last row)
  const target = event.target as HTMLElement;
  if (!target) return false;

  const actualElementHeight = target.scrollHeight - target.clientHeight;
  const scrollPosition = target.scrollTop;
  // We want to fetch more data when we are close to the end of the table (start seeing the last row)
  const shouldFetchMore = actualElementHeight - scrollPosition < scrollPositionThreshold;
  return shouldFetchMore;
};
