import { DragEvent, FC, MutableRefObject, SyntheticEvent, useRef } from 'react';

import { FileInputAnalyticsEvents } from './constants';
import styles from './JitFileInput.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import colors from 'themes/colors.module.scss';

interface Props {
  inputRef: MutableRefObject<HTMLInputElement | null>;
  handleFileUpload: (files: FileList | null, e: SyntheticEvent<HTMLInputElement>) => void;
}

export const JitFileInputEmptyState: FC<Props> = ({ inputRef, handleFileUpload }) => {
  const dropRef = useRef(null);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const onFileDragOver = (e: DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onFileDrop = async (e: DragEvent<HTMLInputElement>) => {
    sendAnalyticsEvent({ action: FileInputAnalyticsEvents.FileDropped });
    await handleFileUpload(e.dataTransfer.files, e);
  };

  const onUploadClick = async () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current?.click();
  };

  return (
    <div
      ref={dropRef}
      className={styles.emptyStateWrapper}
      data-testid='file-input-empty-state'
      onDragOver={onFileDragOver}
      onDrop={onFileDrop}
    >

      <JitButton
        analytics={{ action: FileInputAnalyticsEvents.FileUploadClicked }}
        className={styles.uploadButton}
        data-testid='file-input-empty-state-upload-button'
        onClick={onUploadClick}
      >
        <JitText color={colors.iris} text='Click to upload' />
      </JitButton>

      <JitText
        color={colors.gray}
        data-testid='file-input-empty-state-drag-drop-text'
        text='or drag and drop'
      />

    </div>
  );
};
