import Paper from '@mui/material/Paper';
import { FC, useEffect, useState } from 'react';

import {
  FindingDetailsViewFindings,
  FindingDetailsViewPlan,
  FindingDetailViewDetailsContext,
  FindingDetailViewHeader,
} from './components';
import { FindingDetailsTickets } from './components/FindingDetailsTickets/FindingDetailsTickets';

import { IFinding } from 'types/interfaces';

interface Props {
  finding: IFinding;
  onClose: () => void;
  onSetIgnoredIndication: (findingToUpdate: IFinding, ignored: boolean) => void;
  isIgnoreRulesReadOnly?: boolean;
}

export const FindingDetails: FC<Props> = ({ finding, onClose, onSetIgnoredIndication, isIgnoreRulesReadOnly }) => {
  const [shouldDisablePopper, setShouldDisablePopper] = useState(false);

  const onScroll = () => {
    setShouldDisablePopper(true);
  };

  useEffect(() => {
    if (shouldDisablePopper) {
      setShouldDisablePopper(false);
    }
  }, [shouldDisablePopper]);

  return (
    <Paper
      onScroll={onScroll}
      style={{
        width: '100%',
        height: '100%',
        fontWeight: '0.5rem',
        border: 0,
      }}
      sx={{ overflow: 'auto' }}
      variant='outlined'
    >
      <div style={{
        margin: 16,
        padding: 16,
      }}
      >

        <FindingDetailViewHeader
          finding={finding}
          isIgnoreRulesReadOnly={isIgnoreRulesReadOnly}
          onClose={onClose}
          onSetIgnoredIndication={onSetIgnoredIndication}
          shouldDisablePopper={shouldDisablePopper}
        />

        <FindingDetailsViewPlan finding={finding} />

        <FindingDetailsViewFindings finding={finding} />

        <FindingDetailViewDetailsContext finding={finding} />

        <FindingDetailsTickets finding={finding} onClose={onClose} />

      </div>
    </Paper>
  );
};
