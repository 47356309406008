import { CSSProperties, FC } from 'react';

import { Empty } from 'assets';
import colors from 'themes/colors.module.scss';
import { IMouseEvent, ISvg } from 'types/interfaces';

/* eslint-disable jsx-a11y/no-noninteractive-tabindex  */

/* eslint-disable jsx-a11y/no-static-element-interactions  */

interface Props {
  icon?: FC<ISvg>;
  color?: string;
  strokeColor?: string;
  onClick?: (e: IMouseEvent) => void;
  onMouseEnter?: (e: IMouseEvent) => void;
  onMouseLeave?: (e: IMouseEvent) => void;
  className?: string;
  iconClassName?: string;
  size?: number | string;
  width?: number | string;
  height?: number | string;
  style?: CSSProperties;
  withBackSquare?: {
    width?: number | string;
    height?: number | string;
    padding?: number | string;
    backgroundColor?: string;
    borderColor?: string;
    className?: string;
    borderRadius?: number | string;
  };
  rotateDegrees?: number | string;
  centered?: boolean
  'data-testid'?: string
  'data-value'?: string
  'data-action'?: string
}

export const JitIcon: FC<Props> = ({
  icon: Icon = Empty,
  onClick,
  onMouseEnter,
  onMouseLeave,
  rotateDegrees = 0,
  iconClassName,
  color,
  strokeColor,
  size,
  width,
  height,
  style = {},
  className,
  withBackSquare,
  centered = true,
  ...props
}) => {
  const fill = color || colors.white;
  const defaultSize = 22;
  const IconComponent = (
    <Icon
      height={size || height || defaultSize}
      style={{
        fill,
        stroke: strokeColor,
        overflow: 'visible',
        transform: `rotate(${rotateDegrees}deg)`,
      }}
      width={size || width || defaultSize}
      {...(iconClassName && { className: iconClassName })}
      data-testid={props['data-testid'] || 'jitIcon'}
    />
  );

  if (!withBackSquare) {
    return (
      <div
        className={className}
        data-action={props['data-action']}
        data-value={props['data-value']}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        role={onClick ? 'button' : 'presentation'}
        style={{
          cursor: onClick ? 'pointer' : 'inherit',
          display: centered ? 'flex' : 'inherit',
          alignItems: centered ? 'center' : 'inherit',
          alignSelf: centered ? 'center' : 'inherit',
          ...style,
        }}
        tabIndex={onClick ? 0 : undefined}
      >
        {IconComponent}
      </div>
    );
  }

  const {
    width: backSquareWidth = 40,
    height: backSquareHeight = 40,
    borderRadius = 8,
    backgroundColor = colors.black,
    borderColor = colors.darkGray,
    padding = 6,
  } = withBackSquare;

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role={onClick ? 'button' : 'presentation'}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: backSquareWidth,
        height: backSquareHeight,
        borderRadius,
        backgroundColor,
        border: borderColor && `1px solid ${borderColor || colors.darkGray}`,
        padding,
        cursor: onClick ? 'pointer' : 'inherit',
        ...style,
      }}
      tabIndex={onClick ? 0 : undefined}
    >
      {IconComponent}
    </div>
  );
};
