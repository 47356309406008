import Divider from '@mui/material/Divider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PlanItemDetailsFindings } from './components/PlanItemDetailsFindings/PlanItemDetailsFindings';
import { PlanItemDetailsInfo } from './components/PlanItemDetailsInfo/PlanItemDetailsInfo';
import { PlanItemDetailsResults } from './components/PlanItemDetailsResults/PlanItemDetailsResults';
import { PlanItemDetailsSupportedLanguages } from './components/PlanItemDetailsSupportedLanguages/PlanItemDetailsSupportedLanguages';
import styles from './PlanItemDetailsBody.module.scss';

import { Cross } from 'assets';
import { ConfirmDialog } from 'components/JitDialogs/ConfirmDialog';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { usePlanInstanceContext } from 'context';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { useGetPlanItemConfigurationsStatus } from 'context/ConfigurationsContext/hooks';
import { useRemoveItemFromPlan } from 'context/PlanInstanceContext/planHooks';
import { useActivatePlanItem } from 'context/PlansContext/hooks/useActivatePlanItem';
import { useUpdatePlanItem } from 'context/PlansContext/hooks/useUpdatePlanItem';
import { i18n } from 'locale/i18n';
import { PLAN_ITEM_INTERNAL_WEBAPP_LINK } from 'pages/OverviewPage/constants';
import { isIntroItemSlug, ItemSlugOptions } from 'pages/PlanPage/components/PlanItemIntroDialog';
import colors from 'themes/colors.module.scss';
import commonStyles from 'themes/common.module.scss';
import {
  IMouseEvent,
  IPlanItemDetails,
  PlanItemAggregatedStatus,
} from 'types/interfaces';
import { ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';
import { stopPropagation } from 'utils';

interface Props {
  planSlug: string;
  planItem: IPlanItemDetails
  handleBack: () => void;
  handleConfigure: (planItem: IPlanItemDetails) => void;
  setIntroItem: Dispatch<SetStateAction<ItemSlugOptions | null>>;
}

export const PlanItemDetailsBody: FC<Props> = ({ planSlug, planItem, handleBack, handleConfigure, setIntroItem }) => {
  const { removeItemFromPlan } = useRemoveItemFromPlan(planSlug);
  const { commitCurrentPlan } = usePlanInstanceContext();
  const { updatePlanItem } = useUpdatePlanItem();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { isConfigurable } = useGetPlanItemConfigurationsStatus();
  const { activatePlanItem } = useActivatePlanItem();
  const { uiHidePlanItemStatusBadge } = useFlags();
  const navigate = useNavigate();

  const resetItemStatus = useCallback(() => {
    if (planItem) {
      updatePlanItem(planSlug, {
        ...planItem,
        status_properties: planItem.status_properties && {
          ...planItem.status_properties,
          status: PlanItemAggregatedStatus.PENDING,
        },
      });
    }
  }, [planItem, planSlug, updatePlanItem]);

  const isItemConfigurable = useMemo(() => isConfigurable(planItem), [isConfigurable, planItem]);
  const [isConfirmDeactivateOpen, setIsConfirmDeactivateOpen] = useState<boolean>(false);

  const isItemRedirecting = !!planItem.tags?.view_url;
  const handleRedirect = () => {
    const viewUrl = planItem.tags?.view_url;
    if (viewUrl) {
      const isJitPlatformRoute = viewUrl.startsWith(PLAN_ITEM_INTERNAL_WEBAPP_LINK);
      if (isJitPlatformRoute) {
        // Remove the 'JIT_WEBAPP' prefix from the viewUrl
        const internalPath = viewUrl.substring(PLAN_ITEM_INTERNAL_WEBAPP_LINK.length);
        navigate(internalPath);
      } else {
        window.open(viewUrl, '_blank');
      }
    }
  };

  const openDeactivateConfirmDialog = () => {
    setIsConfirmDeactivateOpen(true);
  };

  const onActivate = useCallback((e: IMouseEvent) => {
    activatePlanItem(planSlug, planItem);
    if (isIntroItemSlug(planItem.slug)) {
      setIntroItem(planItem.slug);
    }
    stopPropagation(e);
  }, [activatePlanItem, planItem, planSlug, setIntroItem]);

  const handleConfirmDeactivate = useCallback((hasConfirm: boolean) => {
    setIsConfirmDeactivateOpen(false);
    if (hasConfirm) {
      sendAnalyticsEvent({
        action: 'requirement-deactivated',
        params: { 'item-name': [planItem.name] },
      });
      const updatedPlan = removeItemFromPlan(planItem.slug);
      commitCurrentPlan(updatedPlan);
    }
  }, [commitCurrentPlan, planItem, removeItemFromPlan, sendAnalyticsEvent]);

  const confirmDeleteDialogProps = useMemo(() => ({
    title: `${i18n.t('dialogs.deactivatePlanItem.title')}`,
    message: `${i18n.t('dialogs.deactivatePlanItem.message')}`,
    subMessage: `${i18n.t('dialogs.deactivatePlanItem.subMessage', { planItemName: planItem.name })}`,
    actionButtons: [{
      label: `${i18n.t('dialogs.deactivatePlanItem.actions.no')}`,
      variant: ActionButtonVariant.SECONDARY,
      handleClick: () => handleConfirmDeactivate(false),
    }, {
      label: `${i18n.t('dialogs.deactivatePlanItem.actions.yes')}`,
      variant: ActionButtonVariant.DANGER,
      handleClick: () => handleConfirmDeactivate(true),
    }],
    onClose: () => handleConfirmDeactivate(false),
    isOpen: isConfirmDeactivateOpen,
  }), [planItem.name, isConfirmDeactivateOpen, handleConfirmDeactivate]);

  const findingsCount = planItem.status_properties?.findings_count;

  return (
    <div className={styles.body}>
      <ConfirmDialog {...confirmDeleteDialogProps} />

      <div
        style={{
          margin: 13,
          marginBottom: 0,
          display: 'flex',
        }}
      >

        <div className={styles.titleWrapper}>
          <JitText color={colors.lightGray} data-testid='planItemDetailsBodyTitle' size='s' text='pages.plan.itemDetails.title' />

          <div className={styles.titleRightPart}>
            <JitIcon icon={Cross} onClick={handleBack} size={20} />
          </div>
        </div>
      </div>

      <Divider />

      {/* Paper Content */}

      <div className={`${styles.content} ${commonStyles.scrollInnerShadow}`}>

        <div className={styles.bodySectionWrapper}>
          <PlanItemDetailsInfo
            isItemConfigurable={isItemConfigurable}
            isItemRedirecting={isItemRedirecting}
            isPlanItemActive={planItem.is_active}
            onActivate={onActivate}
            onConfigure={() => handleConfigure(planItem)}
            onRedirect={handleRedirect}
            openDeactivateConfirmDialog={openDeactivateConfirmDialog}
            planItem={planItem}
          />
        </div>

        {planItem.is_active && !uiHidePlanItemStatusBadge && (
          <div className={styles.bodySectionWrapper}>
            <PlanItemDetailsResults
              isScheduled={planItem.is_scheduled}
              itemSlug={planItem.slug}
              resetStatus={resetItemStatus}
              statusProperties={planItem.status_properties}
            />
          </div>
        )}

        {/* Findings Count */}

        {!!findingsCount && (
          <div className={styles.bodySectionWrapper}>
            <PlanItemDetailsFindings findingsCount={findingsCount} planItemSlug={planItem.slug} />
          </div>
        )}

        {/* Supported Languages */}

        {!!planItem?.controls?.length && (
          <div className={styles.bodySectionWrapper}>
            <PlanItemDetailsSupportedLanguages planItem={planItem} />
          </div>
        )}
      </div>
    </div>
  );
};
