import { FC, ReactNode } from 'react';

import styles from './GitHubCard.module.scss';

import { GithubIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';

interface Props {
  children: ReactNode;
}

export const GitHubCard: FC<Props> = ({ children }) => (
  <div className={styles.card} data-testid='GitHubCard'>
    <div className={styles.cardContent}>
      <JitIcon centered={false} icon={GithubIcon} size={35} />

      {children}

    </div>
  </div>
);

