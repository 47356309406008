import { useEffect, useMemo, useState } from 'react';

import { useFindingsContext } from 'context';
import { FILTER_BOX_HEIGHT } from 'pages/FindingsPage/constants';
import { MenuItemKeyType } from 'types/interfaces';

export const useManageFilterViewExtend = () => {
  const { filters, filtersVisibleInFirstLine } = useFindingsContext();

  const visibleFilters = useMemo(() => filters.filter((filter) => filter.isVisible), [filters]);
  const [isFilterViewExtended, setIsFilterViewExtended] = useState(false);
  const [initiallyOpenedFilterOption, setInitiallyOpenedFilterOption] = useState<MenuItemKeyType>('');
  const filterBoxHeight = useMemo(() => (isFilterViewExtended ? 'auto' : FILTER_BOX_HEIGHT), [isFilterViewExtended]);
  const shouldAllowExtendFilterView = useMemo(() => visibleFilters.length > filtersVisibleInFirstLine.length, [visibleFilters, filtersVisibleInFirstLine]);

  const handleExtendFilterView = () => {
    if (shouldAllowExtendFilterView) {
      setInitiallyOpenedFilterOption('');
      setIsFilterViewExtended(!isFilterViewExtended);
    }
  };

  useEffect(() => {
    if (visibleFilters.length <= filtersVisibleInFirstLine.length) {
      setIsFilterViewExtended(false);
    }
  }, [visibleFilters, filtersVisibleInFirstLine]);

  return {
    visibleFilters,
    setIsFilterViewExtended,
    filterBoxHeight,
    shouldAllowExtendFilterView,
    isFilterViewExtended,
    initiallyOpenedFilterOption,
    setInitiallyOpenedFilterOption,
    handleExtendFilterView,
  };
};
