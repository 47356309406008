import Paper, { PaperProps } from '@mui/material/Paper';
import { FC } from 'react';

import styles from './PaperTitle.module.scss';

interface Props extends PaperProps {
  template: JSX.Element;
  sticky?: boolean;
}

export const PaperTitle: FC<Props> = ({ template, sticky = false, children, ...props }) => (
  <Paper
    sx={{
      height: '100%',
      overflow: 'auto',
      width: '100%',
    }}
    variant='outlined'
    {...props}
  >
    <div className={`${styles.templateWrapper} ${sticky && styles.sticky}`}>
      {template}
    </div>

    {children}
  </Paper>
);
