import { Input } from '@mui/material';
import { t } from 'i18next';
import Markdown from 'markdown-to-jsx';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './PlanItemManualDialog.module.scss';

import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ActionButton, ActionButtonVariant, PlanItemStatus } from 'types/interfaces';
import { markdownLinksProps } from 'utils/constants/markdownLinksProps';

export const enum Status {
  PASSED = 'passed',
  FAILED = 'failed',
  NOT_APPLICABLE = 'not_applicable',
}

const MenuItems: PlanItemStatus[] = [Status.PASSED, Status.FAILED, Status.NOT_APPLICABLE];

interface Props {
  title: string;
  description: string;
  config: {
    status?: PlanItemStatus;
    note?: string;
  }
  isOpen: boolean;
  isApplyLoading: boolean;
  onClose: () => void;
  onConfigChange: (config: { status: string; note: string }) => void;
}

export const PlanItemManualDialog: FC<Props> = ({ title, description, isOpen, isApplyLoading, onClose, config, onConfigChange }) => {
  const { status: initialStatus, note: initialNote } = config;
  const isInitialStatusValid = useMemo(() => !!(initialStatus && MenuItems.includes(initialStatus)), [initialStatus]);
  const initialMenuStatus: PlanItemStatus = useMemo(() => (initialStatus && isInitialStatusValid ? initialStatus : Status.PASSED), [initialStatus, isInitialStatusValid]);
  const [status, setStatus] = useState<PlanItemStatus>(initialMenuStatus);
  const [note, setNote] = useState<string>(initialNote || '');
  const [isValid, setIsValid] = useState<boolean>(false);

  const menuItems: Partial<MenuItemType>[] = useMemo(() => MenuItems.map((itemKey: string) => ({
    itemKey,
    itemName: `pages.plan.configurationsDialog.manualItemsConfiguration.menuItemsNames.${itemKey}`,
    isSelected: status === itemKey,
  })), [status]);

  const menuDisplayText = useMemo(() => menuItems.find((item) => status === item.itemKey)?.itemName || '', [menuItems, status]);

  const validateInput = useCallback(() => {
    const hasInputFilled = !!status && note.length > 0;
    setIsValid(hasInputFilled);
  }, [note, status]);

  useEffect(() => {
    validateInput();
  }, [validateInput]);

  const onNoteChange = useCallback((e) => {
    setNote(e.target.value);
    validateInput();
  }, [setNote, validateInput]);

  const onStatusChange = useCallback((e) => {
    if (e.itemKey !== status) {
      setStatus(e.itemKey);
      validateInput();
    }
  }, [setStatus, status, validateInput]);

  const onApply = useCallback(async () => {
    if (isValid && (status !== initialStatus || note !== initialNote)) {
      await onConfigChange({
        status,
        note,
      });
    }
  }, [isValid, status, note, initialStatus, initialNote, onConfigChange]);

  const actionButtons: ActionButton[] = [
    {
      label: 'pages.plan.configurationsDialog.cancel',
      variant: ActionButtonVariant.OUTLINED,
      handleClick: onClose,
    },
    {
      label: 'pages.plan.configurationsDialog.apply',
      handleClick: onApply,
      isLoading: isApplyLoading,
      disabled: !isValid,
      tooltip: !isValid ? 'pages.plan.configurationsDialog.manualItemsConfiguration.applyDisabledTooltip' : undefined,
    },
  ];

  const content = (
    <div className={styles.configurationBoxWrapper}>
      <JitText bold color={colors.white} size='l' text={title} />

      <Markdown className={styles.description} {...markdownLinksProps}>
        {description}
      </Markdown>

      <div className={styles.inputSection}>
        <JitText color={colors.gray} size='l' text='pages.plan.configurationsDialog.manualItemsConfiguration.controlStatus' />

        <JitDropdownNew displayText={menuDisplayText} menuItems={menuItems} onMenuItemClick={onStatusChange} />
      </div>

      <div className={styles.inputSection}>
        <JitText color={colors.gray} size='l' text='pages.plan.configurationsDialog.manualItemsConfiguration.note' />

        <Input
          className={styles.inputField}
          data-testid='note-input'
          disableUnderline
          fullWidth
          margin='dense'
          maxRows={3}
          minRows={3}
          multiline
          name='inputField'
          onChange={onNoteChange}
          placeholder={t('pages.plan.configurationsDialog.manualItemsConfiguration.addFreeText')}
          sx={{
            padding: '10px 15px',
          }}
          value={note}
        />
      </div>
    </div>
  );

  return (
    <CustomDialog
      actionButtons={actionButtons}
      content={content}
      isOpen={isOpen}
      onClose={onClose}
      title='pages.plan.configurationsDialog.title'
      width='l'
      withDivider
    />
  );
};
