import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { FC } from 'react';

import { IntegrationDetailsExisting } from './IntegrationDetailsExisting/IntegrationDetailsExisting';

import { Plus } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { AwsIntegrationAccount } from 'types/interfaces';
import { iconsMap } from 'utils';

interface Props {
  integration: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  setIsEdit: (isEdit: boolean) => void;
  onClose: () => void;
}

export const IntegrationDetails: FC<Props> = ({ integration, setIsEdit, onClose }) => (
  <>
    <DialogContent sx={{
      p: '6px 0',
      overflow: 'auto',
    }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '1.5rem',
          marginLeft: '1.5rem',
        }}
      >
        <JitIcon icon={iconsMap.aws.icon} size={84} />

        <JitText className='d-inline-block' text='dialogs.integration.aws.details.subTitle.prefix' />

        <a
          href='https://console.aws.amazon.com/cloudformation/home#/stacks?filteringText=JitControl'
          rel='noreferrer'
          style={{
            marginRight: '0.25rem',
            marginLeft: '0.25rem',
          }}
          target='_blank'
        >
          <JitText className='d-inline-block' color={colors.blue02} text='dialogs.integration.aws.link' />
        </a>

        <JitText className='d-inline-block' text='dialogs.integration.aws.details.subTitle.suffix' />

        {integration?.map((account: AwsIntegrationAccount) => <IntegrationDetailsExisting key={account.account_id} account={account} />)}
      </div>
    </DialogContent>

    <DialogActions>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div
          className='cursor-pointer'
          onClick={() => setIsEdit(true)}
          role='button'
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          tabIndex={0}
        >
          <JitIcon
            color={colors.blue02}
            icon={Plus}
            size='0.7em'
            style={{
              marginRight: '0.5rem',
              marginLeft: '0.5rem',
            }}
          />

          <JitText
            color={colors.blue02}
            text='dialogs.integration.aws.details.addAccount'
          />
        </div>

        <JitButton
          onClick={onClose}
          style={{
            marginRight: '1.5rem',
            marginLeft: '1.5rem',
          }}
          variant='contained'
        >
          <JitText text='dialogs.integration.aws.done' />
        </JitButton>
      </div>

    </DialogActions>
  </>
);
