import { FC, useEffect, useMemo, useState } from 'react';

import styles from './HostingPlatformInput.module.scss';

import { ExpandArrow } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMenu, MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { GitHostingPlatform, MenuItemKeyType } from 'types/interfaces';

interface Props {
  setValue: (value: GitHostingPlatform) => void;
  initialValue?: GitHostingPlatform;
}

export const HostingPlatformInput: FC<Props> = ({ setValue, initialValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<MenuItemType>();

  const menuItems: MenuItemType[] = useMemo(() => (
    Object.entries(GitHostingPlatform).map(([key, value]) => ({
      itemKey: key,
      itemContent: <JitText color={selectedOption?.itemKey === key ? colors.white : colors.black} size='s' text={value} />,
      isSelected: selectedOption?.itemKey === key,
      itemName: value,
    }))
  ), [selectedOption]);

  useEffect(() => {
    // Find the menu item that matches the initial value, or default to the first menu item
    const initialSelectedItem = menuItems.find((item) => item.itemName === initialValue) || menuItems[0];

    setSelectedOption(initialSelectedItem);
    setValue(initialSelectedItem.itemName as GitHostingPlatform);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectItem = ({ itemKey }: { itemKey: MenuItemKeyType }) => {
    const selectedItem = menuItems.find((menuItem) => menuItem.itemKey === itemKey);
    if (!selectedItem) return;

    setSelectedOption(selectedItem);
    setValue(selectedItem.itemName as GitHostingPlatform);
  };

  return (
    <div className={styles.root}>
      <JitText bold color={colors.gray} text='pages.onboarding.formStep.hostingPlatformInput.label' />

      <JitMenu
        data-testid='hosting-platform-input'
        menuItems={menuItems}
        menuSelectedDesign='fillRow'
        onMenuChangeOpenState={(isMenuOpen: boolean) => { setIsOpen(isMenuOpen); }}
        onMenuItemClick={handleSelectItem}
        paperProps={{
          style: {
            width: 380,
            borderRadius: '0px 0px 8px 8px',
            border: `2px solid ${colors.jitPink}`,
            borderTop: `1px solid ${colors.jitPink}`,
            marginTop: -8, // in order to make them look like one component
          },
        }}
        shouldCloseOnItemClick
        variant='bright'
      >
        <div
          className={isOpen ? styles.openInput : styles.closedInput}
        >
          <JitText color={colors.black} data-testid='selected-hosting-platform' text={selectedOption?.itemName} />

          <JitIcon color={colors.black} data-testid='expandIcon' icon={ExpandArrow} size={10} />
        </div>
      </JitMenu>
    </div>
  );
};
