import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useWizardSteps } from './hooks/useWizardSteps';
import styles from './OnboardingWizard.module.scss';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { ActionButton } from 'types/interfaces';

export enum IOnboardingWizardStepStatus {
  NOT_STARTED, // the step is not started yet
  ACTIVE, // this is the active current step
  COMPLETE, // the step is complete
}

export enum IOnboardingWizardStepName {
  GOALS = 'goals',
  FORM = 'form',
}

export interface IOnboardingWizardStep {
  component: FC,
  status: IOnboardingWizardStepStatus,
  actionButtons?: ActionButton[],
}
interface Props {
  stepIndex: number;
  setStepIndex: (stepIndex: number) => void;
  setTotalStepsCount: (totalStepsCount: number) => void;
  handleClose: () => void;
}

// ts-prune-ignore-next
export const OnboardingWizard: FC<Props> = ({ stepIndex, setStepIndex, setTotalStepsCount, handleClose }) => {
  const incrementStepIndex = useCallback(() => setStepIndex(stepIndex + 1), [setStepIndex, stepIndex]);
  const decrementStepIndex = useCallback(() => setStepIndex(stepIndex - 1), [setStepIndex, stepIndex]);
  const steps = useWizardSteps(incrementStepIndex, decrementStepIndex, handleClose);
  useEffect(() => {
    setTotalStepsCount(steps.length);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [didSetInitialStepIndex, setDidSetInitialStepIndex] = useState(false);
  useEffect(() => {
    if (!didSetInitialStepIndex) {
      const currStepIndex = steps?.findIndex((step) => step.status === IOnboardingWizardStepStatus.ACTIVE);
      setStepIndex(currStepIndex);
      setDidSetInitialStepIndex(true);
    }
  }, [didSetInitialStepIndex, setStepIndex, steps]);
  const StepComponent = useMemo(() => (stepIndex < steps.length ? steps[stepIndex].component : () => null), [stepIndex, steps]);

  const formattedSteps = steps.map((step, index) => ({
    isSelected: stepIndex === index,
    isVerified: [IOnboardingWizardStepStatus.COMPLETE].includes(step.status),
    isIntro: index === 0,
    actionButtons: step?.actionButtons,
    content: <StepComponent />,
  }));
  const selectedStep = useMemo(() => formattedSteps.find((step) => step.isSelected), [formattedSteps]);

  return (
    <div className={styles.card}>

      {selectedStep?.content}

      <div className={styles.buttonsWrapper}>
        {selectedStep?.actionButtons?.map((actionButton, index) => (
        // eslint-disable-next-line react/no-array-index-key
          <JitActionButton key={`onboarding-button-${index}`} actionButton={actionButton} />
        ))}
      </div>

    </div>
  );
};
