import uniq from 'lodash/uniq';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { constants } from 'globalConstants';
import { logInfo } from 'services/logger/logger';
import { updateUser } from 'services/TenantService';
import { IFronteggUser } from 'types/interfaces';
import { hasRoles } from 'wrappers/RBAC/hooks/useIsAuthorized';

const { THIRD_PARTY_AUTHENTICATION } = constants;

const getThirdPartyOrigin = () => {
  const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
  let state: { origin?: string; isMarketplace?: string } = {};
  if (queryParams.state) {
    try {
      const decodedState = decodeURIComponent(queryParams.state);
      state = JSON.parse(decodedState);
    } catch (error) {
      console.error('Invalid state', queryParams, error);
    }
  }
  const origin = state?.origin;
  const thirdPartyOrigin = origin && THIRD_PARTY_AUTHENTICATION[origin as keyof typeof THIRD_PARTY_AUTHENTICATION];
  return {
    thirdPartyOrigin,
    isMarketplace: state?.isMarketplace,
  };
};

const isFronteggQueryParams = () => {
  const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
  try {
    const decodedState = decodeURIComponent(queryParams.state);
    const state = JSON.parse(decodedState);
    return queryParams.code && queryParams.state && ['login', 'signUp'].includes(state?.action);
  } catch (error) {
    return false;
  }
};

export const useHanldeRedirectIfNeeded = (frontEggUser: IFronteggUser) => {
  const navigate = useNavigate();
  const isAuthorized = hasRoles(frontEggUser.roles);

  const handleRedirectToThirdPartyApp = useCallback(async () => {
    const { id, accessToken, email, name, refreshToken, profilePictureUrl, tenantId } = frontEggUser;

    const redirectQueryParams = {
      accessToken,
      refreshToken,
      email,
      id,
      name,
      profilePictureUrl,
      tenantId,
    };
    const { thirdPartyOrigin, isMarketplace } = getThirdPartyOrigin();

    if (thirdPartyOrigin && !sessionStorage.getItem(constants.OPEN_THIRD_PARTY)) {
      sessionStorage.setItem(constants.OPEN_THIRD_PARTY, 'true');
      const url = new URL(thirdPartyOrigin.get_redirect_url(isMarketplace === 'true'));
      Object.entries(redirectQueryParams).forEach(([key, value]) => {
        if (value) {
          url.searchParams.append(key, value);
        }
      });
      window.open(url.href, '_self');
      const metadata = JSON.parse(frontEggUser.metadata || '{}');
      await updateUser({
        user_id: id,
        metadata: {
          ...metadata,
          origins: uniq([thirdPartyOrigin.name, ...(metadata.origins || [])]),
        } as Object,
      });
      window.location.href = `/${constants.routes.THIRD_PARTY_AUTHENTICATION}?origin=${thirdPartyOrigin.name}`;
    } else if (isFronteggQueryParams()) {
      // Remove frontegg query params from url
      window.history.pushState({}, '', window.location.href.split('?')[0]);
    }
  }, [frontEggUser]);

  const handleRedirectToInitialPath = useCallback(() => {
    const initialPathname = sessionStorage.getItem(constants.INITIAL_PATHNAME_KEY);
    if (initialPathname) {
      sessionStorage.removeItem(constants.INITIAL_PATHNAME_KEY);
      sessionStorage.setItem(constants.IS_REDIRECTED, 'true');
      navigate(initialPathname);
    }
  }, [navigate]);

  const handleRedirectIfNeeded = useCallback(async () => {
    if (!frontEggUser.accessToken) return;
    handleRedirectToInitialPath();
    await handleRedirectToThirdPartyApp();
  }, [frontEggUser.accessToken, handleRedirectToInitialPath, handleRedirectToThirdPartyApp]);

  useEffect(() => {
    if (isAuthorized) {
      handleRedirectIfNeeded();
    } else {
      logInfo('User is not authorized yet to redirect to third party origin, waiting for roles to be fetched');
    }
  }, [handleRedirectIfNeeded, isAuthorized]);
};
