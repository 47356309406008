import { FC } from 'react';

import {
  CustomAWSButton,
  SelectionList,
  SelectionListCustomAws,
  SelectionListCustomCloud,
  SelectionListCustomGithub,
  TitleLinkDetails,
} from 'components/JitDialogs/AssetsManagementDialog/components';
import { TABS } from 'components/JitDialogs/AssetsManagementDialog/constants';
import { config } from 'config';
import { useTenantContext } from 'context';
import { AssetType, Vendor } from 'types/enums';
import { IAsset } from 'types/interfaces';

interface TabDetails {
  wholeSelectionCheckboxText: string; // Will be passed to the <JitText /> component as the text prop

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  SelectionListComponent: FC<any>;
  titleLinkDetails?: TitleLinkDetails
  CustomLowerBarLeftComponent?: FC;

  shouldAppearInCurrentSelectedTab: (asset: IAsset) => boolean,
}

// This mapping should hold all the different data related to each tab as well as special
// data relevant to specific tabs.
export const useAssetsManagementTabDetailsByTab = (index: TABS): TabDetails => {
  const { githubInstallation } = useTenantContext();

  return {
    [TABS.GITHUB]: {
      wholeSelectionCheckboxText: `dialogs.assetsManagement.wholeSelectionText.${TABS.GITHUB}`,
      titleLinkDetails: {
        text: 'dialogs.assetsManagement.checkPermissions',
        url: githubInstallation?.vendor_attributes?.html_url || (githubInstallation ? config.getGithubAppPermissionsUrl(githubInstallation.owner, githubInstallation.installation_id) : ''),
      },
      SelectionListComponent: SelectionListCustomGithub,

      // In Github case, we don't want to show the organization (which are also assets) in the tab as assets
      shouldAppearInCurrentSelectedTab: (asset: IAsset) => asset.vendor === Vendor.Github && asset.asset_type === AssetType.REPO,
    },
    [TABS.AWS]: {
      wholeSelectionCheckboxText: `dialogs.assetsManagement.wholeSelectionText.${TABS.AWS}`,
      CustomLowerBarLeftComponent: CustomAWSButton,
      SelectionListComponent: SelectionListCustomAws,
      shouldAppearInCurrentSelectedTab: (asset: IAsset) => asset.vendor === Vendor.AWS,
    },
    [TABS.GCP]: {
      wholeSelectionCheckboxText: `dialogs.assetsManagement.wholeSelectionText.${TABS.GCP}`,
      SelectionListComponent: SelectionListCustomCloud,
      shouldAppearInCurrentSelectedTab: (asset: IAsset) => asset.vendor === Vendor.GCP,
    },
    [TABS.AZURE]: {
      wholeSelectionCheckboxText: `dialogs.assetsManagement.wholeSelectionText.${TABS.AZURE}`,
      SelectionListComponent: SelectionListCustomCloud,
      shouldAppearInCurrentSelectedTab: (asset: IAsset) => asset.vendor === Vendor.AZURE,
    },
    [TABS.WEB]: {
      wholeSelectionCheckboxText: `dialogs.assetsManagement.wholeSelectionText.${TABS.WEB}`,
      SelectionListComponent: SelectionList,
      shouldAppearInCurrentSelectedTab: (asset: IAsset) => asset.asset_type === AssetType.WEB,
    },
    [TABS.API]: {
      wholeSelectionCheckboxText: `dialogs.assetsManagement.wholeSelectionText.${TABS.API}`,
      SelectionListComponent: SelectionList,
      shouldAppearInCurrentSelectedTab: (asset: IAsset) => asset.asset_type === AssetType.API,
    },
  }[index];
};
