import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { FC, ReactElement, useMemo, useRef } from 'react';

import brightStyles from './JitPopperBrightVariant.module.scss';
import darkStyles from './JitPopperDarkVariant.module.scss';

import colors from 'themes/colors.module.scss';

const FADE_DURATION = 350;

export type JitPopperVariants = 'dark' | 'bright';

type PossibleArrowPlacements =
  'leftStart'
  | 'left'
  | 'leftEnd'
  | 'rightStart'
  | 'right'
  | 'rightEnd'
  | 'topStart'
  | 'top'
  | 'topEnd'
  | 'bottomStart'
  | 'bottom'
  | 'bottomEnd';

interface Props {
  content: ReactElement;
  className?: string;
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
  isWithArrow?: boolean
  placement?: PopperPlacementType;
  arrowPlacement?: PossibleArrowPlacements
  transition?: boolean;
  shadowVariant?: 'light' | 'dark' | 'none';
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  variant?: JitPopperVariants;
}

const defaultBrightVariantPaperStyle = {
  backgroundColor: colors.white,
  borderRadius: 8,
};

export const JitPopper: FC<Props> = ({
  content, children, open, onClose = () => {}, className = '', isWithArrow = true, placement = 'right', arrowPlacement = 'top', transition = true,
  shadowVariant = 'light', onMouseEnter, onMouseLeave, variant = 'dark',
}) => {
  const anchorElementRef = useRef<HTMLDivElement>(null);

  const styles = useMemo(() => {
    if (variant === 'bright') {
      return brightStyles;
    }

    return darkStyles;
  }, [variant]);

  const defaultVirtualElement = {
    getBoundingClientRect: () => ({
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: 0,
      height: 0,
    }),
  };
  const boxShadowStyles = {
    light: styles.lightShadow,
    dark: styles.darkShadow,
    none: '',
  };

  const paperStyle = variant === 'bright' ? defaultBrightVariantPaperStyle : {};

  return (

    <div>
      <div
        ref={anchorElementRef}
      >
        {children}
      </div>

      <Popper
        anchorEl={anchorElementRef.current || defaultVirtualElement as HTMLDivElement}
        className={className}
        disablePortal
        modifiers={[{
          name: 'preventOverflow',
          enabled: true,
          options: {
            boundariesElement: 'window',
            padding: 40,
          },
        }]}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        open={open}
        placement={placement}
        style={{ zIndex: 1000,
          padding: 12 }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={transition ? FADE_DURATION : 0}>

            <Paper sx={paperStyle}>
              <ClickAwayListener onClickAway={onClose}>
                <Paper className={styles.popoverRoot} sx={paperStyle}>
                  {isWithArrow && (
                  <>
                    <span
                      className={`${styles.pic} ${styles[arrowPlacement]}`}
                    />

                    <span className={`${styles.pic} ${styles[`${arrowPlacement}Border`]}`} />
                  </>
                  )}

                  <Box className={`${styles.popoverPadding} ${boxShadowStyles[shadowVariant]}`}>{content}</Box>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};
