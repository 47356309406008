import { FC, useCallback } from 'react';

import { JitText } from 'components/JitText/JitText';

interface Props {
  titles: (string | undefined)[];
  content: (string | JSX.Element | undefined)[];
  gridTemplateColumnsStyle: string;
}

export const FindingDetailViewGrid: FC<Props> = ({ titles, content, gridTemplateColumnsStyle }) => {
  const TextsList = useCallback(({ textOrComponents, muted }) => (
    <>
      {textOrComponents.map((textOrComponent: string | JSX.Element) => {
        const safeTextOrComponent = textOrComponent || 'N/A';
        if (typeof safeTextOrComponent === 'string') {
          return <JitText key={safeTextOrComponent} muted={muted} text={safeTextOrComponent} />;
        }
        return textOrComponent;
      })}
    </>
  ), []);

  const itemList = titles.map((title, index) => (
    <div key={title}>
      <TextsList muted textOrComponents={[title]} />

      <TextsList textOrComponents={[content[index]]} />
    </div>
  ));

  return (
    <div style={{
      marginTop: 10,
      display: 'grid',
      gridTemplateColumns: gridTemplateColumnsStyle,
      gridRowGap: 16,
      gridColumnGap: 16,
      wordBreak: 'break-word',
    }}
    >
      {itemList}
    </div>
  );
};
