import { config } from 'config';

const servicesNames = {
  jiraService: 'jira',
  linearService: 'linear',
  shortcutService: 'shortcut',
  secretsService: 'secrets',
  tenantService: 'tenant',
  planService: 'plans',
  docsService: 'docs',
  metricService: 'metric',
  assetService: 'asset',
  executionsService: 'execution',
  actionService: 'action',
  analyticsService: 'analytics',
  pipelineService: 'pipeline',
  sbomService: 'sbom',
  artifactsService: 'artifacts',
};

export const getApiUrls = {
  jiraService: {
    createTicket: () => `${servicesNames.jiraService}/ticket`,
  },
  linearService: {
    createTicket: () => `${servicesNames.linearService}/ticket`,
  },
  shortcutService: {
    createTicket: () => `${servicesNames.shortcutService}/ticket`,
  },
  docsService: {
    answer: () => config.docsQaUrl,
  },
  secretsService: {
    getTenantSecrets: () => `${servicesNames.secretsService}/`,
    saveTenantSecret: (secretName: string) => `${servicesNames.secretsService}/${secretName}`,
    deleteTenantSecret: (secretName: string) => `${servicesNames.secretsService}/${secretName}`,
  },
  tenantService: {
    getAllInstallations: () => `${servicesNames.tenantService}/installations`,
    fetchIntegrations: () => `${servicesNames.tenantService}/integrations`,
    patchPreferences: (preferenceType: string) => `${servicesNames.tenantService}/preferences/${preferenceType}`,
    fetchPreferences: () => `${servicesNames.tenantService}/preferences`,
    getTenantUsers: () => `${servicesNames.tenantService}/users`,
    createUserVendor: () => `${servicesNames.tenantService}/user/uservendor`,
    getUserVendorByVendor: (vendor: string) => `${servicesNames.tenantService}/user/uservendor/vendor/${vendor}`,
    updateUser: () => `${servicesNames.tenantService}/user/update`,
    signUp: () => `${servicesNames.tenantService}/sign-up`,
    setCentralizedRepo: (vendor: string, appId: string, installationId: string) => `${servicesNames.tenantService}/vendor/${vendor}/app_id/${appId}/installation/${installationId}/centralized-repo`,
    validateCentralizedRepo: (vendor: string, appId: string, installationId: string) => (
      `${servicesNames.tenantService}/vendor/${vendor}/app_id/${appId}/installation/${installationId}/centralized-repo/validate`
    ),
    partialUpdateOnboardingDetails: () => `${servicesNames.tenantService}/onboarding`,
  },
  planService: {
    getPlans: () => `${servicesNames.planService}`,
    getPlansOld: () => `${servicesNames.planService}`,
    getPlanContent: () => `${servicesNames.planService}/jit-plan/content`,
    partialUpdatePlan: (slug: string) => `${servicesNames.planService}/${slug}`,
    getPlanDetails: (slug: string) => `${servicesNames.planService}/${slug}`,
    getPlanItems: (slug: string) => `${servicesNames.planService}/${slug}/items`,
    generatePlanReport: (slug: string) => `${servicesNames.planService}/${slug}/report`,
    rerunPlanItem: (planSlug: string, itemSlug: string) => `${servicesNames.planService}/${planSlug}/items/${itemSlug}/rerun`,
    updateConfigurations: () => `${servicesNames.planService}/configuration-file`,
    getAllFilesMetadata: () => `${servicesNames.planService}/files`,
    getIntegrationFile: () => `${servicesNames.planService}/integration-file`,
    uploadFileToCentralizedRepo: () => `${servicesNames.planService}/files`,
    getPlanStatusesForAsset: () => `${servicesNames.planService}/jit_plan/statuses`,
    getAllTemplateWorkflows: () => `${servicesNames.planService}/template/workflows`,
    getConfigurations: () => `${servicesNames.planService}/configuration-file`,
    getAllPlanTemplates: () => `${servicesNames.planService}/template/plans`,
    getAllPlanItemTemplates: () => `${servicesNames.planService}/template/items`,
    commitPlan: (vendor: string) => `${servicesNames.planService}/vendor/${vendor}/plan/jit-plan`,
    createAwsAccount: () => `${servicesNames.planService}/aws/create`, // Assuming no dynamic parameters in URL
    reloadAwsAccounts: () => `${servicesNames.planService}/aws/reload`, // Assuming no dynamic parameters in URL

  },
  metricService: {
    patchDevEfficiencyCalculation: () => `${servicesNames.metricService}/statistics/dev-efficiency-calculation`,
    getSnapshot: (snapshotId: string) => `${servicesNames.metricService}/snapshot/${snapshotId}`,
    getSnapshots: () => `${servicesNames.metricService}/snapshot`,
    deleteSnapshot: (snapshotId: string) => `${servicesNames.metricService}/snapshot/${snapshotId}`,
  },
  assetService: {
    getAssets: () => `${servicesNames.assetService}`,
    updateMultipleAssets: () => `${servicesNames.assetService}/assets`,
  },
  executionsService: {
    getExecutionArtifacts: (jitEventId: string, executionId: string) => `${servicesNames.executionsService}/${jitEventId}/executions/${executionId}/zipball/artifacts`,
    fetchExecutions: () => `${servicesNames.executionsService}/`,
    getExecutionLog: (jitEventId: string, executionId: string) => `${servicesNames.executionsService}/${jitEventId}/${executionId}/log`,
  },
  actionService: {
    fetchActions: (queryParams: string) => {
      if (queryParams) {
        return `${servicesNames.actionService}/actions/v2?${queryParams}`;
      }
      return `${servicesNames.actionService}/actions/v2`;
    },
    fetchActionsFiltersDistinctValues: () => `${servicesNames.actionService}/filters-values`,
    fetchActionFindings: (actionId: string) => `${servicesNames.actionService}/actions/${actionId}/findings`,
    openPRForActionFindings: () => `${servicesNames.actionService}/fix-prs`,
    createActionFindingsIgnoreRules: () => `${servicesNames.actionService}/ignore-findings`,
    fetchActionsCount: (incompletedStatuses: string, queryParams: string) => {
      if (queryParams) {
        return `${servicesNames.actionService}/count?${incompletedStatuses}&${queryParams}`;
      }
      return `${servicesNames.actionService}/count?${incompletedStatuses}`;
    },
  },
  analyticsService: {
    identifyUser: `${servicesNames.analyticsService}/contact`,
  },
  pipelineService: {
    getNewPipelines: () => `${servicesNames.pipelineService}/pipelines`,
    getPipelineById: (pipelineId: string) => `${servicesNames.pipelineService}/pipeline/${pipelineId}`,
    getDeploymentsFromCreatedAfter: (environment: string) => `${servicesNames.pipelineService}/pipelines/deployments/environment/${environment}`,
  },
  sbomService: {
    getSbomReportDownload: () => `${servicesNames.sbomService}/`,
    getSbomComponents: () => `${servicesNames.sbomService}/component-groups`,
    getSbomReportStatus: () => `${servicesNames.sbomService}/status`,
  },
  artifactsService: {
    getArtifacts: () => `${servicesNames.artifactsService}/`,
    downloadArtifact: (artifactId: string) => `${servicesNames.artifactsService}/${artifactId}/export`,
  },
};
