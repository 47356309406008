import { FC } from 'react';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { useConfigurationsContext, useDialogsContext } from 'context';
import { useGetPlanItemVendors } from 'context/PlansContext/hooks/useGetPlanItemVendors';
import colors from 'themes/colors.module.scss';
import { Dialogs, Vendor } from 'types/enums';
import { IMouseEvent, IPlanItemDetails } from 'types/interfaces';
import { iconsMap, stopPropagation } from 'utils';
import { getVendorForAssetType } from 'utils/functions/matchVendorToAssetType';

interface Props {
  planItem: IPlanItemDetails;
}

export const PlanItemsDetailsSummaryLabelMissingIntegration: FC<Props> = ({ planItem }) => {
  const { getPlanItemVendors } = useGetPlanItemVendors();
  const { configurations } = useConfigurationsContext();
  const { showDialog } = useDialogsContext();

  const onLinkClick = (e: IMouseEvent) => {
    stopPropagation(e);
    const assetType = planItem.asset_types?.[0]; // We assume here that each plan item has only one asset type
    const vendor = getVendorForAssetType(assetType!);

    // TODO: Remove when we support dialogs for all cloud providers
    if (vendor === Vendor.GCP || vendor === Vendor.AZURE) {
      return;
    }

    showDialog({
      type: Dialogs.Integrations,
      props: {
        vendor: getVendorForAssetType(assetType!),
        integration: configurations[vendor as string],
      },
    });
  };
  const planItemVendorIntegrations = getPlanItemVendors(planItem);

  return (
    <>
      <JitIcon size={15} {...iconsMap.configuration} />

      <JitLink onClick={onLinkClick}>
        <JitText color={colors.lightGray} params={{ planItemVendorIntegrations }} text='pages.plan.planItem.config.integrationRequired' />
      </JitLink>
    </>
  );
};
