import { FC } from 'react';

import { VectorStroke } from 'assets';
import { JitBreadcrumbs } from 'components/JitBreadcrumbs/JitBreadcrumbs';
import { renderLinkBreadCrumbItem, renderTextBreadCrumbItem } from 'components/JitBreadcrumbs/utils/textToBreadcrumbContent';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { constants } from 'globalConstants';
import styles from 'pages/TeamsPage/SubPages/TeamDetailsPage/TeamDetailsPage.module.scss';
import colors from 'themes/colors.module.scss';

interface TeamsBreadcrumbsProps {
  teamId?: string;
  teamName?: string;
  assetName?: string;
}

export const TeamsBreadcrumbs: FC<TeamsBreadcrumbsProps> = ({ teamId, teamName, assetName }) => {
  let teamDetailsContent = renderTextBreadCrumbItem('pages.teams.teamDetails.notFound.breadcrumbs', 'TeamDetailsPageBreadcrumb-NotFound');
  if (teamName) {
    teamDetailsContent = renderTextBreadCrumbItem(teamName, 'TeamDetailsPageBreadcrumb');
    if (assetName) {
      teamDetailsContent = renderLinkBreadCrumbItem(teamName, `/${constants.routes.TEAMS}/${teamId}`, 'TeamDetailsPageBreadcrumb');
    }
  }

  const items = [
    {
      content: renderLinkBreadCrumbItem(
        'pages.teams.title',
        `/${constants.routes.TEAMS}`,
        'teamsPageBreadcrumb',
      ),
      itemKey: 'teamsPageBreadcrumb',
    },
    {
      content: teamDetailsContent,
      itemKey: 'TeamDetailsPageBreadcrumb',
    },
  ];

  if (assetName) {
    items.push({
      content: renderTextBreadCrumbItem(assetName, 'AssetDetailsPageBreadcrumb'),
      itemKey: 'AssetDetailsPageBreadcrumb',
    });
  }
  return (
    <JitBreadcrumbs
      items={items}
      separator={<JitIcon color={colors.basicLight} icon={VectorStroke} iconClassName={styles.breadcrumbsSeparator} size='10px' />}
    />
  );
};
