import { FC } from 'react';

import { NavButton, NavButtonGroup } from '..';

import { ITabItem, ITabGroup, Tab, TabItemTypes } from 'components/Navbar/hooks';
import styles from 'components/Navbar/Navbar.module.scss';

interface Props {
  isCollapsed: boolean,
  items: Tab[],
  onClick?: (tabItem: ITabItem) => void,
  selectedTabName?: string,
}

export const NavbarItems: FC<Props> = ({ isCollapsed, items, onClick, selectedTabName }) => {
  const renderPageTabsItem = (tabItem: ITabItem) => (
    <NavButton
      key={tabItem.name}
      icon={tabItem.icon}
      isCollapsed={isCollapsed}
      isNew={tabItem.isNew}
      isSelected={tabItem.name === selectedTabName}
      onClick={() => { onClick?.(tabItem); }}
      rightSideIcon={tabItem.rightSideIcon}
      text={tabItem.name}
    />

  );
  const renderTabGroup = (tabGroup: ITabGroup) => (
    <NavButtonGroup
      key={tabGroup.name}
      isCollapsed={isCollapsed}
      onTabItemClick={(tabItem) => { onClick?.(tabItem); }}
      selectedTabName={selectedTabName}
      tabGroup={tabGroup}
    />
  );

  return (
    <div className={styles.scrollableContentGroup}>
      {items.map((tab) => {
        if (tab.type === TabItemTypes.item) return renderPageTabsItem(tab);
        if (tab.type === TabItemTypes.group) return renderTabGroup(tab);
        return null; // We should never get here
      })}
    </div>
  );
};
