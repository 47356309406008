
import { i18n } from 'locale/i18n';
import { IPlanItemDetails } from 'types/interfaces';
import { getVendorForAssetType } from 'utils/functions/matchVendorToAssetType';

export const useGetPlanItemVendors = () => {
  const { t } = i18n;

  const getPlanItemVendors = (planItem: IPlanItemDetails): string => planItem.asset_types?.map((assetType) => {
    const vendor = getVendorForAssetType(assetType);
    return t(`vendor.${vendor || ''}`, { defaultValue: '' });
  }).join(', ') || '';

  return { getPlanItemVendors };
};
