import { isEmpty } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useLocation, useNavigate } from 'react-router-dom';

import { FindingDetails, FindingsFilters, FindingsSelectedFindings } from './components';
import { ExportFindingsToCsv } from './components/ExportFindingsToCsv/exportFindingsToCsv';
import { SavedFiltersDropdown } from './components/SavedFiltersDropdown/SavedFiltersDropdown';
import styles from './FindingsPage.module.scss';
import { useGetTableColumns } from './hooks/useGetTableColumns';

import { IgnoreFindingsDialog } from 'components/IgnoreFindingsDialog/IgnoreFindingsDialog';
import { useManagePagination } from 'components/JitTable/hooks/useManagePagination';
import { useManageTable } from 'components/JitTable/hooks/useManageTable';
import { JitTable } from 'components/JitTable/JitTable';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { useAuthContext, useFindingsContext, useTenantContext } from 'context';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useInitFilters } from 'context/FindingsContext/hooks/useInitFilters';
import { EmptyFindingsTable } from 'pages/FindingsPage/components/EmptyFindingTable/EmptyFindingsTable';
import { FILTERS_MAX_SIZE_DEFAULT_SIZE, MAX_FINDINGS_IN_PAGE, REDUCED_WIDTH_FROM_FILTERS_LINE } from 'pages/FindingsPage/constants';
import { useResetPageOnFilterSortChange } from 'pages/FindingsPage/hooks/useResetPageOnFiltersChange';
import { useSelectedFindings } from 'pages/FindingsPage/hooks/useSelectedFindings';
import { useUpdateFindingsStatus } from 'pages/FindingsPage/hooks/useUpdateFindingsStatus';
import { IFinding } from 'types/interfaces';
import { TableColumn } from 'types/types';
import { parseObjectToQueryParams, useQueryParams, useUpdateEffect } from 'utils';
import { PERMISSIONS } from 'wrappers/RBAC';

export const FindingsPage: FC = () => {
  const [selectedRow, setSelectedRow] = useState<IFinding | null>(null);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { isGithubIntegrated } = useTenantContext();

  const { queryParams } = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();

  useInitFilters();

  const {
    findings,
    isLoadingFilters,
    currentSortColumn,
    setCurrentSortColumn,
    getFindings,
    totalFindingsAmount,
  } = useFindingsContext();

  const { hasPermission } = useAuthContext();
  const isIgnoreRulesReadOnly = !hasPermission(PERMISSIONS.WRITE_IGNORE_RULES);
  const isFindingReadOnly = !hasPermission(PERMISSIONS.WRITE_FINDINGS);

  useEffect(() => {
    setSelectedRow(null);
  }, [findings]);

  const updateSelectedRow = useCallback((row: IFinding | null) => {
    setSelectedRow(row);
    const params = Object.fromEntries(new URLSearchParams(location.search));
    delete params.id;
    const appliedFiltersAsQueryParams = parseObjectToQueryParams(params);
    const currentParams = appliedFiltersAsQueryParams ? `?${appliedFiltersAsQueryParams}` : '';
    if (row) {
      const idParamSeperator = currentParams ? '&' : '?';
      navigate(`${location.pathname}${currentParams}${idParamSeperator}id=${row.id}`);
    } else {
      navigate(`${location.pathname}${currentParams}`);
    }
  }, [location, navigate]);

  const onSelectRow = useCallback((row: IFinding) => {
    const isCurrentSelectedRow = selectedRow && selectedRow.id === row?.id;
    updateSelectedRow(isCurrentSelectedRow ? null : row);
    if (!isCurrentSelectedRow) {
      sendAnalyticsEvent({
        action: 'finding-clicked',
        params: {
          finding_name: row.name,
          finding_severity: row.severity,
          security_tool: row.securityControl,
          asset_type: row.assetType,
        },
      });
    }
  }, [updateSelectedRow, selectedRow, sendAnalyticsEvent]);

  const { width, ref } = useResizeDetector();
  const filtersMaxSize = width && width - REDUCED_WIDTH_FROM_FILTERS_LINE;

  const { selectedFindingIds, isPageSelected, setPageSelected, findingsWithSelectedProperty, resetSelectedFindings } = useSelectedFindings(findings);

  const fetchFindings = useCallback((pageKey: string | undefined) => {
    setSelectedRow(null);
    return getFindings(pageKey);
  }, [getFindings]);

  const paginationManager = useManagePagination({
    data: findings as never[],
    fetchData: fetchFindings,
  });

  const {
    updateMultipleFindingsStatus, updateFindingStatus, findingsToIgnore, setFindingsToIgnore, completeIgnoreFindings, isIgnoreLoading,
  } = useUpdateFindingsStatus(paginationManager.reFetchCurrentPage);

  const onSetIsIgnoredToSelectedRows = useCallback(async (isIgnored: boolean) => {
    await updateMultipleFindingsStatus(selectedFindingIds, isIgnored);
    const shouldUpdateSelectedRow = selectedFindingIds.includes(selectedRow?.id || '');
    if (shouldUpdateSelectedRow) {
      const updatedSelectedRow = findings.find((finding: IFinding) => finding.id === selectedRow?.id) || null;
      updateSelectedRow(updatedSelectedRow);
    }
  }, [updateMultipleFindingsStatus, selectedFindingIds, selectedRow?.id, findings, updateSelectedRow]);

  const onSetIgnoredToSingleRow = useCallback(async (findingToUpdate: IFinding, selectedIsIgnored: boolean) => {
    await updateFindingStatus(findingToUpdate, selectedIsIgnored);
    const shouldUpdateSelectedRow = findingToUpdate.id === selectedRow?.id;
    if (shouldUpdateSelectedRow) {
      const updatedSelectedRow = findings.find((finding: IFinding) => finding.id === selectedRow?.id) || null;
      updateSelectedRow(updatedSelectedRow);
    }
  }, [updateFindingStatus, selectedRow?.id, findings, updateSelectedRow]);

  const isFindingDetailsTabOpen = !!selectedRow;

  const { columns } = useGetTableColumns({
    currentSortColumn,
    setCurrentSortColumn,
    isPageSelected,
    setPageSelected,
    isFindingDetailsTabOpen,
    updateFindingIgnoredState: onSetIgnoredToSingleRow,
    isIgnoreRulesReadOnly,
  });

  const tableInstance = useManageTable({
    ...paginationManager,
    columns: columns as TableColumn<object>[],
    data: findingsWithSelectedProperty,
  });

  useResetPageOnFilterSortChange(paginationManager.resetPagination);

  const trySelectQueryParamFinding = useCallback(() => {
    if ('id' in queryParams) {
      const queryFinding = findings.find((finding) => finding.id === queryParams.id);
      updateSelectedRow(queryFinding || null);
    }
  }, [updateSelectedRow, queryParams, findings]);

  useUpdateEffect(() => {
    trySelectQueryParamFinding();
  }, [findings]);

  return (
    <div ref={ref} className={styles.findingsPage}>
      <div className={styles.topRow}>
        <PageTitles subtitle='pages.findings.subtitle' title='pages.findings.title' />

        <div className={styles.topRowRightSide}>
          <ExportFindingsToCsv currentSortColumn={currentSortColumn} />
        </div>
      </div>

      <div className={styles.filtersTopRow}>
        <div className={styles.filtersWrapper}>
          <FindingsFilters filtersMaxSize={filtersMaxSize || FILTERS_MAX_SIZE_DEFAULT_SIZE} isFindingReadOnly={isFindingReadOnly} />
        </div>

        <SavedFiltersDropdown />
      </div>

      <div className={styles.container}>
        <div
          className={`${styles.widthTransition} ${styles.findings} ${selectedRow ? styles.findingsTableWithSelectedRow : styles.findingsTable}`}
        >
          <JitTable
            className={styles.tableWrapper}
            emptyTableView={<EmptyFindingsTable />}
            isLoading={isGithubIntegrated && (paginationManager.isLoading || isLoadingFilters)}
            onPageChange={() => setSelectedRow(null)} // TODO: Think about it
            onSelectRow={onSelectRow}
            pageMaxLength={MAX_FINDINGS_IN_PAGE}
            selectedRow={selectedRow}
            tableInstance={tableInstance}
            totalRecordsAmount={totalFindingsAmount || undefined}
          />
        </div>

        <div className={`${styles.widthTransition} ${styles.details} ${selectedRow ? styles.detailsWithSelectedRow : ''}`}>
          {isFindingDetailsTabOpen
            && (
              <FindingDetails
                finding={selectedRow!}
                isIgnoreRulesReadOnly={isIgnoreRulesReadOnly}
                onClose={() => setSelectedRow(null) /* TODO: Think about it */}
                onSetIgnoredIndication={onSetIgnoredToSingleRow}
              />
            )}
        </div>
      </div>

      {
        !isEmpty(selectedFindingIds) && !paginationManager.isLoading && (
          <FindingsSelectedFindings
            findings={findings}
            isIgnoreRulesReadOnly={isIgnoreRulesReadOnly}
            isRowOpen={!!selectedRow}
            onSetIsIgnored={onSetIsIgnoredToSelectedRows}
            resetSelectedFindings={resetSelectedFindings}
            selectedFindingIds={selectedFindingIds}
            selectedFindingsAmount={selectedFindingIds.length}
          />
        )
      }

      <IgnoreFindingsDialog
        ignoreFunction={completeIgnoreFindings}
        isIgnoredLoading={isIgnoreLoading}
        onClose={() => setFindingsToIgnore([])}
        open={!isEmpty(findingsToIgnore)}
      />
    </div>
  );
};
