import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useHandlePreferencesUpdateWebSocketNotification } from './tenantHooks/useHandlePreferencesUpdateWebSocketNotification';
import { useHandleAWSVersionError } from './tenantHooks/useHandleVersionError';

import { TenantContext } from 'context';
import { useListenToInstallationsWebsocket } from 'context/TenantContext/tenantHooks/useListenToInstallationsWebsocket';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { constants } from 'globalConstants';
import { useTenantService } from 'services/TenantService/useTenantService';
import { Vendor, WebSocketNotificationTopics } from 'types/enums';
import { IIntegration, IPreferences } from 'types/interfaces';

export const TenantProvider: FC = ({ children }) => {
  const [preferences, setPreferences] = useState<IPreferences>();
  const [isPreferencesLoading, setIsPreferencesLoading] = useState<boolean>(false);
  const [integrations, setIntegrations] = useState<IIntegration[]>([]);
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { installations, isLoadingInstallation, hasTriedFetchInstallations } = useListenToInstallationsWebsocket();
  const { handlePreferencesUpdateWebSocketNotification } = useHandlePreferencesUpdateWebSocketNotification({ setPreferences });
  const { fetchIntegrations, fetchPreferences } = useTenantService();

  const hasUserPreferencesInitialized = useMemo(() => !!preferences && !!preferences.display, [preferences]);

  const awsInstallation = useMemo(() => installations?.find((installation) => installation.vendor === Vendor.AWS), [installations]);
  const { handleAWSVersionError } = useHandleAWSVersionError(awsInstallation);

  const fetchMergedPreferences = useCallback(async () => {
    setIsPreferencesLoading(true);
    const res = await fetchPreferences();
    setPreferences(res?.data);
    setIsPreferencesLoading(false);
  }, [fetchPreferences]);

  const initPreferences = useCallback(async () => {
    if (!preferences && !isPreferencesLoading) {
      fetchMergedPreferences();
    }
  }, [fetchMergedPreferences, isPreferencesLoading, preferences]);

  const initUserPreferences = useCallback(async () => {
    if (!hasUserPreferencesInitialized && !isPreferencesLoading) {
      fetchMergedPreferences();
    }
  }, [fetchMergedPreferences, hasUserPreferencesInitialized, isPreferencesLoading]);

  const initIntegrations = useCallback(async () => {
    const res = await fetchIntegrations();
    setIntegrations(res?.data || []);
  }, [fetchIntegrations]);

  const getIntegrations = useCallback(() => {
    if (!integrations.length) {
      initIntegrations();
    }
    return integrations;
  }, [integrations, initIntegrations]);

  useEffect(() => {
    handleAWSVersionError();
  }, [handleAWSVersionError]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Preferences, handlePreferencesUpdateWebSocketNotification);
  }, [handlePreferencesUpdateWebSocketNotification, websocketSubscribe]);

  const githubInstallation = useMemo(() => installations.find((installation) => installation.vendor === constants.GITHUB), [installations]);

  const isGithubIntegrated = useMemo(() => !!githubInstallation?.centralized_repo_asset_id, [githubInstallation]);

  const value = useMemo(() => ({
    installations,
    githubInstallation,
    hasTriedFetchInstallations,
    preferences,
    setPreferences,
    isPreferencesLoading,
    setIsPreferencesLoading,
    initPreferences,
    isLoadingInstallation,
    getIntegrations,
    initUserPreferences,
    isGithubIntegrated,
  }), [
    installations,
    githubInstallation,
    hasTriedFetchInstallations,
    preferences,
    isPreferencesLoading,
    initPreferences,
    isLoadingInstallation,
    getIntegrations,
    initUserPreferences,
    isGithubIntegrated,
  ]);

  return (
    <TenantContext.Provider value={value}>
      {children}
    </TenantContext.Provider>
  );
};
