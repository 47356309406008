
export enum OnboardingStatus {
  DONE = 'Done',
  IN_PROGRESS = 'InProgress',
}

export enum GitHostingPlatform {
  GITHUB = 'Github.com',
  GITLAB = 'GitLab',
  BITBUCKET = 'Bitbucket',
  AZURE_DEVOPS = 'AzureDevOps',
  OTHER = 'Other',
}

export interface OnboardingDetailsContent {
  name?: string;
  mail?: string;
  devs_count?: string;
  git_hosting_platform?: GitHostingPlatform
  plan?: string;
  goal?: string;
}

export interface OnboardingDetails extends OnboardingDetailsContent {
  tenant_id: string;
  type: string;
}

export interface IOnboardingState {
  status?: OnboardingStatus
  details?: OnboardingDetails
}
