import colors from 'themes/colors.module.scss';

export const markdownLinksProps = {
  options: {
    overrides: {
      a: {
        props: {
          target: '_blank',
          style: {
            color: colors.iris,
          },
        },
      },
    },
  },
};
