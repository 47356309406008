import { t } from 'i18next';
import Markdown from 'markdown-to-jsx';
import { FC } from 'react';

import styles from './Welcome.module.scss';

import { JittyWithMvsPlan } from 'assets';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ISvg } from 'types/interfaces';
import { markdownLinksProps } from 'utils/constants/markdownLinksProps';

interface Props {
  onContinueButtonClick: () => void;
  subtitle?: string;
  icon?: FC<ISvg>;
}

export const Welcome: FC<Props> = ({ onContinueButtonClick, subtitle, icon }) => (
  <DialogContentWrapper
    actionButtons={[{
      label: 'gettingStartedWizard.welcome.buttonText',
      handleClick: onContinueButtonClick,
    }]}
  >
    <div className={styles.iconWrapper}>
      <JitIcon icon={icon || JittyWithMvsPlan} size={205} />
    </div>

    <div className={styles.textsWrapper}>
      <JitText align='center' bold size='l' text='gettingStartedWizard.welcome.contentTitle' />

      <Markdown className={styles.description} {...markdownLinksProps}>
        {subtitle || t('gettingStartedWizard.welcome.contentSubtitle')}
      </Markdown>
    </div>
  </DialogContentWrapper>

);
