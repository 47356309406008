import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import {
  CreateIgnoreRuleRequest,
  IBaseIgnoreRule,
  IFinding,
  IFindingFilter,
  IFindingServer,
  IFindingsStatistics,
  ISortColumn,
} from 'types/interfaces';
import { ISavedFilter } from 'types/interfaces/Findings/ISavedFilter';

interface IFindingsContext {
  findings: IFinding[];
  getFindings: (paginationKey: string | undefined) => Promise<string | undefined>;
  ignoreFindings: (ignoreRulesToCreate: CreateIgnoreRuleRequest[]) => Promise<IBaseIgnoreRule[] | undefined>;
  undoIgnoreFindings: (findingsToUndoIgnore: IFinding[]) => Promise<void>;
  setFindings: (findings: IFinding[]) => void;
  isFetchingCSV: boolean;
  findingsStatistics?: IFindingsStatistics;
  isLoadingStatistics: boolean;
  getFindingsStatistics: (isSilentFetch: boolean, requestFilters: IFindingFilter[]) => Promise<void>;
  getCsvFile: (requestedFilters: IFindingFilter[], currentSortColumn: ISortColumn | undefined) => Promise<string | undefined>;
  fetchedFindings: (findingIds: string[]) => Promise<IFindingServer[] | undefined>;
  isLoadingFilters: boolean;
  savedFilters: ISavedFilter[];
  editSavedFilter: (savedFilter: ISavedFilter) => void;
  createSavedFilter: (savedFilter: ISavedFilter) => void;
  removeSavedFilter: (savedFilter: ISavedFilter) => void;
  filters: IFindingFilter[];
  setFilters: Dispatch<SetStateAction<IFindingFilter[]>>;
  setSavedFilterAsDefault: (savedFilter: ISavedFilter) => void;
  selectSavedFilter: (savedFilter: ISavedFilter) => void;
  selectedFilter: ISavedFilter | undefined;
  filtersVisibleInFirstLine: IFindingFilter[];
  setFiltersVisibleInFirstLine: (filters: IFindingFilter[]) => void;
  setIsLoadingFilters: (isLoadingFilters: boolean) => void;
  getSavedFilters: (shouldApplyDefault: boolean) => void;
  currentSortColumn: ISortColumn | undefined;
  setCurrentSortColumn: Dispatch<SetStateAction<ISortColumn | undefined>>;
  totalFindingsAmount: number | undefined;
  getTotalFindingsAmount: () => Promise<void>;
  setTotalFindingsAmount: Dispatch<SetStateAction<number | undefined>>;
}

export const FindingsContext = createContext<IFindingsContext>({
  setTotalFindingsAmount: () => undefined,
  currentSortColumn: undefined,
  setCurrentSortColumn: () => undefined,
  getFindings: () => Promise.resolve(undefined),
  setFindings: () => undefined,
  ignoreFindings: async () => undefined,
  undoIgnoreFindings: () => Promise.resolve(),
  findings: [],
  isFetchingCSV: false,
  isLoadingStatistics: false,
  isLoadingFilters: false,
  getFindingsStatistics: () => Promise.resolve(),
  getCsvFile: async () => undefined,
  fetchedFindings: async () => undefined,
  savedFilters: [],
  editSavedFilter: () => undefined,
  createSavedFilter: () => undefined,
  removeSavedFilter: () => undefined,
  setSavedFilterAsDefault: () => undefined,
  selectSavedFilter: () => undefined,
  selectedFilter: undefined,
  filters: [],
  setFilters: () => undefined,
  filtersVisibleInFirstLine: [],
  setFiltersVisibleInFirstLine: () => undefined,
  setIsLoadingFilters: () => undefined,
  getSavedFilters: () => undefined,
  getTotalFindingsAmount: () => Promise.resolve(),
  totalFindingsAmount: undefined,
});

export const useFindingsContext = () => useContext(FindingsContext);

