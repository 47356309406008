import { constants } from 'globalConstants';
import { TimeAgo } from 'types/enums';

const rangeToDaysNumber = {
  [TimeAgo.OneWeek]: 7,
  [TimeAgo.TwoWeeks]: 14,
  [TimeAgo.OneMonth]: 30,
  [TimeAgo.ThreeMonths]: 90,
};

export const getTimeAgoRange = (timeAgo: TimeAgo) => {
  const endDate = new Date(Date.now() + constants.DAY_IN_MILLISECONDS); // temporary: this is a patch to overcome a BE bug (upper limit filters the last day)
  const startDate = new Date(endDate.getTime() - (rangeToDaysNumber[timeAgo] + 1) * constants.DAY_IN_MILLISECONDS);
  return {
    startDate,
    endDate,
  };
};
