import { FC, useCallback, useMemo, useState } from 'react';

import { SlackIcon } from 'assets';
import { DialogCard } from 'components/JitDialogs/DialogCard/DialogCard';
import { SlackIntegrationDialog, SlackIntegrationDialogStage } from 'components/JitDialogs/SlackIntegrationDialog/SlackIntegrationDialog';
import { useTenantContext } from 'context';
import { getSlackRedirectUri, isVendorInstalled } from 'pages/IntegrationsPage/utils';
import { Vendor } from 'types/enums';

interface Props {
  description: string;
  configureText: string;
}

export const SlackIntegrationCard: FC<Props> = ({ description, configureText }) => {
  const [isConfigurationDialogOpen, setIsConfigurationDialogOpen] = useState(false);
  const { getIntegrations, installations, hasTriedFetchInstallations, isLoadingInstallation } = useTenantContext();

  const integration = getIntegrations().find((currIntegration) => currIntegration.vendor === Vendor.SLACK);

  const isConnected = useMemo(
    () => isVendorInstalled(Vendor.SLACK, installations),
    [installations],
  );

  const isLoading = useMemo(
    () => !hasTriedFetchInstallations || isLoadingInstallation || !integration,
    [hasTriedFetchInstallations, isLoadingInstallation, integration],
  );

  const onConnect = useCallback(() => {
    const href = getSlackRedirectUri(integration?.installation_path);
    if (href) window.open(href, '_self');
  }, [integration?.installation_path]);

  return (
    <div data-testid='slack-integration-card'>
      <SlackIntegrationDialog
        isOpen={isConfigurationDialogOpen}
        onClose={() => setIsConfigurationDialogOpen(false)}
        stage={{ type: SlackIntegrationDialogStage.TENANT }}
      />

      <DialogCard
        configureText={configureText}
        description={description}
        icon={SlackIcon}
        isConnected={isConnected}
        isLoading={isLoading}
        onConfigure={() => { setIsConfigurationDialogOpen(true); }}
        onConnect={onConnect}
        vendor={Vendor.SLACK}
        width='100%'
      />
    </div>
  );
};
