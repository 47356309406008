import { useIntegrationsTemplate } from 'context/IntegrationsContext/templates/useIntegrationTemplates';
import { useSecretsContext } from 'context/SecretsContext/SecretsContext';

export const useValidateSecretByIntegrationKey = () => {
  const { tenantSecrets } = useSecretsContext();
  const { getTemplateByVendorKey } = useIntegrationsTemplate();

  const isSecretValidForIntegration = (integrationKey: string): boolean => {
    const integration = getTemplateByVendorKey(integrationKey);
    const secretName = integration?.options?.fields?.name?.value;
    const secretExists = !!secretName && !!tenantSecrets?.find((secret) => secret.name === secretName);
    return secretExists;
  };

  return isSecretValidForIntegration;
};
