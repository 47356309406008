import { useCallback, useMemo, useState } from 'react';

import { FormStepContent } from 'pages/OnboardingPage/components/OnboardingWizard/components/FormStepContent/FormStepContent';
import {
  IOnboardingWizardStep,
  IOnboardingWizardStepName,
  IOnboardingWizardStepStatus,
} from 'pages/OnboardingPage/components/OnboardingWizard/OnboardingWizard';
import { ActionButtonVariant, OnboardingDetailsContent } from 'types/interfaces';

interface Props {
  incrementStepIndex: () => void;
  handleNextClick: (stepName: string, onboardingDetails: OnboardingDetailsContent) => Promise<void>;
  isLoading: boolean;
}

export const useFormStep = ({ incrementStepIndex, handleNextClick, isLoading }: Props): IOnboardingWizardStep => {
  const [status, setStatus] = useState<IOnboardingWizardStepStatus>(IOnboardingWizardStepStatus.ACTIVE);
  const [disabledNextButton, setDisabledNextButton] = useState(true);
  const [onboardingDetails, setOnboardingDetails] = useState<OnboardingDetailsContent>({});

  const onContinueButtonClick = useCallback(async () => {
    await handleNextClick(IOnboardingWizardStepName.FORM, onboardingDetails);
    setStatus(IOnboardingWizardStepStatus.COMPLETE);
    incrementStepIndex();
  }, [handleNextClick, onboardingDetails, incrementStepIndex]);

  const component = useCallback(() => (
    <FormStepContent setDisabledNextButton={setDisabledNextButton} setOnboardingDetails={setOnboardingDetails} />
  ), []);

  const actionButtons = useMemo(() => [{
    label: 'pages.onboarding.nextButtonText',
    disabled: disabledNextButton,
    variant: ActionButtonVariant.JIT_PRIMARY,
    handleClick: onContinueButtonClick,
    isLoading,
  }], [disabledNextButton, onContinueButtonClick, isLoading]);

  return useMemo(() => ({
    component,
    status,
    actionButtons,
  }), [actionButtons, component, status]);
};
