export enum IgnoreRuleType {
  FINGERPRINT = 'fingerprint',
  IGNORE = 'ignore',
  EXCLUDE = 'exclude',
}

export enum IgnoreRuleReason {
  ACCEPT_RISK = 'accept_risk',
  FALSE_POSITIVE = 'false_positive',
}

export enum IgnoreRequestSource {
  GITHUB = 'github',
  ACTION = 'action',
  BACKLOG = 'backlog',
  API = 'api',
}

export enum OperatorTypes {
  EQUAL = 'equal',
}
