/* eslint-disable react/jsx-max-depth */
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { CSSProperties, FC, useState } from 'react';
import { Row } from 'react-table';

import { CellVerticalAlign, DefaultCellVerticalAlign, DefaultTableDesign, TableDesignVariants } from '../../constants';
import { ElementToShowOnHoverProps, JitTableRow } from '../JitTableRow/JitTableRow';

import styles from './GroupTableRow.module.scss';

import { VectorStroke } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ISvg, ITableRow } from 'types/interfaces';

interface Props {
  onSelectRow?: (row: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  selectedRow?: ITableRow | null;
  groupIcon?: FC<ISvg>;
  groupText: string;
  rows: Row<ITableRow>[];
  cellsNumber: number;
  rowHoverStyle?: CSSProperties;
  cellVerticalAlign?: CellVerticalAlign;
  tableDesign?: TableDesignVariants;
  ElementToShowOnRowHover?: FC<ElementToShowOnHoverProps>;
  prepareRow: (row: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const GroupTableRow: FC<Props> = ({
  onSelectRow,
  selectedRow,
  cellVerticalAlign = DefaultCellVerticalAlign,
  tableDesign = DefaultTableDesign,
  prepareRow,
  groupIcon,
  groupText,
  rows,
  rowHoverStyle,
  cellsNumber,
  ElementToShowOnRowHover,
}) => {
  const [shouldShow, setShouldShow] = useState(true);

  const handleClickOnGroup = () => {
    setShouldShow(!shouldShow);
    if (onSelectRow && selectedRow) {
      onSelectRow(selectedRow);
    }
  };

  return (
    <>
      <TableRow onClick={handleClickOnGroup} style={{ backgroundColor: colors.toggle }}>
        <TableCell colSpan={cellsNumber}>
          <div className={styles.headerCellWrapper}>
            <div
              className={styles.iconWrapper}
              data-testid='cellIconWrapper'
              style={{
                transform: `rotate(${shouldShow ? '90deg' : 0})`,
              }}
            >
              <JitIcon
                color={colors.iris}
                data-testid='arrowIcon'
                icon={VectorStroke}
                size={10}
              />
            </div>

            {groupIcon && <JitIcon data-testid='cellIcon' icon={groupIcon} size={15} />}

            <JitText bold data-testid='cellText' text={groupText} textAlign='end' />

          </div>
        </TableCell>
      </TableRow>

      { shouldShow && rows?.map((row) => (
        <JitTableRow
          key={row.id}
          cellVerticalAlign={cellVerticalAlign}
          ElementToShowOnHover={ElementToShowOnRowHover}
          isSelected={!!(selectedRow?.id && row?.original?.id === selectedRow?.id)}
          onSelectRow={onSelectRow}
          prepareRow={prepareRow}
          row={row}
          rowHoverStyle={rowHoverStyle}
          tableDesign={tableDesign}
        />
      ))}
    </>
  );
};
