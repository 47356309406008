import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { JittyWithFtrPlan, JittyWithWebAppPlan } from 'assets';
import { IGettingStartedWizardStep, IGettingStartedWizardStepStatus } from 'components/GettingStartedWizard/GettingStartedWizard';
import { Welcome } from 'components/GettingStartedWizard/StepComponents';
import { usePlansContext, useTenantContext } from 'context';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { ISvg } from 'types/interfaces';

interface Props {
  incrementStepIndex: () => void;
  isRedirectedBackFromGHInstallation: boolean;
  planSlug?: string;
}

const PLAN_TO_ICON_MAP: { [key: string]: FC<ISvg> } = {
  'plan-aws-ftr': JittyWithFtrPlan,
  'plan-web-app': JittyWithWebAppPlan,
};

export const useGetWelcomeStep = ({ incrementStepIndex, isRedirectedBackFromGHInstallation, planSlug }: Props): IGettingStartedWizardStep => {
  const [hasClickedContinue, setHasClickedContinue] = useState(false);
  const { installations } = useTenantContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { plans } = usePlansContext();

  const planDetails = useMemo(
    () => !!planSlug?.length && plans[planSlug],
    [planSlug, plans],
  );

  useEffect(() => {
    if (planDetails) {
      sendAnalyticsEvent({ action: 'onboarding-viewed-with-goal-plan',
        params: { 'plan-name': planDetails.name } });
    }
  }, [planDetails, sendAnalyticsEvent]);

  const status = useMemo(() => {
    const hasInstallations = installations.length > 0;
    const isAlreadyStartedOnboarding = hasClickedContinue || isRedirectedBackFromGHInstallation || hasInstallations;

    return (isAlreadyStartedOnboarding ? IGettingStartedWizardStepStatus.COMPLETE : IGettingStartedWizardStepStatus.ACTIVE);
  }, [hasClickedContinue, isRedirectedBackFromGHInstallation, installations]);

  const onContinueButtonClick = useCallback(() => {
    setHasClickedContinue(true);
    incrementStepIndex();
    sendAnalyticsEvent({ action: 'wizard-welcome-viewed' });
  }, [incrementStepIndex, sendAnalyticsEvent]);

  const subtitle = useMemo(() => (planDetails ? planDetails?.onboarding_attributes?.description : undefined), [planDetails]);
  const icon = useMemo(() => (planSlug ? PLAN_TO_ICON_MAP?.[planSlug] : undefined), [planSlug]);

  return useMemo(() => ({
    title: 'gettingStartedWizard.welcome.title',
    component: () => <Welcome icon={icon} onContinueButtonClick={onContinueButtonClick} subtitle={subtitle} />,
    status,
  }), [status, icon, onContinueButtonClick, subtitle]);
};
