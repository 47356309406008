import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TeamsLeaderboardEmptyState } from './components/TeamsLeaderboardEmptyState/TeamsLeaderboardEmptyState';
import { getMedalForScore } from './getMedalForScore';
import styles from './TeamsLeaderboard.module.scss';

import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { ScoreGauge } from 'pages/SecurityPlansPage/components/ScoreGauge/ScoreGauge';
import colors from 'themes/colors.module.scss';
import { ITeam } from 'types/interfaces/Teams/ITeam';

interface TeamsLeaderboardProps {
  teams: ITeam[];
}

const renderMedalOrPosition = (teams: ITeam[], index: number) => {
  const medalComponent = getMedalForScore(teams, index);
  if (medalComponent) {
    return medalComponent;
  }
  return (
    <JitText
      color={colors.gray}
      style={{ marginLeft: 3,
        paddingRight: 1 }}
      text={`#${index + 1}`}
    />
  );
};

export const TeamsLeaderboard: React.FC<TeamsLeaderboardProps> = ({ teams }) => {
  const navigate = useNavigate();
  if (teams.length === 0) {
    return (
      <TeamsLeaderboardEmptyState />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <JitText bold size='l' text='pages.overview.teamsLeaderboard.title' />

        <JitText color={colors.gray} text='pages.overview.teamsLeaderboard.subtitle' />

        <div className={styles.teamList}>
          {teams.map((team, index) => (
            <div key={team.name} className={styles.teamCard}>
              <div className={styles.medal}>
                {renderMedalOrPosition(teams, index)}
              </div>

              <div className={styles.teamName}>
                <JitText noWrap overflowHiddenEllipsis size='l' text={team.name} />
              </div>

              <div className={styles.score} data-testid={`team-score-${team.id}`}>
                <JitText text={`${team.score}%`} />
              </div>

              <div className={`${styles.scoreGauge} score-gauge-${team.id}`} data-testid={`score-gauge-${team.id}`}>
                <ScoreGauge score={team.score / 100} showAnimation variant='xs' />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.footerLink}>
        <JitText
          color={colors.iris}
          onClick={() => {
            navigate(constants.routes.TEAMS);
          }}
          size='l'
          text='pages.overview.teamsLeaderboard.footerButton'
        />
      </div>
    </div>
  );
};
