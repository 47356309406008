import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { SecurityPlanCard } from '../SecurityPlanCard/SecurityPlanCard';

import styles from './SecurityPlansCard.module.scss';

import { SetAsGoalOn } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { usePlansContext } from 'context';
import { PLANS_ROUTE } from 'pages/OverviewPage/constants';
import colors from 'themes/colors.module.scss';
import { ActionButtonVariant, IPlanDetails } from 'types/interfaces';

interface Props {
  goals: IPlanDetails[];
}

export const SecurityPlansCard: FC<Props> = ({ goals }) => {
  const navigate = useNavigate();
  const { hasFetchedPlans } = usePlansContext();

  return (
    <div
      className={styles.wrapper}
      data-testid='securityPlans-card'
    >
      <div className={styles.cardHeader}>
        <div className={styles.header}>
          <JitIcon
            centered={false}
            color={colors.white}
            icon={SetAsGoalOn}
            size={26}
          />

          <div className={styles.headerText}>
            <JitText bold text='pages.overview.securityPlans.card.myGoals' />
          </div>
        </div>

        <div className={styles.actionButton}>
          <JitActionButton actionButton={{ label: 'pages.overview.securityPlans.card.viewAllSecurityPlansButton',
            handleClick: () => { navigate(PLANS_ROUTE); },
            variant: ActionButtonVariant.OUTLINED }}
          />
        </div>
      </div>

      <div className={styles.cardContentWrapper}>
        {
          !hasFetchedPlans ? <LoadingBar /> : (
            <>
              <div className={styles.goalsCardsWrapper}>

                <SecurityPlanCard plan={goals[0]} size='s' />

                {goals.length > 1 ? (
                  <SecurityPlanCard plan={goals[1]} size='m' />
                ) : (
                  <div className={styles.mediumGoalPlaceHolder} />
                )}

                {goals.length > 2 ? (
                  <SecurityPlanCard plan={goals[2]} size='l' />
                ) : (
                  <div className={styles.largeGoalPlaceHolder} />
                )}
              </div>

              {goals.length > 3 && (
              <div className={styles.plusCard} data-testid='plusCard'>
                <JitText bold text={`+${goals.length - 3}`} />
              </div>
              )}
            </>
          )
}
      </div>
    </div>

  );
};
