import { FC } from 'react';

import { IntegrationDetailsExistingAccount } from './IntegrationDetailsExistingAccount';
import { getAwsAccounts } from './utils/getAwsAccounts';

import { JitText } from 'components/JitText/JitText';
import { useAssetsContext } from 'context';
import { AwsIntegrationType } from 'types/enums';
import { AwsIntegrationAccount } from 'types/interfaces';

interface Props {
  account: AwsIntegrationAccount
}

export const IntegrationDetailsExistingOrg: FC<Props> = ({ account }) => {
  const { awsAccountAssets } = useAssetsContext();

  const awsAccounts = account.type === AwsIntegrationType.Organization ? getAwsAccounts(account, awsAccountAssets) : [];

  return (
    <div>
      <JitText bold style={{ marginTop: '1.5rem' }} text={`Management account name: ${account.account_name}`} />

      <div
        className='ms-4'
        style={{
          marginTop: '0.5rem',
        }}
      >
        {awsAccounts.map((awsAccount) => <IntegrationDetailsExistingAccount key={awsAccount.account_name} account={awsAccount} />)}
      </div>
    </div>
  );
};
