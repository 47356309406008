import { isEmpty } from 'lodash';

import { TimeAgo } from 'types/enums';
import { IFindingFilter, ISortColumn, ITableQueryParams, TIME_AGO_FILTER_KEY } from 'types/interfaces';
import { getTimeAgoRange } from 'utils';

const filterToKeyValuePair = (filter: IFindingFilter): object => {
  if (filter.key === TIME_AGO_FILTER_KEY) {
    const { startDate, endDate } = getTimeAgoRange(filter.selectedValue as TimeAgo);
    return {
      created_at_start: startDate.toISOString().split('T')[0],
      created_at_end: endDate.toISOString().split('T')[0],
    };
  }
  const filterValue = Array.isArray(filter.selectedValue) ? filter.selectedValue : [filter.selectedValue];
  return { [filter.key]: filterValue };
};

type Args = { currentSortColumn?: ISortColumn, filters?: IFindingFilter[], nextPageKey?: string };
export const buildGetFindingsRequestParams = ({ currentSortColumn, filters, nextPageKey }: Args): ITableQueryParams => {
  const relevantFilters = (filters || []).filter((filter) => !isEmpty(filter.selectedValue) && filter.isVisible);
  const filterParams = {
    sortKey: currentSortColumn?.sortKey,
    sortDirection: currentSortColumn?.direction,
    filters: relevantFilters,
  };
  const formattedFilters = filterParams?.filters?.filter((filter) => filter.isVisible)
    ?.reduce((currentFormattedFilters: object, currentFilter: IFindingFilter) => ({
      ...currentFormattedFilters,
      ...filterToKeyValuePair(currentFilter),
    }), {});
  return ({
    sort: [filterParams?.sortKey || 'created_at'],
    start_key: nextPageKey,
    filters: formattedFilters || {},
    sort_desc: filterParams?.sortDirection ? filterParams.sortDirection === 'desc' : true,
  });
};
