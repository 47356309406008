import { FC, useEffect, useState, useCallback } from 'react';

import { ExportButton } from 'components/ExportButton';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { useSbomContext } from 'context/SbomContext/SbomContext';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { useArtifactsService } from 'services/ArtifactsService/useArtifactsService';
import { logError } from 'services/logger/logger';
import { AssetType, WebSocketNotificationTopics } from 'types/enums';
import { ArtifactType } from 'types/interfaces/Artifacts/IArtifact';
import { SbomReportStatus } from 'types/interfaces/Sbom/SbomReportStatus';

export const ExportSbomReport: FC = () => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { reportStatus, isPremiumFeatureEnabled } = useSbomContext();
  const [artifactId, setArtifactId] = useState<string>();
  const { getArtifacts, downloadArtifact } = useArtifactsService();

  const { websocketSubscribe } = useWebsocketSubscribe();

  const getArtifactId = useCallback(async () => {
    const artifacts = await getArtifacts({
      artifactType: ArtifactType.SBOM,
      assetType: AssetType.ORG,
    });
    if (artifacts?.status === 200 && artifacts.data.data.length > 0) {
      const id = artifacts.data.data[0].artifactId; // We should only have one SBOM artifact of type org.
      setArtifactId(id);
    }
  }, [getArtifacts]);

  useEffect(() => {
    getArtifactId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Fetch artifactId only once

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.SbomFirstReportGenerated, getArtifactId);
  }, [getArtifactId, websocketSubscribe]);

  const handleClick = async () => {
    sendAnalyticsEvent({
      action: 'sbom-report-download-clicked',
    });
    // We can use `artifactId!` here since we know it's not undefined on this point.
    const response = await downloadArtifact(artifactId!);
    const downloadLink = response?.data?.downloadUrl;
    if (downloadLink) {
      sendAnalyticsEvent({
        action: 'sbom-report-download-success',
      });
    } else {
      logError('Failed to get download link for SBOM report', {
        status: response?.status,
        statusText: response?.statusText,
        data: response?.data,
      });
      sendAnalyticsEvent({
        action: 'sbom-report-download-failure',
      });
    }
    return downloadLink;
  };
  const isDisabled = reportStatus !== SbomReportStatus.ACTIVE || !isPremiumFeatureEnabled || !artifactId;

  return (
    <ExportButton
      disabled={isDisabled}
      handleClick={handleClick}
      text='pages.sbom.exportReport'
    />
  );
};
