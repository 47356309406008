import styles from './CommitPlanRequired.module.scss';

import { JittyNotCommittedPlan } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';

export const CommitPlanRequired = () => (
  <div data-testid='commitPlanRequired'>
    <JitIcon
      className={styles.jittyNotCommittedPlanWrapper}
      data-testid='commitIcon'
      icon={JittyNotCommittedPlan}
      iconClassName={styles.jittyNotCommittedPlanIcon}
    />

    <JitText align='center' bold data-testid='commitPlanRequiredText' size='l' text='pages.findings.emptyFindingsTable.commitPlanRequired.commitPlanRequired' />

    <JitText align='center' data-testid='firstCommitPlan' marginTop='10px' size='m' text='pages.findings.emptyFindingsTable.commitPlanRequired.firstCommit' />

    <JitText align='center' data-testid='findingsShow' size='m' text='pages.findings.emptyFindingsTable.commitPlanRequired.findingsShowUp' />
  </div>
);
