import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ConfigurePlanItemDialogButtons } from '../ConfigurePlanItemDialogButtons/ConfigurePlanItemDialogButtons';

import styles from './ConfigurePlanItemDialog.module.scss';
import { useValidation } from './useValidation';
import { getDisabledTooltip as getDisabledTooltipUtil } from './utils';
import { ValidationBanner } from './ValidationBanner';

import { CircleSuccessFilled } from 'assets';
import { TextAndLink } from 'components/Configurations/ConfigurationTrigger/components/TextAndLink/TextAndLink';
import { ConfigurationDetailsStep } from 'components/Configurations/interfaces/configurations';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { DialogStep, StepsDialog } from 'components/JitDialogs/StepsDialog/StepsDialog';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import {
  useAssetsContext,
  useConfigurationsContext,
  usePlanInstanceContext,
  usePlansContext,
} from 'context';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { useCommitConfigurations } from 'context/ConfigurationsContext/hooks';
import { useActivatePlanItem } from 'context/PlansContext/hooks/useActivatePlanItem';
import { IActiveToast } from 'context/ToastsContext/interfaces';
import colors from 'themes/colors.module.scss';
import commonStyles from 'themes/common.module.scss';
import { AssetType, ToastType } from 'types/enums';
import { IAnalyticsEvent, ISvg } from 'types/interfaces';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  planSlug: string;
  steps?: ConfigurationDetailsStep[];
  icon?: FC<ISvg>;
  planItemSlugToConfigure: string;
  initialStep?: number;
  analyticsEvent?: IAnalyticsEvent;
  requiredAssetType?: AssetType;
}

export const ConfigurePlanItemDialog: FC<Props> = ({
  isOpen, handleClose, planSlug, steps, icon, planItemSlugToConfigure, initialStep = 0, analyticsEvent, requiredAssetType,
}) => {
  const commitedSuccessfullyToast: IActiveToast = {
    type: ToastType.Success,
    overrideProps: { title: 'toasts.commonTitles.success',
      subtitle: 'toasts.planActivated.subtitle',
      icon: CircleSuccessFilled },
  };
  const commitConfigurations = useCommitConfigurations(commitedSuccessfullyToast);
  const { isCommittingConfigurations, setConfigurations, fetchedConfigurations, configurations } = useConfigurationsContext();
  const { plans } = usePlansContext();
  const { activatePlanItem } = useActivatePlanItem();
  const [isDoneStep, setIsDoneStep] = useState<boolean>(true);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { assets } = useAssetsContext();
  const { setPlanItemSlugsInCommit } = usePlanInstanceContext();

  const [hasClickedApply, setHasClickedApply] = useState(false);
  const [currentStep, setCurrentStep] = useState(initialStep);

  const [shouldHideStepsDialog, setShouldHideStepsDialog] = useState(false);

  const currentConfigurationStep = useMemo(
    () => (steps && steps[currentStep]) || {} as ConfigurationDetailsStep,
    [currentStep, steps],
  );

  const {
    validationError,
    isFailedValidation,
    setIsFailedValidation,
    isValidationLoading,
    validate,
  } = useValidation(currentConfigurationStep, configurations, fetchedConfigurations);

  const numberOfSteps = steps ? steps.length : 0;

  useEffect(() => {
    setHasClickedApply(false);
  }, [planItemSlugToConfigure]);

  useEffect(() => {
    const hasConfigurationCommited = (hasClickedApply && !isCommittingConfigurations);
    if (hasConfigurationCommited) {
      handleClose();
    }
  }, [hasClickedApply, isCommittingConfigurations, handleClose]);

  const planItem = useMemo(
    () => plans[planSlug].items?.[planItemSlugToConfigure],
    [planItemSlugToConfigure, planSlug, plans],
  );

  const isActive = planItem?.is_active;
  const applyText = useMemo(() => (isActive
    ? 'pages.plan.configurationsDialog.apply'
    : 'pages.plan.configurationsDialog.applyAndActivate'
  ), [isActive]);

  const isLastStep = currentStep === numberOfSteps - 1;
  const shouldApplyMultiStepDialog = numberOfSteps > 1;

  const onNextButtonClick = useCallback(async () => {
    const isValid = await validate();

    if (isValid) {
      if (currentConfigurationStep.button?.commitOnCLick) {
        await commitConfigurations();
      }
      setCurrentStep((prevStep) => ((prevStep < numberOfSteps - 1) ? prevStep + 1 : prevStep));
    }
  }, [validate, currentConfigurationStep, commitConfigurations, numberOfSteps]);

  const onFinishAllSteps = useCallback(async () => {
    const isValid = await validate();
    if (isValid) {
      setHasClickedApply(true);
      await commitConfigurations();
      if (!isActive) {
        if (planItem) {
          setPlanItemSlugsInCommit((prevState: string[]) => [...prevState, planItem.slug]);
        }
        activatePlanItem(planSlug, planItem!);
      }
      if (analyticsEvent) {
        sendAnalyticsEvent(analyticsEvent);
      }
    }
  }, [activatePlanItem, analyticsEvent, commitConfigurations, isActive, planItem, planSlug, sendAnalyticsEvent, setPlanItemSlugsInCommit, validate]);

  const discardChanges = () => {
    setConfigurations(fetchedConfigurations!);
    handleClose();
  };

  const buttonsClass = (isValidationLoading || validationError) ? styles.buttonsWrapperWhenBanner : styles.buttonsWrapper;

  const hasRequiredAssetType = useMemo(() => {
    if (!requiredAssetType || !isLastStep) {
      return true;
    }
    return !!assets.find((asset) => asset.asset_type === requiredAssetType);
  }, [assets, isLastStep, requiredAssetType]);

  const isFinishedButtonDisabled = !isDoneStep || isFailedValidation || isValidationLoading || isCommittingConfigurations || !hasRequiredAssetType;

  const getDisabledTooltip = (fallback: string) => getDisabledTooltipUtil(
    fallback,
    isValidationLoading,
    isFailedValidation,
    isCommittingConfigurations,
    !hasRequiredAssetType,
  );

  const buttons = (
    <ConfigurePlanItemDialogButtons
      className={buttonsClass}
      disabledFinishButtonTooltip={getDisabledTooltip('pages.plan.configurationsDialog.missingField')}
      finishText={applyText}
      isFinishButtonDisabled={isFinishedButtonDisabled}
      onCancelClicked={discardChanges}
      onFinish={onFinishAllSteps}
      shouldShowCircularProgress={isCommittingConfigurations || isValidationLoading || !hasRequiredAssetType}
    />
  );

  const buttonsForStepsDialog = (
    <ConfigurePlanItemDialogButtons
      className={`${styles.ConfigurePlanItemDialogButtons} ${buttonsClass}`}
      disabledFinishButtonTooltip={getDisabledTooltip(currentConfigurationStep.button?.disabledTooltip
        ? currentConfigurationStep.button?.disabledTooltip : '')}
      finishText={currentConfigurationStep.button ? currentConfigurationStep.button.text : applyText}
      isFinishButtonDisabled={isFinishedButtonDisabled}
      onCancelClicked={discardChanges}
      onFinish={isLastStep ? onFinishAllSteps : onNextButtonClick}
      shouldShowCircularProgress={isCommittingConfigurations || isValidationLoading || !hasRequiredAssetType}
    />
  );

  const content = (
    <div className={styles.contentWrapper}>
      <div className={styles.header}>

        {icon && <JitIcon data-testid='configurationDialog-icon' icon={icon} size={52} style={{ marginBottom: 17 }} />}

        <div className={styles.title}>
          <JitText bold data-testid='configurationDialog-title' size='m' text={currentConfigurationStep.title} />
        </div>

        {currentConfigurationStep.subtitle && currentConfigurationStep.subtitleLink ? (
          <TextAndLink
            data-testid='configurationDialog-subtitle-link'
            link={currentConfigurationStep.subtitleLink}
            text={{
              text: currentConfigurationStep.subtitle,
              color: colors.lightGray,
            }}
          />
        ) : (<JitText color={colors.lightGray} data-testid='configurationDialog-subtitle' size='m' text={currentConfigurationStep.subtitle} />
        )}
      </div>

      <div className={`${styles.configurationsWrapper} ${commonStyles.scrollInnerShadow}`}>
        <div className={styles.configurationsWrapperPadding}>
          {!!currentConfigurationStep?.ConfigurationComponent && (
          <currentConfigurationStep.ConfigurationComponent
            onConfigurationChange={() => setIsFailedValidation(false)}
            setIsDoneStep={setIsDoneStep}
            setShouldHideStepsDialog={setShouldHideStepsDialog}
            stepIndex={currentStep}
          />
          )}
        </div>
      </div>

      <ValidationBanner isValidationLoading={isValidationLoading} validationError={validationError} />

      {shouldApplyMultiStepDialog ? buttonsForStepsDialog : buttons}
    </div>
  );

  const dialogSteps: DialogStep[] = steps?.map((stepConfiguration, index) => ({
    stepName: stepConfiguration.stepDisplayText || '',
    isSelected: index === currentStep,
    stepIndex: index,
    isVerified: index < currentStep,
    content,
  })) || [];

  return (
    shouldApplyMultiStepDialog ? (
      <StepsDialog
        height='m'
        isHidden={shouldHideStepsDialog}
        isOpen={isOpen}
        onClose={discardChanges}
        setStep={setCurrentStep}
        steps={dialogSteps}
        title='pages.plan.configurationsDialog.title'
        width='xl'
        withDivider
      />
    ) : (
      <CustomDialog
        content={content}
        isOpen={isOpen}
        onClose={discardChanges}
        title='pages.plan.configurationsDialog.title'
        width='l'
        withDivider
      />
    )
  );
};
