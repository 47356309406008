import { FC, useCallback, useState } from 'react';

import { StepGithubCard } from './components/StepGithubCard';
import { AnalyticsEvents } from './constants';
import styles from './CreateCentralizedRepo.module.scss';

import { GithubIcon } from 'assets';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { useSetCentralizedRepo } from 'context/TenantContext/tenantHooks/useSetCentralizedRepo';
import { useValidateCentralizedRepo } from 'context/TenantContext/tenantHooks/useValidateCentralizedRepo';
import { CentralizedAssetValidationStatus } from 'types/enums/CentralizedAssetValidationStatus';
import { IAsset } from 'types/interfaces';

interface Props {
  initializedSelectedAsset?: IAsset;
  closeWizard: () => void;
}

export const CreateCentralizedRepo: FC<Props> = ({ initializedSelectedAsset, closeWizard }) => {
  const [selectedAsset, setSelectedAsset] = useState<IAsset | undefined>(initializedSelectedAsset);
  const { handleSetCentralizedRepo, centralizedRepoResponseStatus } = useSetCentralizedRepo();
  const { handleValidateCentralizedRepo, validateCentralizedRepoValidationStatus, setValidateCentralizedRepoValidationStatus } = useValidateCentralizedRepo();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const onContinueButtonClick = useCallback(async () => {
    if (selectedAsset) {
      sendAnalyticsEvent({ action: AnalyticsEvents.CentralizedRepoSetRepoClicked });
      const status = await handleSetCentralizedRepo(selectedAsset);
      if (status === CentralizedAssetValidationStatus.VALID) {
        closeWizard();
      }
    }
  }, [selectedAsset, sendAnalyticsEvent, handleSetCentralizedRepo, closeWizard]);

  const validateSelectedAsset = useCallback(async (asset: IAsset) => {
    const status = await handleValidateCentralizedRepo(asset);
    if (status === CentralizedAssetValidationStatus.INVALID) {
      sendAnalyticsEvent({ action: AnalyticsEvents.CentralizedRepoValidationFailed });
    }
  }, [handleValidateCentralizedRepo, sendAnalyticsEvent]);

  const isPendingSetCentralizedRepo = centralizedRepoResponseStatus === CentralizedAssetValidationStatus.PENDING;

  const continueButtonLabel = 'GithubIntegrationWizard.createCentralizedRepo.next';

  return (
    <DialogContentWrapper
      actionButtons={[
        {
          label: continueButtonLabel,
          handleClick: onContinueButtonClick,
          disabled: !(validateCentralizedRepoValidationStatus === CentralizedAssetValidationStatus.VALID) || isPendingSetCentralizedRepo,
          isLoading: isPendingSetCentralizedRepo,
        },
      ]}
      icon={GithubIcon}
      leftButtonsElement={(
        <JitExternalLink href={config.docsGithubInstallation} text='GithubIntegrationWizard.createCentralizedRepo.learnMore' />
)}
    >
      <div className={styles.content}>

        <JitText bold size='l' text='GithubIntegrationWizard.createCentralizedRepo.title' />

        <StepGithubCard
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          setValidationStatus={setValidateCentralizedRepoValidationStatus}
          validateSelectedAsset={validateSelectedAsset}
          validationStatus={validateCentralizedRepoValidationStatus}
        />

      </div>

    </DialogContentWrapper>
  );
};

