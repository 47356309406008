import { startCase } from 'lodash';

import { FindingStatus } from 'types/enums';
import { IFinding, IFindingServer, IFindingServerCloudAttributes } from 'types/interfaces';
import { capitalizeFirstLetter, capitalizeFirstLetterOfEachWord, formatDate } from 'utils';

interface IParseLocationParams {
  assetDomain: string,
  assetName: string,
  cloudAttributes?: IFindingServerCloudAttributes,
  locationText?: string
}

export const parseLocation = ({
  assetDomain,
  assetName,
  cloudAttributes,
  locationText,
}: IParseLocationParams): string => {
  let location = 'N/A';
  if (locationText) {
    location = locationText;
  } else if (cloudAttributes) {
    location = cloudAttributes.region
      ? `${cloudAttributes.account_id}/${cloudAttributes.region}/${cloudAttributes.cloud_service_name}`
      : `${cloudAttributes.account_id}`;
  } else if (assetDomain) {
    location = assetDomain;
    if (assetName) {
      location = `${location}/${assetName}`;
    }
  }

  return location;
};

export const parseFinding = (finding: IFindingServer): IFinding => {
  const {
    id,
    test_name: testName,
    last_detected_at: lastDetectedAt,
    issue_text: issueText,
    references,
    code_attributes: codeAttributes,
    issue_severity: issueSeverity,
    job_name: jobName,
    control_name: controlName,
    cloud_attributes: cloudAttributes,
    asset_domain: assetDomain,
    asset_name: assetName,
    asset_type: assetType,
    location,
    location_text: locationText,
    asset_id: assetId,
    vendor,
    created_at: createdAt,
    fingerprint,
    resolution,
    plan_layer: planLayer,
    vulnerability_type: vulnerabilityType,
    app_attributes: appAttributes,
    ignored,
    ignore_rules_ids: ignoreRulesIds,
  } = finding;

  let formattedFinding: IFinding = {
    id,
    name: capitalizeFirstLetter(testName),
    date: formatDate(lastDetectedAt),
    createdAt: formatDate(createdAt),
    issueText,
    referencesUrls: references?.map((f) => f.url) || [],
    lastDetectedDateTime: formatDate(lastDetectedAt),
    lastDetectedDate: formatDate(lastDetectedAt, false),
    severity: issueSeverity,
    securityControl: capitalizeFirstLetterOfEachWord(jobName.replaceAll('-', ' ')),
    controlName,
    location,
    locationText: parseLocation({
      assetDomain,
      assetName,
      cloudAttributes,
      locationText,
    }),
    organization: assetDomain,
    repository: assetName,
    assetType,
    vendor,
    assetId,
    fingerprint,
    resolution: resolution || FindingStatus.OPEN,
    planLayer,
    vulnerabilityType: startCase(vulnerabilityType) || 'unknown',
    assetName,
    ignored,
    ignoreRulesIds,
  };

  if (codeAttributes) {
    formattedFinding = {
      ...formattedFinding,
      codeAttributes: {
        filename: codeAttributes?.filename,
        codeSnippet: codeAttributes?.code_snippet,
        lineRange: codeAttributes?.line_range,
        branch: codeAttributes?.branch,
      },
    };
  }

  if (cloudAttributes) {
    formattedFinding = {
      ...formattedFinding,
      cloudAttributes: {
        accountId: cloudAttributes?.account_id,
        accountName: cloudAttributes?.account_name,
        region: cloudAttributes?.region,
        cloudServiceName: cloudAttributes?.cloud_service_name,
      },
    };
  }

  if (appAttributes) {
    formattedFinding = {
      ...formattedFinding,
      appAttributes: {
        targetUrl: appAttributes?.target_url,
        requestHeader: appAttributes?.request_header,
        responseHeader: appAttributes?.response_header,
        param: appAttributes?.param,
        path: appAttributes?.path,
        method: appAttributes?.method,
        solution: appAttributes?.solution,
        cweid: appAttributes?.cweid,
        tags: appAttributes?.tags,
      },
    };
  }
  return formattedFinding;
};

export const parseFindings = (data: IFindingServer[]): IFinding[] => data.map((finding: IFindingServer) => parseFinding(finding));

