import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useEffect, useState } from 'react';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { InviteMembersDialog } from 'pages/TeamsPage/SubPages/TeamsPage/components/InviteMembersDialog/InviteMembersDialog';
import styles from 'pages/TeamsPage/SubPages/TeamsPage/components/PageContent/PageContent.module.scss';
import { TeamsSearch } from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsSearch/TeamsSearch';
import { TeamsTable } from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/TeamsTable';
import { ActionButtonVariant } from 'types/interfaces';
import { useDebounce } from 'utils/hooks/useDebounce';

interface PageContentProps {
}

export const PageContent: FC<PageContentProps> = () => {
  const { teams, getTeams, sortedBy, sortOrder } = useTeamsContext();
  const [searchValue, setSearchValue] = useState('');
  const { uiShowSlackInvitation } = useFlags();
  const [isInviteMembersDialogOpen, setIsInviteMembersDialogOpen] = useState(false);

  const { debounceValue } = useDebounce(searchValue);
  useEffect(() => {
    getTeams(true, searchValue);
  }, [sortOrder, sortedBy, debounceValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <TeamsSearch onChange={setSearchValue} searchValue={searchValue} />

        {uiShowSlackInvitation && (
        <JitActionButton actionButton={{
          label: 'pages.teams.invite.button',
          handleClick: () => setIsInviteMembersDialogOpen(true),
          variant: ActionButtonVariant.OUTLINED,
        }}
        />
        )}
      </div>

      <InviteMembersDialog
        isOpen={isInviteMembersDialogOpen}
        onClose={() => setIsInviteMembersDialogOpen(false)}
      />

      <TeamsTable searchValue={searchValue} teams={teams.data} />

    </div>
  );
};
