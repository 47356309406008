import { FC } from 'react';

import styles from './DialogCardActionsContent.module.scss';

import { Loading } from 'assets';
import { INSTALLING_INTEGRATION_STATUSES } from 'components/Configurations/Integrations/constants';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IntegrationStatus } from 'types/interfaces';

export interface JitCardActionsContentProps {
  integrationStatus?: IntegrationStatus,
  onConnect: () => void,
  isLoading?: boolean,
}

const getActionButtonText = (integrationStatus?: IntegrationStatus) => {
  if (!integrationStatus) {
    return 'cards.buttons.connect';
  }
  if (IntegrationStatus.CONNECTED === integrationStatus) {
    return 'configurations.notifications.cardStatuses.connected';
  }
  if (IntegrationStatus.ERROR === integrationStatus) {
    return 'configurations.notifications.cardStatuses.integrationFailed';
  }
  if (INSTALLING_INTEGRATION_STATUSES.includes(integrationStatus)) {
    return 'configurations.notifications.cardStatuses.installing';
  }
  return '';
};

const getActionButtonColor = (integrationStatus?: IntegrationStatus) => {
  if (IntegrationStatus.CONNECTED === integrationStatus) {
    return colors.successGreen;
  }
  if (IntegrationStatus.ERROR === integrationStatus) {
    return colors.failRed;
  }
  return colors.white;
};

export const DialogCardActionsContent: FC<JitCardActionsContentProps> = ({ integrationStatus, onConnect, isLoading }) => {
  const actionButtonText = getActionButtonText(integrationStatus);
  if (integrationStatus && INSTALLING_INTEGRATION_STATUSES.includes(integrationStatus)) {
    return (
      <div className={styles.loadingLabel}>
        <div className={styles.loadingIcon}>
          <JitIcon icon={Loading} size={12} />
        </div>

        {actionButtonText && (<JitText bold color={colors.basicLight} text={actionButtonText} />)}
      </div>
    );
  }
  if (!integrationStatus || integrationStatus === IntegrationStatus.DISCONNECTED) {
    return (
      <JitButton
        className={styles.connectButton}
        disabled={isLoading}
        isLoading={isLoading}
        onClick={onConnect}
        variant='contained'
      >
        <JitText
          bold
          color={colors.white}
          noWrap
          size='m'
          text='cards.buttons.connect'
        />
      </JitButton>
    );
  }
  return actionButtonText ? (
    <JitText
      bold
      className={styles.connectionIndicator}
      color={getActionButtonColor(integrationStatus)}
      noWrap
      size='m'
      text={actionButtonText}
    />
  ) : null;
};
