/* eslint-disable max-statements */
import { AxiosResponse } from 'axios';
import {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useMemo,
  useRef,
  useState,
} from 'react';

import { UploadStatus } from './constants';
import styles from './JitFileInput.module.scss';
import { JitFileInputEmptyState } from './JitFileInputEmptyState';
import { JitFileInputNonEmptyState } from './JitFileInputNonEmptyState';

import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { UploadFileResponse } from 'services/PlanService';
import { IAnalyticsEvent } from 'types/interfaces';

interface Props {
  accept?: string;
  handleChange: (value: string) => void;
  isFileContentValid: (value: string) => Promise<boolean>;
  handleUpload: (file: File) => Promise<AxiosResponse<UploadFileResponse> | undefined>;
  analytics?: {
    invalidType?: IAnalyticsEvent,
    invalidContent?: IAnalyticsEvent,
    success?: IAnalyticsEvent,
  },
  inputValue: string;
  'data-testid'?: string,
}

export const JitFileInput: FC<Props> = ({
  accept, handleChange, isFileContentValid, handleUpload, analytics, inputValue, ...props
}) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const isEmpty = useMemo(() => !file && !inputValue, [file, inputValue]);
  const isFailed = useMemo(() => uploadStatus && [UploadStatus.FAIL, UploadStatus.INVALID].includes(uploadStatus), [uploadStatus]);

  const upload = async (fileToUpload: File) => {
    if (!fileToUpload) {
      return;
    }

    setUploadStatus(UploadStatus.LOADING);

    // validate the content of the file
    const fileContent = await fileToUpload.text();
    const isValid = await isFileContentValid(fileContent);
    if (!isValid) {
      setUploadStatus(UploadStatus.INVALID);
      if (analytics?.invalidContent) {
        sendAnalyticsEvent({ ...analytics.invalidContent,
          params: {
            file_name: fileToUpload.name,
          },
        });
      }
      return;
    }

    const response = await handleUpload(fileToUpload);

    if (response?.status && response.status === 200) {
      if (analytics?.success) {
        sendAnalyticsEvent({ ...analytics.success,
          params: {
            file_name: fileToUpload.name,
          },
        });
      }
      setUploadStatus(UploadStatus.COMPLETE);
      handleChange(response.data.file_path);
    } else setUploadStatus(UploadStatus.FAIL);
  };

  const handleFileUpload = async (files: FileList | null, e: SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!files || !files.length) {
      return;
    }

    const isTypeNotSupported = accept && files[0]?.type && !accept.includes(files[0].type);
    const fileEx = files[0]?.name.split('.').pop();
    const isExNotSupported = accept && fileEx && !accept.includes(fileEx);

    if (isTypeNotSupported || isExNotSupported) {
      if (analytics?.invalidType) {
        sendAnalyticsEvent({ ...analytics.invalidType,
          params: {
            file_name: files[0].name,
            file_extension: fileEx || '',
          },
        });
      }

      // non supported file type in case of drag & drop
      return;
    }

    setFile(files[0]);
    await upload(files[0]);
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await handleFileUpload(e.target.files, e);
  };

  const handleFileClear = () => {
    setFile(undefined);
    setUploadStatus(undefined);
    handleChange('');
  };

  return (
    <div className={`${styles.fileInputWrapper} ${isEmpty && styles.emptyState} ${isFailed && styles.failed}`} data-testid={props['data-testid'] || 'jit-file-input-container'}>
      <input ref={inputRef} accept={accept} data-testid='jit-file-input' onChange={onFileChange} style={{ display: 'none' }} type='file' />

      {isEmpty
        ? (
          <JitFileInputEmptyState
            handleFileUpload={handleFileUpload}
            inputRef={inputRef}
          />
        )
        : (
          <JitFileInputNonEmptyState
            file={file}
            handleFileClear={handleFileClear}
            inputValue={inputValue}
            uploadStatus={uploadStatus}
          />
        )}
    </div>
  );
};
