import { useCallback, useState } from 'react';

import { useTenantContext } from 'context/TenantContext';
import { useTenantService } from 'services/TenantService/useTenantService';
import { CentralizedAssetValidationStatus } from 'types/enums/CentralizedAssetValidationStatus';
import { IAsset } from 'types/interfaces';

export const useValidateCentralizedRepo = () => {
  const { githubInstallation } = useTenantContext();
  const [validateCentralizedRepoValidationStatus, setValidateCentralizedRepoValidationStatus] = useState<CentralizedAssetValidationStatus | undefined>(undefined);
  const { validateCentralizedRepo } = useTenantService();

  const handleValidateCentralizedRepo = useCallback(async (centralizedRepoAsset: IAsset) => {
    let status: CentralizedAssetValidationStatus | undefined;
    if (githubInstallation) {
      status = CentralizedAssetValidationStatus.PENDING;
      setValidateCentralizedRepoValidationStatus(status);
      try {
        const res = await validateCentralizedRepo(githubInstallation, centralizedRepoAsset);
        if (res?.status === 200) {
          status = CentralizedAssetValidationStatus.VALID;
          setValidateCentralizedRepoValidationStatus(status);
        } else {
          status = CentralizedAssetValidationStatus.INVALID;
          setValidateCentralizedRepoValidationStatus(status);
        }
      } catch (error) {
        status = CentralizedAssetValidationStatus.INVALID;
        setValidateCentralizedRepoValidationStatus(status);
      }
    }
    return status;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [githubInstallation]);

  return {
    handleValidateCentralizedRepo,
    validateCentralizedRepoValidationStatus,
    setValidateCentralizedRepoValidationStatus,
  };
};
