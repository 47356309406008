import { FC } from 'react';

import styles from './IntegrationSteps.module.scss';

import { Loading } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';

interface Props {
  integrationType: string,
  handleLinkClick: () => void,
  disabled: boolean;
  isLoading?: boolean;
}

export const IntegrationSteps: FC<Props> = ({
  disabled,
  integrationType,
  handleLinkClick,
  isLoading,
}) => {
  const enableStackSetsUrl = 'https://console.aws.amazon.com/cloudformation/home#/stacksets';

  const getIntegrationLinkComponent = () => (
    <div className={styles.awsIntegrationLink}>
      <JitButton
        data-testid='awsIntegrationLink'
        disabled={disabled}
        onClick={handleLinkClick}
        variant='contained'
      >
        <JitText text='dialogs.integration.aws.linkMessage.integrationMessage' />

        {isLoading && (
        <JitIcon className={styles.loadingIcon} icon={Loading} size={30} />
        )}
      </JitButton>

    </div>
  );
  if (integrationType === 'org') {
    return (
      <div>
        <ol>
          <li>
            <JitText
              className={styles.text}
              size='s'
              text='dialogs.integration.aws.linkMessage.enableStackSets'
            />

            <a aria-disabled href={enableStackSetsUrl} rel='noreferrer' target='_blank'>
              {i18n.t('dialogs.integration.aws.linkMessage.stackSetMessage')}
            </a>
          </li>

          <li>
            <JitText className={styles.middleText} size='s' text='dialogs.integration.aws.linkMessage.createStackSet' />
          </li>

        </ol>

        {getIntegrationLinkComponent()}
      </div>
    );
  }
  return (
    <div>
      <JitText
        bold
        className={styles.text}
        size='m'
        text='dialogs.integration.aws.linkMessage.readAccessAccountExplain'
      />

      <JitText size='m' text='dialogs.integration.aws.linkMessage.linkAccountExplain' />

      <br />

      {getIntegrationLinkComponent()}
    </div>
  );
};
