import { FC } from 'react';

import styles from './WorkflowNew.module.scss';

import { Dot } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { WORKFLOW_STATUS_TO_COLOR } from 'pages/PipelinesPage/constants';
import { getStatusFromAssets } from 'pages/PipelinesPage/utils/utilsNew';
import { Runner } from 'types/enums/Runner';
import { IMouseEvent } from 'types/interfaces';
import { IAssetNew, IAssetFieldsNew, IWorkflowFieldsNew } from 'types/interfaces/Pipelines/IPipelineNew';
import { stopPropagation } from 'utils';

interface Props {
  workflowSlug: string;
  workflow: IWorkflowFieldsNew;
  jobRunner: string;
  prLink: string;
  shouldDisableLink?: boolean;
}

export const WorkflowNew: FC<Props> = ({ workflowSlug, workflow, jobRunner, prLink, shouldDisableLink, children }) => {
  const shouldBeClickable = !shouldDisableLink && Object.values(Object.values(workflow.assets)[0].jobs).some(((job) => job.originalRepoExternalJobId));
  const handleClickOnWorkflow = (e: IMouseEvent) => {
    if (!shouldBeClickable) return;
    const jobKey = Object.values(Object.values(workflow.assets)[0].jobs)[0].originalRepoExternalJobId;
    if (jobRunner === Runner.GITHUB_ACTIONS && jobKey) {
      window.open(`${prLink}/checks?check_run_id=${jobKey}`);
    }
    stopPropagation(e);
  };

  const getWorkflowStatus = (assetsOfWorkflow: IAssetNew) => {
    const assets = Object.values(assetsOfWorkflow) as IAssetFieldsNew[];
    const status = getStatusFromAssets(assets);
    return status;
  };

  return (
    <div className={styles.workflowWrapper} data-testid='WorkflowNew'>
      <div className={styles.workflowHeader}>
        <JitIcon
          className={styles.icon}
          color={WORKFLOW_STATUS_TO_COLOR[getWorkflowStatus(workflow.assets)]}
          icon={Dot}
          size={7}
        />

        <TooltipOnlyOnOverflow tooltipText={workflow.name}>
          <JitText
            key={workflow.name}
            addUnderlineOnHover={shouldBeClickable}
            bold
            className={shouldBeClickable ? (styles.overflowEllipsis) : (`${styles.overflowEllipsis} ${styles.notClickable}`)}
            data-testid={workflowSlug}
            id={workflowSlug}
            noWrap
            onClick={handleClickOnWorkflow}
            overflowHiddenEllipsis
            text={workflow.name}
          />
        </TooltipOnlyOnOverflow>

      </div>

      {children}

    </div>
  );
};
