import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './GettingStartedWizard.module.scss';

import { useWizardSteps } from 'components/GettingStartedWizard/hooks/useWizardSteps';
import { StepsDialog } from 'components/JitDialogs/StepsDialog/StepsDialog';
import { useAuthContext, usePlanInstanceContext, useTenantContext } from 'context';
import { constants } from 'globalConstants';
import { ROLES } from 'wrappers/RBAC/constants';

export enum IGettingStartedWizardStepStatus {
  NOT_STARTED, // the step is not started yet
  ACTIVE, // this is the active current step
  COMPLETE, // the step is complete
}

export interface IGettingStartedWizardStep {
  title: string,
  component: FC,
  status: IGettingStartedWizardStepStatus,
}
export const GettingStartedWizard: FC = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const [didSetInitialStepIndex, setDidSetInitialStepIndex] = useState(false);
  const { hasTriedFetchInstallations, githubInstallation, isLoadingInstallation } = useTenantContext();
  const { pathname } = useLocation();
  const { isPlanExist, isLoadingPlan } = usePlanInstanceContext();

  const { frontEggUser } = useAuthContext();
  const isAdminOrMember = frontEggUser.roles.some((role) => role.key !== ROLES.DEVELOPER);

  const incrementStepIndex = useCallback(() => setStepIndex(stepIndex + 1), [stepIndex]);
  const steps = useWizardSteps(incrementStepIndex);

  const setInitialStepIndex = () => {
    const currStepIndex = steps?.findIndex((step) => step.status === IGettingStartedWizardStepStatus.ACTIVE);
    setStepIndex(currStepIndex);
    setDidSetInitialStepIndex(true);
  };

  useEffect(() => {
    if (!didSetInitialStepIndex && hasTriedFetchInstallations) setInitialStepIndex();
  }, [didSetInitialStepIndex, hasTriedFetchInstallations]); // eslint-disable-line react-hooks/exhaustive-deps

  const isRedirectedBackFromGHInstallation = pathname === constants.GITHUB_INSTALLATION_REDIRECT_PATH;
  const hasLoadingAttributes = isLoadingInstallation || isLoadingPlan;
  const isAlreadyDoneWithOnboarding = !isRedirectedBackFromGHInstallation && !!githubInstallation?.centralized_repo_asset && isPlanExist;
  const hasCompletedAllSteps = steps.every((step) => step.status === IGettingStartedWizardStepStatus.COMPLETE);

  const StepComponent = useMemo(() => (stepIndex < steps.length ? steps[stepIndex].component : () => null), [stepIndex, steps]);

  const isThirdPartyAuthenticationView = pathname === `/${constants.routes.THIRD_PARTY_AUTHENTICATION}`;

  const formattedSteps = steps.map((step, index) => ({
    stepName: step.title,
    isSelected: stepIndex === index,
    isVerified: [IGettingStartedWizardStepStatus.COMPLETE].includes(step.status),
    isIntro: index === 0, // This is the welcome step
    content: <StepComponent />,
  }));

  if (!hasTriedFetchInstallations || hasCompletedAllSteps || isAlreadyDoneWithOnboarding || hasLoadingAttributes || isThirdPartyAuthenticationView || !isAdminOrMember) {
    return null;
  }
  return (
    <div className={styles.background}>
      <StepsDialog height='m' isOpen setStep={setStepIndex} steps={formattedSteps} title='gettingStartedWizard.title' width='xl' withDivider />
    </div>
  );
};
