import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { toBase64 } from './utils/fileEncoder';
import { getParsedPlanItemTemplates } from './utils/getParsedPlanItemTemplates';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import {
  IPlanOld,
  IPlanPartialUpdateRequest,
  IDownloadFileResponse,
  ITenantPlanItem,
  IPlanDetailsOverviewResponse,
  IPlanDetailsResponse,
  IConfigurations,
  IPlanItemTemplateServer,
  IPlanTemplateContent,
  IPlanTemplate,
  IPlanItemTemplate,
  IAsset,
} from 'types/interfaces';
import { IFileMetadata } from 'types/interfaces/Files/IFileMetadata';
import { PlanItemAssetStatus } from 'types/interfaces/PlanItemAssetStatus/IPlanItemAssetStatus';
import { IGetPlanItemTemplateResponse } from 'types/interfaces/PlanTemplate/IGetPlanItemTemplateResponse';
import { IWorkFlowTemplateResponse, IWorkFlowTemplateServer } from 'types/interfaces/PlanTemplate/IWorkFlowTemplateServer';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';
import { yamlLoad } from 'utils/functions/yaml';

const convertWorkflowTemplateObjectToCamelCase = (workFlowTemplateServer: IWorkFlowTemplateServer): IWorkFlowTemplateResponse => ({
  ...workFlowTemplateServer,
  parsedContent: workFlowTemplateServer.parsed_content,
  isScheduled: workFlowTemplateServer.is_scheduled,
});

interface UploadFileResponse {
  file_path: string;
}

const serviceName = 'plans';

interface ConfigurationsUpdateResponse {
  content: {
    sha: string;
  };
  commit: object;
}

interface ConfigurationsResponse {
  sha: string;
  content: IConfigurations;
}

// eslint-disable-next-line max-statements
export const usePlanService = () => {
  const { client } = useClient();

  const getPlans = useCallback(async () => {
    const url = `${serviceName}`;
    return client.get<IPlanDetailsOverviewResponse[]>({
      url,
      allowedStatuses: [200],
    });
  }, [client]);

  const getPlansOld = useCallback(async () => {
    const url = getApiUrls.planService.getPlansOld();
    return client.get<IPlanOld[]>({
      url,
      allowedStatuses: [200, 404],
    });
  }, [client]);

  const getPlanContent = useCallback(() => {
    const url = getApiUrls.planService.getPlanContent();
    return client.get<string>({
      url,
      allowedStatuses: [200, 404],
    });
  }, [client]);

  const partialUpdatePlan = useCallback(async (request: IPlanPartialUpdateRequest) => {
    const url = getApiUrls.planService.partialUpdatePlan(request.slug);
    return client.patch<IPlanOld>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: {
          is_goal: request.is_goal,
        },
      },
    });
  }, [client]);

  const getPlanDetails = useCallback(async (slug: string) => {
    const url = getApiUrls.planService.getPlanDetails(slug);
    return client.get<IPlanDetailsResponse>({
      url,
      allowedStatuses: [200, 404],
    });
  }, [client]);

  const getPlanItems = useCallback(async (slug: string) => {
    const url = getApiUrls.planService.getPlanItems(slug);
    return client.get<ITenantPlanItem[]>({
      url,
      allowedStatuses: [200, 404],
    });
  }, [client]);

  const generatePlanReport = async (slug: string): Promise<string> => {
    const url = getApiUrls.planService.generatePlanReport(slug);

    const response = await client.get<IDownloadFileResponse>({
      url,
      allowedStatuses: [200, 400],
    });

    return response?.data.download_url || '';
  };

  const triggerRerun = useCallback(async (planSlug: string, itemSlug) => {
    const url = getApiUrls.planService.rerunPlanItem(planSlug, itemSlug);
    return client.post({
      url,
      allowedStatuses: [201],
    });
  }, [client]);
  const updateConfigurations = (configurations: IConfigurations, commitSha: string) => {
    const url = getApiUrls.planService.updateConfigurations();
    return client.put<ConfigurationsUpdateResponse>({ url,
      allowedStatuses: [200],
      requestConfig: {
        data: {
          payload: configurations,
          commit: commitSha,
        } } });
  };

  const getAllFilesMetadata = async (): Promise<AxiosResponse<IFileMetadata[]> | undefined> => {
    const url = getApiUrls.planService.getAllFilesMetadata();
    const response = await client.get<IFileMetadata[]>({ url,
      allowedStatuses: [200] });
    if (response?.status && response.status <= 299) {
      response.data = camelizeSnakeCaseKeys(response.data) as IFileMetadata[];
    }
    return response;
  };

  const getIntegrationFile = async (): Promise<AxiosResponse<IFileMetadata> | undefined> => {
    const url = getApiUrls.planService.getIntegrationFile();
    const response = await client.get<IFileMetadata>({ url,
      allowedStatuses: [200, 400, 404] });

    if (response?.status && response.status <= 299) {
      response.data = camelizeSnakeCaseKeys(response.data) as IFileMetadata;
    }
    return response;
  };

  const uploadFileToCentralizedRepo = async (file: File): Promise<AxiosResponse<UploadFileResponse> | undefined> => {
    const url = getApiUrls.planService.uploadFileToCentralizedRepo();
    const data = {
      path: file.name,
      content: await toBase64(file),
    };

    return client.post<UploadFileResponse>({
      url,
      allowedStatuses: [201],
      requestConfig: {
        data,
        headers: { 'content-type': 'application/json' },
      } });
  };

  const getPlanStatusesForAsset = useCallback(async (assetId: string): Promise<PlanItemAssetStatus[] | undefined> => {
    const url = getApiUrls.planService.getPlanStatusesForAsset();
    const result = await client.get<PlanItemAssetStatus[]>({ url,
      allowedStatuses: [200],
      requestConfig: {
        params: {
          asset_id: assetId,
        },
      } });

    if (result && result.status <= 299) {
      result.data = camelizeSnakeCaseKeys(result.data) as PlanItemAssetStatus[];
      return result.data;
    }
    return undefined;
  }, [client]);

  const getAllTemplateWorkflows = useCallback(async (): Promise<IWorkFlowTemplateResponse[] | undefined> => {
    const url = getApiUrls.planService.getAllTemplateWorkflows();
    const response = await client.get<IWorkFlowTemplateServer[] | undefined >({
      url,
      allowedStatuses: [200, 404],
    });
    return response ? response.data?.map(convertWorkflowTemplateObjectToCamelCase) : [];
  }, [client]);

  const getConfigurations = useCallback(async (): Promise<AxiosResponse<ConfigurationsResponse> | undefined> => {
    const url = getApiUrls.planService.getConfigurations();
    return client.get<ConfigurationsResponse>({
      url,
      allowedStatuses: [200],
    });
  }, [client]);

  const getAllPlanTemplates = useCallback(async (): Promise<IPlanTemplate[] | undefined> => {
    const url = getApiUrls.planService.getAllPlanTemplates();
    const templates = await client.get<IPlanItemTemplateServer[]>({
      url,
      allowedStatuses: [200],
    });
    const templatesWithParsedContent = templates?.data?.map((template) => {
      const res = yamlLoad(template.content) as IPlanTemplateContent | undefined;
      if (!res) return undefined;
      const { is_enabled: isEnabled, description, references, author, title, labels, layers, version, onboarding_attributes: onboardingAttributes } = res;
      return {
        ...template,
        description,
        isEnabled,
        reference: references?.[0] || '',
        author,
        title,
        labels,
        layers,
        version,
        onboardingAttributes,
      };
    });
    const parsedTemplates = templatesWithParsedContent?.filter(Boolean) as (IPlanTemplate[] | undefined);
    return parsedTemplates;
  }, [client]);

  const getAllPlanItemTemplates = useCallback(async (): Promise<IPlanItemTemplate[] | undefined> => {
    const url = getApiUrls.planService.getAllPlanItemTemplates();
    const response = await client.get<IGetPlanItemTemplateResponse>({
      url,
      allowedStatuses: [200],
    });
    if (!response?.data?.items) return undefined;
    return getParsedPlanItemTemplates(response.data.items);
  }, [client]);

  const commitPlan = (vendor: string, stringPlan: string) => {
    const url = getApiUrls.planService.commitPlan(vendor);
    return client.post<string>({ url,
      allowedStatuses: [200],
      requestConfig: {
        data: stringPlan,
      },
    });
  };

  interface ReloadAssets {
    created_assets: IAsset[];
    deleted_assets: IAsset[];
  }

  const reloadAwsAccounts = useCallback(async (rootAccountId: string): Promise<AxiosResponse<ReloadAssets> | undefined> => {
    const url = getApiUrls.planService.reloadAwsAccounts();
    return client.post<ReloadAssets>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params: { root_account_id: rootAccountId },
      },
    });
  }, [client]);

  return {
    getPlans,
    getPlansOld,
    getPlanContent,
    partialUpdatePlan,
    getPlanDetails,
    getPlanItems,
    generatePlanReport,
    triggerRerun,
    getAllFilesMetadata,
    getIntegrationFile,
    updateConfigurations,
    uploadFileToCentralizedRepo,
    getPlanStatusesForAsset,
    getAllTemplateWorkflows,
    getConfigurations,
    getAllPlanTemplates,
    getAllPlanItemTemplates,
    commitPlan,
    reloadAwsAccounts,
  };
};
