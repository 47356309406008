import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CardStatusToBorderColor, StatusIndicationIconProps } from '../AWSIntegrationCard/constants';

import { GithubIntegrationWizard } from 'components/GithubIntegrationWizard/GithubIntegrationWizard';
import { JitCard } from 'components/JitCard/JitCard';
import { TABS } from 'components/JitDialogs/AssetsManagementDialog/constants';
import {
  useDialogsContext,
  useTenantContext,
} from 'context';
import { CustomIntegrationCardDefaultProps } from 'context/IntegrationsContext/templates/interfaces';
import { constants } from 'globalConstants';
import { getCurrentInstallationSortedByStatus } from 'pages/IntegrationsPage/utils/getCurrentInstallationSortedByStatus';
import { groupInstallationsByVendor } from 'pages/IntegrationsPage/utils/groupInstallationsByVendor';
import { Dialogs, Vendor } from 'types/enums';
import { ActionButton, ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';

export const GithubIntegrationCard: FC<CustomIntegrationCardDefaultProps> = ({ title, icon, tags }) => {
  const { installations, isGithubIntegrated } = useTenantContext();
  const { showDialog } = useDialogsContext();
  const { pathname } = useLocation();
  const isRedirectedBackFromGHInstallation = pathname === constants.GITHUB_INSTALLATION_REDIRECT_PATH;
  const [showInstallationWizard, setShowInstallationWizard] = useState<boolean>(!!isRedirectedBackFromGHInstallation);

  const showGithubAssetsManagementDialog = () => {
    showDialog({
      type: Dialogs.AssetsManagement,
      props: { defaultTab: TABS.GITHUB },
    });
  };
  const installationsByVendor = groupInstallationsByVendor(installations);
  const githubInstallation = getCurrentInstallationSortedByStatus(Vendor.Github, installationsByVendor);

  useEffect(() => {
    if (isGithubIntegrated) sessionStorage.removeItem(constants.RECENT_LOCATION_ROUTE_KEY);
  }, [isGithubIntegrated]);

  const toggleShowInstallationWizard = () => {
    setShowInstallationWizard((prevState) => !prevState);
  };

  const handleInstallClick = () => {
    sessionStorage.setItem(constants.RECENT_LOCATION_ROUTE_KEY, pathname);
  };

  const configureActionButton: ActionButton = {
    label: 'Configure',
    handleClick: showGithubAssetsManagementDialog,
    variant: ActionButtonVariant.OUTLINED,
  };
  const connectActionButton: ActionButton = {
    label: 'Connect',
    handleClick: toggleShowInstallationWizard,
    variant: ActionButtonVariant.PRIMARY,
  };

  const actionButtons: ActionButton[] = [isGithubIntegrated ? configureActionButton : connectActionButton];

  const status = isGithubIntegrated ? githubInstallation?.status : undefined;
  const statusIcon = StatusIndicationIconProps?.[status as keyof typeof StatusIndicationIconProps];
  const borderColor = CardStatusToBorderColor?.[status as keyof typeof CardStatusToBorderColor];
  const headerIcons = { icons: statusIcon ? [{ icon }, { icon: statusIcon }] : [{ icon }] };

  return (
    <div>
      <JitCard
        actionButtons={actionButtons}
        borderColor={borderColor}
        description='cards.github.descriptions.primary'
        headerIcons={headerIcons}
        tags={tags}
        testid='github'
        title={title || 'vendor.github'}
      />

      {showInstallationWizard && <GithubIntegrationWizard handleClose={toggleShowInstallationWizard} handleInstallClick={handleInstallClick} />}
    </div>
  );
};
