import { useCallback, useMemo, useState } from 'react';

import { IGettingStartedWizardStep, IGettingStartedWizardStepStatus } from 'components/GettingStartedWizard/GettingStartedWizard';
import { InstallJitApp } from 'components/GettingStartedWizard/StepComponents';
import { config } from 'config';
import { useAuthContext } from 'context';
import { createStateToken } from 'services/OauthService/OauthService';
import { Vendor } from 'types/enums';

interface Props {
  isPrevStepCompleted: boolean;
  incrementStepIndex: () => void;
  isRedirectedBackFromGHInstallation: boolean;
  planSlug?: string;
}

export const useGetInstallJitAppStep = ({
  isPrevStepCompleted, incrementStepIndex, isRedirectedBackFromGHInstallation, planSlug,
}: Props): IGettingStartedWizardStep => {
  const [hasClickedContinue, setHasClickedContinue] = useState<boolean>(false);
  const { frontEggUser } = useAuthContext();

  const status = useMemo(() => {
    if (isPrevStepCompleted) {
      if (hasClickedContinue) return IGettingStartedWizardStepStatus.COMPLETE;
      return IGettingStartedWizardStepStatus.ACTIVE;
    }
    return IGettingStartedWizardStepStatus.NOT_STARTED;
  }, [hasClickedContinue, isPrevStepCompleted]);

  const onInstallClick = useCallback(async () => {
    const stateTokenRes = await createStateToken({
      tenant_id: frontEggUser.tenantId,
      vendor: Vendor.Github,
      extra: { initial_plan: planSlug },
    });
    window.location.href = config.getInstallGithubUrl(stateTokenRes.token);
  }, [frontEggUser.tenantId, planSlug]);

  const onContinueButtonClick = useCallback(() => {
    setHasClickedContinue(true);
    incrementStepIndex();
  }, [incrementStepIndex]);

  return useMemo(() => ({
    title: 'gettingStartedWizard.installJitApp.title',
    component: () => (
      <InstallJitApp
        isRedirectedBackFromGHInstallation={isRedirectedBackFromGHInstallation}
        onContinueButtonClick={onContinueButtonClick}
        onInstallClick={onInstallClick}
      />
    ),
    status,
  }), [isRedirectedBackFromGHInstallation, onContinueButtonClick, onInstallClick, status]);
};
