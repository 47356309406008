import { FC } from 'react';

import { FindingIgnoredStatusDropdown } from '..';
import { CopyFindingsButton } from '../CopyFindingsButton/CopyFindingsButton';

import styles from './SelectedFindings.module.scss';

import { Minus } from 'assets';
import { CreateTicketButton } from 'components/CreateTicketButton';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IFinding } from 'types/interfaces';

interface Props {
  isRowOpen: boolean;
  selectedFindingsAmount: number;
  onSetIsIgnored: (selectedStatus: boolean) => void;
  resetSelectedFindings: () => void;
  findings: IFinding[];
  selectedFindingIds: string[];
  isIgnoreRulesReadOnly: boolean;
}

export const FindingsSelectedFindings: FC<Props> = ({
  selectedFindingsAmount,
  onSetIsIgnored,
  resetSelectedFindings,
  isRowOpen,
  findings,
  selectedFindingIds,
  isIgnoreRulesReadOnly,
}) => {
  const selectedFindings = findings.filter((finding) => selectedFindingIds.includes(finding.id));
  const ignoredValues = selectedFindings.map((finding) => finding.ignored);
  const initialStatus = ignoredValues?.[0];
  return (
    <div className={`${styles.wrapper} ${isRowOpen ? styles.rowOpen : styles.rowClosed}`}>
      <div className={styles.amountSelected}>
        <JitButton onClick={resetSelectedFindings} style={{ backgroundColor: colors.blue }}>
          <JitIcon icon={Minus} size={16} />

        </JitButton>

        <JitText bold size='xl' style={{ alignSelf: 'center' }} text={selectedFindingsAmount} />

        <JitText bold size='l' style={{ alignSelf: 'center' }} text='pages.findings.selectedFindings.findingSelected' />

      </div>

      <div className={styles.rowActions}>
        <CreateTicketButton findings={selectedFindings} onClose={resetSelectedFindings} />

        <FindingIgnoredStatusDropdown
          initialStatus={initialStatus}
          isReadOnly={isIgnoreRulesReadOnly}
          onSetIgnoredIndication={onSetIsIgnored}
        />

        <div className={styles.copyButton}>
          <CopyFindingsButton findings={selectedFindings} style={{ height: 35 }} />
        </div>
      </div>
    </div>
  );
};
