import { Link } from 'react-router-dom';

import styles from '../JitBreadcrumbs.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export const renderTextBreadCrumbItem = (text: string, dataTestId?: string) => (
  <JitText color={colors.basicLight} data-testid={dataTestId || 'BreadcrumbItem'} size='l' text={text} />
);

export const renderLinkBreadCrumbItem = (text: string, link?: string, dataTestId?: string) => (
  <Link className={styles.breadcrumbsLink} data-testid={dataTestId ? `${dataTestId}-link` : `${text}-link`} to={link || '/'}>
    {renderTextBreadCrumbItem(text, dataTestId)}
  </Link>
);
