export enum AnalyticsEvents {
  CentralizedRepoSelected = 'centralized-repo-selected',
  CentralizedRepoValidationFailed = 'centralized-repo-validation-failed',
  CentralizedRepoSetRepoClicked = 'centralized-repo-set-repo-clicked',
  CentralizedRepoReloadClicked = 'centralized-repo-reload-clicked',
  CentralizedRepoCreateRepoClicked = 'centralized-repo-create-repo-clicked',
  CentralizedRepoEditAccessClicked = 'centralized-repo-edit-access-clicked',

}

export const GET_SPECIFIC_PLAN_PAGE_ROUTE = (plan_slug: string) => `plans/${plan_slug}`;
