export enum Vendor {
  Github = 'github',
  AWS = 'aws',
  AZURE = 'azure',
  GCP = 'gcp',
  Domain = 'domain',
  SLACK = 'slack',
  Jira = 'jira',
  Shortcut = 'shortcut',
  Drata = 'drata',
  LINEAR = 'linear',
  JIT = 'jit',
  SEMGREP = 'semgrep',
  GHSECURITY = 'github_security',
  ZAP_API = 'zapApi',
  ZAP_WEB = 'zapWeb',
  GITLAB = 'gitlab',
  BITBUCKET = 'bitbucket',
  AZURE_REPOS = 'azurerepos',
  GOSEC = 'gosec',
  BANDIT = 'bandit',
  CHECKMARX = 'checkmarx',
  GITLEAKS = 'gitleaks',
  NPM_AUDIT = 'npmaudit',
  OSV_SCANNER = 'osvscanner',
  NANCY = 'nancy',
  DEPENDABOT = 'dependabot',
  KICS = 'kics',
  KUBESCAPE = 'kubescape',
  TRIVY = 'trivy',
  PRISMA = 'prisma',
  PROWLER = 'prowler',
  CHAIN_BENCH = 'chain_bench',
  ZAP = 'zap',
  NPM = 'npm',
}
