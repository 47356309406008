import { FC, ReactNode } from 'react';

import { JitTooltip } from '../JitTooltip/JitTooltip';
import { TooltipOnlyOnOverflow } from '../TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';

import styles from './JitCard.module.scss';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTag } from 'components/JitTag/JitTag';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';
import { ActionButton } from 'types/interfaces/ActionButton/ActionButton';
import { Link } from 'types/interfaces/Link/Link';
import { ExtractProps } from 'utils/functions/extractProps';

export interface Tag {
  color: string;
  text: string;
}

export interface HeaderIcon extends ExtractProps<typeof JitIcon>{
  icon: FC<ISvg>;
  tooltip?: string;
}

export type HorizontalAlign = 'left' | 'right';
const defaultHorizontalAlign = 'left';

interface HeaderIcons {
  icons: HeaderIcon[];
  horizontalAlign?: HorizontalAlign;
}

const flexDirectionStyleMap = new Map<HorizontalAlign, string>([
  ['left', styles.headerIconsAlignLeft],
  ['right', styles.headerIconsAlignRight],
]);

interface Props {
  headerIcons?: HeaderIcons;
  title?: string;
  subTitle?: string;
  description?: string;
  actionButtons?: ActionButton[];
  tags?: Tag[];
  testid?: string;
  link?: Link;
  borderColor?: string;
  backgroundColor?: string;
  height?: string;
  width?: string;
  children?: ReactNode;
  cardClassName?: string;
}

export const JitCard: FC<Props> = ({ borderColor, backgroundColor, headerIcons, title, subTitle, description, actionButtons, testid, tags, link, height, width, children }) => (
  <div
    className={styles.generalCardContainer}
    data-testid={`jit-card-${testid}`}
    style={{
      border: borderColor ? `1px solid ${borderColor}` : undefined,
      height: height || undefined,
      width: width || undefined,
      background: backgroundColor || undefined,
    }}
  >
    <div className={styles.cardContentStyle}>
      <div className={`${styles.headerIcons} ${headerIcons?.horizontalAlign ? flexDirectionStyleMap.get(headerIcons.horizontalAlign || defaultHorizontalAlign) : ''}`}>
        {headerIcons?.icons?.map((icon, index) => {
          // eslint-disable-next-line react/no-array-index-key
          const iconComponent = (
            <JitIcon
              size={30}
              {...icon}
              // eslint-disable-next-line react/no-array-index-key
              key={`jit-card-icon-${index}`}
              data-testid={`card-icon-${icon['data-testid'] || index}`}
            />
          );
          if (icon.tooltip) {
            return (
            // eslint-disable-next-line react/no-array-index-key
              <JitTooltip key={`jit-card-tooltip-${index}`} followCursor={false} placement='top' title={icon.tooltip}>
                {iconComponent}
              </JitTooltip>
            );
          }
          return iconComponent;
        })}
      </div>

      <TooltipOnlyOnOverflow bold className={styles.title} data-testid='card-title' height='25px' noWrap overflowHiddenEllipsis size='l' text={title} />

      <JitText bold className={styles.subTitle} data-testid='card-subTitle' size='m' text={subTitle} />

      <div className={styles.tagsWrapper}>
        {tags?.map((tag) => <JitTag key={`${title}-${tag.text}`} color={tag.color} text={tag.text} />)}
      </div>

      <TooltipOnlyOnOverflow text={description}>
        <JitText
          className={styles.description}
          color={colors.lightGray}
          data-testid='card-description'
          overflowHiddenEllipsis
          text={description}
        />
      </TooltipOnlyOnOverflow>

      {link && (
        <div className={styles.link}>
          <JitExternalLink data-testid='card-link' href={link.href} text={link.text} />
        </div>
      )}

      {children}

    </div>

    {actionButtons && (
      <div className={styles.cardBottom}>
        <div className={styles.actionButtonsWrapper} data-testid='actionButtons'>
          {actionButtons.map((actionButton) => <JitActionButton key={`actionButton-${actionButton.label}`} actionButton={actionButton} />)}
        </div>
      </div>
    )}
  </div>
);
