import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { FC } from 'react';

import { StyledLinearProgress } from './AppLoadingBar.styles';

import colors from 'themes/colors.module.scss';

export const AppLoadingBar: FC = () => (
  <Dialog open PaperProps={{ style: { width: 300 } }} style={{ backgroundColor: colors.black02 }} transitionDuration={0}>
    <Box sx={{ width: '100%' }}>
      <StyledLinearProgress data-testid='appLoadingBar' value={50} />
    </Box>
  </Dialog>
);
