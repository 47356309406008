import Avatar from '@mui/material/Avatar';
import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import styles from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/Cells/MembersCell/MembersCell.module.scss';
import { ITableCell } from 'types/interfaces';
import { IMember } from 'types/interfaces/Teams/ITeam';

const MAX_MEMBERS_TO_DISPLAY = 5;

export const MembersCell: FC<ITableCell> = ({ cell: { value } }) => (
  <div className={styles.wrapper}>
    {value.members.slice(0, MAX_MEMBERS_TO_DISPLAY).map((member: IMember) => (
      <JitTooltip
        key={member.id}
        followCursor={false}
        offset={[0, 4]}
        placement='top'
        title={member.name}
      >
        <Avatar
          src={member.avatarUrl}
          sx={{
            width: 24,
            height: 24,
          }}
        />
      </JitTooltip>
    ))}

    {value.members.length > MAX_MEMBERS_TO_DISPLAY && (
      <JitText horizontalAlign='center' muted size='s' text={`+${value.members.length - MAX_MEMBERS_TO_DISPLAY}`} />
    )}
  </div>
);
