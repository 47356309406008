import React from 'react';

import { IAnalyticsEvent } from '../Analytics';

export enum ActionButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  OUTLINED = 'outlined',
  DANGER = 'danger',
  SECONDARY_DANGER = 'secondaryDanger',
  JIT_PRIMARY = 'jitPrimary',
  JIT_OUTLINED = 'jitOutlined',
}

export interface ActionButton {
  label: string
  handleClick: ((event: React.MouseEvent<HTMLButtonElement>) => void) | (() => void)
  variant?: ActionButtonVariant
  disabled?: boolean
  tooltip?: string
  isLoading?: boolean
  shouldShowChildrenWhileLoading?: boolean;
  analytics?: IAnalyticsEvent;
}
