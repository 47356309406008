import { useFindingsContext } from 'context/FindingsContext/FindingsContext';
import { IFindingFilter } from 'types/interfaces';

export const useAddFilterToEndOfList = () => {
  const { filters, setFilters } = useFindingsContext();

  const addFilterToEndOfList = (newFilter: IFindingFilter) => {
    const currentFilters = filters.filter((currentFilter) => currentFilter.key !== newFilter.key);
    currentFilters.push(newFilter);
    setFilters(currentFilters);
  };
  return { addFilterToEndOfList };
};
