import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';

import { initialDevCalculationState, initialMetricsState, metricCalls } from './initialMetricsState';

import {
  getDevEfficiencyCalculation, getDevProductivityMetric,
} from 'services/MetricService/MetricService';
import { useMetricService } from 'services/MetricService/useMetricService';
import { OverviewMetricNames } from 'types/enums/MetricNames';
import { DevProductivityCalculationGetRequest } from 'types/interfaces/Metrics/DevEfficiencyCalculation';

export const useFetchMetrics = () => {
  const [metrics, setMetrics] = useState(initialMetricsState);
  const [devProductivityCalculation, setDevProductivityCalculation] = useState<DevProductivityCalculationGetRequest>(initialDevCalculationState);
  const { patchDevEfficiencyCalculation } = useMetricService();

  const setMetric = useCallback((metricName: OverviewMetricNames, metric) => {
    setMetrics((prevState) => ({
      ...prevState,
      [metricName]: metric,
    }));
  }, []);

  const fetchDevCalculation = useCallback(async (isSilent?: boolean) => {
    if (!isSilent) {
      setDevProductivityCalculation({ isLoading: true });
    }
    const devCalculation = await getDevEfficiencyCalculation();
    setDevProductivityCalculation({ isLoading: false,
      devProductivityCalculation: devCalculation });
  }, [setDevProductivityCalculation]);

  const fetchMetrics = useCallback(async () => {
    metricCalls.forEach(({ metric, fetchMetric }) => {
      setMetric(metric, { isLoading: true });
      fetchMetric().then((result) => {
        const isResultEmpty = result && isEmpty(result);
        // We make sure that the result returned valid information and not an empty object.
        setMetric(metric, {
          metric: isResultEmpty ? undefined : result,
          isLoading: false,
        });
      });
    });
  }, [setMetric]);

  const silentFetchDevProductivity = useCallback(async () => {
    const devProductivityMetric = await getDevProductivityMetric();
    setMetric(OverviewMetricNames.TrendingDevProductivity, { isLoading: false,
      metric: devProductivityMetric });
  }, [setMetric]);

  return {
    fetchMetrics,
    metrics,
    fetchDevCalculation,
    devProductivityCalculation,
    patchDevEfficiencyCalculation,
    silentFetchDevProductivity,
  };
};
