import { FronteggThemeOptions } from '@frontegg/react';

import styles from './LoginPage.module.scss';

import { AicpaSoc, CyberSecurity, Slashdot, SourceForge } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { i18n } from 'locale/i18n';
import { LoginPage } from 'pages/LoginPage/LoginPage';
import colors from 'themes/colors.module.scss';

const fontFamily = 'Inter';

const fixedBackgroundStyle = {
  boxShadow: 'none',
  backgroundColor: 'rgb(229, 229, 229)',
};
const fixedPageBackgroundStyle = {
  ...fixedBackgroundStyle,
  '& div.MuiPaper-root': {
    ...fixedBackgroundStyle,
  },
};

export const fronteggLoginThemeOptions: FronteggThemeOptions = {
  loginBox: {
    logo: {
      style: {
        display: 'none',
      },
    },
    socialLogins: {
      githubButtonStyle: {
        base: {
          maxWidth: '65%',
          fontFamily,
          height: '100%',
          backgroundColor: colors.jitPink,
          color: colors.white,
          path: { fill: colors.white }, // GH Icon color
        },
        hover: {
          opacity: 0.6,
          backgroundColor: colors.jitPink,
        },
      },
    },
    login: {
      title: i18n.t('pages.login.getStartedForFree'),
      typographyStyleOptions: { fontFamily },
      titleStyle: {
        color: colors.cards,
        fontSize: '30px',
        fontFamily,
        fontWeight: '500',
      },
      rootStyle: fixedPageBackgroundStyle,
      layout: {
        type: 'float-right',
        sideElement: LoginPage,
      },
    },

    signup: {
      hideSignUpForm: true,
      rootStyle: fixedPageBackgroundStyle,
      layout: {
        type: 'float-right',
        sideElement: LoginPage,
      },
    },

    boxFooter: () => (
      <div className={styles.loginBoxWrapper}>

        <JitText
          align='center'
          color={colors.darkGray}
          components={{
            termsOfService: (
              <JitText
                color={colors.darkGray}
                display='inline'
                onClick={() => window.open(config.docsTermsOfServiceLink)}
                size='l'
                text='pages.login.termsOfService'
                withUnderline
              />
            ),
            privacyPolicy: (
              <JitText
                color={colors.darkGray}
                display='inline'
                onClick={() => window.open(config.docsPrivacyPolicyLink)}
                size='l'
                text='pages.login.privacyPolicy'
                withUnderline
              />
            ),
          }}
          muted
          size='l'
          text='pages.login.disclosure'
        />

        <div className={styles.awardBadgesBlock}>
          <JitIcon icon={AicpaSoc} size={42} />

          <JitIcon icon={SourceForge} size={42} />

          <JitIcon icon={CyberSecurity} size={42} />

          <JitIcon icon={Slashdot} size={42} />

        </div>
      </div>
    ),
  },
  adminPortal: {
    themeName: 'dark',
    palette: {
      primary: {
        light: colors.iris,
        main: colors.iris,
        dark: colors.iris,
        hover: colors.iris,
        active: colors.iris,
        contrastText: colors.iris,
      },
    },
    layout: {
      fullScreenMode: false,
    },
    pages: {
      users: {
        hideInviteWithEmail: true,
      },
      header: {
        backgroundColor: colors.generalSystemsBG,
      },
      content: {
        backgroundColor: colors.generalSystemsBG,
      },
    },
  },
};

