import { useAuthUser, useTenantsState } from '@frontegg/react';
import { FC, useState } from 'react';

import styles from './SelectTenantPopperContent.module.scss';

import { TenantRow } from 'components/Navbar/components/SelectTenantPopperContent/components/TenantRow/TenantRow';
import { UserDetails } from 'components/Navbar/components/SelectTenantPopperContent/components/UserDetails/UserDetails';

export const SelectTenantPopperContent: FC = () => {
  const frontEggUser = useAuthUser();
  const { tenants } = useTenantsState();
  const [hoveredTenant, setHoveredTenant] = useState<string>();

  const sortedTenants = [...tenants];
  sortedTenants.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div className={styles.contentWrapper} data-testid='SelectTenantPopperContent'>
      <UserDetails email={frontEggUser.email} profilePictureUrl={frontEggUser.profilePictureUrl} />

      <div className={styles.tenantsList}>
        {sortedTenants.map(({ tenantId, name }, index) => (
          <TenantRow
            key={tenantId}
            isActiveTenant={frontEggUser.tenantId === tenantId}
            isHoveredTenant={tenantId === hoveredTenant}
            isLast={index === sortedTenants.length - 1}
            setHoveredTenant={setHoveredTenant}
            tenantId={tenantId}
            tenantName={name}
          />
        ))}

      </div>

    </div>
  );
};
