import { FC, UIEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { JittyNotFoundDouble, JittyTheExplorer } from 'assets';
import { MissingGithubIntegration } from 'components/JitTable/components';
import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { calcShouldFetchMore } from 'components/JitTable/utils';
import { useTenantContext } from 'context';
import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { constants } from 'globalConstants';
import { TEAM_ROW_APPROXIMATE_HEIGHT } from 'pages/TeamsPage/constants';
import styles from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/TeamsTable.module.scss';
import { useGetColumns } from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/useGetColumns';
import { TagNames } from 'types/enums/Tags';
import { IGetTeamResponseItem, ITeam } from 'types/interfaces/Teams/ITeam';

interface TeamsTableProps {
  teams: IGetTeamResponseItem[];
  searchValue?: string;
}

export const TeamsTable: FC<TeamsTableProps> = ({ teams, searchValue }) => {
  const { getTeams, teams: { hasReachedEnd, isLoading } } = useTeamsContext();
  const { isGithubIntegrated } = useTenantContext();

  const { assets } = useAssetsContext();
  const navigate = useNavigate();

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    if (isLoading || hasReachedEnd) return;

    const shouldFetchMore = calcShouldFetchMore(event, TEAM_ROW_APPROXIMATE_HEIGHT);
    if (shouldFetchMore) {
      getTeams(false, searchValue);
    }
  };

  const onSelectRow = (row: ITeam) => {
    navigate(`/${constants.routes.TEAMS}/${row.id}`);
  };

  const teamsWithAssetsCount: (IGetTeamResponseItem & { assetsCount: number })[] = useMemo(() => {
    if (!isGithubIntegrated) return [];
    return teams.map(
      (team) => ({
        ...team,
        assetsCount: assets.filter((asset) => {
          const teamTagsValues = asset.tags.filter((tag) => tag.name === TagNames.Teams).map((tag) => tag.value);
          return teamTagsValues.includes(team.name);
        }).length,
      }),
    );
  }, [isGithubIntegrated, assets, teams]);

  const emptyTableViewAtt = searchValue ? {
    emptyTableSubtext: 'pages.teams.teamsTable.noSearchResults.description',
    emptyTableText: 'pages.teams.teamsTable.noSearchResults.title',
    jittyIcon: JittyTheExplorer,
  } : {
    emptyTableSubtext: 'pages.teams.teamsTable.noData.description',
    emptyTableText: 'pages.teams.teamsTable.noData.title',
    jittyIcon: JittyNotFoundDouble,
  };

  const emptyTableViewComp = useMemo(() => (
    !isGithubIntegrated ? (
      <MissingGithubIntegration
        subtitle='pages.teams.teamsTable.missingGithubIntegration'
      />
    ) : undefined
  ), [isGithubIntegrated]);

  const columns = useGetColumns();
  const tableInstance = useGetTableInstance(columns, teamsWithAssetsCount);
  return (
    <div className={styles.tableWrapper}>
      <JitTable
        cellVerticalAlign='center'
        emptyTableView={emptyTableViewComp}
        handleScroll={handleScroll}
        isFetching={isGithubIntegrated && isLoading}
        isLoading={isGithubIntegrated && isLoading && !teams.length}
        onSelectRow={onSelectRow}
        rowHoverStyle={{ cursor: 'pointer' }}
        showPagination={false}
        tableDesign='bright'
        tableInstance={tableInstance}
        {...emptyTableViewAtt}
      />

    </div>
  );
};
