import { FC } from 'react';

import styles from './FindingsTableGeneralHeader.module.scss';

import { ArrowDown } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { SortDirections } from 'types/enums';
import { ServerFindingKeys } from 'types/interfaces';

interface Props {
  columnKey: ServerFindingKeys;
  columnName: string;
  sortDirection?: SortDirections;
  onChangeSortOrder?: (columnKey: ServerFindingKeys, columnName: string) => void;
}

export const FindingsTableGeneralHeader: FC<Props> = ({
  columnKey,
  columnName,
  onChangeSortOrder,
  sortDirection,
}) => {
  const hoveredClassName = onChangeSortOrder ? styles.hovered : '';
  const isAscending = sortDirection === SortDirections.ASC;
  return (
    <div
      className={`${styles.container} ${hoveredClassName}`}
      onClick={() => (onChangeSortOrder ? onChangeSortOrder(columnKey, columnName) : undefined)}
      role='button'
      tabIndex={0}
    >
      <JitText size='s' style={{ alignSelf: 'center' }} text={columnName} />

      {onChangeSortOrder && (<JitIcon className={styles.icon} icon={ArrowDown} rotateDegrees={isAscending ? 180 : 0} size={16} />)}

    </div>
  );
};

