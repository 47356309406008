
import { FC } from 'react';

import style from './PlusFiltersAmount.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  amountOfFilters: number;
}

export const PlusFiltersAmount: FC<Props> = ({ amountOfFilters }) => (
  <div className={style.plusFiltersAmount}>
    <JitText
      bold
      color={colors.black}
      size='s'
      style={{
        marginTop: -1,
        paddingRight: 3,
        paddingLeft: 2 }}
      text={`+${amountOfFilters}`}
    />
  </div>
);
