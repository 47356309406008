import { FC, useEffect, useState } from 'react';

import { JitDropdown } from 'components/JitDropdown/JitDropdown';
import { i18n } from 'locale/i18n';
import { IgnoreStatusOptions } from 'pages/FindingsPage/constants';

interface Props {
  initialStatus?: boolean ;
  onSetIgnoredIndication: (selectedIgnoredIndication: boolean) => void;
  isReadOnly?: boolean;
}

export const FindingIgnoredStatusDropdown: FC<Props> = ({
  onSetIgnoredIndication,
  initialStatus,
  isReadOnly,
}) => {
  const { t } = i18n;

  const [currentIgnoredIndication, setCurrentIgnoredIndication] = useState<boolean | undefined>(initialStatus);

  useEffect(() => {
    setCurrentIgnoredIndication(initialStatus);
  }, [initialStatus]);
  const filterOptions = IgnoreStatusOptions.filter((option) => option.key !== currentIgnoredIndication?.toString());
  let currentIgnoredText = 'pages.findings.selectedFindings.DefaultPlaceholder';
  if (currentIgnoredIndication !== undefined) {
    const ignoredValueText = t(`pages.findings.ignored.${currentIgnoredIndication}`);
    currentIgnoredText = `Ignored: ${ignoredValueText}`;
  }
  const onIgnoredChange = (selectedResult: string[]) => {
    const [isIgnored] = selectedResult;
    onSetIgnoredIndication(isIgnored === 'true');
    setCurrentIgnoredIndication(Boolean(isIgnored));
  };
  return (
    <JitDropdown
      checkedFilters={[currentIgnoredText || '']}
      filterOptions={filterOptions}
      isReadOnly={isReadOnly}
      placeholder='pages.findings.selectedFindings.IgnoredPlaceholder'
      setCheckedFilters={onIgnoredChange}
    />
  );
};

