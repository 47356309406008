import { startCase } from 'lodash';
import { useCallback, useMemo } from 'react';

import { INTEGRATION_ERROR_TOAST_ID } from '../constants';

import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useFilesContext } from 'context/FilesContext';
import { IActiveToast } from 'context/ToastsContext/interfaces';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { ToastType } from 'types/enums';
import { FileEntityType } from 'types/enums/FileEntityType';
import { IIntegration, IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

export const useHandleIntegrationError = (integrations: IIntegration[] | undefined) => {
  const { showToast, hideToast, activeToasts } = useToastsContext();
  const { filesMetadata } = useFilesContext();

  const failedIntegration = useMemo(() => integrations?.find((integration) => integration.status === IntegrationStatus.FAILURE), [integrations]);
  const toastId = useMemo(() => (failedIntegration ? `${INTEGRATION_ERROR_TOAST_ID}-${failedIntegration.vendor}-${failedIntegration.reason}` : INTEGRATION_ERROR_TOAST_ID), [failedIntegration]);

  // If there is active failed integration toast with the same data leave it, if not hide it and show the new one.
  // If there isn't active failed integration toast, show the new one.
  const activeIntegrationToastId = useMemo(() => activeToasts.findIndex((toast) => toast.toastId?.includes(INTEGRATION_ERROR_TOAST_ID)), [activeToasts]);
  const isActiveToastDifferentData = activeIntegrationToastId !== -1 && activeToasts[activeIntegrationToastId].toastId !== toastId;
  const shouldShowNewErrorToast = failedIntegration && (activeIntegrationToastId === -1 || isActiveToastDifferentData);
  const shouldHideCurrentErrorToast = (!failedIntegration && activeIntegrationToastId > -1) || isActiveToastDifferentData;

  const integrationFileMetadata = useMemo(() => filesMetadata?.find((fileMetadata) => fileMetadata.type === FileEntityType.INTEGRATION_FILE), [filesMetadata]);
  const fileLink = useMemo(
    () => ((integrationFileMetadata && integrationFileMetadata.owner && integrationFileMetadata.repo && integrationFileMetadata.filePath && integrationFileMetadata.branch)
      ? config.getGithubRefFileLink(integrationFileMetadata.owner, integrationFileMetadata.repo, integrationFileMetadata.branch, integrationFileMetadata.filePath) : undefined),
    [integrationFileMetadata],
  );

  const integrationErrorToast: IActiveToast = useMemo(() => ({
    type: ToastType.SystemError,
    toastId,
    overrideProps: {
      content: (
        <>
          <JitText bold text='toasts.failedIntegration.title' />

          <div>
            <JitText
              components={{
                linkText: fileLink ? (
                  <JitText
                    onClick={() => {
                      window.open(fileLink);
                    }}
                    size='l'
                    text={`${startCase(failedIntegration?.vendor)} integration file`}
                    withUnderline
                  />
                ) : 'integration',
              }}
              sx={{
                display: 'flex',
                gap: '0.2em',
                alignItems: 'center',
              }}
              text='toasts.failedIntegration.subtitle'
            />

            {failedIntegration?.reason ? (
              <JitText size='l' text={`<b>Reason</b>: ${failedIntegration.reason}`} />
            ) : ''}
          </div>
        </>
      ),
    },
  }), [failedIntegration?.reason, failedIntegration?.vendor, fileLink, toastId]);

  const handleIntegrationError = useCallback(() => {
    if (shouldShowNewErrorToast) {
      showToast(integrationErrorToast);
    }
    if (shouldHideCurrentErrorToast) {
      hideToast(activeIntegrationToastId);
    }
  }, [activeIntegrationToastId, hideToast, integrationErrorToast, shouldHideCurrentErrorToast, shouldShowNewErrorToast, showToast]);

  return { handleIntegrationError };
};
