import { t } from 'i18next';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import { GoalCard } from './GoalCard';
import styles from './GoalsList.module.scss';

import { RightArrow, RightArrowDisabled, RightArrowHover } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { OnboardingDetailsContent } from 'types/interfaces';
import { IGoalCard, goalCards } from 'utils/constants/goalsToPlanMap';

interface Props {
  readonly setOnboardingDetails: Dispatch<SetStateAction<OnboardingDetailsContent>>,
}

export const GoalCardsList: FC<Props> = ({ setOnboardingDetails }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [isRightArrowHover, setIsRightArrowHover] = useState(false);
  const [isLeftArrowHover, setIsLeftArrowHover] = useState(false);

  const [selectedCard, setSelectedCard] = useState<IGoalCard | null>(null);

  useEffect(() => {
    const checkScroll = () => {
      if (scrollRef.current) {
        const { scrollLeft, clientWidth, scrollWidth } = scrollRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
      }
    };

    checkScroll();

    const scrollContainer = scrollRef.current;
    scrollContainer?.addEventListener('scroll', checkScroll);

    return () => scrollContainer?.removeEventListener('scroll', checkScroll);
  }, [scrollRef]);

  const onScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300,
        behavior: 'smooth' });
    }
  };

  const onScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300,
        behavior: 'smooth' });
    }
  };

  const activeRightArrowIcon = isRightArrowHover ? RightArrowHover : RightArrow;
  const activeLeftArrowIcon = isLeftArrowHover ? RightArrowHover : RightArrow;

  const onSelectCard = useCallback((card: IGoalCard) => {
    setSelectedCard(card);
    setOnboardingDetails((prev) => ({
      ...prev,
      goal: t(card.goal),
      plan: card?.plan,
    }));
  }, [setOnboardingDetails]);

  return (
    <div className={styles.cardsWrapper} data-testid='carousel-container'>
      {canScrollLeft && (
      <JitIcon
        className={styles.scrollLeft}
        color='none'
        data-testid='scroll-left-button'
        icon={activeLeftArrowIcon}
        onClick={onScrollLeft}
        onMouseEnter={() => setIsLeftArrowHover(true)}
        onMouseLeave={() => setIsLeftArrowHover(false)}
        rotateDegrees={180}
        size={28}
      />
      )}

      <div
        ref={scrollRef}
        className={styles.carouselContainer}
        style={{
          padding: canScrollLeft ? '0 33px 0 0' : '0 0 0 60px',
          transition: 'padding 0.2s',
        }}
      >
        {goalCards.map((goalCard, index) => (
          <GoalCard
            key={goalCard.goal}
            data-testid={`goal-card-${index}`}
            description={goalCard.goal}
            handleClick={() => onSelectCard(goalCard)}
            icon={goalCard.icon}
            isSelected={goalCard.key === selectedCard?.key}
          />
        ))}
      </div>

      {!canScrollRight
        ? (<JitIcon className={styles.scrollRight} color='none' data-testid='scroll-right-button' icon={RightArrowDisabled} size={28} />)
        : (
          <JitIcon
            className={styles.scrollRight}
            color='none'
            data-testid='scroll-right-button'
            icon={activeRightArrowIcon}
            onClick={onScrollRight}
            onMouseEnter={() => setIsRightArrowHover(true)}
            onMouseLeave={() => setIsRightArrowHover(false)}
            size={28}
          />
        )}
    </div>
  );
};
