import { FC } from 'react';

import { PremiumLock } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import styles from 'pages/SbomPage/components/SbomTable/PaywallGradiant/PaywallGradiant.module.scss';

export const PaywallGradiant: FC = () => (
  <div className={styles.paywallGradient} data-testid='paywall-gradient'>

    <div className={styles.titleAndLockWrapper}>
      <JitIcon icon={PremiumLock} />

      <JitText bold text='pages.sbom.table.paywallGradiant.title' />
    </div>

    <div className={styles.descriptionWrapper}>
      <JitText text='pages.sbom.table.paywallGradiant.description' />
    </div>

    <div className={styles.buttonWrapper}>
      <JitButton
        href={config.contactUsUrl}
        target='_blank'
        variant='contained'
      >
        <JitText text='pages.sbom.table.paywallGradiant.contactUs' />
      </JitButton>
    </div>
  </div>
);
