import { FC } from 'react';

import styles from './DevelopersCountInput.module.scss';

import { JitText } from 'components/JitText/JitText';
import { JitToggleButtonGroup, ToggleButtonGroupOption } from 'components/JitToggleButtonGroup/JitToggleButtonGroup';
import colors from 'themes/colors.module.scss';

const options: ToggleButtonGroupOption[] = [{
  key: 'up-to-10',
  value: '<=10',
  label: 'pages.onboarding.formStep.numberOfDevelopers.options.1',
}, {
  key: '11-to-100',
  value: '11-100',
  label: 'pages.onboarding.formStep.numberOfDevelopers.options.2',
}, {
  key: '101-to-500',
  value: '101-500',
  label: 'pages.onboarding.formStep.numberOfDevelopers.options.3',
}, {
  key: '501-and-more',
  value: '501=<',
  label: 'pages.onboarding.formStep.numberOfDevelopers.options.4',
}];

interface Props {
  readonly initialValue?: string;
  readonly setValue: (e: string | undefined) => void;
}

export const DevelopersCountInput: FC<Props> = ({ initialValue, setValue }) => (
  <div className={styles.wrapper}>

    <JitText bold color={colors.gray} text='pages.onboarding.formStep.numberOfDevelopers.label' />

    <div className={styles.toggleButtonGroupWrapper}>

      <JitToggleButtonGroup onChange={setValue} options={options} value={initialValue} />
    </div>
  </div>

);
