import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { FC, useState } from 'react';

import { Cross } from 'assets';
import { IntegrationDialogContent } from 'components/JitDialogs/IntegrationDialog/components';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { AwsIntegrationType } from 'types/enums';
import { AwsIntegrationAccount, IChangeInputEvent } from 'types/interfaces';

const paperStyle = {
  backgroundColor: colors.cards,
  boxShadow: 'none',
  border: `1px solid ${colors.darkGray}`,
  borderRadius: 8,
  width: 550,
  height: 600,
  backgroundImage: 'none',
};

interface Props {
  integration: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  vendor: string;
  onClose: () => void
}

export const AwsConnectDialog: FC<Props> = ({ integration, vendor, onClose }) => {
  const [integrationFields, setIntegrationFields] = useState<AwsIntegrationAccount>({
    type: '',
    account_id: '',
    account_name: '',
    regions: [],
    deploy_region: '',
  });
  const [isEdit, setIsEdit] = useState(true);
  const [hasNextSelected, setHasNextSelected] = useState(false);

  const handleSelectChange = (regions: string[]) => {
    setIntegrationFields({
      ...integrationFields,
      regions,
    });
  };

  const handleStackRegionSelectChange = (deploy_region: string) => {
    setIntegrationFields({
      ...integrationFields,
      deploy_region,
    });
  };

  const handleChange = (e: IChangeInputEvent) => {
    const { name, value } = e.target;
    setIntegrationFields({
      ...integrationFields,
      [name]: value,
    });
  };

  const handleSelectType = (type?: AwsIntegrationType) => {
    setIntegrationFields({
      ...integrationFields,
      type,
    });
  };

  const handleNextClick = () => {
    setHasNextSelected(true);
    setIsEdit(false);
  };

  const validateFields = () => integrationFields.regions.length > 0;

  return (
    <Dialog
      maxWidth='xl'
      onClose={onClose}
      open
      PaperProps={{ style: paperStyle }}
      scroll='paper'
    >
      <DialogTitle
        data-testid='dialogTitle'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >

        <JitText
          bold
          size='l'
          text='dialogs.integration.aws.title'
        />

        <IconButton
          className='pt-1 overflow-hidden'
          onClick={onClose}
          style={{
            width: '1.2em',
            height: '1.2em',
          }}
        >
          <JitIcon icon={Cross} size='1em' />
        </IconButton>

      </DialogTitle>

      <Divider />

      <IntegrationDialogContent
        handleChange={handleChange}
        handleNextClick={handleNextClick}
        handleSelectChange={handleSelectChange}
        handleSelectType={handleSelectType}
        handleStackRegionSelectChange={handleStackRegionSelectChange}
        hasNextSelected={hasNextSelected}
        integration={integration}
        integrationFields={integrationFields}
        isEdit={isEdit}
        onClose={onClose}
        setIsEdit={setIsEdit}
        validateFields={validateFields}
        vendor={vendor}
      />

    </Dialog>
  );
};
