/* eslint-disable no-nested-ternary */
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useMemo } from 'react';

import { AWSIntegrationCard } from './components/integrationCards/AWSIntegrationCard/AWSIntegrationCard';
import { GithubIntegrationCard } from './components/integrationCards/GithubIntegrationCard/GithubIntegrationCard';
import { IntegrationCard } from './components/integrationCards/IntegrationCard/IntegrationCard';
import { SlackIntegrationCard } from './components/integrationCards/SlackIntegrationCard/SlackIntegrationCard';
import styles from './IntegrationsPage.module.scss';
import { getVariantTagArray } from './utils/variantTags';

import { AwsIcon, AzureIcon, GcpIcon, GithubIcon, JiraIcon, JitIcon, LinearIcon, ShortcutIcon, SlackIcon, DrataIcon } from 'assets';
import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import {
  useFilesContext,
  useIntegrationsContext,
  useTenantContext,
} from 'context';
import { CustomIntegrationCardDefaultProps, IntegrationTemplate, IntegrationType, IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { useIntegrationsTemplate } from 'context/IntegrationsContext/templates/useIntegrationTemplates';
import { Vendor } from 'types/enums';
import { FileEntityType } from 'types/enums/FileEntityType';

export const IntegrationsPage: FC = () => {
  const { isLoadingInstallation } = useTenantContext();
  const { integrations, isLoading } = useIntegrationsContext();
  const { filesMetadata } = useFilesContext();
  const { templates: integrationTemplates, groupedTemplates } = useIntegrationsTemplate();
  const { uiShowIntegrationSecurityTools, securityPlanShowPlanSoc2ByDrata } = useFlags();

  const integrationFileLink = useMemo(() => filesMetadata?.find((file) => file.type === FileEntityType.INTEGRATION_FILE && file.vendorResponse?.htmlUrl), [filesMetadata]);
  // TODO: should be removed when we remove uiShowIntegrationSecurityTools FF
  const dynamicIntegrations = [{
    vendor: Vendor.GCP,
    variant: IntegrationVendorType.cloud,
    icon: GcpIcon,
  },
  {
    vendor: Vendor.AZURE,
    variant: IntegrationVendorType.cloud,
    icon: AzureIcon,
  },
  {
    vendor: Vendor.Jira,
    variant: IntegrationVendorType.tms,
    icon: JiraIcon,
  },
  {
    vendor: Vendor.Shortcut,
    variant: IntegrationVendorType.tms,
    icon: ShortcutIcon,
  },
  {
    vendor: Vendor.LINEAR,
    variant: IntegrationVendorType.tms,
    icon: LinearIcon,
  },
  ];
  if (securityPlanShowPlanSoc2ByDrata) {
    dynamicIntegrations.push({
      vendor: Vendor.Drata,
      variant: IntegrationVendorType.commercial,
      icon: DrataIcon,
    });
  }

  const customIntegrationsMapping: Record<string, FC<CustomIntegrationCardDefaultProps>> = {
    aws: AWSIntegrationCard,
    github: GithubIntegrationCard,
    slack: SlackIntegrationCard,
  };

  if (isLoadingInstallation) {
    return (
      <div className={styles.page}>
        <LoadingBar data-testid='loadingTestId' />
      </div>
    );
  }

  const renderIntegrationCard = (integrationTemplate: IntegrationTemplate) => {
    if (integrationTemplate.integrationType === IntegrationType.custom) {
      const CustomIntegrationCard = customIntegrationsMapping[integrationTemplate.vendor];
      return (
        <CustomIntegrationCard
          key={integrationTemplate.key}
          icon={integrationTemplate.icon || JitIcon}
          tags={getVariantTagArray(integrationTemplate.vendorType)}
          title={integrationTemplate.label}
        />
      );
    }
    return (
      <IntegrationCard
        key={integrationTemplate.key}
        description={integrationTemplate.shortDescription}
        icon={integrationTemplate.icon || JitIcon}
        integrationFileLink={integrationTemplate.integrationType === IntegrationType.asCode ? integrationFileLink?.vendorResponse?.htmlUrl : undefined}
        integrations={integrations?.filter((integration) => integrationTemplate.vendor === integration.vendor)}
        integrationTemplate={integrationTemplate}
        isLoading={isLoading}
        testId={`integrationCard-${integrationTemplate.vendor}`}
        title={integrationTemplate.label}
        variant={integrationTemplate.vendorType}
        vendor={integrationTemplate.vendor}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      <PageTitles subtitle='pages.integrations.subtitle' title='pages.integrations.title' />

      <div className={styles.page}>

        {isLoadingInstallation ? (
          <LoadingBar data-testid='loadingTestId' />
        ) : (

          !uiShowIntegrationSecurityTools ? (
            <div className={styles.integrationGroup}>
              <GithubIntegrationCard icon={GithubIcon} tags={getVariantTagArray(IntegrationVendorType.sourceCodeManagement)} title='vendor.github' />

              <AWSIntegrationCard icon={AwsIcon} tags={getVariantTagArray(IntegrationVendorType.cloud)} title='vendor.aws' />

              <SlackIntegrationCard icon={SlackIcon} tags={getVariantTagArray(IntegrationVendorType.notificationPlatform)} title='vendor.slack' />

              {!uiShowIntegrationSecurityTools && dynamicIntegrations.map((dynamicIntegration) => (
                <IntegrationCard
                  key={dynamicIntegration.vendor}
                  data-testid={`integrationCard-${dynamicIntegration.vendor}`}
                  icon={dynamicIntegration.icon}
                  integrationFileLink={integrationFileLink?.vendorResponse?.htmlUrl}
                  integrations={integrations?.filter((integration) => dynamicIntegration.vendor === integration.vendor)}
                  isLoading={isLoading}
                  variant={dynamicIntegration.variant}
                  vendor={dynamicIntegration.vendor}
                />
              ))}

              {
              // filter out all the security tools integrations
              integrationTemplates.filter(
                (integrationTemplate) => integrationTemplate.integrationType === IntegrationType.secret,
              ).map((integrationTemplate) => (
                renderIntegrationCard(integrationTemplate)
              ))
}
            </div>
          ) : (
            groupedTemplates.map((group) => (
              <div key={group.key}>
                <JitText className={styles.groupTitle} data-testid={`integration-group-title-${group.title}`} text={group.title} />

                <div className={styles.integrationGroup}>
                  {group.templates.map((integrationTemplate) => (
                    renderIntegrationCard(integrationTemplate)
                  ))}
                </div>
              </div>
            ))
          )

        )}
      </div>
    </div>
  );
};
