import { StiggProvider } from '@stigg/react-sdk';
import { CustomizedTheme } from '@stigg/react-sdk/dist/theme/Theme';
import { FC, ReactElement, useMemo, useState } from 'react';

import { customCss } from './PricingPlanTheme';

import { StiggExtendedContext, useAuthContext } from 'context';

interface Props {
  children: ReactElement;
  apiKey: string;
}

export const StiggExtendedProvider: FC<Props> = ({ children, apiKey }) => {
  const [showStiggPayWall, setShowStiggPayWall] = useState(false);
  const { frontEggUser } = useAuthContext();

  const value = useMemo(() => ({
    showStiggPayWall,
    setShowStiggPayWall,
  }), [showStiggPayWall]);

  const theme: CustomizedTheme = useMemo(() => ({
    customCss,
  }), []);
  return (
    <StiggProvider apiKey={apiKey} customerId={frontEggUser.tenantId} theme={theme}>
      <StiggExtendedContext.Provider value={value}>
        {children}
      </StiggExtendedContext.Provider>
    </StiggProvider>
  );
};
