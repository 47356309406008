import { FC, useState } from 'react';

import styles from './JitCopyToClipboardButton.module.scss';

import { CopyIcon } from 'assets';
import { JitButton, JitButtonVariants } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';

interface Props {
  buttonText?: string;
  tooltipText?: string;
  variant?: JitButtonVariants;
  valueToCopy: string;
}

const copyToClipboard = async (text: string) => navigator.clipboard.writeText(text);

export const JitCopyToClipboardButton: FC<Props> = ({
  buttonText = 'components.copyToClipboardButton.copy',
  tooltipText = 'components.copyToClipboardButton.copied',
  variant = 'contained',
  valueToCopy,
}) => {
  const [isCopiedTooltipOpen, setIsCopiedTooltipOpen] = useState(false);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const onClick = () => {
    sendAnalyticsEvent({
      action: 'value-copied-to-clipboard',
      params: { valueToCopy },
    });
    copyToClipboard(valueToCopy).then(() => {
      setIsCopiedTooltipOpen(true);
      setTimeout(() => {
        setIsCopiedTooltipOpen(false);
      }, 2000);
    });
  };

  return (
    <JitTooltip
      followCursor={false}
      open={isCopiedTooltipOpen}
      placement='top'
      title={tooltipText}
    >
      <JitButton data-testid='JitCopyToClipboardButton' onClick={onClick} variant={variant}>
        <div className={styles.buttonContent}>
          <JitIcon icon={CopyIcon} />

          <JitText bold noWrap text={buttonText} />
        </div>
      </JitButton>
    </JitTooltip>
  );
};
