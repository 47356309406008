import { FC, ReactNode } from 'react';

import styles from './PlanItemDetailsBodySection.module.scss';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ActionButton } from 'types/interfaces';

interface Props {
  title: string;
  children: ReactNode;
  buttons?: ActionButton[];
}

export const PlanItemDetailsBodySection: FC<Props> = ({ title, children, buttons }) => (
  <div className={styles.wrapper}>
    <div className={styles.childrenWrapper}>
      <JitText bold color={colors.gray} size='s' text={title} />

      {children}
    </div>

    <div className={styles.centralized}>
      <div className={styles.buttonsWrapper}>
        {buttons?.map((button) => (
          <JitActionButton key={button.label} actionButton={button} />
        ))}
      </div>
    </div>
  </div>
);
