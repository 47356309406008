/* eslint-disable react/jsx-max-depth */
import { parse, stringify } from 'query-string';
import { FC } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import {
  AssetsProvider,
  ConfigurationsProvider,
  DialogsProvider,
  FilesProvider,
  IntegrationsProvider,
  NavigationProvider,
  NewPipelinesProvider,
  PlanInstanceProvider,
  PlansProvider,
  PlanTemplatesProvider,
  PullRequestsProvider,
  ReferralProvider,
  SecretsProvider,
  SlackProvider,
  TenantProvider,
  ToastsProvider,
  WebSocketProvider,
} from 'context';
import { AnalyticsProvider } from 'context/AnalyticsContext';
import { GithubProvider } from 'context/GithubContext/GithubProvider';
import { SbomProvider } from 'context/SbomContext/SbomProvider';
import { TeamsProvider } from 'context/TeamsContext/TeamsProvider';

export const ProtectedContextProviders: FC = ({ children }) => (
  <QueryParamProvider
    adapter={ReactRouter6Adapter}
    options={{
      searchStringToObject: parse,
      objectToSearchString: stringify,
    }}
  >
    <AnalyticsProvider>
      <WebSocketProvider>
        <ReferralProvider>
          <ToastsProvider>
            <GithubProvider>
              <TenantProvider>
                <AssetsProvider>
                  <PlanTemplatesProvider>
                    <PlansProvider>
                      <PlanInstanceProvider>
                        <DialogsProvider>
                          <NavigationProvider>
                            <NewPipelinesProvider>
                              <ConfigurationsProvider>
                                <PullRequestsProvider>
                                  <SlackProvider>
                                    <FilesProvider>
                                      <IntegrationsProvider>
                                        <SecretsProvider>
                                          <TeamsProvider>
                                            <SbomProvider>
                                              {children}
                                            </SbomProvider>
                                          </TeamsProvider>
                                        </SecretsProvider>
                                      </IntegrationsProvider>
                                    </FilesProvider>
                                  </SlackProvider>
                                </PullRequestsProvider>
                              </ConfigurationsProvider>
                            </NewPipelinesProvider>
                          </NavigationProvider>
                        </DialogsProvider>
                      </PlanInstanceProvider>
                    </PlansProvider>
                  </PlanTemplatesProvider>
                </AssetsProvider>
              </TenantProvider>
            </GithubProvider>
          </ToastsProvider>
        </ReferralProvider>
      </WebSocketProvider>
    </AnalyticsProvider>
  </QueryParamProvider>
);
