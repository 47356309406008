import { FC } from 'react';

import styles from './GithubIntegrationCard.module.scss';

import { GithubIcon } from 'assets';
import githubCardStyles
  from 'components/GettingStartedWizard/StepComponents/components/GitHubCard/GitHubCard.module.scss';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ActionButtonVariant } from 'types/interfaces';

interface Props {
  handleClick?: () => void;
}

export const GithubIntegrationCard: FC<Props> = ({ handleClick }) => (
  <div className={styles.card} data-testid='github-integration-card'>
    <div className={styles.cardContent}>
      <JitIcon centered icon={GithubIcon} size={45} />

      <div className={githubCardStyles.cardLeftSide} data-testid='github-integration-card-title'>
        <JitText bold size='l' text='pages.plan.githubIntegration.card.title' />

        <JitText data-testid='github-integration-card-subtitle' muted text='pages.plan.githubIntegration.card.subtitle' />
      </div>

      <div className={githubCardStyles.cardRightSide} data-testid='github-integration-card-button'>
        <JitActionButton actionButton={{
          label: 'pages.plan.githubIntegration.card.button',
          handleClick: handleClick || (() => null),
          variant: ActionButtonVariant.PRIMARY,
        }}
        />
      </div>

    </div>
  </div>
);
