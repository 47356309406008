import { isEmpty } from 'lodash';
import { FC, useState } from 'react';

import colors from '../../themes/colors.module.scss';
import { ActionButton, ActionButtonVariant } from '../../types/interfaces/ActionButton/ActionButton';
import { CustomDialog } from '../JitDialogs/CustomDialog';

import { IgnoreFindingsDialogMenu } from './components/IgnoreFindingsDialogMenu';
import styles from './IgnoreFindingsDialog.module.scss';

import { JitText } from 'components/JitText/JitText';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { IgnoreReason } from 'types/enums/IgnoreReason';

interface IgnoreFindingsDialogProps {
  open: boolean;
  onClose: () => void;
  ignoreFunction: (ignoreReason: IgnoreReason, comment: string) => void;
  isIgnoredLoading: boolean;
}

export const IgnoreFindingsDialog: FC<IgnoreFindingsDialogProps> = ({ open, onClose, ignoreFunction, isIgnoredLoading }) => {
  const [ignoreDescription, setIgnoreDescription] = useState<string>('');
  const [reason, setReason] = useState<string>('');

  const handleIgnoreFindings = () => {
    ignoreFunction(reason as IgnoreReason, ignoreDescription);
  };

  const handleDescriptionChange = (value: string) => {
    setIgnoreDescription(value);
  };
  const handleClose = () => {
    onClose();
    setIgnoreDescription('');
    setReason('');
  };
  const buttons: ActionButton[] = [{
    label: 'pages.pullRequests.prsLists.cancelButton',
    handleClick: handleClose,
    variant: ActionButtonVariant.SECONDARY,
  }, {
    label: 'pages.pullRequests.prsLists.ignoreButton',
    handleClick: handleIgnoreFindings,
    variant: ActionButtonVariant.PRIMARY,
    disabled: isEmpty(reason),
    isLoading: isIgnoredLoading,
  }];

  const content = (
    <div className={styles.wrapper} data-testid='dialogContent'>
      <JitText size='m' style={{ marginBottom: 8 }} text='pages.actions.actionCard.ignoreDialog.reasonDropDownTitle' />

      <IgnoreFindingsDialogMenu data-testid='ignoreFindingsDialogMenu' selectedReason={reason} setSelectedReason={setReason} />

      <div className={styles.TextWrapper} data-testid='descriptionTitle'>
        <JitText
          size='m'
          text='pages.actions.actionCard.ignoreDialog.descriptionTitle'
        />

        <JitText
          color={colors.gray}
          size='m'
          text='pages.actions.actionCard.ignoreDialog.optionalNote'
        />
      </div>

      <JitTextInput
        data-testid='descriptionInput'
        fullWidth
        maxRows={3}
        multiline
        onChange={handleDescriptionChange}
        placeholder='pages.actions.actionCard.ignoreDialog.descriptionPlaceholder'
        rows={3}
        value={ignoreDescription}
        variant='light'
      />
    </div>
  );
  return (
    <div>
      <CustomDialog
        actionButtons={buttons}
        content={content}
        isOpen={open}
        onClose={handleClose}
        title='Ignore'
        width='m'
        withDivider
      />
    </div>
  );
};

