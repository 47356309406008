import { AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { ISaveTenantSecretRequest } from 'types/interfaces/Secrets/ISaveTenantSecretRequest';
import { ITenantSecret } from 'types/interfaces/Secrets/ITenantSecret';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const useSecretService = () => {
  const { client } = useClient();

  const getTenantSecrets = useCallback(async (): Promise<AxiosResponse<ITenantSecret[]> | undefined> => {
    const url = getApiUrls.secretsService.getTenantSecrets();
    const response = await client.get({
      url,
      allowedStatuses: [200, 404],
    });
    if (response?.status && response?.status <= 299) {
      response.data = camelizeSnakeCaseKeys(response.data) as never;
    }
    return response;
  }, [client]);

  const saveTenantSecret = useCallback(async (saveTenantSecretRequest: ISaveTenantSecretRequest): Promise<AxiosResponse<ITenantSecret> | undefined> => {
    const url = getApiUrls.secretsService.saveTenantSecret(saveTenantSecretRequest.secretName);
    const response = await client.put({
      url,
      allowedStatuses: [201],
      requestConfig: {
        data: saveTenantSecretRequest.secretValue,
        headers: { 'Content-Type': 'text/plain' },
      },
    });
    if (response?.status && response?.status <= 299) {
      response.data = camelizeSnakeCaseKeys(response.data) as never;
    }
    return response;
  }, [client]);

  const deleteTenantSecret = useCallback(async (secretName: string): Promise<AxiosResponse<ITenantSecret> | undefined> => {
    const url = getApiUrls.secretsService.deleteTenantSecret(secretName);
    const response = await client.delete({
      url,
      allowedStatuses: [204],
    });
    if (response?.status && response.status <= 299) {
      response.data = camelizeSnakeCaseKeys(response.data) as never;
    }
    return response;
  }, [client]);

  return {
    getTenantSecrets,
    saveTenantSecret,
    deleteTenantSecret,
  };
};
