import { constants } from 'globalConstants';
import { FindingStatus } from 'types/enums';
import { IInsightCategory, IInsightCategoryItem } from 'types/interfaces/Insights';
import { IPullRequest, IPullRequestAnalysisPercentage, IPullRequestsAnalysis } from 'types/interfaces/PullRequests';

const parsePRItemToInsightCategoryItem = (prs: IPullRequest[] = []): IInsightCategoryItem[] => (
  prs.map((pr) => (
    {
      repoOrg: pr.owner,
      repoName: pr.repository,
      url: pr.url,
      timestamp: pr.updatedAt,
      title: pr.title,
      owner: pr.author,
      ownerAvatar: pr.authorAvatar,
      showDetailsUrl: pr.pipelineId && `/${constants.routes.PIPELINES}/${pr.pipelineId}`,
      findings: {
        newFindings: pr.analysis?.resolutionsCount[FindingStatus.OPEN] || 0,
        ignoredFindings: pr.analysis?.resolutionsCount[FindingStatus.IGNORED] || 0,
        fixedFindings: pr.analysis?.resolutionsCount[FindingStatus.FIXED] || 0,
        // existingFindings: 0,
      },
    }
  ))
);

export const getPullRequestInsightCategories = (prAnalysis: IPullRequestsAnalysis | undefined, prPercentage: IPullRequestAnalysisPercentage | undefined): IInsightCategory[] => ([{
  key: 'mergedPrsWithFailed',
  title: 'pages.pullRequests.prsLists.types.mergedPrsWithFailed.title',
  subTitle: 'pages.pullRequests.prsLists.types.mergedPrsWithFailed.subTitle',
  items: parsePRItemToInsightCategoryItem(prAnalysis?.mergedPrsWithFailed),
  changePercentage: prPercentage?.merged_prs_with_failed_percentage,
  oppositeColor: true,

  boldFindingStatus: FindingStatus.OPEN,
}, {
  key: 'mergedPrsWithFailedIgnored',
  title: 'pages.pullRequests.prsLists.types.mergedPrsWithFailedIgnored.title',
  subTitle: 'pages.pullRequests.prsLists.types.mergedPrsWithFailedIgnored.subTitle',
  items: parsePRItemToInsightCategoryItem(prAnalysis?.mergedPrsWithFailedIgnored),
  changePercentage: prPercentage?.merged_with_failed_ignored_percentage,
  oppositeColor: true,

  boldFindingStatus: FindingStatus.IGNORED,

}, {
  key: 'openPrsWithFailedChecks',
  title: 'pages.pullRequests.prsLists.types.openPrsWithFailedChecks.title',
  subTitle: 'pages.pullRequests.prsLists.types.openPrsWithFailedChecks.subTitle',
  items: parsePRItemToInsightCategoryItem(prAnalysis?.openPrsWithFailedChecks),
  changePercentage: prPercentage?.open_prs_with_failed_checks_percentage,
  oppositeColor: true,
  shouldDisplayStaleIndication: true,
  boldFindingStatus: FindingStatus.OPEN,
}, {
  key: 'mergedPrsWithFixedChecks',
  title: 'pages.pullRequests.prsLists.types.mergedPrsWithFixedChecks.title',
  subTitle: 'pages.pullRequests.prsLists.types.mergedPrsWithFixedChecks.subTitle',
  items: parsePRItemToInsightCategoryItem(prAnalysis?.mergedPrsWithFixedChecks),
  changePercentage: prPercentage?.merged_prs_with_fixed_checks_percentage,
  oppositeColor: false,
  boldFindingStatus: FindingStatus.FIXED,
}]
);
