import { FC } from 'react';

import {
  SlackChannelNotificationsItem,
} from 'components/JitDialogs/SlackIntegrationDialog/components/SlackChannelNotificationsItem/SlackChannelNotificationsItem';
import { useTenantContext } from 'context';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { useGetSlackChannels } from 'context/SlackContext/hooks/useGetSlackChannels';
import { useTenantService } from 'services/TenantService/useTenantService';
import { PreferencesScope } from 'types/enums/PreferencesScope';
import { TeamsNotificationPreference, SpecificTeamsNotificationPreference } from 'types/interfaces';

export const NOTIFICATIONS_PREFERENCES: Record<string, keyof SpecificTeamsNotificationPreference> = {
  score_decrease: 'score_decrease',
};

interface Props {
  teamName: string;
}

export const TeamSlackNotifications: FC<Props> = ({ teamName }) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { slackChannels } = useGetSlackChannels();
  const { preferences, setPreferences } = useTenantContext();
  const { patchPreferences } = useTenantService();
  const updateNotificationPreferences = async (preferenceKey: string, enabled: boolean, channel?: string) => {
    const teamPreferenceKey = preferenceKey as keyof SpecificTeamsNotificationPreference;
    const updatedTeamPreference: Record<string, SpecificTeamsNotificationPreference> = {
      [teamName]: {
        ...preferences?.notifications?.teams_notifications?.[teamName] || {},
        [teamPreferenceKey]: {
          channel,
          enabled,
        },
      },
    };
    const updatedTeamsNotificationPreference: Record<string, TeamsNotificationPreference> = {
      teams_notifications: {
        ...preferences?.notifications?.teams_notifications,
        ...updatedTeamPreference,
      },
    };
    if (preferences) {
      setPreferences({
        ...preferences,
        notifications: {
          ...preferences?.notifications,
          ...updatedTeamsNotificationPreference,
        },
      });
    }
    await patchPreferences<TeamsNotificationPreference>('notifications', updatedTeamsNotificationPreference, PreferencesScope.TENANT);
    if (updatedTeamPreference[teamName]) {
      const teamConfig = updatedTeamPreference[teamName];
      sendAnalyticsEvent({ action: 'slack-notification-team-preference-updated',
        params: { 'preference-key': preferenceKey,
          teamName,
          enabled: (teamConfig.score_decrease.enabled || false).toString(),
          channel: (teamConfig.score_decrease.channel || '') } });
    }
  };
  return (
    <div data-testid='slackChannelSection'>
      {Object.values(NOTIFICATIONS_PREFERENCES).map((preferenceKey) => (
        <SlackChannelNotificationsItem
          key={preferenceKey}
          preferenceKey={preferenceKey}
          selected={preferences?.notifications?.teams_notifications?.[teamName]?.[preferenceKey]}
          slackChannels={slackChannels || []}
          updateNotificationPreferences={updateNotificationPreferences}

        />
      ))}
    </div>
  );
};

