import { FC } from 'react';

import styles from './JitInfoBanner.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { ISvg } from 'types/interfaces';

interface Props {
  bgColor: string,
  icon: FC<ISvg>,
  iconColor: string,
  'data-testid'?: string,
  className?: string,
}

export const JitInfoBanner: FC<Props> = ({ children, icon, bgColor, iconColor, className, ...props }) => (
  <div
    className={className ? `${styles.banner} ${className}` : styles.banner}
    data-testid={props['data-testid'] || 'JitInfoBanner'}
    style={{
      backgroundColor: bgColor,
    }}
  >
    <JitIcon color={iconColor} icon={icon} />

    {children}
  </div>
);
