import { createContext, useContext } from 'react';

import { IInstallation, IIntegration, IPreferences } from 'types/interfaces';

interface ITenantContext {
  installations: IInstallation[];
  hasTriedFetchInstallations: boolean;
  preferences?: IPreferences;
  setPreferences: (preferences: IPreferences) => void;
  isPreferencesLoading: boolean;
  initPreferences: () => Promise<void>;
  isLoadingInstallation: boolean;
  githubInstallation?: IInstallation;
  getIntegrations: () => IIntegration[];
  initUserPreferences: () => Promise<void>;
  isGithubIntegrated: boolean;
}

export const TenantContext = createContext<ITenantContext>({
  installations: [],
  hasTriedFetchInstallations: false,
  isLoadingInstallation: true,
  setPreferences: () => {},
  isPreferencesLoading: false,
  initPreferences: () => Promise.resolve(),
  getIntegrations: () => [],
  initUserPreferences: () => Promise.resolve(),
  isGithubIntegrated: false,
});

export const useTenantContext = () => useContext(TenantContext);
