import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetCreateCentralizedRepoStep } from './useGetCreateCentralizedRepoStep';

import { useGetInstallJitAppStep, useGetWelcomeStep } from '.';

import { IGettingStartedWizardStep, IGettingStartedWizardStepStatus } from 'components/GettingStartedWizard/GettingStartedWizard';
import { constants } from 'globalConstants';

export const useWizardSteps = (incrementStepIndex: () => void) => {
  const { pathname } = useLocation();
  const planSlug = sessionStorage.getItem(constants.GOAL_PLAN_KEY) || undefined;

  const isRedirectedBackFromGHInstallation = useMemo(() => pathname === constants.GITHUB_INSTALLATION_REDIRECT_PATH, [pathname]);
  const isStepCompleted = useCallback((step: IGettingStartedWizardStep) => step.status === IGettingStartedWizardStepStatus.COMPLETE, []);

  const welcomeStep = useGetWelcomeStep({
    incrementStepIndex,
    isRedirectedBackFromGHInstallation,
    planSlug,
  });
  const installJitAppStep = useGetInstallJitAppStep({
    isPrevStepCompleted: isStepCompleted(welcomeStep),
    incrementStepIndex,
    isRedirectedBackFromGHInstallation,
    planSlug,
  });
  const createCentralizedRepoStep = useGetCreateCentralizedRepoStep({
    isPrevStepCompleted: isStepCompleted(installJitAppStep),
  });

  return useMemo(() => [welcomeStep, installJitAppStep, createCentralizedRepoStep], [welcomeStep, installJitAppStep, createCentralizedRepoStep]);
};
