import { useAuthActions } from '@frontegg/react';
import { FC } from 'react';

import { ArrowRight, Check } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import styles from 'components/Navbar/components/SelectTenantPopperContent/SelectTenantPopperContent.module.scss';
import colors from 'themes/colors.module.scss';

interface Props {
  isLast: boolean;
  tenantId: string;
  setHoveredTenant: (tenantId: string) => void;
  isActiveTenant: boolean;
  isHoveredTenant: boolean;
  tenantName: string;
}

export const TenantRow: FC<Props> = ({
  tenantId, isLast, setHoveredTenant, isActiveTenant, tenantName, isHoveredTenant,
}) => {
  const { switchTenant } = useAuthActions();

  let bgColor = colors.navbarFooterHovered;
  if (isActiveTenant) {
    bgColor = colors.navbarSelected;
  }
  if (isHoveredTenant) {
    bgColor = colors.navbarHovered;
  }

  return (
    <>
      <div className={styles.separator} />

      <div
        key={tenantId}
        className={styles.row}
        onClick={() => switchTenant({ tenantId })}
        onMouseEnter={() => setHoveredTenant(tenantId)}
        onMouseLeave={() => setHoveredTenant('')}
        role='button'
        style={{
          backgroundColor: bgColor,
          borderRadius: isLast ? '0 0 5px 5px' : undefined,
        }}
        tabIndex={0}
      >
        <div className={styles.rowTextsWrapper}>
          <JitText size='m' text={tenantName} />

          {isActiveTenant && (<JitIcon icon={Check} size={10} />)}
        </div>

        {isLast && (
          <div className={styles.arrow}>
            <JitIcon color={bgColor} icon={ArrowRight} rotateDegrees={180} size={50} />
          </div>
        )}
      </div>
    </>
  );
};
