import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { Route } from 'react-router-dom';

import { ROUTES_PERMISSIONS } from '../constants';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { useAuthContext, useTenantContext } from 'context';
import { constants } from 'globalConstants';
import { ActionsPageWrapper } from 'pages/ActionsPage/ActionPageWrapper';
import { FindingsPageWrapper } from 'pages/FindingsPage/FindingsPageWrapper';
import { InsightsPage, PullRequestsPage } from 'pages/InsightsPage';
import { DeploymentsPageWrapper } from 'pages/InsightsPage/SubPages/DeploymentsPage/DeploymentsPageWrapper';
import { SnapshotNotFound } from 'pages/InsightsPage/SubPages/PerformancePage/components/SnapshotNotFound/SnapshotNotFound';
import { PerformancePageWrapper } from 'pages/InsightsPage/SubPages/PerformancePage/PerformancePageWrapper';
import { SecurityImpact } from 'pages/InsightsPage/SubPages/SecurityImpact/SecurityImpact';
import { IntegrationsPageWrapper } from 'pages/IntegrationsPage/IntegrationsPageWrapper';
import { OverviewPage } from 'pages/OverviewPage/OverviewPage';
import { NewPipelinesPageWrapper } from 'pages/PipelinesPage/NewPipelinesPageWrapper';
import { PipelineDetailsPage } from 'pages/PipelinesPage/PipelineDetailsPage/PipelineDetailsPage';
import { PlanPageWrapper } from 'pages/PlanPage/PlanPageWrapper';
import { SbomPageWrapper } from 'pages/SbomPage/SbomPageWrapper';
import { SecurityMappingPage } from 'pages/SecurityMappingPage/SecurityMappingPage';
import { SecurityPlansPage } from 'pages/SecurityPlansPage/SecurityPlansPage';
import { ResourcePlanItemsStatusesPageWrapper } from 'pages/TeamsPage/components/ResourcePlanItemsStatusesPage/ResourcePlanItemsStatusesPageWrapper';
import { TeamDetailsPageWrapper } from 'pages/TeamsPage/SubPages/TeamDetailsPage/TeamDetailsPageWrapper';
import { TeamsPageWrapper } from 'pages/TeamsPage/SubPages/TeamsPage/TeamsPageWrapper';
import { AccessDenied } from 'wrappers/RBAC/components';

export const useRoutesOfUser = () => {
  const { frontEggUser } = useAuthContext();
  const { uiShowGithubIntegrationWidget, uiShowSbomPage, uiShowTheLoopPage } = useFlags();
  const { hasTriedFetchInstallations, isGithubIntegrated } = useTenantContext();
  const permissionKeys = frontEggUser.permissions.map((p) => p.key);

  const hasPermission = (permissionKey: string | null) => {
    // if permissionKey is null then the route is public
    const isRoutePublic = !permissionKey;
    return isRoutePublic || permissionKeys.includes(permissionKey);
  };

  const {
    FINDINGS, INTEGRATIONS, PIPELINES, ACTIONS, PLAN, OVERVIEW, PLANS, TEAMS, RESOURCE, SBOM, SECURITY_MAPPING,
    insights: { BASE_ROUTE: BASE_INSIGHTS_ROUTE, DEPLOYMENTS, PERFORMANCE, PULL_REQUESTS, SNAPSHOT, SNAPSHOT_NOT_FOUND, SECURITY_IMPACT },
  } = constants.routes;
  const { PIPELINE_ID, SNAPSHOT_ID, PLAN_SLUG, TEAM_ID, RESOURCE_ID } = constants.routes.pathParameters;

  let routesComponents = useMemo(() => ({
    [`/${PIPELINES}`]: <NewPipelinesPageWrapper />,
    [`/${PLAN}`]: <SecurityPlansPage />,
    [`/${PLAN}/:${PLAN_SLUG}`]: <PlanPageWrapper />,
    [`/${PLANS}`]: <SecurityPlansPage />,
    [`/${PLANS}/:${PLAN_SLUG}`]: <PlanPageWrapper />,
    [`/${PIPELINES}/:${PIPELINE_ID}`]: <PipelineDetailsPage />,
    [`/${ACTIONS}`]: <ActionsPageWrapper />,
    [`/${TEAMS}`]: <TeamsPageWrapper />,
    [`/${TEAMS}/:${TEAM_ID}/${RESOURCE}/:${RESOURCE_ID}`]: <ResourcePlanItemsStatusesPageWrapper />,
    [`/${TEAMS}/:${TEAM_ID}`]: <TeamDetailsPageWrapper />,
    [`/${RESOURCE}/:${RESOURCE_ID}`]: <ResourcePlanItemsStatusesPageWrapper />,
    [`/${BASE_INSIGHTS_ROUTE}/${SECURITY_IMPACT}`]: <InsightsPage page={<SecurityImpact />} pageKey={SECURITY_IMPACT} />,
    [`/${BASE_INSIGHTS_ROUTE}/${DEPLOYMENTS}`]: <InsightsPage page={<DeploymentsPageWrapper />} pageKey={DEPLOYMENTS} />,
    [`/${BASE_INSIGHTS_ROUTE}/${PULL_REQUESTS}`]: <InsightsPage page={<PullRequestsPage />} pageKey={PULL_REQUESTS} />,
    [`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}`]: <InsightsPage page={<PerformancePageWrapper />} pageKey={PERFORMANCE} />,
    [`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}/${SNAPSHOT}/:${SNAPSHOT_ID}`]: <InsightsPage page={<PerformancePageWrapper />} pageKey={PERFORMANCE} />,
    [`/${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}/${SNAPSHOT_NOT_FOUND}`]: <InsightsPage page={<SnapshotNotFound />} pageKey={SNAPSHOT_NOT_FOUND} />,
    [`/${FINDINGS}`]: <FindingsPageWrapper />,
    [`/${INTEGRATIONS}`]: <IntegrationsPageWrapper />,
    [`/${OVERVIEW}`]: <OverviewPage />,
    unauthorized: <AccessDenied />,
  }), [
    ACTIONS,
    RESOURCE,
    RESOURCE_ID,
    BASE_INSIGHTS_ROUTE,
    DEPLOYMENTS,
    FINDINGS,
    INTEGRATIONS,
    PERFORMANCE,
    PIPELINES,
    PIPELINE_ID,
    PLAN,
    PLANS,
    PLAN_SLUG,
    PULL_REQUESTS,
    SECURITY_IMPACT,
    SNAPSHOT,
    SNAPSHOT_ID,
    SNAPSHOT_NOT_FOUND,
    TEAMS,
    TEAM_ID,
    OVERVIEW,
  ]);

  const defaultRoute = useMemo(() => {
    if (uiShowGithubIntegrationWidget) {
      if (!hasTriedFetchInstallations) {
        return {
          key: OVERVIEW,
          component: <LoadingBar />,
        };
      }
      const planSlug = sessionStorage.getItem(constants.GOAL_PLAN_KEY) || undefined;
      if (planSlug) {
        return {
          key: PLAN,
          component: <PlanPageWrapper planSlug={planSlug} />,
        };
      }
      const recentLocationRoute = sessionStorage.getItem(constants.RECENT_LOCATION_ROUTE_KEY) || undefined;
      if (recentLocationRoute && recentLocationRoute in routesComponents) {
        return {
          key: recentLocationRoute.slice(1),
          component: routesComponents[recentLocationRoute],
        };
      }
      if (!isGithubIntegrated) {
        return {
          key: PLANS,
          component: <SecurityPlansPage />,
        };
      }
    }
    return {
      key: OVERVIEW,
      component: <OverviewPage />,
    };
  }, [isGithubIntegrated, hasTriedFetchInstallations, uiShowGithubIntegrationWidget,
    OVERVIEW, PLAN, PLANS, routesComponents]);

  routesComponents = useMemo(() => {
    let routes = {
      ...routesComponents,
      '*': defaultRoute.component,
    };
    if (uiShowSbomPage) {
      routes = {
        ...routes,
        [`/${SBOM}`]: <SbomPageWrapper />,
      };
    }

    if (uiShowTheLoopPage) {
      routes = {
        ...routes,
        [`/${SECURITY_MAPPING}`]: <SecurityMappingPage />,
      };
    }

    return routes;
  }, [SBOM, SECURITY_MAPPING, defaultRoute.component, routesComponents, uiShowSbomPage, uiShowTheLoopPage]);

  const routesToRender = Object.entries(ROUTES_PERMISSIONS).map(([route, permissionKey]) => (
    <Route
      key={route}
      element={(hasPermission(permissionKey)) ? routesComponents[route] : routesComponents.unauthorized}
      path={route}
    />
  ));

  return {
    routesToRender,
    defaultRoute,
  };
};
