import { CSSProperties, FC, useRef, useState } from 'react';

import styles from './JitDropdownNew.module.scss';

import { Check, CircleFailureColorChange, ExpandArrow } from 'assets';
import { CircularProgressBar } from 'components/CircularProgressBar/CircularProgressBar';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMenu, MenuItemSearchBox, MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';
import { IMouseEvent, MenuItemKeyType } from 'types/interfaces';

interface Props {
  displayText?: string;
  menuItems: Partial<MenuItemType>[];
  onMenuItemClick?: ({ e, categoryKey, itemKey }: { e: IMouseEvent, categoryKey?: string, itemKey: MenuItemKeyType }) => void
  isEnabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  isVerified?: boolean;
  defaultDisplayText?: string;
  width?: string | number;
  menuWidth?: string | number;
  height?: string | number;
  menuDataTestId?: string;
  enabledColor?: string;
  noBorder?: boolean;
  noBackground?: boolean;
  noGap?: boolean;
  bold?: boolean
  menuItemsWrapperStyle?: CSSProperties;
  withSearchBox?: MenuItemSearchBox;
  errorTooltip?: string;
}

export const JitDropdownNew: FC<Props> = ({
  menuItems, onMenuItemClick, isEnabled = true, isLoading = false,
  isVerified = false, displayText, defaultDisplayText = '', width, menuWidth,
  height, menuDataTestId = 'jitDropDownMenu', enabledColor = colors.white, noBorder,
  noBackground, noGap, bold, menuItemsWrapperStyle,
  withSearchBox, isError, errorTooltip,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const boxContainerRef = useRef<HTMLDivElement>(null);
  const textColor = isEnabled ? enabledColor : colors.gray;

  const getIcon = () => {
    const expandIcon = <JitIcon className={styles.expandIcon} color={textColor} data-testid='expandIcon' icon={ExpandArrow} size={10} />;
    if (isLoading) {
      return (
        <CircularProgressBar className={styles.loadingProgress} size='18px' />
      );
    }
    return (
      <>
        {isVerified && <JitIcon className={styles.checkIcon} color={colors.successGreen} data-testid='checkIcon' icon={Check} size={10} /> }

        {isError && (
        <JitTooltip followCursor={false} placement='top' title={errorTooltip || ''}>
          <JitIcon data-testid='errorIcon' icon={CircleFailureColorChange} size={20} />
        </JitTooltip>
        )}

        {expandIcon}

      </>
    );
  };
  const getBoxContainer = () => (
    <div
      ref={boxContainerRef}
      className={`${isEnabled ? styles.boxContainer : styles.boxContainerDisabled} ${isMenuOpen ? styles.openedBoxContainer : ''}`}
      data-testid='jitDropDownBoxContainer'
      style={{
        width,
        height,
        ...(noBorder && { borderWidth: '0' }),
        ...(noBackground && { background: 'none' }),
        ...(noGap && { gap: '0' }),
        ...(isError && { border: `1px solid ${colors.failRed}` }),
      }}
    >
      <JitText
        bold={bold}
        color={displayText ? textColor : colors.lightGray}
        data-testid='jitDropdownDisplayText'
        size='m'
        text={displayText || defaultDisplayText}
      />

      <div className={styles.dropDownIcons} />

      {getIcon()}
    </div>
  );

  const getMenuItemContent = (menuItem: Partial<MenuItemType>) => (
    <JitText
      color={colors.white}
      data-testid='jitDropdownMenuItem'
      text={menuItem.itemName || menuItem.itemKey?.toString()}
    />
  );
  return (
    <JitMenu
      data-testid={menuDataTestId}
      inputColor='light'
      isEnabled={isEnabled && !isLoading}
      menuItems={menuItems.map((menuItem) => ({
        ...menuItem as MenuItemType,
        itemContent: getMenuItemContent(menuItem),
      }))}
      menuItemStyle={{
        fontSize: 14,
        padding: '8px',
        width: (menuWidth || width) || boxContainerRef.current?.clientWidth,
      }}
      menuItemsWrapperClassName={`${styles.menuItemsWrapper} ${noBorder && styles.noBorder}`}
      menuItemsWrapperStyle={menuItemsWrapperStyle}
      menuSelectedDesign='fillRow'
      onMenuChangeOpenState={(isOpen: boolean) => { setIsMenuOpen(isOpen); }}
      onMenuItemClick={onMenuItemClick}
      paperProps={{ style: { borderRadius: 0 } }}
      searchBoxPlaceHolder={withSearchBox?.placeholder}
      shouldCloseOnItemClick
      withSearchBox={withSearchBox}
    >
      {getBoxContainer()}

    </JitMenu>
  );
};
