import { FC, useMemo } from 'react';

import styles from './InviteMembersDialog.module.scss';
import { SlackIntegrationCard } from './SlackIntegrationCard';

import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const InviteMembersDialog: FC<Props> = ({ onClose, isOpen }) => {
  const content = useMemo(() => (
    <div className={styles.wrapper}>

      <div className={styles.textWrapper}>
        <JitText
          bold
          data-testid='invite-members-dialog-title'
          size='l'
          text='pages.teams.invite.dialog.title'
        />

        <JitText
          color={colors.lightGray}
          data-testid='invite-members-dialog-subtitle'
          size='m'
          text='pages.teams.invite.dialog.subtitle'
        />
      </div>

      <SlackIntegrationCard
        configureText='pages.teams.invite.dialog.slackIntegrationCard.configure'
        description='pages.teams.invite.dialog.slackIntegrationCard.description'
      />

    </div>
  ), []);

  return (
    <CustomDialog
      content={content}
      dataTestId='invite-members-dialog'
      isOpen={isOpen}
      onClose={onClose}
      title='pages.teams.invite.dialog.title'
      width='m'
      withDivider
    />
  );
};
