import { ErrorBoundary } from '@sentry/react';
import { FC } from 'react';

import { ErrorBoundaryFallback } from 'components/GeneralErrorPage/ErrorBoundaryFallback/ErrorBoundaryFallback';
import { ContextProviders } from 'context';
import { Router } from 'Router';
import { Wrappers } from 'wrappers';
import { BlockMobileWrapper } from 'wrappers/BlockMobileWrapper';

export const App: FC = () => (
  <ErrorBoundary fallback={(<ErrorBoundaryFallback />)}>
    <BlockMobileWrapper>
      <ContextProviders>

        <Wrappers />

        <Router />
      </ContextProviders>

    </BlockMobileWrapper>
  </ErrorBoundary>
);
