import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { FC, UIEvent, useMemo, CSSProperties } from 'react';
import { Row } from 'react-table';

import { ElementToShowOnHoverProps, JitTableHeader, JitTablePagination, JitTableRow } from './components';
import { GroupedRow, GroupedTableRows } from './components/GroupedTableRows/GroupedTableRows';
import { CellVerticalAlign, DefaultCellVerticalAlign, DefaultTableDesign, TableDesignVariants } from './constants';
import styles from './JitTable.module.scss';

import { JittyEmpty } from 'assets';
import { JitEmpty } from 'components/JitEmpty/JitEmpty';
import { ITableInstance as ITableInstanceOld } from 'components/JitTable/hooks/useGetTableInstance';
import { ITableInstance as ITableInstanceNew } from 'components/JitTable/hooks/useManageTable';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { ITableRow, ISvg } from 'types/interfaces';

interface Props {
  onSelectRow?: (row: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  selectedRow?: ITableRow | null;
  entityName?: string;
  isLoading: boolean;
  isFetching?: boolean; // sc-5861 - Different JitTable component for infinite scroll
  onPageChange?: (pageIndex: number, paginationKey?: string) => void;
  handleScroll?: (event: UIEvent<HTMLDivElement>) => void;
  className?: string;
  tableInstance: ITableInstanceOld | ITableInstanceNew;
  emptyTableView?: JSX.Element;
  cellsNumber?: number;
  cellVerticalAlign?: CellVerticalAlign;
  tableDesign?: TableDesignVariants;
  emptyTableText?: string;
  emptyTableSubtext?: string;
  jittyIcon?: FC<ISvg> | null;
  rowHoverStyle?: CSSProperties;
  ElementToShowOnRowHover?: FC<ElementToShowOnHoverProps>;
  groupRowsBy?: (page: Row<ITableRow>[]) => GroupedRow[] | undefined;
  cellPadding?: string;
  showPagination?: boolean;
  pageMaxLength?: number;
  totalRecordsAmount?: number;
  enableScrolling?: boolean;
}

export const JitTable: FC<Props> = ({
  onSelectRow,
  isLoading,
  isFetching,
  handleScroll,
  className,
  entityName,
  selectedRow,
  tableInstance,
  cellsNumber = 1,
  cellVerticalAlign = DefaultCellVerticalAlign,
  tableDesign = DefaultTableDesign,
  emptyTableView,
  emptyTableText,
  emptyTableSubtext,
  jittyIcon = JittyEmpty,
  rowHoverStyle,
  ElementToShowOnRowHover,
  groupRowsBy,
  cellPadding,
  showPagination = true,
  totalRecordsAmount,
  pageMaxLength,
  enableScrolling = true,
}) => {
  const { t } = i18n;

  if (showPagination && !pageMaxLength) throw new Error('pageMaxLength is required when showPagination is true');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    getNextPage,
    getPreviousPage,
    canPreviousPage,
    canNextPage,
    pageIndex,
  } = tableInstance;

  const tableProps = useMemo(() => getTableProps(), [getTableProps]);
  const tableBodyProps = useMemo(() => getTableBodyProps(), [getTableBodyProps]);
  const shouldShowTable = useMemo(() => !isLoading && page.length > 0, [isLoading, page]);

  const groupedRows = useMemo(() => (groupRowsBy && groupRowsBy(page)), [groupRowsBy, page]);

  const loadingBarSxProps = isFetching ? { sx: { w: 1 } } : {};

  const wrapperClassName = {
    dark: styles.wrapperDark,
    bright: styles.wrapperBright,
  };

  return (
    <>
      <Paper
        className={`${wrapperClassName[tableDesign]} ${className}`}
        sx={{ borderColor: colors.darkGray }}
      >
        <TableContainer
          onScroll={handleScroll}
          sx={{
            '&::-webkit-scrollbar': {
              width: '2px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: colors.cards,
              marginTop: '10px',
              marginBottom: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: colors.darkGray,
            },
            height: '100%',
            marginLeft: '-3px',
            overflowY: enableScrolling ? 'auto' : 'hidden',
          }}
        >
          <Table
            {...tableProps}
            className={styles.table}
            stickyHeader
            sx={{
              backgroundColor: colors.cards,
              borderSpacing: !isLoading && page.length === 0 ? '' : '0 8px !important',
            }}
          >
            <JitTableHeader headerGroups={headerGroups} tableDesign={tableDesign} />

            {shouldShowTable && (
              <TableBody className={styles.body} {...tableBodyProps}>
                {(groupRowsBy && groupedRows)
                  ? (
                    <GroupedTableRows
                      cellsNumber={cellsNumber}
                      cellVerticalAlign={cellVerticalAlign}
                      ElementToShowOnRowHover={ElementToShowOnRowHover}
                      groupedRows={groupedRows}
                      onSelectRow={onSelectRow}
                      prepareRow={prepareRow}
                      rowHoverStyle={rowHoverStyle || {}}
                      selectedRow={selectedRow}
                      tableDesign={tableDesign}
                    />
                  )
                  : page?.map((row) => (
                    <JitTableRow
                      key={row.id}
                      cellPadding={cellPadding}
                      cellVerticalAlign={cellVerticalAlign}
                      ElementToShowOnHover={ElementToShowOnRowHover}
                      isSelected={!!(selectedRow?.id && row?.original?.id === selectedRow?.id)}
                      onSelectRow={onSelectRow}
                      prepareRow={prepareRow}
                      row={row}
                      rowHoverStyle={rowHoverStyle || {}}
                      tableDesign={tableDesign}
                    />
                  ))}
              </TableBody>
            )}
          </Table>

          {(isLoading || isFetching) && (
            <LoadingBar {...loadingBarSxProps} />
          )}

          <div className={styles.emptyTableWrapper} {...page.length && { style: { display: 'none' } }}>
            {!isLoading && (totalRecordsAmount === undefined || totalRecordsAmount === 0) && page.length === 0 && (
              emptyTableView
              || (
                <JitEmpty
                  description={emptyTableSubtext}
                  descriptionColor={colors.lightGray}
                  icon={jittyIcon ?? undefined}
                  showJitty={!!jittyIcon}
                  title={t(emptyTableText || 'table.noData', { entityName })}
                  titleColor={colors.white}
                />
              ))}
          </div>
        </TableContainer>

      </Paper>

      {showPagination && (!!page.length || !!totalRecordsAmount) && (
        <JitTablePagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          currentPageLength={page.length}
          getNextPage={getNextPage}
          getPreviousPage={getPreviousPage}
          pageIndex={pageIndex}
          pageMaxLength={pageMaxLength!} // We validate it exist when showPagination is true above
          totalRecordsAmount={totalRecordsAmount}
        />
      )}
    </>
  );
};
