import { FC } from 'react';

import styles from './JitTag.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  text: string;
  color: string;
  backgroundColor?: string;
}

export const JitTag: FC<Props> = ({ text, color, backgroundColor }) => (
  <div className={styles.tagContainer} style={{ backgroundColor: backgroundColor || colors.cards02 }}>
    <JitText
      bold
      color={color}
      data-testid='card-tag'
      overflowHiddenEllipsis
      size='s'
      text={text}
    />
  </div>
);
