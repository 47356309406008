const getUTCTime = (date: Date) => new Date(date.getTime() - date.getTimezoneOffset() * 60000);

// eslint-disable-next-line max-statements
export const getFormattedTimeSince = (dateString: string | undefined) => {
  const date = dateString && new Date(dateString);
  if (!date || Number.isNaN(date)) {
    return '';
  }

  const utcTime = getUTCTime(date);
  const seconds = Math.floor((Date.now() - utcTime.getTime()) / 1000);

  const inYears = seconds / (60 * 60 * 24 * 30 * 12);
  if (inYears > 1) {
    return `${Math.floor(inYears)}y ago`;
  }
  const inMonths = seconds / (60 * 60 * 24 * 30);
  if (inMonths > 1) {
    return `${Math.floor(inMonths)}mo ago`;
  }
  const inWeeks = seconds / (60 * 60 * 24 * 7);
  if (inWeeks > 1) {
    return `${Math.floor(inWeeks)}w ago`;
  }
  const inDays = seconds / (60 * 60 * 24);
  if (inDays > 1) {
    return `${Math.floor(inDays)}d ago`;
  }
  const inHours = seconds / (60 * 60);
  if (inHours > 1) {
    return `${Math.floor(inHours)}h ago`;
  }
  const inMinutes = seconds / 60;
  if (inMinutes > 1) {
    return `${Math.floor(inMinutes)}m ago`;
  }
  return `${seconds}s ago`;
};

// eslint-disable-next-line max-statements
export const getFormattedDuration = (timeInSeconds: string | undefined) => {
  const seconds = timeInSeconds && parseInt(timeInSeconds, 10);

  if (!seconds) {
    return '';
  }
  const inYears = seconds / (60 * 60 * 24 * 30 * 12);
  if (inYears > 1) {
    return `${Math.floor(inYears)}y`;
  }
  const inMonths = seconds / (60 * 60 * 24 * 30);
  if (inMonths > 1) {
    return `${Math.floor(inMonths)}mo`;
  }
  const inWeeks = seconds / (60 * 60 * 24 * 7);
  if (inWeeks > 1) {
    return `${Math.floor(inWeeks)}w`;
  }
  const inDays = seconds / (60 * 60 * 24);
  if (inDays > 1) {
    return `${Math.floor(inDays)}d`;
  }
  const inHours = seconds / (60 * 60);
  if (inHours > 1) {
    return `${Math.floor(inHours)}h`;
  }
  const inMinutes = seconds / 60;
  if (inMinutes > 1) {
    return `${Math.floor(inMinutes)}m`;
  }
  return `${seconds}s`;
};

export const isoFormatToDateFormat = (timeInIsoFormat: string | undefined) => {
  const datea = timeInIsoFormat && new Date(timeInIsoFormat);
  if (!datea || Number.isNaN(datea)) {
    return '';
  }

  const utcTime = getUTCTime(datea);
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const date = new Date(utcTime);
  const day = date.getUTCDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const month = monthNames[date.getMonth()];
  return `${month} ${day}, ${year} at ${hours}:${minutes}`;
};
