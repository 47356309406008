import { FC } from 'react';

import styles from './PlanItemIntroDialog.module.scss';

import { GreenDotCheck } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { usePipelineContextNew } from 'context';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { useNavigatePipelinesDemo } from 'pages/PipelinesPage/hooks/useNavigatePipelinesDemo';
import { createMockPipelineNew, createPlaceholderMockPipelineNew, runPipelineDemoNew } from 'pages/PipelinesPage/utils/MockPipelineNew/mockPipelineNew';
import { PipelineDemoCommitType } from 'types/interfaces/Ci';
import { IMockPipelineNew } from 'types/interfaces/Pipelines/IPipelineNew';

const IntroItemSlugs = [
  'item-dependency-check',
  'item-secret-detection',
  'item-iac-misconfiguration-detection',
  'item-code-vulnerability',
  'item-container-scan',
] as const;

export type ItemSlugOptions = typeof IntroItemSlugs[number];

export const isIntroItemSlug = (itemSlug: string): itemSlug is ItemSlugOptions => IntroItemSlugs.includes(itemSlug as ItemSlugOptions);

const useGetContentForSlugItem = (itemSlug: ItemSlugOptions, handleRedirect: (itemName: string) => void, handleClose: (_event: object, reason?: string | undefined) => void) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const itemSlugToData = {
    'item-dependency-check': {
      description: 'dialogs.planItemIntro.itemsDescriptions.SoftwareComponentAnalysis',
    },
    'item-secret-detection': {
      description: 'dialogs.planItemIntro.itemsDescriptions.SecretsDetection',
    },
    'item-iac-misconfiguration-detection': {
      description: 'dialogs.planItemIntro.itemsDescriptions.IacMisconfigDetection',
    },
    'item-code-vulnerability': {
      description: 'dialogs.planItemIntro.itemsDescriptions.StaticCodeAnalysisPython',
    },
    'item-container-scan': {
      description: 'dialogs.planItemIntro.itemsDescriptions.ContainerScan',
    },
  };

  const itemPreview = itemSlugToData[itemSlug];

  const redirectToPipelines = () => {
    sendAnalyticsEvent({
      action: 'activation-demo-clicked',
      params: { requirement_name: itemSlug },
    });
    handleRedirect(itemSlug);
  };

  return (
    <div className={styles.container}>

      <JitIcon icon={GreenDotCheck} size='40px' />

      <JitText bold className={styles.title} size='l' text='dialogs.planItemIntro.title' />

      <JitText className={styles.description} size='m' text={itemPreview.description} />

      <div className={styles.actions}>
        <JitButton
          className={styles.testMeButton}
          onClick={redirectToPipelines}
          variant='contained'
        >
          <JitText bold text='dialogs.planItemIntro.buttons.testMeButton' />
        </JitButton>

        <JitButton
          className={styles.closeButton}
          onClick={handleClose}
          variant='text'
        >
          <JitText bold className={styles.closeButtonText} data-testid='slugItemContentCloseButton' text='Close' />
        </JitButton>
      </div>
    </div>
  );
};

interface Props {
  itemSlug: ItemSlugOptions;
  isOpen: boolean
  onClose: () => void
}

export const PlanItemIntroDialog: FC<Props> = ({ isOpen, onClose, itemSlug }) => {
  const { navigatePipelinesDemo } = useNavigatePipelinesDemo();

  const pipelineContextNew = usePipelineContextNew();

  const setActivationStateNew = (mockPipeline: IMockPipelineNew, showActivationPopper: boolean) => {
    if (pipelineContextNew) {
      pipelineContextNew.setMockPipeline(mockPipeline);
      pipelineContextNew.setShowActivationPopper(showActivationPopper);
    }
  };

  const handleRedirect = (itemName: string, commitType: PipelineDemoCommitType = PipelineDemoCommitType.CLEAN) => {
    navigatePipelinesDemo();
    createPlaceholderMockPipelineNew(itemName, commitType, setActivationStateNew);
    runPipelineDemoNew(itemName, commitType).then((res) => {
      if (res) {
        createMockPipelineNew(res.base.repo.name, res.number, itemName, commitType, res.base.repo.owner.login, setActivationStateNew);
      }
    });
  };

  const handleClose = (_event: object, reason?: string) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  const content = useGetContentForSlugItem(itemSlug, handleRedirect, handleClose);

  return (
    <div className={styles.container}>
      <CustomDialog
        content={content}
        isOpen={isOpen}
        onClose={handleClose}
      />
    </div>
  );
};
