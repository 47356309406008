import { FC } from 'react';

import styles from './SecurityPostureDescription.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  resolvedFindingsCount: number;
  openFindingsCount: number
  openFindingsFromCurrentPeriodCount: number;
  averageMttrString: string;
}

export const SecurityPostureDescription: FC<Props> = ({ resolvedFindingsCount, openFindingsCount, openFindingsFromCurrentPeriodCount, averageMttrString }) => (
  <div className={styles.wrapper} data-testid='security-posture-description'>
    <JitText
      color={colors.lightGray}
      components={{
        resolvedFindingsCount: <JitText
          bold
          color={colors.performanceGreen}
          display='inline'
          params={{
            resolvedFindingsCount,
          }}
          size='xxl'
          text='pages.securityImpact.securityPosture.resolvedFindingsCount'
        />,
        openFindingsCount: <JitText
          bold
          color={colors.failRed}
          display='inline'
          params={{ openFindingsCount }}
          size='xxl'
          text='pages.securityImpact.securityPosture.openFindingsCount'
        />,
        openFindingsFromCurrentPeriodCount: <JitText
          bold
          display='inline'
          params={{ openFindingsFromCurrentPeriodCount }}
          size='xxl'
          text='pages.securityImpact.securityPosture.openFindingsFromCurrentPeriodCount'
        />,
        averageMttrString: <JitText
          bold
          display='inline'
          size='xxl'
          text={averageMttrString}
        />,
        resolutionBold: <JitText
          bold
          color={colors.performanceGreen}
          display='inline'
          size='xxl'
          text='pages.securityImpact.securityPosture.resolutionBold'
        />,
      }}
      size='xxl'
      text='pages.securityImpact.securityPosture.description'
    />
  </div>
);
