import { VALID_SECRET_NAME_REGEX } from '../constants';

import { ITenantSecret } from 'types/interfaces/Secrets/ITenantSecret';

export enum SecretNameValidationResult {
  VALID_SECRET_NAME = 'ValidSecretName',
  INVALID_SECRET_NAME = 'InvalidSecretName',
  SECRET_NAME_ALREADY_EXISTS = 'SecretNameAlreadyExists',
}
export const validateSecretName = (secretName: string, existingSecrets?: ITenantSecret[]): SecretNameValidationResult => {
  if (!VALID_SECRET_NAME_REGEX.test(secretName)) {
    return SecretNameValidationResult.INVALID_SECRET_NAME;
  }
  if (existingSecrets?.some((secret) => secret.name === secretName)) {
    return SecretNameValidationResult.SECRET_NAME_ALREADY_EXISTS;
  }
  return SecretNameValidationResult.VALID_SECRET_NAME;
};
