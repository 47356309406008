import colors from 'themes/colors.module.scss';
import { FindingStatus, Severity } from 'types/enums';

export enum IgnoreFilterKeys {
  IGNORED = 'true',
  NOT_IGNORED = 'false',
}
export const IgnoreStatusOptions = [{
  key: IgnoreFilterKeys.NOT_IGNORED,
  name: 'pages.findings.ignored.false',
}, {
  key: IgnoreFilterKeys.IGNORED,
  name: 'pages.findings.ignored.true',
}];

export const findingStatusToTextColor = {
  [FindingStatus.FIXED]: colors.findingStatusFixedText,
  [FindingStatus.OPEN]: colors.findingStatusOpenText,
  [FindingStatus.IGNORED]: colors.findingStatusIgnoreText,
};

export const findingStatusToBackgroundColor = {
  [FindingStatus.FIXED]: colors.findingStatusFixedBG,
  [FindingStatus.OPEN]: colors.findingStatusOpenBG,
  [FindingStatus.IGNORED]: colors.findingStatusIgnoreBG,
};

export const findingSeverityToColor = {
  [Severity.Critical]: colors.severityDarkRed,
  [Severity.High]: colors.severityRed,
  [Severity.Medium]: colors.severityPurple,
  [Severity.Low]: colors.severityBlue,
};

export const FILTER_BOX_HEIGHT = 42;
export const SPACE_BETWEEN_FILTERS = 10;
export const REDUCED_WIDTH_FROM_FILTERS_LINE = 400;
export const FILTERS_MAX_SIZE_DEFAULT_SIZE = 0;

export const MAX_FINDINGS_IN_PAGE = 20;
