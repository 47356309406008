import { FC } from 'react';

import { CreateTicketButton } from 'components/CreateTicketButton';
import { IFinding } from 'types/interfaces';

interface Props {
  finding: IFinding;
  onClose: () => void;
}

export const FindingDetailsTickets: FC<Props> = ({
  finding,
  onClose,
}) => (
  <div>

    <CreateTicketButton findings={[finding]} onClose={onClose} />

  </div>
);
