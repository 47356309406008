// ScoreCell.tsx
import { FC } from 'react';

import { Score } from 'components/Score/Score';
import { ITableCell } from 'types/interfaces';

export const ScoreCell: FC<ITableCell> = ({ cell: { value } }) => (
  <div>
    <Score score={value} />
  </div>
);
