import { useCallback } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import {
  IAsset,
} from 'types/interfaces';

export const useAssetService = () => {
  const { client } = useClient();

  const getAssets = useCallback(async (teamName?: string) => {
    const url = getApiUrls.assetService.getAssets();
    return client.get<IAsset[]>(
      {
        url,
        requestConfig: {
          params: {
            team: teamName,
          },
        },
        allowedStatuses: [200, 404],
      },
    );
  }, [client]);

  const updateMultipleAssets = useCallback(async (assets: IAsset[]) => {
    const url = getApiUrls.assetService.updateMultipleAssets();
    const assetsPatch = assets.map((asset) => ({
      asset_id: asset.asset_id,
      risk_score: asset.risk_score,
      new_name: asset.asset_name,
      is_covered: asset.is_covered,
    }));
    return client.post<IAsset[]>({
      url,
      allowedStatuses: [200, 404],
      requestConfig: {
        data: assetsPatch,
      },
    });
  }, [client]);

  return {
    getAssets,
    updateMultipleAssets,
  };
};

