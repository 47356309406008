import { MutableRefObject, useCallback, useEffect } from 'react';

import { useFindingsContext } from 'context';
import { SPACE_BETWEEN_FILTERS } from 'pages/FindingsPage/constants';
import { IFindingFilter } from 'types/interfaces';

export const useManageFiltersFixedWidth = (visibleFilters: IFindingFilter[], itemsRef: MutableRefObject<HTMLDivElement[]>, filtersMaxSize: number) => {
  const { setFiltersVisibleInFirstLine } = useFindingsContext();
  const getFirstItemPosition = useCallback(() => {
    if (itemsRef.current !== null && itemsRef.current.length > 0) {
      return itemsRef.current[0].getBoundingClientRect().top;
    }
    return 0;
  }, [itemsRef]);

  const calculateWidthToFitFilterBoxes = (): number => {
    /*
     We calculate the needed width to fit all the boxes, since Flex (in CSS) does not provide a solution
     to fit all the boxes in the available space without taking the maximum allocated width, if we have more than
     1 line of filters.
     */
    let currentLength = 0;
    let reachedLimit = false;
    visibleFilters.forEach((_, index) => {
      const itemRef = itemsRef.current[index];
      if (itemRef) {
        if (currentLength + itemRef.getBoundingClientRect().width <= (filtersMaxSize) && !reachedLimit) {
          currentLength += itemRef.getBoundingClientRect().width + SPACE_BETWEEN_FILTERS;
        } else {
          reachedLimit = true;
        }
      }
    });
    if (currentLength > SPACE_BETWEEN_FILTERS) {
      currentLength -= SPACE_BETWEEN_FILTERS;
    }
    return currentLength;
  };

  const getFiltersVisibleInFirstLine = useCallback((): IFindingFilter[] => {
    const firstFilterPosition = getFirstItemPosition();
    let currentVisibleFiltersInFirstLine: IFindingFilter[] = [];
    itemsRef.current.forEach((itemRef, index) => {
      if (itemRef && itemRef.getBoundingClientRect().top === firstFilterPosition) {
        currentVisibleFiltersInFirstLine.push(visibleFilters[index]);
      }
    });
    currentVisibleFiltersInFirstLine = currentVisibleFiltersInFirstLine.filter((filter) => filter !== undefined);
    return currentVisibleFiltersInFirstLine;
  }, [itemsRef, visibleFilters, getFirstItemPosition]);

  const calculatedWidthToFitFilterBoxes = calculateWidthToFitFilterBoxes();

  useEffect(() => {
    const currentFiltersVisibleInFirstLine = getFiltersVisibleInFirstLine();
    setFiltersVisibleInFirstLine(currentFiltersVisibleInFirstLine);
  }, [itemsRef, visibleFilters, calculatedWidthToFitFilterBoxes, setFiltersVisibleInFirstLine, getFiltersVisibleInFirstLine]);

  return {
    calculatedWidthToFitFilterBoxes,
  };
};
