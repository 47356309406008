import { FC, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { SecurityPlanCard } from '../SecurityPlanCard/SecurityPlanCard';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import {
  usePlansContext,
  useTenantContext,
} from 'context';
import { constants } from 'globalConstants';
import { useGetLabelToColorMapping } from 'pages/SecurityPlansPage/hooks/plansTemplates';

export const SecurityPlansBody: FC = () => {
  const { initUserPreferences } = useTenantContext();
  const { plans, hasFetchedPlans, fetchPlans } = usePlansContext();
  const navigate = useNavigate();

  const { getLabelToColorMapping } = useGetLabelToColorMapping(Object.values(plans));

  useEffect(() => {
    initUserPreferences();
  }, [initUserPreferences]);

  useEffect(() => {
    if (!hasFetchedPlans) fetchPlans();
  }, [fetchPlans, hasFetchedPlans]);

  const navigateToPlanPage = (planSlug: string) => {
    navigate(`/${constants.routes.PLANS}/${planSlug}`);
  };

  const labelToColor = useMemo(() => getLabelToColorMapping(), [getLabelToColorMapping]);

  return !hasFetchedPlans ? <LoadingBar /> : (
    <>
      {Object.values(plans).map((plan) => (
        <SecurityPlanCard
          key={plan.slug}
          labelColorsMap={labelToColor}
          navigate={navigateToPlanPage}
          plan={plan}
        />
      ))}
    </>
  );
};
