import { FC } from 'react';

import { ActionCard } from './components/ActionCard/ActionCard';
import { EmptyActionCard } from './components/EmptyActionCard/EmptyActionCard';

import { IAction, IConcealedAction } from 'types/interfaces';

interface Props {
  actions: (IAction | IConcealedAction)[];
  count: number;
  teamName?: string;
}

export const Actions: FC<Props> = ({ actions, count, teamName }) => {
  const notConcealedActions = actions.filter((action) => !action.is_concealed) as IAction[];

  return (
    !actions.length ? <EmptyActionCard /> : (
      <ActionCard
        action={notConcealedActions.reduce((prev, current) => ((prev.created_at > current.created_at) ? prev : current))}
        allActions={count}
        teamName={teamName}
      />
    )
  );
};
