import { FC, useState } from 'react';

import styles from './FindingsCountBanner.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface FindingsCountBannerProps {
  onClick: () => void;
  findingsCount?: number;
}

export const FindingsCountBanner: FC<FindingsCountBannerProps> = ({ onClick, findingsCount }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const findingsCountText = findingsCount ?? '---';
  return (
    <div
      className={styles.wrapper}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role='button'
      tabIndex={0}
    >
      <JitText muted text='pages.teams.teamDetails.securityFindings' />

      <JitText color={colors.blue02} data-testid='findings-count-banner-count' text={isHovered ? 'pages.teams.teamDetails.seeDetails' : findingsCountText} />
    </div>
  );
};
