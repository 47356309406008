import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './StatsCards.module.scss';

import { JitText } from 'components/JitText/JitText';
import { Score } from 'components/Score/Score';
import { constants } from 'globalConstants';
import { FindingsCountBanner } from 'pages/TeamsPage/components/FindingsCountBanner/FindingsCountBanner';
import colors from 'themes/colors.module.scss';

interface Props {
  score: number;
  totalFindingsCount?: number;
  resourceName?: string;
}

export const StatsCards: FC<Props> = memo(({ score, totalFindingsCount, resourceName }: Props) => {
  const navigate = useNavigate();

  const handleFindingBannerClick = () => {
    navigate(`/${constants.routes.FINDINGS}?asset_name=${resourceName}&resolution=OPEN&ignored=false`);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <JitText color={colors.lightGray} text='pages.teams.resourcePlanItemsStatuses.stats.score' />

        <Score score={score} />
      </div>

      <FindingsCountBanner findingsCount={totalFindingsCount} onClick={handleFindingBannerClick} />
    </div>
  );
});
