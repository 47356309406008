import { FC } from 'react';

import styles from './SecurityPlanTopRowCard.module.scss';

interface Props {
  width?: string;
}

export const SecurityPlanTopRowCard: FC<Props> = ({ width, children }) => (
  <div className={styles.wrapper} style={{ width }}>

    {children}

  </div>
);
