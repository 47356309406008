import { useFindingsContext } from 'context/FindingsContext/FindingsContext';
import { IFindingFilter } from 'types/interfaces';

export const useUpdateSingleFilter = () => {
  const { filters, setFilters } = useFindingsContext();

  const updateSingleFilter = (updatedFilter: IFindingFilter) => {
    const updatedFilters = filters.map((currentFilter) => (currentFilter.key === updatedFilter.key ? updatedFilter : currentFilter));
    setFilters(updatedFilters);
  };
  return { updateSingleFilter };
};
