import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CONFIGURE_PLAN_ITEM_SLUG_QUERY_PARAM, CONFIGURE_PLAN_ITEM_SLUG_STEP_INDEX_QUERY_PARAM } from './constants';
import styles from './PlanPageBody.module.scss';

import { PlanItemManual } from 'components/Configurations/PlanItemManual/PlanItemManual';
import { usePlansContext } from 'context';
import { useGetConfigurationsComponentDetails } from 'context/ConfigurationsContext/hooks';
import {
  ConfigurePlanItemDialog,
  ItemSlugOptions,
  PlanCategoryList,
  PlanItemDetails,
  PlanItemIntroDialog,
} from 'pages/PlanPage/components';
import { IPlanItemDetails } from 'types/interfaces';

interface Props {
  planSlug: string;
}

export const PlanPageBody: FC<Props> = ({ planSlug }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPlanSlugToConfigure = searchParams.get(CONFIGURE_PLAN_ITEM_SLUG_QUERY_PARAM) || '';
  const initialPlanSlugToConfigureStepNumber = (initialPlanSlugToConfigure && Number(searchParams.get(CONFIGURE_PLAN_ITEM_SLUG_STEP_INDEX_QUERY_PARAM))) || undefined;
  const { plans } = usePlansContext();

  const [selectedPlanItemSlug, setSelectedPlanItemSlug] = useState<string>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [planItemSlugToConfigure, setPlanItemSlugToConfigure] = useState<string>(initialPlanSlugToConfigure);
  const [introItem, setIntroItem] = useState<ItemSlugOptions | null>(null);

  const selectedPlanItem = useMemo(() => (selectedPlanItemSlug ? plans[planSlug]?.items?.[selectedPlanItemSlug] : undefined), [planSlug, plans, selectedPlanItemSlug]);
  const [isManualDialogOpen, setIsManualDialogOpen] = useState<boolean>(!!selectedPlanItem?.is_manual);

  const planItemDetailsToConfigure = useMemo(() => plans[planSlug]?.items?.[planItemSlugToConfigure], [planItemSlugToConfigure, planSlug, plans]);

  const getConfigurationsComponentDetails = useGetConfigurationsComponentDetails();

  useEffect(() => {
    setSelectedPlanItemSlug(undefined);
    setExpanded(false);
  }, [planSlug]);

  const onCloseDetailedItem = () => {
    setExpanded(false);
    // delay allowing animation to complete
    setTimeout(() => {
      setSelectedPlanItemSlug(undefined);
    }, 300);
  };

  const onSelectItem = (item: IPlanItemDetails | undefined) => {
    if (item) {
      setSelectedPlanItemSlug(item.slug);
      setExpanded(true);
    } else {
      onCloseDetailedItem();
    }
  };

  useEffect(() => {
    if (!planItemSlugToConfigure) {
      searchParams.delete(CONFIGURE_PLAN_ITEM_SLUG_QUERY_PARAM);
      searchParams.delete(CONFIGURE_PLAN_ITEM_SLUG_STEP_INDEX_QUERY_PARAM);
    }
    setSearchParams(searchParams);
  }, [planItemSlugToConfigure, searchParams, setSearchParams]);

  const handleClose = () => {
    setPlanItemSlugToConfigure('');
    setIsManualDialogOpen(false);
  };

  const ConfigureManualDialog = useCallback((isOpen: boolean) => (
    <PlanItemManual
      isOpen={isOpen}
      onClose={handleClose}
      planItem={planItemDetailsToConfigure}
      planSlug={planSlug}
    />
  ), [planItemDetailsToConfigure, planSlug]);

  const ConfigureAutomaticDialog = useMemo(
    () => (planItemDetailsToConfigure ? getConfigurationsComponentDetails(planItemDetailsToConfigure) : null),
    [planItemDetailsToConfigure, getConfigurationsComponentDetails],
  );

  const configurePlanItemDialogComponent = useMemo(
    () => (
      planItemSlugToConfigure && (
        ConfigureAutomaticDialog
          ? (
            <ConfigurePlanItemDialog
              handleClose={() => {
                setPlanItemSlugToConfigure('');
              }}
              initialStep={initialPlanSlugToConfigureStepNumber}
              isOpen={!!planItemSlugToConfigure}
              planItemSlugToConfigure={planItemSlugToConfigure}
              planSlug={planSlug}
              {...ConfigureAutomaticDialog}
            />
          )
          : ConfigureManualDialog(isManualDialogOpen)
      )
    ),
    [planSlug, ConfigureAutomaticDialog, ConfigureManualDialog, initialPlanSlugToConfigureStepNumber, isManualDialogOpen, planItemSlugToConfigure],
  );

  const handleConfigure = (planItem: IPlanItemDetails) => {
    setPlanItemSlugToConfigure(planItem.slug);
    setIsManualDialogOpen(!!planItem.is_manual);
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.widthTransition} ${styles.list}`}>

        <PlanCategoryList
          detailedPlanItem={selectedPlanItem}
          handleConfigure={handleConfigure}
          planSlug={planSlug}
          setDetailedPlanItem={onSelectItem}
          setIntroItem={setIntroItem}
        />

      </div>

      {selectedPlanItem
                && (
                <PlanItemDetails
                  handleBack={onCloseDetailedItem}
                  handleConfigure={handleConfigure}
                  isExpanded={expanded}
                  planItem={selectedPlanItem}
                  planSlug={planSlug}
                  setIntroItem={setIntroItem}
                />
                )}

      {!!introItem && (
        <PlanItemIntroDialog
          isOpen
          itemSlug={introItem}
          onClose={() => setIntroItem(null)}
        />
      )}

      {planItemSlugToConfigure && configurePlanItemDialogComponent}

    </div>
  );
};
