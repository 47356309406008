import { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { AnalyticsEvents } from '../constants';

import styles from './RepoSelectionDropdown.module.scss';

import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { JitText } from 'components/JitText/JitText';
import { useAssetsContext } from 'context';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import colors from 'themes/colors.module.scss';
import { CentralizedAssetValidationStatus } from 'types/enums/CentralizedAssetValidationStatus';
import { IAsset } from 'types/interfaces';

interface Props {
  validateSelectedAsset: (asset: IAsset) => void;
  selectedAsset?: IAsset;
  setSelectedAsset: Dispatch<SetStateAction<IAsset | undefined>>;
  validationStatus?: CentralizedAssetValidationStatus;
  assets: IAsset[];
  isLoading: boolean;
}

export const RepoSelectionDropdown: FC<Props> = ({
  validateSelectedAsset,
  selectedAsset,
  setSelectedAsset,
  validationStatus,
  assets,
  isLoading,
}) => {
  const { initAssets, isLoadingAssets } = useAssetsContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const assetsMenuItems = assets.map((asset: IAsset) => ({
    itemKey: asset.asset_id,
    itemName: asset.asset_name,
    isSelected: asset.asset_id === selectedAsset?.asset_id,
  })).sort((a, b) => a.itemName.localeCompare(b.itemName));
  const isDropdownEnabled = !!assets.length;

  const handleAssetSelection = useCallback((assetId: string) => {
    sendAnalyticsEvent({ action: AnalyticsEvents.CentralizedRepoSelected });
    const newSelectedAsset = assets.find((asset: IAsset) => asset.asset_id === assetId);
    if (newSelectedAsset) {
      setSelectedAsset(newSelectedAsset);
      validateSelectedAsset(newSelectedAsset);
    }
  }, [assets, sendAnalyticsEvent, setSelectedAsset, validateSelectedAsset]);

  const reloadAssets = useCallback(() => {
    sendAnalyticsEvent({ action: AnalyticsEvents.CentralizedRepoReloadClicked });
    initAssets();
  }, [initAssets, sendAnalyticsEvent]);

  return (
    <div className={styles.dropdownRow}>
      <JitDropdownNew
        defaultDisplayText={`GithubIntegrationWizard.createCentralizedRepo.dropdown.${isDropdownEnabled ? 'placeholder' : 'emptyPlaceholder'}`}
        displayText={selectedAsset?.asset_name}
        errorTooltip='GithubIntegrationWizard.createCentralizedRepo.dropdown.errorTooltip'
        isEnabled={isDropdownEnabled}
        isError={validationStatus === CentralizedAssetValidationStatus.INVALID}
        isLoading={isLoading || isLoadingAssets || validationStatus === CentralizedAssetValidationStatus.PENDING}
        isVerified={validationStatus === CentralizedAssetValidationStatus.VALID}
        menuItems={assetsMenuItems}
        onMenuItemClick={({ itemKey }) => handleAssetSelection(itemKey as string)}
        width={450}
      />

      <JitText
        color={colors.iris}
        data-testid='reload-assets'
        onClick={reloadAssets}
        text='GithubIntegrationWizard.createCentralizedRepo.dropdown.reload'
      />
    </div>
  );
};

