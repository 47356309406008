import { FC } from 'react';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IMouseEvent } from 'types/interfaces';
import { iconsMap } from 'utils';

interface Props {
  handlePlanItemClick: (e: IMouseEvent) => void
}

export const PlanItemsDetailsSummaryLabelMisconfigured: FC<Props> = ({ handlePlanItemClick }) => (
  <>
    <JitIcon size={15} {...iconsMap.configuration} />

    <JitLink onClick={handlePlanItemClick}>
      <JitText color={colors.lightGray} text='pages.plan.planItem.config.configurationRequired' />
    </JitLink>
  </>
);
