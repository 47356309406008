import { FC, useMemo, useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react';

import { DevelopersCountInput } from './components/DevelopersCountInput/DevelopersCountInput';
import { HostingPlatformInput } from './components/HostingPlatformInput/HostingPlatformInput';
import styles from './FormStepContent.module.scss';

import { JitText } from 'components/JitText/JitText';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { SecurityPlansScreenshot } from 'components/SecurityPlansScreenshot/SecurityPlansScreenshot';
import colors from 'themes/colors.module.scss';
import { GitHostingPlatform, IOnboardingState, OnboardingDetailsContent } from 'types/interfaces';
import { useOnboardingState } from 'utils/hooks/useOnboardingState';

interface Props {
  setDisabledNextButton: (value: boolean) => void;
  setOnboardingDetails: Dispatch<SetStateAction<OnboardingDetailsContent>>,
}

export const FormStepContent: FC<Props> = ({ setDisabledNextButton, setOnboardingDetails }) => {
  const { getOnboardingState } = useOnboardingState();
  const onboardingState: IOnboardingState | undefined = useMemo(() => getOnboardingState(), [getOnboardingState]);
  const [name, setName] = useState(onboardingState?.details?.name);
  const [email, setEmail] = useState(onboardingState?.details?.mail);
  const [devsCount, setDevsCount] = useState(onboardingState?.details?.devs_count);
  const [hostingPlatform, setHostingPlatform] = useState(onboardingState?.details?.git_hosting_platform);

  const isEmailValid = useMemo(() => {
    if (!email) return false;
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  }, [email]);

  useEffect(
    () => {
      setDisabledNextButton(!name || !isEmailValid);
    },
    [email, isEmailValid, name, setDisabledNextButton],
  );

  const onChangeName = useCallback((e: string) => {
    setName(e);
    setOnboardingDetails((prev) => ({
      ...prev,
      name: e,
    }));
  }, [setOnboardingDetails]);

  const onChangeEmail = useCallback((e: string | undefined) => {
    setEmail(e);
    setOnboardingDetails((prev) => ({
      ...prev,
      mail: e,
    }));
  }, [setOnboardingDetails]);

  const onChangeDevsCount = useCallback((e: string | undefined) => {
    setDevsCount(e);
    setOnboardingDetails((prev) => ({
      ...prev,
      devs_count: e,
    }));
  }, [setOnboardingDetails]);

  const onChangeHostingPlatform = useCallback((e: GitHostingPlatform | undefined) => {
    setHostingPlatform(e);
    setOnboardingDetails((prev) => ({
      ...prev,
      git_hosting_platform: e,
    }));
  }, [setOnboardingDetails]);

  return (
    <div className={styles.root}>

      <div className={styles.content}>

        <div className={styles.titlesWrapper}>

          <JitText bold color={colors.black} size='huge' text='pages.onboarding.formStep.title' />

          <JitText color={colors.black} size='l' text='pages.onboarding.formStep.subtitle' />
        </div>

        <JitTextInput
          autoFocus={!name}
          boldLabel
          inputDataTestId='name-input'
          label='pages.onboarding.formStep.nameInput.label'
          onChange={onChangeName}
          overrideStyles={{ input: { cursor: 'pointer' },
            cursor: 'pointer' }}
          placeholder='pages.onboarding.formStep.nameInput.placeholder'
          value={name}
          variant='bright'
        />

        <JitTextInput
          boldLabel
          inputDataTestId='email-input'
          isError={!isEmailValid}
          label='pages.onboarding.formStep.emailInput.label'
          onChange={onChangeEmail}
          onErrorDisplayedMessage='pages.onboarding.formStep.emailInput.errorMessage'
          overrideStyles={{ input: { cursor: 'pointer' },
            cursor: 'pointer' }}
          placeholder='pages.onboarding.formStep.emailInput.placeholder'
          value={email}
          variant='bright'
        />

        <DevelopersCountInput initialValue={devsCount} setValue={onChangeDevsCount} />

        <HostingPlatformInput initialValue={hostingPlatform} setValue={onChangeHostingPlatform} />
      </div>

      <SecurityPlansScreenshot />

    </div>
  );
};
