import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { PlanItemDetailsBody } from './components';
import styles from './PlanItemDetails.module.scss';

import { ItemSlugOptions } from 'pages/PlanPage/components/PlanItemIntroDialog';
import { IPlanItemDetails } from 'types/interfaces';

interface Props {
  planSlug: string;
  planItem: IPlanItemDetails;
  handleBack: () => void;
  handleConfigure: (planItem: IPlanItemDetails) => void;
  setIntroItem: Dispatch<SetStateAction<ItemSlugOptions | null>>;
  isExpanded?: boolean;
}

export const PlanItemDetails: FC<Props> = ({ planSlug, planItem, handleBack, handleConfigure, setIntroItem, isExpanded }) => {
  const planItemName = planItem.name;
  const [currentPlanItemName, setCurrentPlanItemName] = useState(planItemName);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  useEffect(() => {
    if (!isExpanded) {
      setIsAnimatingOut(true);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (planItem.name !== currentPlanItemName) {
      setCurrentPlanItemName(planItem.name);
    }
  }, [planItem.name, currentPlanItemName]);

  return (
    <div
      className={`${styles.wrapperAbsolute} ${isAnimatingOut ? styles.animatingOut : ''}`}
    >
      <div
        className={styles.fadedDiv}
        onClick={() => handleBack()}
        role='presentation'
      />

      <div
        className={styles.PlanItemDetailsBodyWrapper}
      >
        <PlanItemDetailsBody
          handleBack={handleBack}
          handleConfigure={handleConfigure}
          planItem={planItem}
          planSlug={planSlug}
          setIntroItem={setIntroItem}
        />
      </div>
    </div>
  );
};
