import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { FC, useEffect, useState } from 'react';

import styles from './CopyFindingsButton.module.scss';

import { CopyIcon } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { copyFindingToClipboard } from 'pages/FindingsPage/utils/copyToClipboard';
import { IFinding } from 'types/interfaces';

interface Props {
  style?: React.CSSProperties;
  findings: IFinding[];
  shouldDisablePopper?: boolean;
}

export const CopyFindingsButton: FC<Props> = ({
  style,
  findings,
  shouldDisablePopper,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
    copyFindingToClipboard(findings);
    setTimeout(() => {
      setIsOpen(false);
    }, constants.COPY_TO_CLIPBOARD_DELAY);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (shouldDisablePopper) {
      setIsOpen(false);
    }
  }, [shouldDisablePopper]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={style}>

        <JitButton onClick={handleClick}>
          <JitIcon icon={CopyIcon} />
        </JitButton>

        <Popper
          anchorEl={anchorEl}
          className={styles.speechBubble}
          open={isOpen}
          placement='bottom-end'
        >
          <JitText style={{ margin: 7 }} text='pages.findings.copy.copiedToClipboard' />
        </Popper>
      </div>
    </ClickAwayListener>

  );
};
