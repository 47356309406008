import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { JitText } from 'components/JitText/JitText';
import { useSbomContext } from 'context/SbomContext/SbomContext';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { constants } from 'globalConstants';
import { logInfo } from 'services/logger/logger';
import colors from 'themes/colors.module.scss';
import { WebSocketNotificationTopics, ToastType } from 'types/enums';
import { IWebsocketNotificationSystemMessage } from 'types/interfaces';
import { SbomReportStatus } from 'types/interfaces/Sbom/SbomReportStatus';

type IStatusChangePayload = {
  status: SbomReportStatus,
};
export const useSubscribeToSbomStatusWebsocket = (reportStatus: SbomReportStatus | undefined, setSbomReportStatus: (status: SbomReportStatus) => void) => {
  const { getSbomComponents } = useSbomContext();
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { showToast } = useToastsContext();
  const navigate = useNavigate();
  const [lastUpdatedStatus, setLastUpdatedStatus] = useState<SbomReportStatus | undefined>(reportStatus);

  const handleSbomReportStatusChange = useCallback(async (notification: IWebsocketNotificationSystemMessage<IStatusChangePayload>) => {
    logInfo('Received Sbom status websocket notification', { notification });
    const { message: { status } } = notification;
    if (status === SbomReportStatus.ACTIVE) {
      await getSbomComponents({
        resetPagination: true,
        searchValue: '',
        silent: true,
      });
    }
    setLastUpdatedStatus(status);
    if (status in [SbomReportStatus.ACTIVE, SbomReportStatus.SCANNING]) {
      setSbomReportStatus(status);
    }
  }, [getSbomComponents, setSbomReportStatus]);

  const handleFirstSbomReportGenerated = useCallback(() => {
    logInfo('First Sbom report generated');
    showToast({
      type: ToastType.Success,
      overrideProps: {
        title: 'The SBOM report has been completed successfully.',
        content: <JitText color={colors.blue} onClick={() => navigate(`/${constants.routes.SBOM}`)} text='Go to SBOM page' />,
      },
    });
    if (lastUpdatedStatus) {
      setSbomReportStatus(lastUpdatedStatus);
    }
  }, [lastUpdatedStatus, navigate, setSbomReportStatus, showToast]);

  useEffect(() => {
    // @ts-ignore
    websocketSubscribe(WebSocketNotificationTopics.SbomStatusChange, handleSbomReportStatusChange);
    websocketSubscribe(WebSocketNotificationTopics.SbomFirstReportGenerated, handleFirstSbomReportGenerated);
  }, [handleFirstSbomReportGenerated, handleSbomReportStatusChange, websocketSubscribe]);
};
