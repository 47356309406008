import { useMemo } from 'react';

import { useFindingsContext } from 'context';
import { buildGetFindingsRequestParams } from 'context/FindingsContext/utils/buildGetFindingsRequestParams';
import { useUpdateEffect } from 'utils';

export const useResetPageOnFilterSortChange = (resetPage: () => void) => {
  const { filters, currentSortColumn, getTotalFindingsAmount } = useFindingsContext();
  const getFindingsRequestParams = useMemo(() => JSON.stringify( // We stringify the object to ensure change checks by value
    buildGetFindingsRequestParams({
      filters,
      currentSortColumn,
    }),
  ), [filters, currentSortColumn]);

  useUpdateEffect(() => {
    resetPage();
    getTotalFindingsAmount();
  }, [getFindingsRequestParams]);
};
