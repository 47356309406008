import { FC, useCallback, useMemo, useState } from 'react';

import styles from './SetAsGoalDialog.module.scss';

import { SetAsGoalOn } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useTenantContext } from 'context';
import { useTenantService } from 'services/TenantService/useTenantService';
import colors from 'themes/colors.module.scss';
import { PreferencesScope } from 'types/enums/PreferencesScope';
import { DisplayPreferences } from 'types/interfaces';

export type SetAsGoalDialogVariant = 'insidePlan' | 'goToPlan';

interface Props {
  plan: { slug: string, title: string }
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
  variant: SetAsGoalDialogVariant
}

export const SetAsGoalDialog: FC<Props> = ({ plan, onConfirm, onClose, isOpen, variant }) => {
  const { preferences } = useTenantContext();
  const { patchPreferences } = useTenantService();
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const shouldShowTheDialog = useMemo(() => preferences?.display?.show_set_as_goal_modal, [preferences?.display?.show_set_as_goal_modal]);

  const updateDontShowAgainPreference = useCallback(async () => {
    if (dontShowAgain) {
    // we update the preference only if the user checked the checkbox
    // since if the user already asked it not to show again in the past, this component will not be rendered again
      await patchPreferences<DisplayPreferences>(
        'display',
        {
          show_set_as_goal_modal: !dontShowAgain,
        },
        PreferencesScope.USER,
      );
    }
  }, [dontShowAgain, patchPreferences]);

  const handleConfirm = useCallback(async () => {
    updateDontShowAgainPreference();
    onConfirm();
  }, [onConfirm, updateDontShowAgainPreference]);

  const handleClose = useCallback(
    () => {
      updateDontShowAgainPreference();
      onClose();
    },
    [onClose, updateDontShowAgainPreference],
  );

  const content = useMemo(() => (
    <div className={styles.wrapper}>
      <JitIcon
        data-testid='SetAsGoalDialog-icon'
        icon={SetAsGoalOn}
        size={72}
        withBackSquare={
        {
          width: 92,
          height: 92,
          borderRadius: '50%',
          backgroundColor: colors.cards04,
          borderColor: 'transparent',
        }
      }
      />

      <div className={styles.textWrapper}>
        <JitText
          align='center'
          bold
          data-testid='SetAsGoalDialog-text-title'
          params={{ planName: plan.title || '' }}
          size='l'
          text='pages.securityPlans.planCard.setAsGoal.dialog.title'
        />

        <JitText
          align='center'
          color={colors.lightGray}
          data-testid='SetAsGoalDialog-text-subtitle'
          size='m'
          text='pages.securityPlans.planCard.setAsGoal.dialog.subtitle'
        />
      </div>

      <div className={styles.rowWrapper}>
        {variant === 'goToPlan' && (
        <JitButton
          data-testid='SetAsGoalDialog-button-close'
          onClick={handleClose}
          variant='outlined'
        >
          <JitText
            color={colors.iris}
            text='pages.securityPlans.planCard.setAsGoal.dialog.buttons.close'
          />
        </JitButton>
        )}

        <JitButton
          data-testid='SetAsGoalDialog-button-confirm'
          onClick={handleConfirm}
          variant='contained'
        >
          <JitText
            text={`pages.securityPlans.planCard.setAsGoal.dialog.buttons.${variant === 'goToPlan' ? 'plan' : 'gotIt'}`}
          />
        </JitButton>

      </div>

      <JitButton className={styles.dontShowAgainWrapper} data-testid='dontShowAgain-checkbox' disableRipple onClick={() => setDontShowAgain(!dontShowAgain)} variant='text'>
        <JitCheckbox checked={dontShowAgain} />

        <JitText muted text='pages.securityPlans.planCard.setAsGoal.dialog.buttons.dontShowAgain' />
      </JitButton>
    </div>
  ), [dontShowAgain, handleClose, handleConfirm, plan.title, variant]);

  return shouldShowTheDialog ? (
    <CustomDialog
      content={content}
      dataTestId={`set-as-goal-dialog-${plan.slug}`}
      isOpen={isOpen}
      onClose={onClose}
      title='pages.securityPlans.planCard.setAsGoal.dialog.header'
      width='s'
      withDivider
    />
  ) : null;
};
