import { IConfigurations } from 'types/interfaces';

const isUniqueApplicationName = (applicationName: string, applicationNames?: string[]): boolean => {
  // applicationNames contains the current input applicationName plus all other existing application names,
  // this method validates that the input applicationNames array contains the input applicationName only once.
  const map = applicationNames?.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());
  return map?.has(applicationName) ? map.get(applicationName) <= 1 : true;
};

export const ApplicationNameValidator = (applicationName: string, configurations?: IConfigurations) => {
  const applicationNames: string[] | undefined = configurations?.applications?.map((application) => application.application_name);

  return isUniqueApplicationName(applicationName, applicationNames);
};
