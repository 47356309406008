import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { FC } from 'react';

import { StyledLinearProgress } from 'components/AppLoadingBar/AppLoadingBar.styles';

interface Props {
  'data-testid'?: string,
  sx?: SxProps
}

export const LoadingBar: FC<Props> = (props: Props) => {
  const { sx } = props;
  return (
    <Box
      data-testid='loadingBar'
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '300px',
        transform: 'translateX(-50%)',
        ...sx,
      }}
      {...props}
    >
      <StyledLinearProgress value={50} />
    </Box>
  );
};
