import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';

import { useFormatAndExtendFilters, useSyncFiltersWithQueryParams } from '.';

import { useFindingsContext } from 'context/FindingsContext/FindingsContext';
import { buildGetFindingsRequestParams } from 'context/FindingsContext/utils/buildGetFindingsRequestParams';
import { fetchFilterOptions } from 'services/FindingsService';

export const useInitFilters = () => {
  const { filters, isLoadingFilters, setIsLoadingFilters, setFilters, savedFilters, getSavedFilters } = useFindingsContext();
  const { relevantQueryParams, updateFiltersWithInitialQueryParams } = useSyncFiltersWithQueryParams(filters);

  const { formatAndExtendFilters } = useFormatAndExtendFilters();
  const getFilterOptions = useCallback(async () => {
    const formattedFilterParams = buildGetFindingsRequestParams({});
    const fetchedOptions = await fetchFilterOptions(formattedFilterParams);
    if (!fetchedOptions) {
      console.error('Error while getting filter options');
    }
    return fetchedOptions;
  }, []);

  const initFilters = useCallback(async () => {
    setIsLoadingFilters(true);
    const serverFilterOptions = await getFilterOptions();
    if (serverFilterOptions) {
      let updatedFilters = formatAndExtendFilters(filters, serverFilterOptions);
      if (!isEmpty(relevantQueryParams)) {
        updatedFilters = updateFiltersWithInitialQueryParams(updatedFilters);
      }
      setFilters(updatedFilters);
    }
    setIsLoadingFilters(false);
  }, [filters, formatAndExtendFilters, getFilterOptions, relevantQueryParams, setFilters, setIsLoadingFilters, updateFiltersWithInitialQueryParams]);

  useEffect(() => {
    if (filters.length || savedFilters.length || isLoadingFilters) return;
    getSavedFilters(true);
    initFilters();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
