import { createContext, useContext } from 'react';

import { ITenantSecret } from 'types/interfaces/Secrets/ITenantSecret';

interface ISecretsContext {
  tenantSecrets?: ITenantSecret[];
  setTenantSecrets: (tenantSecrets: ITenantSecret[]) => void;
  isLoading: boolean;
  fetchTenantSecrets: () => void;
  showSecretsManagement: boolean;
  setShowSecretsManagement: (show: boolean) => void;
}

export const SecretsContext = createContext<ISecretsContext>({
  tenantSecrets: undefined,
  setTenantSecrets: () => {},
  isLoading: false,
  fetchTenantSecrets: () => {},
  showSecretsManagement: false,
  setShowSecretsManagement: () => {},
});

export const useSecretsContext = () => useContext(SecretsContext);
