import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { FC, ReactElement, useMemo } from 'react';

import { config } from 'config';

interface Props {
  children: ReactElement;
  tenantId: string;
}

export const FeatureFlagProvider: FC<Props> = ({ children, tenantId }) => {
  const LaunchDarklyConfig = useMemo(() => ({
    clientSideID: config.launchDarklyCliendId || '',
    user: {
      key: tenantId || '',
      name: tenantId || '',
    },
  }), [tenantId]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const LaunchDarklyProvider = useMemo(() => withLDProvider(LaunchDarklyConfig)(() => <>{children}</>), [LaunchDarklyConfig, children]);

  return (
    <LaunchDarklyProvider />
  );
};
