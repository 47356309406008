import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useWebsocketSubscribe } from '../WebSocketContext/hooks';

import { useHandleIntegrationError } from './hooks/useHandleIntegrationError';
import { useHandleIntegrationsWebSocketNotification } from './hooks/useHandleIntegrationsWebSocketNotification';

import { IntegrationsContext } from 'context';
import { useIntegrationsService } from 'services/IntegrationsService/useIntegrationService';
import { logInfo } from 'services/logger/logger';
import { WebSocketNotificationTopics } from 'types/enums';
import { IIntegration } from 'types/interfaces/Integrations/IIntegration';

export const IntegrationsProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasIntegrationsFetched, setHasIntegrationsFetched] = useState(false);
  const [fileSha, setFileSha] = useState<undefined | string>(undefined);
  const [integrations, setIntegrations] = useState<undefined | IIntegration[]>(undefined);
  const { getAllIntegrations } = useIntegrationsService();
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handleIntegrationsWebSocketNotification } = useHandleIntegrationsWebSocketNotification({ setIntegrations });
  const { handleIntegrationError } = useHandleIntegrationError(integrations);

  const fetchIntegrations = useCallback(async () => {
    if (!isLoading && !integrations && !hasIntegrationsFetched) {
      setIsLoading(true);
      const integrationsResponse = await getAllIntegrations();
      setHasIntegrationsFetched(true);
      if (integrationsResponse?.status === 200) {
        setIntegrations(integrationsResponse.data.integrations);
        setFileSha(integrationsResponse.data.fileSha);
      } else if (integrationsResponse?.status === 404) {
        setIntegrations([]);
        logInfo('No integrations file found');
      } else {
        setIntegrations(undefined);
      }
      setIsLoading(false);
    }
  }, [getAllIntegrations, hasIntegrationsFetched, integrations, isLoading]);

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  useEffect(() => {
    handleIntegrationError();
  }, [handleIntegrationError]);

  useEffect(() => {
    if (integrations !== undefined) {
      websocketSubscribe(WebSocketNotificationTopics.Integration, handleIntegrationsWebSocketNotification);
    }
  }, [handleIntegrationsWebSocketNotification, integrations, websocketSubscribe]);

  const value = useMemo(() => ({
    integrations,
    fileSha,
    isLoading,
    fetchIntegrations,
  }), [integrations, fileSha, isLoading, fetchIntegrations]);

  return (
    <IntegrationsContext.Provider value={value}>
      {children}
    </IntegrationsContext.Provider>
  );
};
