import { FC } from 'react';

import styles from './SecurityPlansScreenshot.module.scss';

import { SecurityPlansScreenshotImage } from 'assets';

export const SecurityPlansScreenshot: FC = () => (
  <div className={styles.root}>

    <img
      alt='security plans screenshot'
      className={styles.image}
      data-testid='security-plans-screenshot-image'
      draggable={false}
      height={400}
      src={SecurityPlansScreenshotImage}
      width={400}
    />

  </div>

);
