import { useCallback, useMemo } from 'react';

import { usePlanTemplatesContext } from 'context';
import { IPlanItemTemplate } from 'types/interfaces';

export const useGetPlanItemsTemplatesBySlugs = () => {
  const { planItemsTemplates } = usePlanTemplatesContext();
  const planItemTemplateBySlug: { [key: string]: IPlanItemTemplate } = useMemo(() => {
    const result: { [key: string]: IPlanItemTemplate } = {};

    planItemsTemplates.forEach((planItemTemplate: IPlanItemTemplate) => {
      result[planItemTemplate.slug] = planItemTemplate;
    });

    return result;
  }, [planItemsTemplates]);

  const getPlanItemsTemplatesBySlugs = useCallback(
    (planItemSlugs: string[]) => planItemSlugs
      .map((slug) => planItemTemplateBySlug[slug])
      .filter((planItemTemplate): planItemTemplate is IPlanItemTemplate => !!planItemTemplate),
    [planItemTemplateBySlug],
  );

  return { getPlanItemsTemplatesBySlugs };
};
