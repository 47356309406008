import { useCallback, useMemo, useState } from 'react';

import { IOnboardingWizardStep, IOnboardingWizardStepStatus } from '../OnboardingWizard';

import { useFormStep } from './useFormStep';
import { useGoalStep } from './useGoalStep';

import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { constants } from 'globalConstants';
import { useTenantService } from 'services/TenantService/useTenantService';
import { OnboardingDetailsContent, OnboardingStatus } from 'types/interfaces';
import {
  IPartialUpdateOnboardingDetailsRequest,
  IPartialUpdateOnboardingDetailsResponse,
} from 'types/interfaces/TenantService/IOnboardingDetails';
import { useOnboardingState } from 'utils/hooks/useOnboardingState';

enum ClickAction {
  NEXT = 'next',
  BACK = 'back',
}

export const useWizardSteps = (incrementStepIndex: () => void, decrementStepIndex: () => void, handleClose: () => void) => {
  const { partialUpdateOnboardingDetails } = useTenantService();
  const { setOnboardingState, deleteOnboardingState } = useOnboardingState();
  const [isLoading, setIsLoading] = useState(false);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const shouldShowGoalStep = useMemo(() => !sessionStorage.getItem(constants.GOAL_PLAN_KEY), []);

  const handleUpdate = useCallback(async (onboardingDetails: OnboardingDetailsContent, isDone?: boolean) => {
    // prepare request
    const request = onboardingDetails as IPartialUpdateOnboardingDetailsRequest;
    if (isDone) request.onboarding_status = OnboardingStatus.DONE;

    // send request
    setIsLoading(true);
    const res = await partialUpdateOnboardingDetails(request);
    setIsLoading(false);

    if (res?.status === 200) {
      // update onboarding state
      if (isDone) deleteOnboardingState();
      else {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { onboarding_status, ...details } = res.data as IPartialUpdateOnboardingDetailsResponse;
        setOnboardingState({
          status: onboarding_status,
          details,
        });
      }
      // update goal plan
      if (request.plan) sessionStorage.setItem(constants.GOAL_PLAN_KEY, request.plan);
    }
  }, [setIsLoading, partialUpdateOnboardingDetails, setOnboardingState, deleteOnboardingState]);

  const sendClickEvent = useCallback((stepName: string, action: ClickAction) => {
    sendAnalyticsEvent({
      action: `onboarding-${action}-button-click`,
      params: { step: stepName },
    });
  }, [sendAnalyticsEvent]);

  const handleBackClick = useCallback((stepName: string) => {
    sendClickEvent(stepName, ClickAction.BACK);
    decrementStepIndex();
  }, [sendClickEvent, decrementStepIndex]);

  const handleNextClick = useCallback(async (stepName: string, onboardingDetails: OnboardingDetailsContent, isDone?: boolean) => {
    await handleUpdate(onboardingDetails, isDone);
    sendClickEvent(stepName, ClickAction.NEXT);
  }, [handleUpdate, sendClickEvent]);

  const handleLastStepNextClick = useCallback(async (stepName: string, onboardingDetails: OnboardingDetailsContent) => {
    await handleNextClick(stepName, onboardingDetails, true);
    handleClose();
  }, [handleNextClick, handleClose]);

  const isStepCompleted = useCallback((step: IOnboardingWizardStep) => step.status === IOnboardingWizardStepStatus.COMPLETE, []);

  const formStep = useFormStep({
    incrementStepIndex,
    handleNextClick: shouldShowGoalStep ? handleNextClick : handleLastStepNextClick,
    isLoading,
  });
  const goalStep = useGoalStep({
    isPrevStepCompleted: isStepCompleted(formStep),
    handleBackClick,
    handleNextClick: handleLastStepNextClick,
    isLoading,
  });

  return useMemo(() => (shouldShowGoalStep ? [formStep, goalStep] : [formStep]), [formStep, goalStep, shouldShowGoalStep]);
};
