import { useCallback, useEffect, useState } from 'react';

import { getApiUrls } from 'services/apiUrls';
import { useHttpStream } from 'utils';

export const useAskQuestion = () => {
  const { sendRequest, data, error, isPolling } = useHttpStream();
  const [answer, setAnswer] = useState('');

  useEffect(() => {
    setAnswer((prev) => prev + data);
  }, [data]);

  const askQuestion = useCallback(
    async (question: string, tenantId: string, userId: string) => {
      setAnswer('');
      const url = getApiUrls.docsService.answer();

      sendRequest(url, 'POST', {
        question,
        tenantId,
        userId,
      });

      return {
        answer,
        error,
        isPolling,
      };
    },
    [sendRequest, answer, error, isPolling],
  );

  return {
    askQuestion,
    answer,
    isPolling,
    error,
  };
};
