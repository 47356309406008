import { FC } from 'react';

import styles from './CoverageOffering.module.scss';
import { PlanList } from './PlanList';

import { JitText } from 'components/JitText/JitText';
import { IPlan } from 'pages/SecurityMappingPage/interfaces/IPlan';
import colors from 'themes/colors.module.scss';

export interface ICoverageOfferingProps {
  handlePlanClick: (id: string) => void;
  handlePlanLinkClick: (id: string) => void;
  securityPlans: IPlan[];
}

export const CoverageOffering: FC<ICoverageOfferingProps> = ({ handlePlanClick, handlePlanLinkClick, securityPlans }) => (
  <div>
    <div className={styles.header}>
      <JitText
        bold
        data-testid='coverage-offering-header'
        size='l'
        text='pages.securityMapping.coverageOffering.labels.coverageOffering'
      />

      <JitText
        color={colors.lightGray}
        data-testid='coverage-offering-turn-on'
        text='pages.securityMapping.coverageOffering.labels.turnOn'
      />

    </div>

    <PlanList
      handlePlanClick={handlePlanClick}
      handlePlanLinkClick={handlePlanLinkClick}
      securityPlans={securityPlans}
    />
  </div>
);

