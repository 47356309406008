import { FC } from 'react';

import { FTRGoalIcon, MVSGoalIcon1, MVSGoalIcon2, MVSGoalIcon3, MVSGoalIcon4 } from 'assets';
import { constants } from 'globalConstants';
import { ISvg } from 'types/interfaces';

export interface IGoalCard {
  plan: string;
  icon: FC<ISvg>
  goal: string;
  key: string;
}
export const goalCards: IGoalCard[] = [
  {
    plan: constants.PLAN_SLUG.MVS,
    icon: MVSGoalIcon1,
    goal: 'pages.onboarding.goalsStep.goals.1',
    key: '1',
  },
  {
    plan: constants.PLAN_SLUG.MVS,
    icon: MVSGoalIcon2,
    goal: 'pages.onboarding.goalsStep.goals.2',
    key: '2',
  },
  {
    plan: constants.PLAN_SLUG.FTR,
    icon: FTRGoalIcon,
    goal: 'pages.onboarding.goalsStep.goals.3',
    key: '3',
  },
  {
    plan: constants.PLAN_SLUG.MVS,
    icon: MVSGoalIcon3,
    goal: 'pages.onboarding.goalsStep.goals.4',
    key: '4',
  },
  {
    plan: constants.PLAN_SLUG.MVS,
    icon: MVSGoalIcon4,
    goal: 'pages.onboarding.goalsStep.goals.5',
    key: '5',
  },
];

