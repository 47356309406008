import { t } from 'i18next';
import { FC, ReactElement } from 'react';

import { FileInputAnalyticsEvents, UploadStatus } from './constants';
import styles from './JitFileInput.module.scss';

import { Check, ClearIcon, Dot, Loading, UploadFileIcon, ErrorIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useFilesContext } from 'context';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import colors from 'themes/colors.module.scss';
import { FileEntityType } from 'types/enums/FileEntityType';
import { formatBytes } from 'utils/functions/sizeFormatter';

interface Props {
  file: File | undefined;
  handleFileClear: () => void;
  inputValue: string;
  uploadStatus: UploadStatus | undefined;
}

const fileInputFields = t('fileInput', { returnObjects: true }) as { loading: string, complete: string, invalid: string };

export const uploadStatusToIconMap = new Map<UploadStatus, { message: string, component: ReactElement }>([
  [UploadStatus.LOADING, {
    message: fileInputFields.loading,
    component: <JitIcon
      key='file-input-loading-icon'
      className={styles.loadingIcon}
      color={colors.iris}
      data-testid='file-input-loading-icon'
      icon={Loading}
      size={40}
    />,
  }],
  [UploadStatus.COMPLETE, {
    message: fileInputFields.complete,
    component: <JitIcon
      key='file-input-complete-icon'
      color={colors.performanceGreen}
      data-testid='file-input-complete-icon'
      icon={Check}
      size={10}
    />,
  }],
  [UploadStatus.INVALID, {
    message: fileInputFields.invalid,
    component: <JitIcon
      key='file-input-invalid-icon'
      color={colors.performanceRed}
      data-testid='file-input-invalid-icon'
      icon={ErrorIcon}
      size={16}
    /> }],
]);

export const JitFileInputNonEmptyState: FC<Props> = ({ file, handleFileClear, inputValue, uploadStatus }) => {
  const fileName = inputValue ? inputValue.replace('.jit/', '') : file?.name;
  const fileSize = file ? formatBytes(file.size).toLowerCase() : 'NA';
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const { filesMetadata } = useFilesContext();
  const fileMetadata = filesMetadata?.find((f) => (f.type === FileEntityType.OTHER && f.filePath === inputValue));
  const fileLink = (fileMetadata && fileMetadata.owner && fileMetadata.repo && fileMetadata.filePath && fileMetadata.branch)
    ? config.getGithubRefFileLink(fileMetadata.owner, fileMetadata.repo, fileMetadata.branch, fileMetadata.filePath) : undefined;

  const onClearClick = () => {
    sendAnalyticsEvent({ action: FileInputAnalyticsEvents.FileClearClicked });
    handleFileClear();
  };

  const { message, component } = (uploadStatus && uploadStatusToIconMap.get(uploadStatus)) || {
    message: '',
    component: null,
  };

  return (
    <div className={styles.nonEmptyStateWrapper} data-testid='file-input-non-empty-state'>
      <div className={styles.nonEmptyStateContainer}>
        {uploadStatus === UploadStatus.LOADING && (<JitIcon data-testid='file-input-upload-icon' icon={UploadFileIcon} />)}

        <div>
          <JitText
            color={colors.white}
            data-testid='file-input-name-text'
            noWrap
            onClick={fileLink ? () => window.open(fileLink) : undefined}
            text={fileName}
            withUnderline={!!fileLink}
          />

          {file && (
          <div className={styles.nonEmptyStateContainer}>
            <JitText
              color={colors.lightGray}
              data-testid='file-input-size-text'
              size='s'
              text={fileSize}
            />

            <JitIcon color={colors.lightGray} icon={Dot} size={4} />

            <JitText
              color={colors.lightGray}
              data-testid='file-input-status-text'
              size='s'
              text={message || uploadStatus}
            />
          </div>
          )}
        </div>
      </div>

      <div className={styles.nonEmptyStateContainer}>
        <JitIcon data-testid='file-input-clear-button' icon={ClearIcon} onClick={onClearClick} size={10} />

        {uploadStatus && component}
      </div>
    </div>
  );
};
