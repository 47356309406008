import { ServerFindingKeys } from 'types/interfaces/Findings/IFindingServer';

export interface IServerFindingFilterOptions {
  [ServerFindingKeys.location_text]?: string[];
  [ServerFindingKeys.test_name]?: string[];
  [ServerFindingKeys.vulnerability_type]?: string[];
  [ServerFindingKeys.plan_item]?: string[];
  [ServerFindingKeys.issue_severity]?: string[];
  [ServerFindingKeys.asset_type]?: string[];
  [ServerFindingKeys.plan_layer]?: string[];
  [ServerFindingKeys.asset_name]?: string[];
  [ServerFindingKeys.status]?: string[];
  [ServerFindingKeys.control_name]?: string[];
  [ServerFindingKeys.team]?: string[];
}

export const TIME_AGO_FILTER_KEY: string = 'time_ago';

export enum IFindingFilterType {
  SINGLE_SELECT = 'single_select',
  MULTI_SELECT = 'multi_select',
}
export type MenuItemKeyType = string | boolean;

export interface IFindingFilter {
  key: string;
  type: IFindingFilterType;
  valueOptions: MenuItemKeyType[];
  selectedValue: MenuItemKeyType[] | MenuItemKeyType;
  defaultValue: MenuItemKeyType[] | MenuItemKeyType;
  isVisible: boolean;
  defaultVisibility: boolean;
}
