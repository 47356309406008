import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { FC } from 'react';

import styles from './DialogCard.module.scss';
import { DialogCardActionsContent } from './DialogCardActionsContent';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IInstallation, IntegrationStatus, ISvg } from 'types/interfaces';
import { Link } from 'types/interfaces/Link/Link';

interface Props {
  icon?: FC<ISvg>;
  vendor: string;
  description: string;
  onConnect: () => void;
  onConfigure: () => void;
  isLoading?: boolean;
  configureText: string;
  installation?: IInstallation;
  width?: string;
  minHeight?: string;
  maxHeight?: string;
  link?: Link;
  isConnected?: boolean;
}

const style = {
  cardGeneralStyle: {
    width: '550px',
    minHeight: '125px',
    maxHeight: '150px',
    position: 'relative',
    borderRadius: '8px',
    padding: '20px !important',
    backgroundColor: colors.black01,
    border: `1px solid ${colors.statusGray}`,
  },
};

export const DialogCard: FC<Props> = ({ icon, vendor, description, onConnect, onConfigure, configureText, installation,
  width, minHeight, maxHeight, isLoading, link, isConnected }) => {
  const cardLink = link && (
    <JitLink
      href={link.href}
      style={{ textDecoration: 'none',
        cursor: 'pointer' }}
      target='_blank'
    >
      <div className={styles.linkChildren}>
        <JitText color={colors.iris} text={link.text} />

        <JitIcon color={colors.iris} icon={link.icon} size={12} />
      </div>
    </JitLink>
  );
  return (

    <Card
      className={styles.generalCardContainer}
      sx={{
        ...style.cardGeneralStyle,
        width: width || style.cardGeneralStyle.width,
        minHeight: minHeight || style.cardGeneralStyle.minHeight,
        maxHeight: maxHeight || style.cardGeneralStyle.maxHeight,
      }}
    >

      <CardContent className={styles.cardContentContainer}>
        {icon && ( // Conditionally render the icon component
          <div className={styles.iconContainer}>
            <JitIcon centered={false} icon={icon} size={35} />
          </div>
        )}

        <div>
          <JitText bold className={styles.vendorText} size='l' text={`vendor.${vendor}`} />

          <JitText className={styles.descriptionText} color={colors.lightGray} text={description} />

          {installation?.status === IntegrationStatus.CONNECTED || isConnected ? (
            <JitText className={styles.configureText} color={colors.iris} onClick={onConfigure} text={configureText} />
          )
            : cardLink}
        </div>
      </CardContent>

      <CardActions className={styles.cardActionsContainer}>
        <DialogCardActionsContent
          integrationStatus={installation?.status || isConnected ? IntegrationStatus.CONNECTED : undefined}
          isLoading={isLoading}
          onConnect={onConnect}
        />
      </CardActions>
    </Card>
  );
};
