import { createContext, FC, useContext, useMemo, useState } from 'react';

interface INavigationContext {
  isBlockingNavigation?: boolean;
  setIsBlockingNavigation: (value: boolean) => void;
  isAboutToNavigatePath: string;
  setIsAboutToNavigatePath: (value: string) => void;
}

export const NavigationContext = createContext<INavigationContext>({
  isBlockingNavigation: false,
  setIsBlockingNavigation: () => undefined,
  isAboutToNavigatePath: '',
  setIsAboutToNavigatePath: () => undefined,
});

export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationProvider: FC = ({ children }) => {
  const [isBlockingNavigation, setIsBlockingNavigation] = useState<boolean>(false);
  const [isAboutToNavigatePath, setIsAboutToNavigatePath] = useState<string>('');

  const value = useMemo(() => ({
    isBlockingNavigation,
    setIsBlockingNavigation,
    isAboutToNavigatePath,
    setIsAboutToNavigatePath,
  }), [isBlockingNavigation, isAboutToNavigatePath]);

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};
