import { useMemo } from 'react';

import { CreateCentralizedRepo } from '../StepComponents/CreateCentralizedRepo/CreateCentralizedRepo';

import { IGithubIntegrationWizardStep, IGithubIntegrationWizardStepStatus } from 'components/GithubIntegrationWizard/GithubIntegrationWizard';
import { useAssetsContext } from 'context';

interface Props {
  isPrevStepCompleted: boolean;
  closeWizard: () => void;
}

export const useGetCreateCentralizedRepoStep = ({
  isPrevStepCompleted,
  closeWizard,
}: Props): IGithubIntegrationWizardStep => {
  const { centralizedRepo } = useAssetsContext();

  const status = useMemo(() => {
    if (isPrevStepCompleted) {
      if (centralizedRepo) {
        return IGithubIntegrationWizardStepStatus.COMPLETE;
      }
      return IGithubIntegrationWizardStepStatus.ACTIVE;
    }
    return IGithubIntegrationWizardStepStatus.NOT_STARTED;
  }, [centralizedRepo, isPrevStepCompleted]);

  return useMemo(() => ({
    title: 'GithubIntegrationWizard.createCentralizedRepo.tabText',
    component: () => (
      <CreateCentralizedRepo closeWizard={closeWizard} />
    ),
    status,
  }), [status, closeWizard]);
};
