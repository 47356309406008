import { useCallback, useState } from 'react';

import { useFindingsContext } from 'context';
import { IgnoreReason } from 'types/enums/IgnoreReason';
import { IgnoreRuleType, IgnoreRequestSource, OperatorTypes } from 'types/enums/IgnoreRules';
import { IFinding, CreateIgnoreRuleRequest } from 'types/interfaces';

export const useUpdateFindingsStatus = (reFetchCurrentPage: () => Promise<void>) => {
  const [findingsToIgnore, setFindingsToIgnore] = useState<IFinding[]>([]);
  const [isIgnoreLoading, setIsIgnoreLoading] = useState(false);

  const {
    findings,
    ignoreFindings,
    undoIgnoreFindings,
    getTotalFindingsAmount,
  } = useFindingsContext();

  const reloadPage = useCallback(async () => {
    await Promise.all([
      reFetchCurrentPage(),
      getTotalFindingsAmount(),
    ]);
  }, [reFetchCurrentPage, getTotalFindingsAmount]);

  const completeIgnoreFindings = useCallback(async (reason: IgnoreReason, description: string) => {
    setIsIgnoreLoading(true);
    const ignoredRulesToCreate: CreateIgnoreRuleRequest[] = findingsToIgnore.map((finding) => ({
      type: IgnoreRuleType.IGNORE,
      reason,
      comment: description,
      source: IgnoreRequestSource.BACKLOG,
      fields: [
        { name: 'fingerprint',
          value: finding.fingerprint,
          operator: OperatorTypes.EQUAL },
        { name: 'control_name',
          value: finding.controlName,
          operator: OperatorTypes.EQUAL },
        { name: 'asset_id',
          value: finding.assetId,
          operator: OperatorTypes.EQUAL },
      ],
    }));
    await ignoreFindings(ignoredRulesToCreate);
    setIsIgnoreLoading(false);
    setFindingsToIgnore([]);
    await reloadPage();
  }, [findingsToIgnore, ignoreFindings, reloadPage]);

  const updateMultipleFindingsStatus = useCallback(async (selectedFindingIds: string[], ignored: boolean) => {
    const findingsToUpdate = findings.filter((finding) => selectedFindingIds.includes(finding.id) && finding.ignored !== ignored);
    if (ignored) {
      setFindingsToIgnore(findingsToUpdate);
    }
    if (!ignored) {
      await undoIgnoreFindings(findingsToUpdate);
      await reloadPage();
    }
  }, [findings, undoIgnoreFindings, reloadPage]);

  const updateFindingIgnoredStatus = useCallback(async (findingToUpdate: IFinding, ignored: boolean) => {
    if (findingToUpdate.ignored !== ignored) {
      if (ignored) {
        setFindingsToIgnore([findingToUpdate]);
      }
      if (!ignored) {
        await undoIgnoreFindings([findingToUpdate]);
        await reloadPage();
      }
    }
    return findings;
  }, [findings, undoIgnoreFindings, reloadPage]);

  return {
    updateMultipleFindingsStatus,
    updateFindingStatus: updateFindingIgnoredStatus,
    findingsToIgnore,
    setFindingsToIgnore,
    completeIgnoreFindings,
    isIgnoreLoading,
  };
};
