import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import { getNotHiddenTabs } from './utils';

import { Tab, TabItemTypes } from '.';

import { PlanPage, BacklogPage, PipelinesPage, ActionsPage, IntegrationsPage, Insights, OverviewPage, Users, SbomPage, SecurityMappingPage } from 'assets';
import { constants } from 'globalConstants';
import { useRoutesOfUser } from 'wrappers/RBAC';

const {
  FINDINGS, MATRIX, PLAN, PLANS, INTEGRATIONS, PIPELINES, ACTIONS, OVERVIEW, TEAMS, SBOM, SECURITY_MAPPING,
  insights: { BASE_ROUTE: BASE_INSIGHTS_ROUTE, PULL_REQUESTS, DEPLOYMENTS, PERFORMANCE, SECURITY_IMPACT },
} = constants.routes;
export const useGetPageTabItems = () => {
  const { uiShowTeamsPage, uiShowSbomPage, uiShowTheLoopPage } = useFlags();
  const { defaultRoute } = useRoutesOfUser();

  const pagesTabs: Tab[] = useMemo(() => [
    {
      type: TabItemTypes.item,
      route: OVERVIEW,
      name: 'navbar.routes.overview',
      icon: OverviewPage,
      isDefault: defaultRoute.key === OVERVIEW,
    },
    {
      type: TabItemTypes.item,
      route: SECURITY_MAPPING,
      name: 'navbar.routes.securityMapping',
      icon: SecurityMappingPage,
      shouldHide: !uiShowTheLoopPage,
      isNew: true,
    },
    {
      type: TabItemTypes.item,
      route: PLANS,
      name: 'navbar.routes.securityPlans',
      icon: PlanPage,
      isDefault: [PLAN, PLANS].includes(defaultRoute.key),
    },
    {
      type: TabItemTypes.item,
      route: PIPELINES,
      name: 'navbar.routes.pipelines',
      icon: PipelinesPage,
    },
    {
      type: TabItemTypes.item,
      route: ACTIONS,
      name: 'navbar.routes.actions',
      icon: ActionsPage,
    },
    {
      type: TabItemTypes.item,
      route: TEAMS,
      name: 'navbar.routes.teams',
      icon: Users,
      shouldHide: !uiShowTeamsPage,
    },
    {
      type: TabItemTypes.group,
      name: 'navbar.routes.insights',
      icon: Insights,
      items: [{
        type: TabItemTypes.item,
        route: `${BASE_INSIGHTS_ROUTE}/${SECURITY_IMPACT}`,
        name: 'navbar.routes.securityImpact',
      }, {
        type: TabItemTypes.item,
        route: `${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}`,
        name: 'navbar.routes.performance',
      }, {
        type: TabItemTypes.item,
        route: `${BASE_INSIGHTS_ROUTE}/${PULL_REQUESTS}`,
        name: 'navbar.routes.pullRequests',
      }, {
        type: TabItemTypes.item,
        route: `${BASE_INSIGHTS_ROUTE}/${DEPLOYMENTS}`,
        name: 'navbar.routes.deployments',
      }],
    },
    {
      type: TabItemTypes.item,
      route: FINDINGS,
      name: 'navbar.routes.findings',
      icon: BacklogPage,
    },
    {
      type: TabItemTypes.item,
      route: MATRIX,
      name: 'navbar.routes.planMatrix',
      shouldHide: true, // Until further notice, this tab is not available
    },
    {
      type: TabItemTypes.item,
      route: SBOM,
      name: 'navbar.routes.sbom',
      icon: SbomPage,
      shouldHide: !uiShowSbomPage,
      isNew: true,
    },
    {
      type: TabItemTypes.item,
      route: INTEGRATIONS,
      name: 'navbar.routes.integrations',
      icon: IntegrationsPage,
      isDefault: defaultRoute.key === INTEGRATIONS,
    },
  ], [defaultRoute.key, uiShowTeamsPage, uiShowSbomPage, uiShowTheLoopPage]);

  return {
    pagesTabs: getNotHiddenTabs(pagesTabs),
  };
};
