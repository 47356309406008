import { FC } from 'react';

import { GithubIntegrationCard } from './GithubCard/GithubIntegrationCard';
import styles from './GithubIntegrationWidget.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  handleIntegrate?: () => void;
}

export const GithubIntegrationWidget: FC<Props> = ({ handleIntegrate }) => (
  <div className={styles.wrapper} data-testid='github-integration-wrapper'>
    <div className={styles.header} data-testid='github-integration-header'>
      <JitText bold color={colors.basicLight} text='pages.plan.githubIntegration.title' />
    </div>

    <div className={styles.content} data-testid='github-integration-content'>
      <GithubIntegrationCard handleClick={handleIntegrate} />
    </div>
  </div>
);
