import { FC } from 'react';

import { ISegmentSettings, ISegmentWrapper, SegmentId } from '../interfaces/ISegment';
import { Segment } from '../Segment';

export const Plan: FC<ISegmentWrapper> = ({ colors, isTheLoopHovered, activities, onSegmentClick, onSegmentMouseEnter, onSegmentMouseLeave }) => {
  // eslint-disable-next-line max-len
  const path = 'M403.873 318.095L310 105.999L297.502 101C295.911 100.364 294.123 100.438 292.59 101.204L288.53 103.235C286.928 104.036 285.768 105.51 285.366 107.256L283.296 116.254L379.779 330.694L403.873 318.095Z';
  // textPath = PLAN
  // eslint-disable-next-line max-len
  const textPath = 'M305.609 141.732L310.905 139.323L311.855 141.413C312.038 141.815 312.117 142.192 312.092 142.544C312.069 142.896 311.956 143.208 311.753 143.479C311.552 143.751 311.276 143.967 310.924 144.127C310.572 144.287 310.227 144.352 309.889 144.323C309.551 144.294 309.242 144.169 308.961 143.949C308.681 143.731 308.448 143.419 308.263 143.012L307.658 141.68L308.555 141.272L309.078 142.423C309.176 142.638 309.294 142.799 309.432 142.905C309.572 143.012 309.724 143.07 309.888 143.078C310.055 143.088 310.225 143.053 310.399 142.974C310.575 142.894 310.713 142.789 310.812 142.659C310.913 142.529 310.968 142.377 310.976 142.203C310.986 142.028 310.941 141.832 310.842 141.615L310.499 140.86L306.118 142.852L305.609 141.732ZM307.753 146.447L313.049 144.039L313.559 145.158L309.185 147.147L310.218 149.417L309.295 149.837L307.753 146.447ZM310.108 151.627L309.563 150.427L315.691 149.847L316.347 151.29L311.881 155.524L311.335 154.324L314.818 151.139L314.799 151.098L310.108 151.627ZM312.156 150.605L313.445 153.44L312.571 153.837L311.282 151.003L312.156 150.605ZM319.481 158.183L314.185 160.592L313.745 159.624L316.031 155.804L316.013 155.766L312.68 157.281L312.17 156.162L317.467 153.753L317.914 154.736L315.622 158.537L315.643 158.583L318.974 157.069L319.481 158.183Z';

  const settings: ISegmentSettings = {
    gradient: {
      coordinates: {
        x1: '331.648',
        x2: '305.322',
        y1: '346.584',
        y2: '105.834',
      },
    },
    tooltip: {
      height: '105',
      width: '270',
      position: {
        x: '290',
        y: '40',
      },
    },
  };

  return (
    <Segment
      activities={activities}
      colors={colors}
      id={SegmentId.Plan}
      isTheLoopHovered={isTheLoopHovered}
      onSegmentClick={onSegmentClick}
      onSegmentMouseEnter={onSegmentMouseEnter}
      onSegmentMouseLeave={onSegmentMouseLeave}
      path={path}
      settings={settings}
      textPath={textPath}
    />
  );
};
