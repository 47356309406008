import { isEmpty } from 'lodash';
import { FC, UIEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { PIPELINE_ROW_APPROXIMATE_HEIGHT } from './constants';
import { useGetTableColumnsV3 } from './hooks/useGetTableColumnsV3';
import styles from './PipelinesPage.module.scss';
import { downcastMockPipelineNew } from './utils/MockPipelineNew/mockPipelineNew';
import { shouldShowPipeline } from './utils/utilsNew';

import { JitFilters } from 'components/JitFilters/JitFilters';
import { MissingGithubIntegration } from 'components/JitTable/components';
import { AppliedFiltersHasNoData } from 'components/JitTable/components/AppliedFiltersHasNoData/AppliedFiltersHasNoData';
import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { JitText } from 'components/JitText/JitText';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { useTenantContext } from 'context';
import { useResetFiltersNew } from 'context/PipelinesContext/hooks/useResetFilters/useResetFiltersNew';
import { useUpdateFilterNew } from 'context/PipelinesContext/hooks/useUpdateFilter/useUpdateFilterNew';
import { usePipelineContextNew } from 'context/PipelinesContext/PipelinesContextNew';
import { isFiltersApplied } from 'context/PipelinesContext/utils/isFiltersApplied';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';
import { IMockPipelineNew, IPipelineNew } from 'types/interfaces/Pipelines/IPipelineNew';

export const NewPipelinesPage: FC = () => {
  const navigate = useNavigate();

  const { columnsData } = useGetTableColumnsV3();
  const {
    mockPipeline, secondaryMockPipeline, setSecondaryMockPipeline, pipelinesState, fetchMore, filters, setSelectedPipeline,
  } = usePipelineContextNew();
  const { pipelines, hasLoaded, isLoading } = pipelinesState;
  const { resetFilters } = useResetFiltersNew();
  const { updateFilter } = useUpdateFilterNew();
  const { isGithubIntegrated } = useTenantContext();

  const findPipelineIndexInArray = (pipelineToFind: IPipelineNew, pipelinesArray?: IPipelineNew[]) => {
    const originalRepository = pipelineToFind.additionalAttributes?.originalRepository;
    const pullRequestNumber = pipelineToFind.additionalAttributes?.pullRequestNumber;
    const pipelineIndex = pipelinesArray ? pipelinesArray.findIndex((pipeline: IPipelineNew) => (
      pipeline.additionalAttributes?.originalRepository === originalRepository
      && parseInt(pipeline.additionalAttributes?.pullRequestNumber as string, 10)
      === parseInt(pullRequestNumber as string, 10)
    )) : -1;
    return pipelineIndex;
  };

  const prevDisplayPipelines = useMemo(() => {
    if (!secondaryMockPipeline) {
      return pipelines || [];
    }
    const pipelineIndex = findPipelineIndexInArray(secondaryMockPipeline, pipelines);
    const isFound = pipelineIndex > -1;
    if (isFound) {
      setSecondaryMockPipeline(null);
      return pipelines || [];
    }
    if (!shouldShowPipeline(filters, secondaryMockPipeline)) {
      return pipelines || [];
    }
    return [downcastMockPipelineNew(secondaryMockPipeline), ...(pipelines || [])];
  }, [filters, pipelines, secondaryMockPipeline, setSecondaryMockPipeline]);

  const displayPipelines = useMemo(() => {
    if (!isGithubIntegrated) return [];

    if (mockPipeline) {
      if (!shouldShowPipeline(filters, mockPipeline)) {
        return prevDisplayPipelines;
      }
      const pinnedPipelineIndex = findPipelineIndexInArray(mockPipeline, prevDisplayPipelines);
      const isFound = pinnedPipelineIndex > -1;
      if (isFound) {
        const pinnedPipeline: IMockPipelineNew = {
          ...prevDisplayPipelines[pinnedPipelineIndex],
          planItemSlug: mockPipeline.planItemSlug,
          commitType: mockPipeline.commitType,
        };
        setSelectedPipeline(pinnedPipeline);
        return [pinnedPipeline, ...prevDisplayPipelines.slice(0, pinnedPipelineIndex), ...prevDisplayPipelines.slice(pinnedPipelineIndex + 1)];
      }
      if (!isEmpty(prevDisplayPipelines)) {
        setSelectedPipeline(mockPipeline);
      }
      return [mockPipeline, ...prevDisplayPipelines];
    }
    return [...prevDisplayPipelines];
  }, [filters, mockPipeline, prevDisplayPipelines, setSelectedPipeline, isGithubIntegrated]);

  const tableInstance = useGetTableInstance(columnsData.columns, displayPipelines);

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    if (!target) return;
    const actualTableHeight = target.scrollHeight - target.clientHeight;
    const scrollPosition = target.scrollTop;
    const SCROLL_POSITION_THRESHOLD = PIPELINE_ROW_APPROXIMATE_HEIGHT * 5;
    // We want to fetch more data when we are close to the end of the table (start seeing the last 5 rows)
    const shouldFetchMore = actualTableHeight - scrollPosition < SCROLL_POSITION_THRESHOLD;
    if (shouldFetchMore) {
      fetchMore();
    }
  };

  const onSelectRow = (row: IPipelineNew) => {
    setSelectedPipeline(row);
    navigate(`/${constants.routes.PIPELINES}/${row.pipelineId}`);
  };

  const elementToShowOnPipelineHover: FC = () => (
    <div className={styles.elementToShowOnPipelineHover}>
      <JitText color={colors.iris} text='pages.pipelines.pipelinesTable.openDetails' />
    </div>
  );

  const emptyTableView = useMemo(() => {
    if (!isGithubIntegrated) {
      return (
        <MissingGithubIntegration
          subtitle='pages.pipelines.pipelinesTable.missingGithubIntegration'
        />
      );
    }
    if (isFiltersApplied(filters) || isLoading) {
      return (
        <AppliedFiltersHasNoData
          clearFilters={resetFilters}
          entityName='pipelines'
        />
      );
    }
    return undefined;
  }, [isGithubIntegrated, filters, isLoading, resetFilters]);

  return (
    <div className={styles.pipelinesPage} data-testid='NewPipelinesPage'>
      <div className={styles.titleWrapper}>
        <PageTitles subtitle='pages.pipelines.subTitle' title='pages.pipelines.title' />
      </div>

      <div className={styles.filtersWrapper}>
        <JitFilters
          filters={filters}
          resetFilters={resetFilters}
          updateFilter={updateFilter}
        />
      </div>

      <div className={styles.pipelinesTable} data-testid='pipelinesTableWrapper'>
        <JitTable
          cellPadding='5px 0 25px 20px'
          cellVerticalAlign='top'
          ElementToShowOnRowHover={elementToShowOnPipelineHover}
          emptyTableText='pages.pipelines.pipelinesTable.noPipelinesData'
          emptyTableView={emptyTableView}
          entityName='pipelines'
          handleScroll={handleScroll}
          isFetching={isGithubIntegrated && isLoading}
          isLoading={isGithubIntegrated && !hasLoaded}
          onSelectRow={onSelectRow}
          rowHoverStyle={{ cursor: 'pointer' }}
          showPagination={false}
          tableDesign='bright'
          tableInstance={tableInstance}
        />
      </div>
    </div>
  );
};
