import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import { GitHubCard } from '../../components/GitHubCard/GitHubCard';
import githubCardStyles from '../../components/GitHubCard/GitHubCard.module.scss';

import { ErrorBanner } from './ErrorBanner';
import { RepoSelectionDropdown } from './RepoSelectionDropdown';
import styles from './RepoSelectionDropdown.module.scss';

import { JitText } from 'components/JitText/JitText';
import { useAssetsContext } from 'context';
import { constants } from 'globalConstants';
import { CentralizedAssetValidationStatus } from 'types/enums/CentralizedAssetValidationStatus';
import { AssetType } from 'types/enums/index';
import { IAsset } from 'types/interfaces';

interface Props {
  validateSelectedAsset: (asset: IAsset) => void;
  selectedAsset?: IAsset;
  setSelectedAsset: Dispatch<SetStateAction<IAsset | undefined>>;
  validationStatus?: CentralizedAssetValidationStatus;
  setValidationStatus: Dispatch<SetStateAction<CentralizedAssetValidationStatus | undefined>>;
}

export const StepGithubCard: FC<Props> = ({
  validateSelectedAsset,
  selectedAsset,
  setSelectedAsset,
  validationStatus,
  setValidationStatus,
}) => {
  const { assets } = useAssetsContext();
  const [isLoading, setIsLoading] = useState(true);
  const [hasClickedCreate, setHasClickedCreate] = useState(false);

  const repoTypeAssets = useMemo(() => assets.filter((asset) => asset.is_active && asset.asset_type === AssetType.REPO), [assets]);
  useEffect(() => {
    if (!repoTypeAssets.length) {
      setSelectedAsset(undefined);
      setValidationStatus(undefined);
    }
  }, [repoTypeAssets.length, setSelectedAsset, setValidationStatus]);

  const loadingTimeout = useMemo(() => setTimeout(() => {
    setIsLoading(false);
  }, constants.LOADER_TIMEOUT), []);

  useEffect(() => {
    if (repoTypeAssets.length > 0) {
      setIsLoading(false);
      clearTimeout(loadingTimeout);
    }
  }, [loadingTimeout, repoTypeAssets.length]);

  const onCreateRepoClick = () => {
    setIsLoading(true);
    setHasClickedCreate(true);
    setTimeout(() => {
      setIsLoading(false);
    }, constants.LOADER_TIMEOUT);
  };
  const shouldShowNoReposError = useMemo(
    () => !repoTypeAssets.length && !isLoading,
    [repoTypeAssets.length, isLoading],
  );
  const shouldShowNoReposLoader = useMemo(() => !repoTypeAssets.length && hasClickedCreate && isLoading, [repoTypeAssets.length, hasClickedCreate, isLoading]);
  const shouldShowValidationError = validationStatus === CentralizedAssetValidationStatus.INVALID;

  return (
    <GitHubCard>
      <div className={styles.cardParts}>
        <div>
          <div className={githubCardStyles.cardLeftSide}>
            <JitText bold size='l' text='gettingStartedWizard.createCentralizedRepo.card.title' />

          </div>

          <JitText muted text='gettingStartedWizard.createCentralizedRepo.card.subtitle' />
        </div>

        <RepoSelectionDropdown
          assets={repoTypeAssets}
          isLoading={shouldShowNoReposLoader}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          validateSelectedAsset={validateSelectedAsset}
          validationStatus={validationStatus}
        />

        {
          (shouldShowNoReposError && <ErrorBanner onCreateRepoClick={onCreateRepoClick} title='gettingStartedWizard.createCentralizedRepo.card.errorBannerNoRepos' />)
            || (shouldShowValidationError && <ErrorBanner onCreateRepoClick={onCreateRepoClick} title='gettingStartedWizard.createCentralizedRepo.card.errorBanner' />)
        }
      </div>
    </GitHubCard>
  );
};

