import { useMemo } from 'react';

import { ResourceNameAndTypeCellV3 } from '../components/ResourceNameAndTypeCell/ResourceNameAndTypeCellV3';
import { StatusIndicationCellNew } from '../components/StatusIndicationCell/StatusIndicationCellNew';
import { WorkflowsCellNew } from '../components/WorkflowsNew/WorkflowsCellNew';
import { RunnerCell } from '../RunnerCell/RunnerCell';
import { getFormattedDuration, getFormattedTimeSince, isoFormatToDateFormat } from '../utils';
import { getJobRunner, getPrLink } from '../utils/utilsNew';

import { config } from 'config';
import { i18n } from 'locale/i18n';
import { IPipelinesTableColumnsV3 } from 'types/interfaces';
import { IMockPipelineNew, IPipelineNew } from 'types/interfaces/Pipelines/IPipelineNew';
import { TableColumns } from 'types/types/TableColumns';

export const useGetTableColumnsV3 = () => {
  const { t } = i18n;
  const columnHeaders: IPipelinesTableColumnsV3 = useMemo(() => t('pages.pipelines.pipelinesTable.columnHeadersV3', { returnObjects: true }), [t]);

  const getColumnsData = () => {
    const columns = [
      {
        Header: columnHeaders.resource,
        accessor: (pipeline: IPipelineNew) => ({
          assetName: pipeline.sourceAssetName,
          assetType: pipeline.sourceAssetType,
          vendor: pipeline.sourceAssetVendor,
          owner: pipeline.sourceAssetOwner,
          start: getFormattedTimeSince(pipeline.registeredAt),
          startTime: isoFormatToDateFormat(pipeline.registeredAt),
          duration: getFormattedDuration(pipeline.duration),
          eventType: pipeline.jitEventName,
          workflows: pipeline.workflows,
          userVendorId: pipeline.additionalAttributes?.userVendorId,
          userVendorName: pipeline.additionalAttributes?.userVendorName,
          environment: pipeline.additionalAttributes?.environment,
        }),
        Cell: ResourceNameAndTypeCellV3,
        width: 250,
      },
      {
        Header: columnHeaders.runner,
        accessor: (pipeline: IPipelineNew) => ({
          jobRunner: getJobRunner(pipeline.workflows),
          shouldNotExpectJobRunner: pipeline.expected === 0,
        }),
        Cell: RunnerCell,
        width: 100,
      },
      {
        Header: columnHeaders.workflows,
        accessor: (pipeline: IMockPipelineNew) => ({
          workflows: pipeline.workflows,
          jobRunner: getJobRunner(pipeline.workflows),
          prLink: getPrLink(pipeline),
          owner: pipeline.sourceAssetOwner,
          jitEventId: pipeline.jitEventId,
          expected: pipeline.expected,
          activationProperties:
            {
              sourceAssetOwner: pipeline.sourceAssetOwner,
              sourceAssetName: pipeline.sourceAssetName,
              planItemSlug: pipeline.planItemSlug,
              commitType: pipeline.commitType,
              prLink: config.getGithubPRLink(
                {
                  org: pipeline.sourceAssetOwner || '',
                  repo: pipeline.additionalAttributes?.originalRepository || '',
                  prNumber: pipeline.additionalAttributes?.pullRequestNumber || '' },
              ),
            },
        }),
        Cell: WorkflowsCellNew,
        width: 250,
      },
      {
        Header: columnHeaders.pipelineStatus,
        accessor: (pipeline: IMockPipelineNew) => ({
          pipeline,
        }),
        Cell: StatusIndicationCellNew,
        width: 140,
      },
    ];
    return { columns };
  };

  const columnsData = useMemo(getColumnsData, [columnHeaders]);

  return { columnsData: columnsData as TableColumns<object> };
};
