import Markdown from 'markdown-to-jsx';
import React from 'react';

import styles from './BotMessage.module.scss';

import { MagicSquare, Like, Dislike } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import colors from 'themes/colors.module.scss';
import { markdownLinksProps } from 'utils/constants/markdownLinksProps';

interface BotMessageProps {
  message: string;
  onFeedback: (e: React.MouseEvent<HTMLElement>) => void;
}

export const BotMessage: React.FC<BotMessageProps> = ({ message, onFeedback }) => {
  const [isLiked, setIsLiked] = React.useState<boolean | null>(null);

  const handleFeedback = (e: React.MouseEvent<HTMLElement>) => {
    const { action } = e.currentTarget.dataset;
    if (action === 'like-ai-bot-answer') {
      setIsLiked(true);
    } else if (action === 'dislike-ai-bot-answer') {
      setIsLiked(false);
    }
    onFeedback(e);
  };

  return (
    <div className={styles.chatItem}>
      <span className={styles.botIcon}><JitIcon icon={MagicSquare} size='2rem' /></span>

      <span className={styles.mdWrapper}>
        <Markdown {...markdownLinksProps}>{message}</Markdown>
      </span>

      <div className={styles.chatItemActions}>
        <JitIcon
          color='transparent'
          data-action='like-ai-bot-answer'
          data-testid='like-ai-bot-answer'
          icon={Like}
          onClick={handleFeedback}
          size={14}
          strokeColor={isLiked === true ? colors.jitPink : colors.lightGray}
        />

        <JitIcon
          color='transparent'
          data-action='dislike-ai-bot-answer'
          data-testid='dislike-ai-bot-answer'
          icon={Dislike}
          onClick={handleFeedback}
          size={14}
          strokeColor={isLiked === false ? colors.jitPink : colors.lightGray}
        />
      </div>
    </div>
  );
};
