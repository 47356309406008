import { FC, useState } from 'react';

import { Graphs } from './components/Graphs/Graphs';
import styles from './PerformancePage.module.scss';

import { PageTitles } from 'components/PageTitles/PageTitles';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { i18n } from 'locale/i18n';
import { PeriodDropdown } from 'pages/InsightsPage/SubPages/PerformancePage/components/PeriodDropdown/PeriodDropdown';
import { ShareSnapshotButton } from 'pages/InsightsPage/SubPages/PerformancePage/components/ShareSnapshotButton/ShareSnapshotButton';
import { ShareSnapshotDialog } from 'pages/InsightsPage/SubPages/PerformancePage/components/ShareSnapshotDialog/ShareSnapshotDialog';
import { getPeriodDate } from 'pages/InsightsPage/utils/getPeriodDates';
import { TimeAgo } from 'types/enums';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IDailyFindingsActivityMetric } from 'types/interfaces/Metrics/PerformanceMetrics/DailyFindingsActivities';
import { IDetectionRateMetric } from 'types/interfaces/Metrics/PerformanceMetrics/DetectionRate';
import { IExposureWindowMetric } from 'types/interfaces/Metrics/PerformanceMetrics/ExposureWindow';
import { IFindingsOverTimeMetric } from 'types/interfaces/Metrics/PerformanceMetrics/FindingsOvertime';
import { IMTTRMetric } from 'types/interfaces/Metrics/PerformanceMetrics/MTTR';
import { IPlanItemFindingsMetric } from 'types/interfaces/Metrics/PerformanceMetrics/PlanItemFindings';
import { IResourceTypeFindingsMetric } from 'types/interfaces/Metrics/PerformanceMetrics/ResourceTypeFindings';
import { Snapshot } from 'types/interfaces/Metrics/Snapshots';
import { formatDate } from 'utils/functions/stringFormatter';

interface Props {
  detectionRateMetric: MetricWithLoadingIndication<IDetectionRateMetric>;
  mttrMetric: MetricWithLoadingIndication<IMTTRMetric>;
  findingsOverTimeMetric: MetricWithLoadingIndication<IFindingsOverTimeMetric>;
  exposureWindowMetric: MetricWithLoadingIndication<IExposureWindowMetric>;
  dailyFindingsActivitiesMetric: MetricWithLoadingIndication<IDailyFindingsActivityMetric>;
  resourceTypeFindingsMetric: MetricWithLoadingIndication<IResourceTypeFindingsMetric>;
  planItemFindingsMetric: MetricWithLoadingIndication<IPlanItemFindingsMetric>;
  period: TimeAgo;
  handlePeriodChange: (newPeriod: TimeAgo) => void;
  snapshot: Snapshot | undefined;
}

export const PerformancePage: FC<Props> = ({ detectionRateMetric, mttrMetric, findingsOverTimeMetric, exposureWindowMetric,
  dailyFindingsActivitiesMetric, resourceTypeFindingsMetric, planItemFindingsMetric, period, handlePeriodChange, snapshot }) => {
  const [isShareSnapshotModalOpen, setIsShareSnapshotModalOpen] = useState(false);
  const { startDate, endDate } = getPeriodDate(period);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const onShareSnapshotButtonClick = () => {
    setIsShareSnapshotModalOpen(true);
    sendAnalyticsEvent({ action: 'share-snapshot-button-clicked' });
  };
  const onCloseShareSnapshotModal = () => {
    setIsShareSnapshotModalOpen(false);
    sendAnalyticsEvent({ action: 'share-snapshot-modal-closed' });
  };

  const getSnapshotDateDisplayText = (startDateText: string, endDateText: string) => {
    const snapshotStartDate = formatDate(startDateText, false);
    const snapshotEndDate = formatDate(endDateText, false);
    const snapshotPeriodDisplayText = i18n.t('pages.performance.shareSnapshot.dateDisplayText', {
      startDate: snapshotStartDate,
      endDate: snapshotEndDate,
    });

    return snapshotPeriodDisplayText;
  };

  const snapshotPeriodDisplayText = snapshot && getSnapshotDateDisplayText(snapshot.startDate, snapshot.endDate);
  const shortTitle = snapshot ? i18n.t('pages.performance.shortTitle', { snapshotName: snapshot.name }) : i18n.t('pages.performance.shortTitleNoSnapshot');

  return (
    <>
      <div className={styles.wrapper} data-testid='performancePage'>
        <div className={styles.pageHeader}>
          <PageTitles shortTitle={shortTitle} subtitle='pages.performance.subtitle' title='pages.performance.title' />

          <div className={styles.pageHeaderRightSide}>
            <PeriodDropdown handlePeriodChange={handlePeriodChange} period={period} snapshotPeriod={snapshotPeriodDisplayText} />

            <ShareSnapshotButton isSnapshotMode={!!snapshot} onClick={onShareSnapshotButtonClick} />
          </div>

        </div>

        <Graphs
          dailyFindingsActivitiesMetric={dailyFindingsActivitiesMetric}
          detectionRateMetric={detectionRateMetric}
          endDate={endDate}
          exposureWindowMetric={exposureWindowMetric}
          findingsOverTimeMetric={findingsOverTimeMetric}
          mttrMetric={mttrMetric}
          period={period}
          planItemFindingsMetric={planItemFindingsMetric}
          resourceTypeFindingsMetric={resourceTypeFindingsMetric}
          startDate={startDate}
        />
      </div>

      {isShareSnapshotModalOpen && <ShareSnapshotDialog isOpen onClose={onCloseShareSnapshotModal} period={period} />}
    </>
  );
};
