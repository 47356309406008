import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';

import { SecretNameValidationResult, validateSecretName } from '../utils/validateSecretName';

import styles from './CreateSecretDialogContent.module.scss';

import { ErrorIcon, Info } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitPasswordInput } from 'components/JitPasswordInput/JitPasswordInput';
import { JitText } from 'components/JitText/JitText';
import { JitTextInput, JitTextInputIconPosition } from 'components/JitTextInput/JitTextInput';
import { useSecretsContext } from 'context';
import { ISecretFields } from 'context/IntegrationsContext/templates/interfaces';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';
import { Link } from 'types/interfaces/Link/Link';
import { ITenantSecret } from 'types/interfaces/Secrets/ITenantSecret';

interface Props {
  existingSecret?: ITenantSecret
  onClose: () => void;
  onSave: (secretName: string, secretValue: string) => void;
  icon?: FC<ISvg>;
  title?: string;
  subtitle?: string;
  fields?: ISecretFields;
  showInfoMessage?: boolean;
  link?: Link;
}

export const CreateSecretDialogContent: FC<Props> = ({ existingSecret, onSave, onClose, icon, title, subtitle, fields, showInfoMessage, link }) => {
  const { tenantSecrets, setTenantSecrets } = useSecretsContext();
  const [secretName, setSecretName] = useState<string>(existingSecret?.name || fields?.name?.value || '');
  const [secretValue, setSecretValue] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const secretNameValidationArgs = useMemo(() => {
    const validationResult = validateSecretName(secretName, tenantSecrets);

    if (!secretName
        || (existingSecret && existingSecret.name === secretName)
        || validationResult === SecretNameValidationResult.VALID_SECRET_NAME) {
      return {};
    }
    if (validationResult === SecretNameValidationResult.INVALID_SECRET_NAME) {
      return {
        icon: ErrorIcon,
        iconPosition: 'end' as JitTextInputIconPosition,
        iconTooltip: 'dialogs.secretsManagement.createSecret.errors.invalidSecretName',
      };
    }
    if (validationResult === SecretNameValidationResult.SECRET_NAME_ALREADY_EXISTS) {
      return {
        icon: ErrorIcon,
        iconPosition: 'end' as JitTextInputIconPosition,
        iconTooltip: 'dialogs.secretsManagement.createSecret.errors.nameAlreadyExists',
      };
    }
    return null;
  }, [secretName, tenantSecrets, existingSecret]);

  const isSaveDisabled = useMemo(() => !secretName || !secretValue || isSaving || !isEmpty(secretNameValidationArgs), [secretName, secretValue, isSaving, secretNameValidationArgs]);

  const generateTenantSecret = (name: string): ITenantSecret => ({
    name,
    modifiedAt: new Date().toISOString(),
  });

  const onSecretNameChange = useCallback((secretNameInput: string) => {
    if (!existingSecret) {
      setSecretName(secretNameInput);
    }
  }, [existingSecret]);

  const onSecretValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSecretValue(event.target.value);
  }, []);

  const saveSecret = useCallback(async () => {
    setIsSaving(true);
    await onSave(secretName, secretValue);
    setIsSaving(false);
    const newSecret = generateTenantSecret(secretName);
    if (!existingSecret) {
      setTenantSecrets(tenantSecrets ? [...tenantSecrets, newSecret] : [newSecret]);
    } else {
      setTenantSecrets(tenantSecrets ? tenantSecrets?.map((secret) => (secret.name === existingSecret.name ? newSecret : secret)) : [newSecret]);
    }
    onClose();
  }, [onSave, secretName, secretValue, existingSecret, onClose, setTenantSecrets, tenantSecrets]);

  const dialogFields = useMemo(() => (
    fields ? (
      <>
        <div>
          <JitText color={colors.lightGray} text={fields.name.label} />

          <div className={styles.inputBox}>

            <JitTextInput
              data-testid='name-Input'
              disabled={!!fields.name.value}
              inputDataTestId='name-TextInput'
              onChange={onSecretNameChange}
              placeholder={fields.name.placeholder || ''}
              value={fields.name.value || ''}
              variant={fields.name.value ? 'dark' : undefined}
            />
          </div>
        </div>

        <div>
          <div className={styles.secretFieldContainer}>
            <JitText color={colors.lightGray} text={fields.secret.label} />

            {fields?.secretLink && <JitExternalLink className={styles.secretLink} href={fields.secretLink.href} text={fields.secretLink.text} />}
          </div>

          <div className={styles.inputBox}>
            <JitPasswordInput
              inputProps={{
                className: styles.inputFieldBox,
                autoFocus: true,
                disableUnderline: false,
                fullWidth: true,
                name: 'password',
                dataTestid: 'secret-TextInput',
                onChange: onSecretValueChange,
                placeholder: fields.secret.placeholder || '',
                value: secretValue,
              }}
            />
          </div>
        </div>
      </>
    ) : (
      <>
        <div>
          <JitText color={colors.lightGray} text='dialogs.secretsManagement.createSecret.name' />

          <div className={styles.inputBox}>

            <JitTextInput
              autoFocus={!existingSecret}
              data-testid='secretNameInput'
              disabled={!!existingSecret}
              inputDataTestId='secretNameTextInput'
              onChange={onSecretNameChange}
              placeholder={existingSecret ? existingSecret.name : 'dialogs.secretsManagement.createSecret.namePlaceHolder'}
              variant={existingSecret ? 'dark' : undefined}
              {...secretNameValidationArgs}
            />
          </div>
        </div>

        <div>
          <JitText color={colors.lightGray} text='dialogs.secretsManagement.createSecret.value' />

          <div className={styles.inputBox}>
            <JitPasswordInput
              inputProps={{
                className: styles.inputFieldBox,
                autoFocus: !!existingSecret,
                disableUnderline: false,
                fullWidth: true,
                name: 'password',
                dataTestid: 'secret-TextInput',
                onChange: onSecretValueChange,
                placeholder: t('dialogs.secretsManagement.createSecret.value'),
                value: secretValue,
              }}
            />
          </div>
        </div>
      </>
    )
  ), [existingSecret, fields, onSecretNameChange, onSecretValueChange, secretNameValidationArgs, secretValue]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        {icon && <div><JitIcon icon={icon} size={50} /></div>}

        <div>
          {title && <JitText bold className={styles.title} text={title} />}

          {subtitle && <JitText className={styles.subtitle} color={colors.lightGray} text={subtitle} />}

          {link && <JitExternalLink data-testid='card-link' href={link.href} text={link.text} />}

        </div>
      </div>

      {dialogFields}

      {showInfoMessage && (
      <JitInfoBanner bgColor={colors.cards04} icon={Info} iconColor={colors.blue}>
        <JitText color={colors.white} components={{ secretName }} text='dialogs.secretsManagement.createSecret.infoMessage' />
      </JitInfoBanner>
      )}

      <div className={styles.buttonsWrapper}>

        <JitButton data-testid='backButton' height={30} onClick={onClose} variant='outlined'>
          <JitText color={colors.iris} text='dialogs.secretsManagement.createSecret.backButton' />
        </JitButton>

        <JitButton data-testid='saveButton' disabled={isSaveDisabled} height={30} isLoading={isSaving} onClick={saveSecret} showChildrenWhileLoading variant='contained'>
          <JitText text={existingSecret ? 'dialogs.secretsManagement.createSecret.updateSecretButton' : 'dialogs.secretsManagement.createSecret.createSecretButton'} />
        </JitButton>
      </div>
    </div>
  );
};
