import { FC } from 'react';

import styles from './SecretRow.module.scss';

import { CalenderNotebook, EditPen, Trash } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { getFormattedTimeSince } from 'pages/PipelinesPage/utils';
import colors from 'themes/colors.module.scss';
import { ITenantSecret } from 'types/interfaces/Secrets/ITenantSecret';

interface Props {
  tenantSecret: ITenantSecret
  onEdit: (tenantSecret: ITenantSecret) => void;
  onDelete: (tenantSecret: ITenantSecret) => void;

}

export const SecretRow: FC<Props> = ({ tenantSecret, onEdit, onDelete }) => (
  <div className={styles.row} data-testid={`secretRow-${tenantSecret.name}`}>
    <div className={styles.leftSide}>
      <TooltipOnlyOnOverflow bold text={tenantSecret.name} />
    </div>

    <div className={styles.rightSide}>
      <div className={styles.modifiedAt}>
        <JitIcon color={colors.lightGray} icon={CalenderNotebook} size={15} />

        <JitText color={colors.lightGray} size='m' text={getFormattedTimeSince(tenantSecret.modifiedAt.split('.')[0])} />
      </div>

      <JitButton data-testid='editButton' noHover onClick={() => onEdit(tenantSecret)}>
        <JitTooltip followCursor={false} placement='top' title='dialogs.secretsManagement.tooltips.edit'>
          <JitIcon color={colors.iris} icon={EditPen} size={15} />
        </JitTooltip>
      </JitButton>

      <JitButton data-testid='deleteButton' noHover onClick={() => onDelete(tenantSecret)}>
        <JitTooltip followCursor={false} placement='top' title='dialogs.secretsManagement.tooltips.delete'>
          <JitIcon icon={Trash} size={15} />
        </JitTooltip>
      </JitButton>
    </div>
  </div>
);
