import { FC } from 'react';
import { Link } from 'react-router-dom';

import styles from './NavbarHeader.module.scss';

import { JitFavicon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import colors from 'themes/colors.module.scss';

export const NavbarHeader: FC = () => (
  <Link to='/'>
    <div className={styles.navbarHeader}>
      <JitIcon color={colors.jitPink} icon={JitFavicon} size={28} />
    </div>
  </Link>
);
