import { useTenantsState } from '@frontegg/react';
import Avatar from '@mui/material/Avatar';
import { FC, useEffect, useState } from 'react';

import { SelectTenantPopperContent } from '../SelectTenantPopperContent/SelectTenantPopperContent';

import { didUserPickDefaultTenantStorageKey, localStorageTrueValue } from './constants';
import styles from './NavbarFooter.module.scss';

import { Logout } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitPopper } from 'components/JitPopper/JitPopper';
import { JitText } from 'components/JitText/JitText';
import { useAuthContext } from 'context';
import colors from 'themes/colors.module.scss';

interface Props {
  isCollapsed: boolean,
  setIsHoverSwitchTenantPopper: (isHover: boolean) => void;
}

export const NavbarFooter: FC<Props> = ({ isCollapsed, setIsHoverSwitchTenantPopper }) => {
  const { frontEggUser, handleLogout } = useAuthContext();
  const { tenants } = useTenantsState();
  const [isHovered, setIsHovered] = useState(false);
  const [isHoverLogoutSection, setIsHoverLogoutSection] = useState(false);
  const [isMultiTenantPopperOpen, setIsMultiTenantPopperOpen] = useState(false);

  const didUserOpenTenantPicker = window.localStorage.getItem(didUserPickDefaultTenantStorageKey) === localStorageTrueValue;
  const setUserOpenTenantPicker = () => window.localStorage.setItem(didUserPickDefaultTenantStorageKey, localStorageTrueValue);

  const shouldAllowMultiTenant = tenants.length > 1;
  const shouldShowMultiTenantHint = !didUserOpenTenantPicker && shouldAllowMultiTenant;

  useEffect(() => {
    if (isCollapsed) {
      setIsMultiTenantPopperOpen(false);
    }
  }, [isCollapsed]);

  const onPopperClose = () => {
    setIsMultiTenantPopperOpen(false);
    setIsHoverSwitchTenantPopper(false);
  };

  const tenantName = tenants.find((tenant) => tenant.tenantId === frontEggUser.tenantId)?.name || '';
  let wrapperClassName = styles.navbarFooter;
  if (isHovered) {
    wrapperClassName += ` ${styles.backgroundShadow}`;
  }
  const logoutSectionColor = isHoverLogoutSection ? colors.iris : colors.white;
  let avatarWrapperProps = {};
  if (shouldAllowMultiTenant) {
    avatarWrapperProps = {
      onClick: () => {
        setIsMultiTenantPopperOpen(true);
        setUserOpenTenantPicker();
      },
      role: 'button',
    };
  }
  let avatarClassName = '';
  if (shouldAllowMultiTenant) {
    if (shouldShowMultiTenantHint) {
      avatarClassName = styles.multiTenantHint;
    } else {
      avatarClassName = styles.avatarWhenMultiTenantEnabled;
    }
  }

  const content = (
    <div
      className={wrapperClassName}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ backgroundColor: isHovered ? colors.navbarFooterHovered : 'transparent' }}
    >

      <div className={styles.navbarFooterLeftSide}>
        <div {...avatarWrapperProps} data-testid='avatarWrapper'>
          <Avatar
            className={avatarClassName}
            src={frontEggUser?.profilePictureUrl || ''}
            sx={{
              width: 32,
              height: 32,
            }}
          />
        </div>

        {!isCollapsed && (
          <div className={styles.textsWrapper}>
            <JitText noWrap overflowHiddenEllipsis size='s' text={tenantName} />

            <JitText muted noWrap overflowHiddenEllipsis size='s' text={frontEggUser.name} />
          </div>
        )}
      </div>

      {!isCollapsed && (
        <div
          className={styles.navbarFooterRightSide}
          onClick={handleLogout}
          onMouseEnter={() => setIsHoverLogoutSection(true)}
          onMouseLeave={() => setIsHoverLogoutSection(false)}
          role='button'
          tabIndex={0}
        >

            {isHovered && (<JitText color={logoutSectionColor} horizontalAlign='center' lineHeight={1} text='Logout' />)}

          <JitIcon color={logoutSectionColor} icon={Logout} size={16} />
        </div>
      )}
    </div>
  );
  return (
    <JitPopper
      content={<SelectTenantPopperContent />}
      isWithArrow={false}
      onClose={onPopperClose}
      onMouseEnter={() => setIsHoverSwitchTenantPopper(true)}
      onMouseLeave={() => setIsHoverSwitchTenantPopper(false)}
      open={isMultiTenantPopperOpen}
      shadowVariant='none'
      transition={false}
    >
      {content}
    </JitPopper>
  );
};
