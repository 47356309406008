import { FC } from 'react';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';

interface Props {
  handleClose: () => void;
  handleSave: () => void;
  isDisabled: boolean;
}

export const FindingsFilterSaveFindingFilterDialogActionButtons: FC<Props> = ({
  handleClose,
  handleSave,
  isDisabled,
}) => (
  <div style={{
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  }}
  >
    <JitButton
      onClick={handleClose}
      style={{
        marginRight: '1.5rem',
        marginLeft: '1.5rem',
      }}
      variant='text'
    >
      <JitText text='dialogs.planItemsCatalog.filter.savedFilters.cancel' />
    </JitButton>

    <JitButton
      disabled={isDisabled}
      onClick={handleSave}
      style={{
        marginRight: '1.5rem',
        marginLeft: '1.5rem',
      }}
      variant='contained'
    >
      <JitText text='dialogs.planItemsCatalog.filter.savedFilters.create' />
    </JitButton>
  </div>
);
