import { FC, useCallback, useEffect, useState } from 'react';

import { IntegrationDetails, IntegrationFieldsBody, IntegrationLinkMessage, IntegrationTypeChooser } from '..';

import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { useAuthContext } from 'context';
import { createStateToken } from 'services/OauthService/OauthService';
import { AwsIntegrationType, Vendor } from 'types/enums';
import { AwsIntegrationAccount, IChangeInputEvent } from 'types/interfaces';
import { iconsMap } from 'utils';

interface Props {
  handleSelectType: (type?: AwsIntegrationType) => void;
  isEdit: boolean;
  integration: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  handleChange: (e: IChangeInputEvent) => void;
  handleSelectChange: (regions: string[]) => void;
  handleStackRegionSelectChange: (deploy_region: string) => void;
  integrationFields: AwsIntegrationAccount;
  vendor: string;
  hasNextSelected: boolean;
  onClose: () => void;
  validateFields: () => boolean;
  handleNextClick: () => void;
  setIsEdit: (isEdit: boolean) => void;
}

export const IntegrationDialogContent: FC<Props> = ({
  isEdit, integration, handleChange, handleSelectChange, handleStackRegionSelectChange, integrationFields,
  vendor, validateFields, hasNextSelected, onClose, setIsEdit, handleNextClick, handleSelectType,
}) => {
  const { frontEggUser } = useAuthContext();

  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [externalId, setExternalId] = useState('');
  const [integrationTypeChosen, setIntegrationTypeChosen] = useState<AwsIntegrationType>();
  const [isRegionSelectOpen, setIsRegionSelectOpen] = useState(false);

  const initExternalId = useCallback(async () => {
    setIsLoading(true);
    const res = await createStateToken({
      tenant_id: frontEggUser.tenantId,
      vendor: Vendor.AWS,
      extra: {
        regions_to_monitor: integrationFields.regions,
        integration_type: integrationFields.type,
      },
    });
    setExternalId(res.token);
    setIsLoading(false);
  }, [frontEggUser.tenantId, integrationFields.regions, integrationFields.type]);

  useEffect(() => {
    if (integrationFields.regions.length && !isRegionSelectOpen) {
      initExternalId();
    }
  }, [initExternalId, integrationFields.regions.length, isRegionSelectOpen]);

  useEffect(() => {
    setIsDone(validateFields() && !isLoading && !isRegionSelectOpen);
  }, [isLoading, isRegionSelectOpen, validateFields]);

  if (integration?.length && !isEdit && !hasNextSelected) {
    return (
      <IntegrationDetails integration={integration} onClose={onClose} setIsEdit={setIsEdit} />
    );
  }

  if (!integrationFields.type) {
    return (
      <DialogContentWrapper
        actionButtons={[{
          label: 'dialogs.integration.aws.next',
          disabled: !integrationTypeChosen,
          handleClick: () => handleSelectType(integrationTypeChosen),
        }]}
        icon={iconsMap.aws.icon}
        iconSize={84}
      >
        <IntegrationTypeChooser
          integrationTypeChosen={integrationTypeChosen}
          setIntegrationTypeChosen={setIntegrationTypeChosen}
        />
      </DialogContentWrapper>
    );
  }

  const integrationLinkComponent = (
    <IntegrationLinkMessage
      disabled={!isDone}
      externalId={externalId}
      integrationType={integrationFields.type}
      isLoading={isLoading}
      onClose={onClose}
    />
  );

  if (hasNextSelected) {
    const actionButtons = isLoading ? [] : [{ disabled: false,
      label: 'dialogs.integration.aws.done',
      handleClick: onClose }];
    const buttonDescription = isLoading ? '' : 'dialogs.integration.aws.linkMessage.pleaseReturnMessage';
    return (
      <DialogContentWrapper
        actionButtons={actionButtons}
        buttonDescription={buttonDescription}
        icon={iconsMap.aws.icon}
        iconSize={84}
      >
        {integrationLinkComponent}
      </DialogContentWrapper>
    );
  }

  if (isEdit || !integration) {
    return (
      <DialogContentWrapper icon={iconsMap.aws.icon} iconSize={84}>
        <IntegrationFieldsBody
          handleChange={handleChange}
          handleNextClick={handleNextClick}
          handleSelectChange={handleSelectChange}
          handleSelectOnToggle={(isOpen) => setIsRegionSelectOpen(isOpen)}
          handleStackRegionSelectChange={handleStackRegionSelectChange}
          integrationFields={integrationFields}
          vendor={vendor}
        />

        {integrationLinkComponent}
      </DialogContentWrapper>
    );
  }
  return null;
};
