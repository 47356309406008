import { client } from '../client';

import { ISignupRequest, ISignupResponse } from 'types/interfaces/TenantService/ISignup';
import { IUpdateUserRequest } from 'types/interfaces/TenantService/IUpdateUserRequest';

const serviceName = 'tenant';

export const updateUser = (body: IUpdateUserRequest) => {
  const url = `${serviceName}/user/update`;
  return client.post(url, body);
};

export const signUp = (body: ISignupRequest): Promise<ISignupResponse | undefined> => {
  const url = `${serviceName}/sign-up`;
  return client.post<ISignupResponse>(url, body);
};
