import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import './index.scss';
import './locale/i18n';

import { App } from 'App';
import { config } from 'config';
// eslint-disable-next-line import/no-unresolved
import 'virtual:fonts.css';

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/
Sentry.init({
  dsn: config.sentryDsn,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: config.isProduction ? 0.1 : 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new BrowserTracing({ tracingOrigins: ['^((?!getbeamer).)*$'] }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: config.isProduction ? 1.0 : 0,
  environment: process.env.VITE_ENVIRONMENT || process.env.NODE_ENV,
  beforeSend: (e) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return e;
  },
});

ReactDOM.render(<App />, document.getElementById('root'));
