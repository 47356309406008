import { FC } from 'react';

import styles from './SelectionList.module.scss';
import { SelectionListAccordion } from './SelectionListAccordion';

import { MissingGithubIntegration } from 'components/JitTable/components';
import { useTenantContext } from 'context';
import { IAsset } from 'types/interfaces';

interface Props {
  assets: IAsset[];
  checkAsset: (assetId: string, checked: boolean) => void;
  checkMultipleAssetsByOwner: (owner: string, checked: boolean) => void;
  handleClose?: () => void;
}

export const SelectionListCustomGithub: FC<Props> = ({
  assets,
  checkAsset,
  checkMultipleAssetsByOwner,
  handleClose,
}) => {
  const { isGithubIntegrated } = useTenantContext();
  if (!isGithubIntegrated) {
    return (
      <div className={styles.emptyWrapper}>
        <MissingGithubIntegration maxWidth='400px' onClick={handleClose} subtitle='dialogs.assetsManagement.selectionList.missingGithubIntegration' />
      </div>
    );
  }
  return (
    <SelectionListAccordion
      accordionOwnerTypeTitle='dialogs.assetsManagement.organizationTopRow'
      accordionTableAssetColumnHeader='dialogs.assetsManagement.accordion.github'
      assets={assets}
      checkAsset={checkAsset}
      checkMultipleAssetsByOwner={checkMultipleAssetsByOwner}
    />
  );
};
