import { FC } from 'react';

import styles from './FindingStatusCell.module.scss';

import { ChevronClosed } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMenu, MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { FindingsStatisticsTextAndBullet } from 'pages/FindingsPage/components/FindingsStatistics/components';
import { findingStatusToBackgroundColor, findingStatusToTextColor } from 'pages/FindingsPage/constants';
import { FindingStatus } from 'types/enums';
import { IFinding, IFindingWithSelected, IMouseEvent, ITableCell, MenuItemKeyType } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props extends ITableCell<IFindingWithSelected>{
  updateFindingStatus?: (findingToUpdate: IFinding, selectedStatus: FindingStatus) => void,
}

export const FindingStatusCell: FC<Props> = ({ cell: { value: status, row }, updateFindingStatus }) => {
  const displayAsDisabledIcon = !updateFindingStatus;
  const isValidStatus = Object.values(FindingStatus).includes(status);
  if (!isValidStatus) {
    return <div />; // We must pass some DOM element to render
  }
  const backgroundColor = findingStatusToBackgroundColor[status as FindingStatus];
  const textColor = findingStatusToTextColor[status as FindingStatus];
  const menuItems: MenuItemType[] = [{
    itemKey: FindingStatus.OPEN,
    itemContent: <FindingsStatisticsTextAndBullet addUnderlineOnHover={false} text='pages.findings.status.open' />,
  }, {
    itemKey: FindingStatus.IGNORED,
    itemContent: <FindingsStatisticsTextAndBullet addUnderlineOnHover={false} text='pages.findings.status.ignored' />,
  }];
  const finding: IFinding = row.original;
  const onMenuItemClick = ({ e, itemKey }: { e: IMouseEvent, itemKey: MenuItemKeyType }) => {
    if (updateFindingStatus) {
      stopPropagation(e);
      updateFindingStatus(finding, itemKey as FindingStatus);
    }
  };
  const shouldDisableMenu = displayAsDisabledIcon || status === FindingStatus.FIXED;
  return (
    <JitMenu
      childrenWrapperStyle={displayAsDisabledIcon ? ({ display: 'flex',
        alignItems: 'center',
        height: '100%' }) : ({
      })}
      disabled={shouldDisableMenu}
      menuItems={menuItems}
      menuItemsWrapperClassName={styles.menuItemsWrapper}
      onMenuItemClick={onMenuItemClick}
    >
      <div className={displayAsDisabledIcon ? (styles.cellWrapperIcon) : (styles.cellWrapper)} style={{ backgroundColor }}>
        <JitText bold className={styles.text} color={textColor} text={`pages.findings.status.${status?.toLowerCase()}`} />

        { !shouldDisableMenu && <JitIcon color={textColor} icon={ChevronClosed} /> }
      </div>

    </JitMenu>
  );
};
