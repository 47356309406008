import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { GithubContext } from 'context/GithubContext/GithubContext';
import { useSubscribeToGithubStatusWebsocket } from 'context/GithubContext/hooks/useSubscribeToGithubStatusWebsocket';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { useGithubService } from 'services/GithubService/useGithubService';
import { ToastType } from 'types/enums';
import { GithubStatus } from 'types/enums/GithubStatus';

export const GithubProvider: FC = ({ children }) => {
  const [isLoadingGithubStatus, setIsLoadingGithubStatus] = useState(false);
  const [githubStatus, setGithubStatus] = useState<GithubStatus>();
  const { fetchGithubStatus } = useGithubService();
  const { showToast } = useToastsContext();

  useSubscribeToGithubStatusWebsocket(setGithubStatus);

  const getGithubStatus = useCallback(async () => {
    setIsLoadingGithubStatus(true);

    const response = await fetchGithubStatus();
    if (response?.status === 200) {
      setGithubStatus(response.data.status);
    }
    setIsLoadingGithubStatus(false);
  }, [fetchGithubStatus]);

  useEffect(() => {
    getGithubStatus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps  -- only run on mount

  useEffect(() => {
    if (githubStatus === GithubStatus.Outage) {
      showToast({
        type: ToastType.SystemError,
        overrideProps: {
          title: 'toasts.githubOutage.title',
          subtitle: 'toasts.githubOutage.subtitle',
        },
      });
    }
  }, [githubStatus, showToast]);

  const value = useMemo(() => ({
    githubStatus,
    isLoadingGithubStatus,
  }), [githubStatus, isLoadingGithubStatus]);

  return (
    <GithubContext.Provider value={value}>
      {children}
    </GithubContext.Provider>
  );
};
