import { FC } from 'react';

import styles from './PremiumActionFeatureCard.module.scss';

import { PremiumLock } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';

export const PremiumActionFeatureCard: FC = () => (
  <div className={styles.card}>
    <div className={styles.content}>
      <div className={styles.premiumFeature}>
        <JitIcon icon={PremiumLock} />

        <JitText bold size='xl' text='pages.actions.actionCard.premium.title' />
      </div>

      <JitText size='l' text='pages.actions.actionCard.premium.subTitle' />

      <div className={styles.contactButton}>
        <JitButton
          className={styles.contactButton}
          href={config.contactUsUrl}
          target='_blank'
          variant='contained'
        >
          <JitText text='pages.actions.actionCard.premium.contactUs' />
        </JitButton>
      </div>
    </div>
  </div>
);
