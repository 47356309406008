import { FC, ReactElement } from 'react';

import { useManageSignup } from './hooks/useManageSignup/useManageSignup';
import styles from './PendingRoleWrapper.module.scss';

import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { useIsAuthorized } from 'wrappers/RBAC/hooks/useIsAuthorized';

interface Props {
  children: ReactElement;
  isWaitingForRoleUpdate: boolean;
  setIsWaitingForRoleUpdate: (isWaitingForRoleUpdate: boolean) => void;
  setIsWaitingForSwitchTenant: (isWaitingForSwitchTenant: boolean) => void;
  isWaitingForSwitchTenant: boolean;
}

export const PendingRoleWrapper: FC<Props> = ({ children, setIsWaitingForSwitchTenant, isWaitingForSwitchTenant, isWaitingForRoleUpdate, setIsWaitingForRoleUpdate }) => {
  const { isAuthorized } = useIsAuthorized();

  useManageSignup(setIsWaitingForSwitchTenant, setIsWaitingForRoleUpdate, isAuthorized);

  if (isAuthorized && !isWaitingForSwitchTenant) return children;

  if (isWaitingForRoleUpdate || isWaitingForSwitchTenant) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingBar />

        <JitText className={styles.dotFlashing} data-testid='loading-text' size='s' text='pages.accessDenied.loading' />
      </div>
    );
  }

  return children;
};
