import { ChangeEvent, FC } from 'react';

import { CustomCookieConfiguration } from './components/CustomCookieConfiguration';
import { LocalStorageConfiguration } from './components/LocalStorageConfigurations';

import { CustomHeaderAuthConfigurations, HeaderType } from 'components/Configurations/Zap/components/CustomHeaderAuthConfigurations';
import { FormBasedAuthConfigurations } from 'components/Configurations/Zap/components/FormBasedAuthConfigurations';
import { ZapApplication, ZapApplicationCustomCookie,
  ZapApplicationFormBasedAuth, ZapApplicationHeaderAuth,
  ZapApplicationLocalStorage, ZapAuthenticationConfigType, ZapAuthMode,
} from 'types/interfaces';

interface Props {
  configurations: ZapApplication;
  handleChangeInput: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChange: (key: string, value: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  type: ZapAuthenticationConfigType;
}

export const ConfigurationsZapExtraFields: FC<Props> = ({
  configurations, handleChange, handleChangeInput, type,
}) => {
  const { authentication_mode: authMode } = configurations;
  if (authMode === ZapAuthMode.FormBased) {
    return (
      <FormBasedAuthConfigurations
        configurations={configurations as ZapApplicationFormBasedAuth}
        handleChange={handleChange}
        handleChangeInput={handleChangeInput}
        type={type}
      />
    );
  }

  if (authMode === ZapAuthMode.BearerTokenHeader) {
    return (
      <CustomHeaderAuthConfigurations
        configurations={configurations as ZapApplicationHeaderAuth}
        handleChange={handleChange}
        headerType={HeaderType.Bearer}
        type={type}
      />
    );
  }

  if (authMode === ZapAuthMode.CookieHeader) {
    return (
      <CustomHeaderAuthConfigurations
        configurations={configurations as ZapApplicationHeaderAuth}
        handleChange={handleChange}
        headerType={HeaderType.Cookie}
        type={type}
      />
    );
  }

  if (authMode === ZapAuthMode.CustomHeader) {
    return (
      <CustomHeaderAuthConfigurations
        configurations={configurations as ZapApplicationHeaderAuth}
        handleChange={handleChange}
        headerType={HeaderType.Custom}
        type={type}
      />
    );
  }

  if (authMode === ZapAuthMode.LocalStorage) {
    return (
      <LocalStorageConfiguration
        configurations={configurations as ZapApplicationLocalStorage}
        handleChange={handleChange}
        type={type}
      />
    );
  }

  if (authMode === ZapAuthMode.CustomCookie) {
    return (
      <CustomCookieConfiguration
        configurations={configurations as ZapApplicationCustomCookie}
        handleChange={handleChange}
        type={type}
      />
    );
  }

  return null;
};
