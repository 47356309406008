import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import styles from './AwsConfigurationTrigger.module.scss';
import { ConfigurationTrigger } from './ConfigurationTrigger';
import { GetUpdatedConfigurationAndPlanItemType } from './hooks/useEditConfigurations';

import { ExpandArrow } from 'assets';
import { useDeploymentConfigurationStateManager } from 'components/Configurations/Deployment/hooks/useDeploymentConfigurationStateManager';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMenu, MenuItemCategories, MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { useAssetsContext } from 'context';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import { ApplicationTypes, AWSApplication, MenuItemKeyType } from 'types/interfaces';

interface Props {
  setIsDoneStep: Dispatch<SetStateAction<boolean>>;
  planItemSlug: string;
  applicationType: ApplicationTypes;
  getUpdatedConfigurationAndPlanItem?: GetUpdatedConfigurationAndPlanItemType;
}

export const AwsConfigurationTrigger: FC<Props> = ({ setIsDoneStep, planItemSlug, applicationType, getUpdatedConfigurationAndPlanItem }) => {
  const { assets } = useAssetsContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTriggerConfigurationIsDoneStep, setIsTriggerConfigurationIsDoneStep] = useState(false);
  const [isScanOnDeploymentChecked, setIsScanOnDeploymentChecked] = useState(false);
  const { configurations, setConfigurations } = useDeploymentConfigurationStateManager({});

  const awsApplicationConfiguration = useMemo(
    () => (configurations?.applications?.find((application) => application.type === applicationType)),
    [applicationType, configurations?.applications],
  ) as AWSApplication;

  const [selectedAwsAccountsIds, setSelectedAwsAccountsIds] = useState<MenuItemKeyType[]>(awsApplicationConfiguration ? awsApplicationConfiguration.account_ids : []);

  const awsAccountsMenuItems: MenuItemType[] = useMemo(() => (
    assets.filter(
      (asset) => asset.vendor === Vendor.AWS && asset.aws_account_id && asset.is_active,
    ).map((asset) => ({
      itemKey: asset.aws_account_id || asset.owner,
      itemName: asset.asset_name,
      itemContent: (<JitText
        horizontalAlign='center'
        text={asset.asset_name}
      />),
      isSelected: selectedAwsAccountsIds.includes(asset.aws_account_id || asset.owner),
    }))), [assets, selectedAwsAccountsIds]);

  const awsMenuItemCategories: MenuItemCategories = useMemo(() => ({
    categoryKey: 'awsAccounts',
    categoryContent: (<JitText
      color={colors.lightGray}
      size='s'
      text='pages.plan.configurationDialog.trigger.fields.awsAccountsInputText'
    />),
    items: awsAccountsMenuItems,
    withCheckbox: true,
  }), [awsAccountsMenuItems]);

  const selectedAwsAccountsMenuItems = useMemo(() => (
    awsAccountsMenuItems.filter((menuItem) => menuItem.isSelected)), [awsAccountsMenuItems]);

  const getBoxContainer = () => (
    <div
      className={`${styles.boxContainer} ${isMenuOpen && styles.openedBoxContainer}`}
    >
      <JitText text={selectedAwsAccountsMenuItems.map((menuItem) => menuItem.itemName).join(', ') || 'pages.plan.configurationDialog.trigger.fields.accountsToScanPlaceHolder'} />

      <JitIcon className={styles.expandIcon} data-testid='filterExpandIcon' icon={ExpandArrow} size={10} />

    </div>
  );
  const onMenuItemClick = ({ itemKey }: { itemKey: MenuItemKeyType }) => {
    if (selectedAwsAccountsIds.includes(itemKey)) {
      setSelectedAwsAccountsIds(selectedAwsAccountsIds.filter((account) => account !== itemKey));
    } else {
      setSelectedAwsAccountsIds([...selectedAwsAccountsIds, itemKey]);
    }
  };

  const isDoneStep = useMemo(() => (isTriggerConfigurationIsDoneStep && (!isScanOnDeploymentChecked
        || (isScanOnDeploymentChecked && selectedAwsAccountsIds.length > 0))), [isScanOnDeploymentChecked, isTriggerConfigurationIsDoneStep, selectedAwsAccountsIds.length]);

  useEffect(() => {
    setIsDoneStep(isDoneStep);
  }, [isDoneStep, setIsDoneStep]);

  useEffect(() => {
    if (isDoneStep && isScanOnDeploymentChecked) {
      setConfigurations({
        ...configurations,
        applications: configurations.applications?.map((application) => {
          if (application.type === applicationType) {
            return {
              ...application,
              account_ids: selectedAwsAccountsIds,
            };
          }
          return application;
        }),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationType, isDoneStep, isScanOnDeploymentChecked, selectedAwsAccountsIds, setConfigurations]);
  return (
    <div>
      <ConfigurationTrigger
        applicationType={applicationType}
        getUpdatedConfigurationAndPlanItem={getUpdatedConfigurationAndPlanItem}
        onIsScanOnDeploymentCheckedChange={(isChecked) => setIsScanOnDeploymentChecked(isChecked)}
        planItemSlug={planItemSlug}
        setIsDoneStep={setIsTriggerConfigurationIsDoneStep}
      />

      {isScanOnDeploymentChecked && (
      <div className={styles.accountsToScanContainer}>
        <JitText color={colors.lightGray} text='pages.plan.configurationDialog.trigger.fields.accountsToScan' />

        <JitMenu
          menuItemsWithCategories={[awsMenuItemCategories]}
          menuItemsWrapperClassName={styles.menuItemsWrapper}
          onMenuChangeOpenState={setIsMenuOpen}
          onMenuItemClick={onMenuItemClick}
        >
          {getBoxContainer()}
        </JitMenu>

      </div>
      )}
    </div>
  );
};
