import { useAuthActions, useAuthUser } from '@frontegg/react';
import * as Sentry from '@sentry/react';
import { isEmpty } from 'lodash';
import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useHanldeRedirectIfNeeded } from './HandleRedirect/useHanldeRedirectIfNeeded';

import { AuthContext } from 'context';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { constants } from 'globalConstants';
import { setClientsHeaders } from 'services/client';
import { signUp } from 'services/TenantService';
import { IFronteggUser } from 'types/interfaces';
import { useOnboardingState } from 'utils/hooks/useOnboardingState';

interface AuthProviderProps {
  children: ReactNode;
  setIsWaitingForRoleUpdate: (isWaitingForRoleUpdate: boolean) => void;
  setTenantId: (tenantId: string) => void;
  setIsWaitingForSwitchTenant: (isWaitingForSwitchTenant: boolean) => void;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children, setIsWaitingForSwitchTenant, setIsWaitingForRoleUpdate, setTenantId }) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const frontEggUser: IFronteggUser = useAuthUser();
  const { setOnboardingState } = useOnboardingState();

  setClientsHeaders({ authorization: `Bearer ${frontEggUser.accessToken}` });

  Sentry.configureScope((scope) => {
    scope.setUser({
      userId: frontEggUser.id,
      email: frontEggUser.email,
      tenantId: frontEggUser.tenantId,
    });
  });

  useEffect(() => {
    setTenantId(frontEggUser.tenantId);
  }, [frontEggUser.tenantId, setTenantId]);

  const { switchTenant } = useAuthActions();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    navigate('/logout');
    sendAnalyticsEvent({ action: 'logout' });
  }, [navigate, sendAnalyticsEvent]);

  useHanldeRedirectIfNeeded(frontEggUser);

  const hasPermission = useCallback((permissionKey: string) => frontEggUser.permissions.some((permission) => permission.key === permissionKey), [frontEggUser.permissions]);

  const value = useMemo(() => ({
    frontEggUser,
    handleLogout,
    hasPermission,
  }), [frontEggUser, handleLogout, hasPermission]);

  const handleSignUp = useCallback(async () => {
    const invitationToken = sessionStorage.getItem(constants.INVITATION_TOKEN_KEY);
    const stateToken = sessionStorage.getItem(constants.STATE_TOKEN_KEY);
    if (invitationToken) {
      sessionStorage.removeItem(constants.INVITATION_TOKEN_KEY);
      sessionStorage.removeItem(constants.STATE_TOKEN_KEY);
      return signUp({ user: frontEggUser,
        invitationToken,
        state: stateToken || undefined,
      });
    }
    return signUp({ user: frontEggUser });
  }, [frontEggUser]);

  const signUpAndSwitchTenant = useCallback(async () => {
    sessionStorage.removeItem(constants.SHOULD_SIGNUP_KEY);
    const result = await handleSignUp();
    if (result) {
      setOnboardingState({ status: result.onboarding_status,
        details: result.onboarding_details });
      const isDifferentTenant = result.tenant_id !== frontEggUser.tenantId;
      const isDifferentRoles = result.role && !frontEggUser.roles.some((role) => role.key === result.role);
      const shouldSwitchTenant = isDifferentTenant || isDifferentRoles;
      if (shouldSwitchTenant) {
        switchTenant({ tenantId: result.tenant_id });
      }
    }
  }, [frontEggUser.roles, frontEggUser.tenantId, handleSignUp, switchTenant, setOnboardingState]);

  const shouldSignup = useMemo(() => sessionStorage.getItem(constants.SHOULD_SIGNUP_KEY), []);
  const isSignup = useMemo(() => sessionStorage.getItem(constants.IS_SIGNUP_KEY), []);

  useEffect(() => () => {
    sessionStorage.removeItem(constants.IS_SIGNUP_KEY);
  }, []);

  const signUpWithSessionStorageCleanup = useCallback(async () => {
    await signUpAndSwitchTenant();
    setIsWaitingForSwitchTenant(false);
    setIsWaitingForRoleUpdate(false);
    sessionStorage.removeItem(constants.SHOULD_SIGNUP_KEY);
  }, [setIsWaitingForRoleUpdate, setIsWaitingForSwitchTenant, signUpAndSwitchTenant]);

  useEffect(() => {
    if (shouldSignup) {
      signUpWithSessionStorageCleanup();
    } else if (!isSignup) {
      setIsWaitingForSwitchTenant(false);
    }
  }, [isSignup, setIsWaitingForSwitchTenant, shouldSignup, signUpWithSessionStorageCleanup]);

  return (
    <AuthContext.Provider value={value}>
      {isEmpty(frontEggUser) ? null : children}
    </AuthContext.Provider>
  );
};
