import { FC, useEffect, useState } from 'react';

import { PlusFiltersAmount } from '../PlusFiltersAmount/PlusFiltersAmount';

import styles from './FindingsFiltersAddFilterBox.module.scss';

import { Plus } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMenu } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { useFindingsContext } from 'context';
import { useAddFilterToEndOfList } from 'context/FindingsContext/hooks/useAddFilterToEndOfList';
import colors from 'themes/colors.module.scss';
import { IFindingFilter, MenuItemKeyType } from 'types/interfaces';

interface Props {
  onAddFilterVisibility: (addedFilterKey: MenuItemKeyType) => void;
  isFiltersViewExtended: boolean;
  amountOfPlusFilters: number;
}

export const FindingsFiltersAddFilterBox: FC<Props> = ({ onAddFilterVisibility, isFiltersViewExtended, amountOfPlusFilters }) => {
  const { filters } = useFindingsContext();
  const { addFilterToEndOfList } = useAddFilterToEndOfList();
  const [shouldRunOnAddFilterVisibility, setShouldRunOnAddFilterVisibility] = useState(false);
  const [currentFilterKey, setCurrentFilterKey] = useState<MenuItemKeyType>('');
  const nonVisibleFilters = filters.filter((filter) => !filter.isVisible).sort((a, b) => a.key.localeCompare(b.key));
  const isEnabled = nonVisibleFilters.length > 0;

  const addFilterVisibility = (filterKey: MenuItemKeyType) => {
    const filter = filters.find((someFilter: IFindingFilter) => someFilter.key === filterKey)!;
    const updatedFilter: IFindingFilter = {
      ...filter,
      isVisible: true,
    };
    addFilterToEndOfList(updatedFilter);

    setShouldRunOnAddFilterVisibility(true);
    setCurrentFilterKey(filterKey);
    /*
      We use setShouldRunOnAdd(true) and run in in useEffect instead of simple calling onAddFilterVisibility() here.
      The reason we do it is that we want the filtersVisibleInFirstLine parameter to be updated, which only happens in the next
      render.

      We are aware that this is a hackey solution, but we don't see a better solution at the moment.
     */
  };

  useEffect(() => {
    if (shouldRunOnAddFilterVisibility && currentFilterKey) {
      onAddFilterVisibility(currentFilterKey);
      setShouldRunOnAddFilterVisibility(false);
    }
  }, [shouldRunOnAddFilterVisibility, currentFilterKey, onAddFilterVisibility]);

  return (
    <JitMenu
      isEnabled={isEnabled}
      menuItems={nonVisibleFilters.map((filter) => ({
        itemKey: filter.key,
        itemContent: <JitText
          color={colors.white}
          size='m'
          style={{ alignSelf: 'center' }}
          text={`pages.findings.filtersSection.filterKeys.${filter.key}`}
        />,
      }))}
      menuItemsWrapperClassName={styles.menuItemsWrapper}
      onMenuItemClick={({ itemKey }: { itemKey: MenuItemKeyType }) => addFilterVisibility(itemKey)}
      shouldCloseOnItemClick
    >
      <div
        className={styles.boxContainer}
        style={{
          cursor: isEnabled ? 'pointer' : 'default',
          opacity: isEnabled ? 1 : 0.5,
        }}
      >
        <div style={{
          paddingTop: 2,
          paddingBottom: 3,
          marginTop: 2,
          marginRight: '0.5rem',
        }}
        >
          <JitIcon
            color={colors.blue02}
            icon={Plus}
            size={14}
          />
        </div>

        <JitText color={colors.blue02} style={{ alignSelf: 'center' }} text='pages.findings.filtersSection.addFilters' />

        {!isFiltersViewExtended && amountOfPlusFilters > 0 && <PlusFiltersAmount amountOfFilters={amountOfPlusFilters} />}
      </div>

    </JitMenu>
  );
};
