import { FC } from 'react';

import styles from './GoalCard.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';

interface Props {
  icon: FC<ISvg>
  description: string;
  'data-testid'?: string
  handleClick: () => void;
  isSelected?: boolean;
}
export const GoalCard: FC<Props> = ({ icon, description, handleClick, isSelected, ...props }) => (
  <div
    className={styles.goalCardWrapper}
    onClick={handleClick}
    role='button'
    style={{ borderColor: isSelected ? colors.jitPink : undefined }}
    tabIndex={0}
  >
    <JitIcon
      data-testid={`${props['data-testid']}-icon`}
      height={175}
      icon={icon}
      width={223}
    />

    <JitText
      bold
      className={styles.goalCardDescription}
      color={colors.black}
      size='m'
      text={description}
    />
  </div>
);

