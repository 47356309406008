import DialogActions from '@mui/material/DialogActions';
import { FC } from 'react';

import styles from './EditSavedFilterActions.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useCanUseSlackNotifications } from 'context/FindingsContext/hooks/useCanUseSlackNotifications';
import { stopPropagation } from 'utils';

interface Props {
  onClose: () => void;
  filterName: string;
  handleSave: () => void;
  filterShouldNotify: boolean;
  setFilterShouldNotify: (filterShouldNotify: boolean) => void;
}

export const EditSavedFilterActions: FC<Props> = ({
  onClose,
  filterName,
  handleSave,
  filterShouldNotify,
  setFilterShouldNotify,
}) => {
  const canChooseNotifications = useCanUseSlackNotifications();
  const tooltipTitle = canChooseNotifications ? '' : 'dialogs.planItemsCatalog.filter.savedFilters.notificationCheckBoxDisabledTooltip';
  return (
    <DialogActions>
      <div className={styles.dialogActionsContainer}>
        <JitTooltip title={tooltipTitle}>
          <div className={styles.jitCheckBoxContainer}>
            <JitCheckbox
              checked={filterShouldNotify}
              disabled={!canChooseNotifications}
              inputProps={{
                // @ts-ignore
                'data-testid': 'edit-saved-filter-actions-check-box',
              }}
              onChange={() => setFilterShouldNotify(!filterShouldNotify)}
              onClick={stopPropagation}
              style={{ marginRight: '0.5rem' }}
            />

            <JitText bold muted={!canChooseNotifications} text='dialogs.planItemsCatalog.filter.savedFilters.notificationCheckBox' />
          </div>
        </JitTooltip>

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flex: 1,
        }}
        >
          <JitButton
            onClick={onClose}
            style={{
              marginRight: '1.5rem',
              marginLeft: '1.5rem',
            }}
            variant='text'
          >
            <JitText text='dialogs.planItemsCatalog.filter.savedFilters.cancel' />
          </JitButton>

          <JitButton
            disabled={!filterName}
            onClick={handleSave}
            style={{
              marginRight: '1.5rem',
              marginLeft: '1.5rem',
            }}
            variant='contained'
          >
            <JitText text='dialogs.planItemsCatalog.filter.savedFilters.saveButton' />
          </JitButton>
        </div>
      </div>
    </DialogActions>
  );
};
