export enum PerformanceMetricNames {
  MTTR = 'mttr',
  DetectionRate = 'detection_rate',
  FindingsOverTime = 'findings_over_time',
  ExposureWindow = 'exposure_window',
  DailyFindingsActivities = 'daily_findings_activities',
  ResourceTypeFindings = 'resource_type_findings',
  PlanItemFindings = 'plan_item_findings',
}

export enum SecurityImpactMetricNames {
  SecurityImpactMTTR = 'security_impact_mttr',
  LayersCoverage = 'plan_stats',
  JitUsageFirstDate = 'jit_usage_first_date',
  DevProductivity = 'dev_productivity',
  ResourcesCoverage = 'resources_coverage',
  TotalFindingsGraph = 'total_findings_graph',
  OpenFindingsCount = 'open_findings_count',
}

export enum OverviewMetricNames {
  TrendingDevProductivity = 'trending_dev_productivity',
  MTTR = 'mttr',
  ResourcesCoverage = 'resources_coverage',
  LayersCoverage = 'plan_stats',
  PlanItemFindings = 'plan_item_findings',
}
