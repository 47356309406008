import { FC } from 'react';

import { getSeverityIcon, getSeverityIconFullWidth } from './utils/getSeverityIcon';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ITableCell } from 'types/interfaces';

export const SeverityCell: FC<ITableCell> = ({ cell: { value } }) => {
  const icon = value.fullWord ? (getSeverityIcon(value.severity, false, true)) : (getSeverityIcon(value));

  return (
    <div className={value.fullWord ? ('') : ('ps-2')}>
      {icon ? <JitIcon icon={icon} size={value.fullWord ? getSeverityIconFullWidth(value.severity) : (22)} /> : <JitText text='unknown' />}
    </div>
  );
};
