import TableCell from '@mui/material/TableCell';
import { FC, useMemo, useState, CSSProperties } from 'react';
import { Cell, Row } from 'react-table';

import { StyledTableRow } from './JitTableRow.styles';

import { CellVerticalAlign, DefaultCellVerticalAlign, TableDesignVariants } from 'components/JitTable/constants';
import { ITableColumn } from 'types/interfaces/Table/ITableColumn';

export interface ElementToShowOnHoverProps {
  row: Row;
}

interface Props {
  prepareRow: (row: Row) => void;
  row: Row<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  onSelectRow?: (row: Row<any>) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  isSelected: boolean;
  cellVerticalAlign?: CellVerticalAlign;
  tableDesign?: TableDesignVariants;
  ElementToShowOnHover?: FC<ElementToShowOnHoverProps>;
  rowHoverStyle?: CSSProperties;
  cellPadding?: string;
}

export const JitTableRow: FC<Props> = ({ prepareRow, row, onSelectRow, isSelected = false, cellVerticalAlign = DefaultCellVerticalAlign,
  tableDesign, ElementToShowOnHover, rowHoverStyle, cellPadding }) => {
  const [toggleClick, setToggleClick] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  useMemo(() => prepareRow(row), [prepareRow, row]);

  const rowProps = useMemo(() => row.getRowProps && row.getRowProps(), [row]);

  return (
    <StyledTableRow
      tabledesign={tableDesign}
      {...rowProps}
      key={row.id}
      data-testid='jitTableRow'
      hoverStyle={rowHoverStyle}
      isClickable={!!onSelectRow}
      isselected={isSelected ? 'true' : undefined}
      onClick={() => {
        if (onSelectRow) {
          onSelectRow(row?.original);
        }
        setToggleClick(!toggleClick);
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      verticalalign={cellVerticalAlign}
    >
      {row?.cells?.map((cell: Cell, index) => {
        const cellProps = cell.getCellProps();
        const { column } = cell;
        const { style = {}, width }: ITableColumn = column;
        const isFirstCell = index === 0;
        const shouldRenderElementToShowOnHover = !!(isFirstCell && isHovering && ElementToShowOnHover);

        if (typeof cell.value === 'object' && cell.value !== null) {
          // eslint-disable-next-line no-param-reassign
          cell.value.toggleClick = toggleClick;
        }

        return (
          <TableCell
            {...cellProps}
            key={`${column?.id}-${row?.id}`}
            style={{
              width,
              ...style,
            }}
            sx={{ padding: cellPadding }}
          >
            {cell.render('Cell')}

            {shouldRenderElementToShowOnHover && <ElementToShowOnHover row={row} />}
          </TableCell>
        );
      })}
    </StyledTableRow>
  );
};
