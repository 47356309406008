import { FC } from 'react';

import styles from './FindingDetailViewContent.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  title: string;
}

export const FindingDetailViewContent: FC<Props> = ({ title, children }) => (
  <div className={styles.wrapper} data-testid='FindingDetailViewContent'>
    <JitText bold size='l' text={title} />

    <div>
      {children}
    </div>
  </div>
);

