import { timeAgoToTime } from 'pages/InsightsPage/utils/getPeriodDates';
import { client } from 'services/client';
import { logError } from 'services/logger/logger';
import { TimeAgo } from 'types/enums';
import { OverviewMetricNames, PerformanceMetricNames, SecurityImpactMetricNames } from 'types/enums/MetricNames';
import { DevProductivityCalculation } from 'types/interfaces/Metrics/DevEfficiencyCalculation';
import { IDailyFindingsActivityMetric } from 'types/interfaces/Metrics/PerformanceMetrics/DailyFindingsActivities';
import { IDetectionRateMetric } from 'types/interfaces/Metrics/PerformanceMetrics/DetectionRate';
import { IExposureWindowMetric } from 'types/interfaces/Metrics/PerformanceMetrics/ExposureWindow';
import { IFindingsOverTimeMetric } from 'types/interfaces/Metrics/PerformanceMetrics/FindingsOvertime';
import { IMTTRMetric } from 'types/interfaces/Metrics/PerformanceMetrics/MTTR';
import { IPlanItemFindingsMetric } from 'types/interfaces/Metrics/PerformanceMetrics/PlanItemFindings';
import { IResourceTypeFindingsMetric } from 'types/interfaces/Metrics/PerformanceMetrics/ResourceTypeFindings';
import { IDevProductivity, ITrendingDevProductivity } from 'types/interfaces/Metrics/SecurityImpactMetrics/DevProductivity';
import { IJitUsageFirstDate } from 'types/interfaces/Metrics/SecurityImpactMetrics/JitUsageFirstDate';
import { IPlanStatus } from 'types/interfaces/Metrics/SecurityImpactMetrics/LayersCoverage';
import { IResourcesCoverage } from 'types/interfaces/Metrics/SecurityImpactMetrics/ResourcesCoverage';
import { ISecurityImpactMTTR } from 'types/interfaces/Metrics/SecurityImpactMetrics/SecurityImpactMTTR';
import { ITotalFindingsGraph } from 'types/interfaces/Metrics/SecurityImpactMetrics/TotalFindingsGraph';
import { Snapshot } from 'types/interfaces/Metrics/Snapshots';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

const serviceName = 'metric';

const formatDateToRequestFormat = (date: Date) => date.toISOString().split('T')[0];

const formatPeriodToRequestParams = (period: TimeAgo) => {
  const endDate = new Date();
  // decreasing 1 from the period days number because we want to get the exact number of days
  // for example: 1 week is 7 days (10.1.23 - 7 days = 3.1.23 which is 8 days)
  const timeAgo = timeAgoToTime[period] - 1;
  const startDate = new Date(endDate.getTime() - timeAgo);
  return {
    start_date: formatDateToRequestFormat(startDate),
    end_date: formatDateToRequestFormat(endDate),
  };
};

export const getDevEfficiencyCalculation = async (): Promise<DevProductivityCalculation | undefined> => {
  const url = `${serviceName}/statistics/dev-efficiency-calculation`;
  return client.get(url).then((data) => camelizeSnakeCaseKeys(data || {}) as DevProductivityCalculation);
};

const getMetric = async <T>(metricName: string, period?: TimeAgo, snapshotId?: string): Promise<T | undefined> => {
  let url = `${serviceName}`;
  if (snapshotId) {
    url += `/snapshot/${snapshotId}/metric`;
  }
  url += `/${metricName}`;

  let params = {};
  if (!snapshotId && period) {
    params = formatPeriodToRequestParams(period);
  }

  return client.get(url, {
    params,
  }).then((data) => camelizeSnakeCaseKeys(data || {}) as T);
};

// performance metrics
export const getDetectionRateMetric = async (period: TimeAgo, snapshotId?: string): Promise<IDetectionRateMetric | undefined> => (
  getMetric<IDetectionRateMetric>(PerformanceMetricNames.DetectionRate, period, snapshotId));
export const getMTTRMetric = async (period: TimeAgo, snapshotId?: string): Promise<IMTTRMetric | undefined> => (
  getMetric<IMTTRMetric>(PerformanceMetricNames.MTTR, period, snapshotId));
export const getFindingsOverTimeMetric = async (period: TimeAgo, snapshotId?: string): Promise<IFindingsOverTimeMetric | undefined> => (
  getMetric<IFindingsOverTimeMetric>(PerformanceMetricNames.FindingsOverTime, period, snapshotId));
export const getExposureWindowMetric = async (period: TimeAgo, snapshotId?: string): Promise<IExposureWindowMetric | undefined> => (
  getMetric<IExposureWindowMetric>(PerformanceMetricNames.ExposureWindow, period, snapshotId));
export const getDailyFindingsActivitiesMetric = async (period: TimeAgo, snapshotId?: string): Promise<IDailyFindingsActivityMetric | undefined> => (
  getMetric<IDailyFindingsActivityMetric>(PerformanceMetricNames.DailyFindingsActivities, period, snapshotId));
export const getResourceTypeFindingsMetric = async (period: TimeAgo, snapshotId?: string): Promise<IResourceTypeFindingsMetric | undefined> => (
  getMetric<IResourceTypeFindingsMetric>(PerformanceMetricNames.ResourceTypeFindings, undefined, snapshotId));
export const getPlanItemFindingsMetric = async (period?: TimeAgo, snapshotId?: string): Promise<IPlanItemFindingsMetric | undefined> => (
  getMetric<IPlanItemFindingsMetric>(PerformanceMetricNames.PlanItemFindings, undefined, snapshotId));

// security impact metrics
export const getDevProductivityMetric = async (): Promise<IDevProductivity | undefined> => (
  getMetric<IDevProductivity>(SecurityImpactMetricNames.DevProductivity));
export const getLayersCoverageMetric = async (): Promise<IPlanStatus | undefined> => (
  getMetric<IPlanStatus>(SecurityImpactMetricNames.LayersCoverage));
export const getJitUsageFirstDateMetric = async (): Promise<IJitUsageFirstDate | undefined> => (
  getMetric<IJitUsageFirstDate>(SecurityImpactMetricNames.JitUsageFirstDate));
export const getResourceCoverageMetric = async (): Promise<IResourcesCoverage | undefined> => (
  getMetric<IResourcesCoverage>(SecurityImpactMetricNames.ResourcesCoverage));
export const getResolvedFindingsMetric = async (): Promise<ITotalFindingsGraph | undefined> => (
  getMetric<ITotalFindingsGraph>(SecurityImpactMetricNames.TotalFindingsGraph));
export const getSecurityImpactMTTRMetric = async (): Promise<ISecurityImpactMTTR | undefined> => (
  getMetric<ISecurityImpactMTTR>(SecurityImpactMetricNames.SecurityImpactMTTR));
export const getOpenFindingsCountMetric = async (): Promise<ISecurityImpactMTTR | undefined> => (
  getMetric<ISecurityImpactMTTR>(SecurityImpactMetricNames.OpenFindingsCount));

// overview metrics
export const getTrendingDevProductivityMetric = async (period: TimeAgo): Promise<ITrendingDevProductivity | undefined> => (
  getMetric<ITrendingDevProductivity>(OverviewMetricNames.TrendingDevProductivity, period));

export const createSnapshot = async (snapshotName: string, period: TimeAgo, expiration: TimeAgo, isPublic: boolean, username: string): Promise<Snapshot | undefined> => {
  const url = `${serviceName}/snapshots`;
  const periodParams = formatPeriodToRequestParams(period);
  const expirationDate = new Date(timeAgoToTime[expiration] + (new Date()).getTime()).toISOString();
  const allMetrics = Object.values(PerformanceMetricNames).map((metricName) => ({ name: metricName }));

  const requestData = {
    name: snapshotName,
    start_date: periodParams.start_date,
    end_date: periodParams.end_date,
    expiration_date: expirationDate,
    is_public: isPublic,
    metrics: allMetrics,
    username,
  };
  const response = await client.post(url, requestData);
  if (response) {
    return camelizeSnakeCaseKeys(response) as Snapshot;
  }
  logError('Failed to create snapshot - See logs in the server for more details', {
    url,
    requestData,
  });
  return undefined;
};
