import { FC, useState } from 'react';

import styles from './NavButton.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { IconProps } from 'components/Navbar/hooks';
import colors from 'themes/colors.module.scss';
import { ISvg } from 'types/interfaces';
import { isSvg } from 'utils/functions/isSvg';

interface Props {
  icon?: FC<ISvg> | FC<IconProps>;
  text: string,
  rightSideIcon?: FC<ISvg> | FC<IconProps>;
  rightSideIconSize?: number,
  onClick: () => void,
  isSelected?: boolean,
  isCollapsed: boolean,
  isNew?: boolean,
}

export const NavButton: FC<Props> = ({
  icon,
  text,
  rightSideIcon,
  onClick,
  isSelected,
  isCollapsed,
  rightSideIconSize = 15,
  isNew,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  let backgroundColor = 'transparent';
  let textAndIconColor = colors.navbarButton;
  if (isSelected) {
    backgroundColor = colors.navbarSelected;
    textAndIconColor = colors.white;
  } else if (isHovered) {
    backgroundColor = colors.navbarHovered;
  }

  const renderIcon = (color: string, size: number, iconToRender?: FC<ISvg> | FC<IconProps>) => {
    if (!iconToRender) return null;
    if (isSvg(iconToRender)) {
      return <JitIcon color={color} icon={iconToRender as FC<ISvg>} size={size} />;
    }
    const Icon = iconToRender as FC<IconProps>;
    return <Icon color={color} size={size} />;
  };

  return (
    <div
      className={styles.wrapper}
      data-testid={`Navbar button-${text}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role='button'
      style={{
        backgroundColor,
      }}
      tabIndex={0}
    >
      <div className={styles.leftSide}>
        <div className={styles.iconWrapper}>
          {isNew && (
            <div className={styles.newIconWrapper}>
              <JitText bold color={colors.black} size='xxs' text='navbar.newIcon' />
            </div>
          )}

          {renderIcon(textAndIconColor, 22, icon)}
        </div>

        {!isCollapsed && (
          <JitText color={textAndIconColor} noWrap size='l' text={text} />
        )}
      </div>

      {!isCollapsed && renderIcon(textAndIconColor, rightSideIconSize, rightSideIcon)}
    </div>
  );
};
