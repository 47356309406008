import { FC } from 'react';

import { FindingDetailViewContent, FindingDetailViewGrid } from '..';

import { JitCollapsibleText } from 'components/JitCollapsibleText/JitCollapsibleText';
import { i18n } from 'locale/i18n';
import { IDictionary, IFinding } from 'types/interfaces';
import {
  getCweIdText, getDescriptionDisplayText,
  getSolutionDisplayText,
  getTagsDisplayText,
} from 'utils/functions/findingsUtils';

interface Props {
  finding: IFinding;
}

export const FindingDetailsViewFindings: FC<Props> = ({ finding }) => {
  const sectionTitle = i18n.t('pages.findings.findingDetails.findingContext.title');

  const hasFindingDetails = finding.cloudAttributes || finding.codeAttributes || finding.appAttributes;
  if (!hasFindingDetails) {
    return null;
  }

  const isCloudFinding = finding.cloudAttributes;
  const isCodeFinding = finding.codeAttributes;
  const isAppFinding = finding.appAttributes;

  let titles: (string)[] = [];
  let titlesFullWidthDisplay: (string)[] = [];
  let details: (string | JSX.Element | undefined)[] = [];
  let detailsFullWidthDisplay: (string | JSX.Element | undefined)[] = [];
  let gridTemplateColumnsStyle: string = '';
  let gridTemplateColumnsStyleFullWidth: string = '';

  if (isCloudFinding) {
    const cloudStrings: IDictionary<string> = i18n.t('pages.findings.findingDetails.findingContext.cloud', { returnObjects: true });
    const { accountId, accountName, region, serviceName } = cloudStrings;
    titles = [accountId, accountName, region, serviceName];
    details = [
      finding.cloudAttributes?.accountId,
      finding.cloudAttributes?.accountName,
      finding.cloudAttributes?.region,
      finding.cloudAttributes?.cloudServiceName,
    ];
    gridTemplateColumnsStyle = '30% 25% 20% 25%';
  } else if (isCodeFinding) {
    const codeStrings: IDictionary<string> = i18n.t('pages.findings.findingDetails.findingContext.code', { returnObjects: true });
    const { organization, repository, branch } = codeStrings;
    titles = [organization, repository, branch];
    details = [
      finding.organization,
      finding.repository,
      finding.codeAttributes?.branch,
    ];
    gridTemplateColumnsStyle = '33% 33% 33%';
  } else if (isAppFinding) {
    gridTemplateColumnsStyle = '49% 49%';
    gridTemplateColumnsStyleFullWidth = '95%';
    const appStrings: IDictionary<string> = i18n.t('pages.findings.findingDetails.findingContext.app', { returnObjects: true });
    const { appName, targetUrl, requestHeader, responseHeader, param, path, method, solution, cweid, description, tags } = appStrings;
    titles = [appName, targetUrl, param, path, method, cweid, tags];
    titlesFullWidthDisplay = [requestHeader, responseHeader, solution, description];
    // Transform for display
    const tagsDisplay = getTagsDisplayText(finding.appAttributes?.tags);
    const cweidDisplay = getCweIdText(finding.appAttributes?.cweid);
    const solutionDisplay = getSolutionDisplayText(finding.appAttributes?.solution);
    const descriptionDisplay = getDescriptionDisplayText(finding.issueText);
    // Create collapsible sections
    const descriptionWithCollapse = <JitCollapsibleText key={`${finding.id}-description-collapse`} text={descriptionDisplay} />;
    const solutionWithCollapse = <JitCollapsibleText key={`${finding.id}-solution-collapse`} text={solutionDisplay} />;
    const requestHeaderWithCollapse = <JitCollapsibleText key={`${finding.id}-request-header-collapse`} text={finding.appAttributes?.requestHeader || ''} />;
    const responseHeaderWithCollapse = <JitCollapsibleText key={`${finding.id}-response-header-collapse`} text={finding.appAttributes?.responseHeader || ''} />;
    details = [
      finding.assetName,
      finding.appAttributes?.targetUrl,
      finding.appAttributes?.param,
      finding.appAttributes?.path,
      finding.appAttributes?.method,
      cweidDisplay,
      tagsDisplay,
    ];
    detailsFullWidthDisplay = [
      requestHeaderWithCollapse,
      responseHeaderWithCollapse,
      solutionWithCollapse,
      descriptionWithCollapse,
    ];
  }

  return (
    <FindingDetailViewContent title={sectionTitle}>
      <FindingDetailViewGrid content={details} gridTemplateColumnsStyle={gridTemplateColumnsStyle} titles={titles} />

      {isAppFinding && <FindingDetailViewGrid content={detailsFullWidthDisplay} gridTemplateColumnsStyle={gridTemplateColumnsStyleFullWidth} titles={titlesFullWidthDisplay} />}
    </FindingDetailViewContent>
  );
};

