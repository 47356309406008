import { FronteggProvider } from '@frontegg/react';
import { FC, ReactElement, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useSetPathBasedItemsIntoSessionStorage } from './utils/useSetPathBasedItemsIntoSessionStorage/useSetPathBasedItemsIntoSessionStorage';

import { AppLoadingBar } from 'components/AppLoadingBar/AppLoadingBar';
import { config } from 'config';
import { AuthProvider } from 'context/AuthContext';
import { constants } from 'globalConstants';
import { fronteggLoginThemeOptions } from 'pages/LoginPage/LoginPageFrontegg';
import { PendingRoleWrapper } from 'wrappers/PendingRoleWrapper';

// These routes are temporary solution for disabling them until frontegg will enable platform support for them
const productionRoutes = {
  forgetPasswordUrl: constants.LOGIN_PATH,
  resetPasswordUrl: constants.LOGIN_PATH,
  resetPhoneNumberUrl: constants.LOGIN_PATH,
};

const authOptions = {
  disableSilentRefresh: false,
  includeQueryParam: true, // This is needed for authentication with a third party app such as (CLI, IDE, etc.)
  routes: {
    loginUrl: constants.LOGIN_PATH,
    logoutUrl: constants.LOGOUT_PATH,
    ...(config.isProduction && productionRoutes),
  },
};

const localizations = {
  en: {
    loginBox: {
      login: {
        signUpMessage: '',
        signUpLink: '',
      },
    },
  },
};

interface Props {
  children: ReactElement;
  setTenantId: (tenantId: string) => void;
  isWaitingForSwitchTenant: boolean;
  setIsWaitingForSwitchTenant: (isWaitingForSwitchTenant: boolean) => void;
}

export const AuthProviderWrapper: FC<Props> = ({ children, setTenantId, isWaitingForSwitchTenant, setIsWaitingForSwitchTenant }) => {
  const [isFrontEggLoading, setIsFrontEggLoading] = useState(false);
  const [isWaitingForRoleUpdate, setIsWaitingForRoleUpdate] = useState(true);
  const { setPathBasedItemsIntoSessionStorage } = useSetPathBasedItemsIntoSessionStorage();

  useEffect(() => {
    setPathBasedItemsIntoSessionStorage();
  }, [setPathBasedItemsIntoSessionStorage]);

  if (config.isTest) {
    return (
      <BrowserRouter>
        {children}
      </BrowserRouter>
    );
  }

  return (
    <>
      <FronteggProvider
        authOptions={authOptions}
        contextOptions={{ baseUrl: config.frontEggBaseUrl }}
        customLoader={setIsFrontEggLoading}
        localizations={localizations}
        themeOptions={fronteggLoginThemeOptions}
      >
        <AuthProvider setIsWaitingForRoleUpdate={setIsWaitingForRoleUpdate} setIsWaitingForSwitchTenant={setIsWaitingForSwitchTenant} setTenantId={setTenantId}>
          <PendingRoleWrapper
            isWaitingForRoleUpdate={isWaitingForRoleUpdate}
            isWaitingForSwitchTenant={isWaitingForSwitchTenant}
            setIsWaitingForRoleUpdate={setIsWaitingForRoleUpdate}
            setIsWaitingForSwitchTenant={setIsWaitingForSwitchTenant}
          >
            {children}
          </PendingRoleWrapper>
        </AuthProvider>
      </FronteggProvider>

      {isFrontEggLoading && <AppLoadingBar />}
    </>
  );
};
