import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';

import styles from '../ConfigurePlanItemDialog/ConfigurePlanItemDialog.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';

interface Props {
  className?: string;
  onCancelClicked: () => void;
  isFinishButtonDisabled: boolean;
  onFinish: () => void;
  finishText: string;
  shouldShowCircularProgress?: boolean;
  disabledFinishButtonTooltip?: string;
}

export const ConfigurePlanItemDialogButtons: FC<Props> = ({ className, onCancelClicked, onFinish,
  finishText, shouldShowCircularProgress, disabledFinishButtonTooltip, isFinishButtonDisabled }) => (
    <div className={`${className || ''}`}>
      <JitButton data-testid='cancelButton' onClick={onCancelClicked} variant='outlined'>
        <JitText color='inherit' text='pages.plan.configurationsDialog.cancel' />
      </JitButton>

      <div className={styles.applyButtonWrapper}>
        <JitTooltip
          childrenWrapperClassName={styles.tooltipWrapper}
          data-testid='applyButtonTooltip'
          followCursor={false}
          placement='top'
          title={(isFinishButtonDisabled && disabledFinishButtonTooltip) || ''}
        >
          <JitButton
            data-testid='applyButton'
            disabled={isFinishButtonDisabled}
            onClick={onFinish}
            variant='contained'
          >
            {shouldShowCircularProgress
              ? (<CircularProgress color='inherit' data-testid='circularProgress' size={20} thickness={6} />)
              : (<JitText color='inherit' size='m' text={finishText} />)}
          </JitButton>

        </JitTooltip>
      </div>
    </div>
);
