import { FC } from 'react';

import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { IFindingWithSelected, ITableCell } from 'types/interfaces';
import { useGetFormatControlName } from 'utils/hooks/useGetFormatControlName';

export const ControlNameCell: FC<ITableCell<IFindingWithSelected>> = ({ cell: { value } }) => {
  const { getFormattedControlName } = useGetFormatControlName();
  const text = getFormattedControlName(value);
  return (
    <TooltipOnlyOnOverflow text={text} />
  );
};
