import colors from 'themes/colors.module.scss';

export const rowBackgroundColorVariantMap = {
  dark: colors.cards04,
  bright: colors.cards04,
};

export const borderColorVarianMap = {
  dark: colors.cards02,
  bright: colors.cards,
};

export const hoveredBorderColorVarianMap = {
  dark: colors.blue02,
  bright: colors.blue02,
};
