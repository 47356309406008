import { FC, useCallback } from 'react';

import styles from './PlanItemDetailsIntegrations.module.scss';

import { OpenInNewTab } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { useAssetsContext, useTenantContext } from 'context';
import colors from 'themes/colors.module.scss';
import { AssetType, Vendor } from 'types/enums';
import { vendorsWithIntegration } from 'utils/constants/vendorIntegrations';
import { getIconForAssetType, getVendorForAssetType } from 'utils/functions/matchVendorToAssetType';
import { getPermissionLinkForVendor } from 'utils/functions/matchVendorToIntegration';

interface Props {
  assetTypes: AssetType[];
}

export const PlanItemDetailsIntegrations: FC<Props> = ({ assetTypes }) => {
  const { isAssetTypeProperlyIntegrated } = useAssetsContext();
  const { isGithubIntegrated } = useTenantContext();

  const isProperlyIntegrated = useCallback((vendor: Vendor, assetType: AssetType) => {
    switch (vendor) {
      case Vendor.Github:
        return isGithubIntegrated;
      default:
        return isAssetTypeProperlyIntegrated(assetType);
    }
  }, [isAssetTypeProperlyIntegrated, isGithubIntegrated]);

  const getCurrentIntegrationTextChildren = useCallback(
    (assetType: AssetType) => {
      const vendor = getVendorForAssetType(assetType) as Vendor;
      const isIntegrated = isProperlyIntegrated(vendor, assetType);

      return (!isIntegrated ? (
        <div>
          <JitText color={colors.gray} text='pages.plan.itemDetails.info.activateToConnect' />

          <JitLink
            data-testid='integration-link'
            href={getPermissionLinkForVendor(vendor)}
            style={{ textDecoration: 'none',
              cursor: 'pointer' }}
            target='_blank'
          >
            <div className={styles.linkChildren}>
              <JitText color={colors.iris} text={`pages.plan.configurationDialog.${vendor}Integration.link`} />

              <JitIcon color={colors.iris} icon={OpenInNewTab} size={12} />
            </div>
          </JitLink>
        </div>
      ) : (
        <JitText
          color={colors.successGreen}
          data-testid='integration-link'
          text='pages.plan.itemDetails.connected'
        />
      ));
    },
    [isProperlyIntegrated],
  );

  const isVendorRequireIntegration = (vendor: Vendor | undefined) => vendor && vendorsWithIntegration.includes(vendor);

  const renderAssetType = (assetType: AssetType) => {
    const icon = getIconForAssetType(assetType);
    return icon ? (
      <JitIcon
        icon={icon}
        size='1.8em'
        withBackSquare={{
          borderRadius: '50%',
          backgroundColor: colors.cards02,
        }}
      />
    )
      : (
        <JitText
          bold
          text={assetType.toLocaleUpperCase()}
        />
      );
  };

  const assetTypesWithIntegration = assetTypes.filter((assetType) => isVendorRequireIntegration(getVendorForAssetType(assetType)));
  if (!assetTypesWithIntegration.length) return null;

  return (
    <div className={styles.wrapper}>
      {assetTypesWithIntegration.map((assetType) => (
        <div
          key={assetType}
          className={styles.integration}
        >
          {renderAssetType(assetType)}

          <div>
            {getCurrentIntegrationTextChildren(assetType)}
          </div>
        </div>
      ))}
    </div>
  );
};
