import { FC, useEffect, useState } from 'react';

import styles from './JitToggleButtonGroup.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export interface ToggleButtonGroupOption {
  label: string;
  value: string;
  key: string;
}

interface Props {
  options: ToggleButtonGroupOption[];
  onChange: (val: string) => void;
  value?: string;
}

export const JitToggleButtonGroup: FC<Props> = ({ options, onChange, value }) => {
  const [selectedButton, setSelectedButton] = useState<ToggleButtonGroupOption | undefined>(undefined);

  useEffect(() => {
    if (value) setSelectedButton(options.find((option) => option.value === value));
  }, [value, options]);

  const handleSelect = (option: ToggleButtonGroupOption) => {
    setSelectedButton(option);
    onChange(option.value);
  };

  return (
    <>
      {options.map((option) => (
        <div
          key={option.key}
          className={option.key === selectedButton?.key ? styles.selectedToggleButton : styles.toggleButton}
          data-testid={`toggleButton-${option.key}`}
          onClick={() => handleSelect(option)}
          role='button'
          tabIndex={0}
        >
          <JitText color={colors.black} size='s' text={option.label} />
        </div>
      ))}
    </>

  );
};
