import { uniq } from 'lodash';
import { FC } from 'react';

import { i18n } from 'locale/i18n';
import { StatisticsWidget } from 'pages/InsightsPage/components/StatisticsWidget/StatisticsWidget';
import { IPullRequest, IPullRequestAnalysisPercentage, IPullRequestsAnalysis } from 'types/interfaces/PullRequests';

interface PRsStatisticsProps {
  prAnalysis?: IPullRequestsAnalysis;
  prPercentage?: IPullRequestAnalysisPercentage;
}

export const PRsStatistics: FC<PRsStatisticsProps> = ({ prAnalysis, prPercentage }) => {
  const failedPrsCategoriesNames = [
    'openPrsWithFailedChecks',
    'mergedPrsWithFixedChecks',
    'mergedPrsWithFailed',
    'mergedPrsWithFailedIgnored',
  ] as (keyof IPullRequestsAnalysis)[];

  const getRelatedReposAmount = (prs: IPullRequest[]) => uniq(prs.map((pr) => pr.repository)).length;

  const failedPrs = failedPrsCategoriesNames.reduce((prs: IPullRequest[], key) => ([...prs, ...(prAnalysis?.[key] || [])]), []);
  const failedPrsAmount = failedPrs.length;
  const failedPrsReposAmount = getRelatedReposAmount(failedPrs);

  const allPrsAmount = prAnalysis?.allPrs?.length || 0;
  const allPrsReposAmount = getRelatedReposAmount(prAnalysis?.allPrs || []);

  const passedPrsAmount = allPrsAmount - failedPrsAmount;
  const failedTooltipTitle = i18n.t('pages.pullRequests.statisticsWidget.failedPrsTooltip', {
    failedPrsAmount,
    failedPrsReposAmount,
    allPrsReposAmount,
  });
  const passedTooltipTitle = i18n.t('pages.pullRequests.statisticsWidget.passedPrsTooltip', { passedPrsAmount });
  const totalTooltipTitle = i18n.t('pages.pullRequests.statisticsWidget.totalReviewedPrsTooltip', { allPrsAmount });
  return (
    <StatisticsWidget
      failedAmount={failedPrsAmount}
      failedPercentage={prPercentage?.failed_prs_percentage}
      failedTitle='pages.pullRequests.statisticsWidget.failedPrs'
      failedTooltipTitle={failedTooltipTitle}
      passedAmount={passedPrsAmount}
      passedPercentage={prPercentage?.passed_prs_percentage}
      passedTitle='pages.pullRequests.statisticsWidget.passedPrs'
      passedTooltipTitle={passedTooltipTitle}
      totalAmount={allPrsAmount}
      totalTooltipTitle={totalTooltipTitle}
    />
  );
};

