
import styles from './NoFindings.module.scss';

import { JittyNoFindings } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';

export const NoFindings = () => (
  <div data-testid='noFindings'>
    <JitIcon
      color='rgba(0, 0, 0, 0)'
      data-testid='NoFindingsIcon'
      icon={JittyNoFindings}
      iconClassName={styles.jittyNoFindingsIcon}
    />

    <JitText align='center' bold data-testid='wellDone' size='l' text='pages.findings.emptyFindingsTable.noFindings.wellDone' />

    <JitText align='center' data-testid='noFindingsSoFar' marginTop='10px' size='m' text='pages.findings.emptyFindingsTable.noFindings.noFindingsSoFar' />

  </div>
);
