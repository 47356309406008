import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FC } from 'react';

import { AssetsManagementButtons, Title, TopBar, TabPanel } from './components';
import { TABS, TABS_ARRAY } from './constants';
import { useAssetsDialogManagement } from './hooks';

import { useTenantContext } from 'context';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

const styles = {
  paperStyle: {
    backgroundColor: colors.black,
    border: `1px solid ${colors.darkGray}`,
    borderRadius: 20,
    minWidth: 844,
    minHeight: 600,
    maxHeight: 600,
    backgroundImage: 'none',
    paddingLeft: 10,
    paddingRight: 10,
  },
};

interface Props {
  defaultTab?: TABS;
  onClose?: () => void
  tagAllAsCheckedForVendors?: string[]

  // An object to help inner components act based on the extra attributes
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  extraDetails?: {
    [key: string]: any
  }
}

export const AssetsManagementDialog: FC<Props> = ({
  defaultTab = TABS.GITHUB,
  onClose,
  tagAllAsCheckedForVendors,
  extraDetails,
}) => {
  const {
    displayedAssets,
    handleClose,
    handleSave,
    handleSearchAsset,
    handleTabChange,
    handleToggleSelectAllAssets,
    isAllAssetsSelectedTabCovered,
    ownerDisplayNames,
    selectedTab,
    checkAsset,
    checkMultipleAssetsByOwner,
    tabDetails,
  } = useAssetsDialogManagement(defaultTab, onClose, tagAllAsCheckedForVendors);
  const { isGithubIntegrated } = useTenantContext();
  const shouldDisableTab = (tab: TABS) => !isGithubIntegrated && tab !== TABS.GITHUB;

  return (
    <Dialog
      data-testid='AssetsManagementDialog'
      onClose={handleClose}
      open
      PaperProps={{ style: styles.paperStyle }}
      scroll='paper'
    >
      <Title
        onClose={handleClose}
        titleLinkDetails={tabDetails.titleLinkDetails}
      />

      <DialogContent className='p-0'>

        <TopBar
          handleSearchAsset={handleSearchAsset}
          handleToggleSelectAllAssets={handleToggleSelectAllAssets}
          isAllAssetsSelectedTabCovered={isAllAssetsSelectedTabCovered}
          wholeSelectionCheckboxText={tabDetails.wholeSelectionCheckboxText}
        />

        <Box>
          <Tabs
            onChange={handleTabChange}
            style={{ borderBottom: `1px solid ${colors.darkGray}` }}
            TabIndicatorProps={{
              style: {
                backgroundColor: colors.blue02,
              },
            }}
            value={selectedTab}
          >

            {TABS_ARRAY.map((tab) => (
              <Tab
                key={tab}
                disabled={shouldDisableTab(tab)}
                label={i18n.t(`dialogs.assetsManagement.tabTitles.${tab}`)}
                style={{ paddingLeft: 45,
                  paddingRight: 45,
                  paddingBottom: 5,
                  color: shouldDisableTab(tab) ? colors.gray : colors.blue02,
                  textTransform: 'none' }}
                value={tab}
              />
            ))}
          </Tabs>
        </Box>

        {TABS_ARRAY.map((tab) => (
          <TabPanel key={tab} index={tab} value={selectedTab}>
            <tabDetails.SelectionListComponent
              assets={displayedAssets}
              checkAsset={checkAsset}
              checkMultipleAssetsByOwner={checkMultipleAssetsByOwner} // In use for the AWS tab
              handleClose={handleClose}
              ownerDisplayNames={ownerDisplayNames} // In use for the Github tab
            />
          </TabPanel>
        ))}
      </DialogContent>

      <AssetsManagementButtons
        CustomLowerBarLeftComponent={tabDetails.CustomLowerBarLeftComponent}
        extraDetails={extraDetails}
        handleClose={handleClose}
        handleSave={handleSave}
      />
    </Dialog>
  );
};
