import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { FC } from 'react';

import { Cross } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

export interface TitleLinkDetails {
  text: string;
  url: string;
}

interface Props {
  onClose: () => void;

  // In case passed to the component, a text-based link will be presented left to the exit button
  titleLinkDetails?: TitleLinkDetails;
}

export const Title: FC<Props> = ({
  onClose,
  titleLinkDetails,
}) => (
  <DialogTitle
    data-testid='dialogTitle'
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `1px solid ${colors.darkGray}`,
      width: '100%',
    }}
  >
    <JitText
      bold
      size='l'
      text='dialogs.assetsManagement.title'
    />

    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {titleLinkDetails && (
        <a
          href={titleLinkDetails.url}
          rel='noreferrer'
          target='_blank'
        >
          <JitText
            className='ms-3'
            color={colors.blue02}
            size='s'
            text={titleLinkDetails.text}
          />
        </a>
      )}

      <IconButton onClick={onClose}>
        <JitIcon icon={Cross} size={25} />
      </IconButton>
    </div>
  </DialogTitle>
);
