import { ChangeEvent, FC, useState } from 'react';

import styles from './ConfigurationsGithubBranchProtectionRequireApproval.module.scss';

import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitDropdown } from 'components/JitDropdown/JitDropdown';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import { stopPropagation } from 'utils';

interface Props {
  numOfRequiredApprovals: number;
  handleChangeNumOfApprovals: (newValue: number) => void;
}

export const ConfigurationsGithubBranchProtectionRequireApproval: FC<Props> = ({ numOfRequiredApprovals, handleChangeNumOfApprovals }) => {
  const [requireApproval, setRequireApproval] = useState<boolean>(numOfRequiredApprovals > 0);

  const handleCheckboxChecked = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setRequireApproval(checked);
    handleChangeNumOfApprovals(+checked);
  };

  const prefix = i18n.t('configurations.githubBranchProtection.fields.requireApproval.dropDownText', { returnObjects: true });
  const getDropDownText = (value: number) => `${prefix} ${value.toString()}`;
  const availableOptions = [1, 2, 3, 4, 5];
  const filterOptions = availableOptions.map((option) => getDropDownText(option));

  const setCheckedFilters = (checkedFilters: string[]) => {
    if (!checkedFilters.length) {
      return;
    }
    const [selectedValue] = checkedFilters.map((item) => item.replace(prefix, ''));
    const requiredApprovals = +selectedValue;
    handleChangeNumOfApprovals(requiredApprovals);
  };

  return (
    <div className={styles.branchProtectionSameLineContainer}>
      <JitCheckbox
        checked={requireApproval}
        data-testid='ConfigurationsGithubBranchProtectionRequireApproval-checkbox'
        onChange={handleCheckboxChecked}
        onClick={stopPropagation}
        style={{ marginRight: '0.5rem' }}
      />

      <div className={styles.branchProtectionBody}>
        <JitText bold text='configurations.githubBranchProtection.fields.requireApproval.label' />

        <JitText text='configurations.githubBranchProtection.fields.requireApproval.description' />

        {requireApproval && (
        <JitDropdown
          checkedFilters={[getDropDownText(numOfRequiredApprovals)]}
          filterOptions={filterOptions}
          formControlProps={{ style: { marginTop: 10 } }}
          setCheckedFilters={setCheckedFilters}
        />
        )}
      </div>
    </div>
  );
};

