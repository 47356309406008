import { FC, useState } from 'react';
import { Cell, Pie, PieChart, Label } from 'recharts';
import { PolarViewBox } from 'recharts/types/util/types';

import { commonPieCellProps, commonPieChartProps, commonPieChartWrapperProps, commonPieLabelProps } from '../../../../constants';

import { FindingCountList } from './components/FindingCountList/FindingCountList';
import { LabelContent } from './components/LabelContent/LabelContent';
import styles from './GraphBody.module.scss';

import { FindingCountListItem } from 'types/interfaces/Metrics/PieChartCountList/PieChartCountList';

interface Props {
  items: FindingCountListItem[];
  onItemClick: (searchValue: string) => void;
}

export const GraphBody: FC<Props> = ({ items, onItemClick }) => {
  const [hoveredItem, setHoveredItem] = useState<FindingCountListItem | undefined>(undefined);
  const totalFindingsCount = items.reduce((acc, resourceType) => acc + resourceType.count, 0);

  const renderLabel = (viewBox?: PolarViewBox) => (
    <LabelContent totalFindingsCount={totalFindingsCount} viewBox={viewBox} />
  );

  return (
    <div className={styles.wrapper}>

      <div className={styles.graphBody} data-testid='graphBody'>
        <PieChart {...commonPieChartWrapperProps}>
          <Pie data={items} {...commonPieChartProps}>
            {items.map((resourceType) => (
              <Cell
                key={`cell-${resourceType.displayName}`}
                // adds the alpha value (opacity) to not hovered items
                fill={hoveredItem?.displayName !== resourceType.displayName && hoveredItem ? `${resourceType.color}20` : resourceType.color}
                {...commonPieCellProps}
              />
            ))}

            <Label
              // label content get's a callback as parameter and gets called with the viewBox as parameter
              content={({ viewBox }) => renderLabel(viewBox as PolarViewBox)}
              {...commonPieLabelProps}
            />
          </Pie>

        </PieChart>
      </div>

      <div className={styles.findingListCount}>
        <FindingCountList hoveredItem={hoveredItem} items={items} onItemClick={onItemClick} setHoveredItem={setHoveredItem} />
      </div>
    </div>
  );
};
