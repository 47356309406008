import Avatar from '@mui/material/Avatar';
import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import styles from 'components/Navbar/components/SelectTenantPopperContent/SelectTenantPopperContent.module.scss';

interface Props {
  profilePictureUrl: string | null;
  email: string;
}
export const UserDetails: FC<Props> = ({ profilePictureUrl, email }) => (
  <div className={styles.userDetails}>
    <Avatar
      src={profilePictureUrl || ''}
      sx={{
        width: 20,
        height: 20,
      }}
    />

    <JitText muted size='s' text={email} />

  </div>
);
