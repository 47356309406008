import { FC } from 'react';

import { FindingDetailViewContent, FindingDetailViewGrid } from '..';

import { AssetType } from 'components/AssetType/AssetType';
import { i18n } from 'locale/i18n';
import { IDictionary, IFinding } from 'types/interfaces';
import { useGetFormatControlName } from 'utils/hooks/useGetFormatControlName';

interface Props {
  finding: IFinding;
}

export const FindingDetailsViewPlan: FC<Props> = ({ finding }) => {
  const { getFormattedControlName } = useGetFormatControlName();
  const planContextTexts: IDictionary<string> = i18n.t('pages.findings.findingDetails.planContext', { returnObjects: true });
  const { vulnerabilityType, controlName, assetType, title } = planContextTexts;

  const titles = [vulnerabilityType, controlName, assetType];

  const planDetails = [
    finding.vulnerabilityType || '',
    getFormattedControlName(finding.controlName || ''),
    <AssetType key={finding.assetType} assetName={finding.assetType} vendor={finding.vendor} />,
  ];

  return (
    <FindingDetailViewContent title={title}>
      <FindingDetailViewGrid content={planDetails} gridTemplateColumnsStyle='35% 35% 30%' titles={titles} />
    </FindingDetailViewContent>
  );
};

