import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { PlanItemDetailsBodySection } from '../PlanItemDetailsBodySection/PlanItemDetailsBodySection';

import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { FindingStatus } from 'types/enums';
import { ActionButtonVariant } from 'types/interfaces';

interface Props {
  planItemSlug: string;
  findingsCount: number;
}

export const PlanItemDetailsFindings: FC<Props> = ({ planItemSlug, findingsCount }) => {
  const navigate = useNavigate();
  const onSeeFindingsClick = () => {
    navigate(`/${constants.routes.FINDINGS}?plan_item=${planItemSlug}&resolution=${FindingStatus.OPEN}&ignored=${false}`);
  };

  const text = `pages.plan.itemDetails.findingsCount.${findingsCount === 1 ? 'singular' : 'plural'}`;
  const buttons = [{
    label: 'pages.plan.itemDetails.findingsCount.button',
    handleClick: onSeeFindingsClick,
    variant: ActionButtonVariant.OUTLINED,
    analytics: {
      action: 'plan-item-see-findings',
      params: { 'plan-items': planItemSlug },
    },
  }];

  return (
    <PlanItemDetailsBodySection buttons={buttons} title='pages.plan.itemDetails.findingsCount.title'>
      <JitText params={{ count: findingsCount }} text={text} />
    </PlanItemDetailsBodySection>
  );
};
