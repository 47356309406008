import { FC, useState } from 'react';
import { Area, AreaChart, CartesianGrid, ReferenceLine, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

import {
  commonAreaChartProps,
  commonAreaProps,
  commonGridProps,
  commonTooltipProps,
  commonXAxisProps,
  commonYAxisProps,
  xAxisDxToPeriod,
} from '../../constants';
import {
  CHART_HEIGHT,
  LONG_TERM_CATEGORY_NAME,
  LONG_TERM_FINDINGS_GRAPH_NAME,
  MID_TERM_CATEGORY_NAME,
  MID_TERM_FINDINGS_GRAPH_NAME,
  SHORT_TERM_CATEGORY_NAME,
  SHORT_TERM_FINDINGS_GRAPH_NAME,
} from '../constants';
import { TooltipFormatter } from '../TooltipFormatter';

import { getGraphXAxisDomain } from 'pages/InsightsPage/SubPages/PerformancePage/components/Graphs/utils';
import { Legend } from 'pages/InsightsPage/SubPages/PerformancePage/components/Legend/Legend';
import colors from 'themes/colors.module.scss';
import { TimeAgo } from 'types/enums';
import { IDictionary } from 'types/interfaces';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IExposureWindowDay, IExposureWindowMetric } from 'types/interfaces/Metrics/PerformanceMetrics/ExposureWindow';

interface Props {
  days: IExposureWindowDay[];
  exposureWindowMetric: MetricWithLoadingIndication<IExposureWindowMetric>;
  xAxisTicks: number[];
  period: TimeAgo;
}

export const GraphBody: FC<Props> = ({ days, exposureWindowMetric, xAxisTicks, period }) => {
  const categoriesDisplayNames: IDictionary<string> = exposureWindowMetric.metric?.categoriesDisplayNames ?? {};
  const yAxisTicksFormatter = (value: number) => value.toLocaleString();

  const getTooltipFormatter = (props: TooltipProps<string, string>) => (
    <TooltipFormatter
      {...props}
      categoriesDisplayNames={exposureWindowMetric.metric?.categoriesDisplayNames ?? {}}
    />
  );
  const legendItems = [
    {
      color: colors.exposureWindowShortTermAreaColor,
      text: categoriesDisplayNames[SHORT_TERM_CATEGORY_NAME],
    },
    {
      color: colors.exposureWindowMidTermAreaColor,
      text: categoriesDisplayNames[MID_TERM_CATEGORY_NAME],
    },
    {
      color: colors.exposureWindowLongTermAreaColor,
      text: categoriesDisplayNames[LONG_TERM_CATEGORY_NAME],
    }];
  const [hoverXValue, setHoverXValue] = useState('');
  const handleOnMouseMove = (e: CategoricalChartState) => {
    if (e.activeLabel) {
      setHoverXValue(e.activeLabel);
    }
  };
  const handleOnMouseLeave = () => {
    setHoverXValue('');
  };
  const maxValue = Math.max(...days.map((day) => day[SHORT_TERM_FINDINGS_GRAPH_NAME]
    + day[MID_TERM_FINDINGS_GRAPH_NAME]
    + day[LONG_TERM_FINDINGS_GRAPH_NAME]));
  const formattedMaxValue = yAxisTicksFormatter(maxValue);

  return (
    <div data-testid='exposureWindowGraphBody'>
      <AreaChart {...commonAreaChartProps} data={days} height={CHART_HEIGHT} onMouseLeave={handleOnMouseLeave} onMouseMove={handleOnMouseMove}>
        <CartesianGrid {...commonGridProps} />

        <Area
          {...commonAreaProps}
          data-testid='shortTermArea'
          dataKey={(point: IExposureWindowDay) => point[SHORT_TERM_FINDINGS_GRAPH_NAME]
            + point[MID_TERM_FINDINGS_GRAPH_NAME]
            + point[LONG_TERM_FINDINGS_GRAPH_NAME]}
          fill={colors.exposureWindowShortTermAreaColor}
          name={categoriesDisplayNames[SHORT_TERM_FINDINGS_GRAPH_NAME]}
          stroke={colors.exposureWindowShortTermAreaColor}
        />

        <Area
          {...commonAreaProps}
          data-testid='midTermArea'
          dataKey={(point: IExposureWindowDay) => point[MID_TERM_FINDINGS_GRAPH_NAME] + point[LONG_TERM_FINDINGS_GRAPH_NAME]}
          fill={colors.exposureWindowMidTermAreaColor}
          name={categoriesDisplayNames[MID_TERM_FINDINGS_GRAPH_NAME]}
          stroke={colors.exposureWindowMidTermAreaColor}
        />

        <Area
          {...commonAreaProps}
          data-testid='longTermArea'
          dataKey={LONG_TERM_FINDINGS_GRAPH_NAME}
          fill={colors.exposureWindowLongTermAreaColor}
          name={categoriesDisplayNames[LONG_TERM_FINDINGS_GRAPH_NAME]}
          stroke={colors.exposureWindowLongTermAreaColor}
        />

        <YAxis {...commonYAxisProps(formattedMaxValue.length)} allowDecimals={false} tickFormatter={yAxisTicksFormatter} />

        <XAxis {...commonXAxisProps} domain={getGraphXAxisDomain(xAxisTicks)} dx={xAxisDxToPeriod[period]} ticks={xAxisTicks} />

        <Tooltip {...commonTooltipProps} content={getTooltipFormatter} />

        <ReferenceLine stroke={colors.black} strokeDasharray='3 3' x={hoverXValue} />
      </AreaChart>

      <div style={{ marginLeft: 15 }}>
        <Legend legendItems={legendItems} />
      </div>
    </div>
  );
};
