/* eslint-disable react/jsx-max-depth */

import { FC, useRef } from 'react';

import parentStyle from '../../FindingsPage.module.scss';

import { FindingsFiltersAddFilterBox, FindingsFiltersBoxes, FindingsFiltersClearButton } from './components';
import { FindingsFiltersSaveButton } from './components/FindingsFiltersSave/FindingsFiltersSave';
import style from './FindingsFilters.module.scss';
import { useManageFilterViewExtend } from './hooks/useManageFilterViewExtend';

import { CloseFilters, ExtendFilters, Filters } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { useFindingsContext } from 'context';
import { useManageFiltersFixedWidth } from 'pages/FindingsPage/components/FindingsFilters/components/FindingsFiltersBoxes/hooks/useManageFiltersFixedWidth';
import { REDUCED_WIDTH_FROM_FILTERS_LINE } from 'pages/FindingsPage/constants';
import colors from 'themes/colors.module.scss';
import { IFindingFilter, IFindingFilterType, MenuItemKeyType } from 'types/interfaces';

interface Props {
  filtersMaxSize: number;
  isFindingReadOnly?: boolean;
}

export const FindingsFilters: FC<Props> = ({ filtersMaxSize, isFindingReadOnly }) => {
  const { filters, filtersVisibleInFirstLine, setFilters, createSavedFilter } = useFindingsContext();

  const {
    visibleFilters,
    setIsFilterViewExtended,
    filterBoxHeight,
    shouldAllowExtendFilterView,
    isFilterViewExtended,
    initiallyOpenedFilterOption,
    setInitiallyOpenedFilterOption,
    handleExtendFilterView,
  } = useManageFilterViewExtend();

  const itemsRef = useRef<Array<HTMLDivElement>>([]);
  const { calculatedWidthToFitFilterBoxes } = useManageFiltersFixedWidth(visibleFilters, itemsRef, filtersMaxSize - REDUCED_WIDTH_FROM_FILTERS_LINE);

  const clearFilters = () => {
    const updatedFilters = [...filters].map((filter: IFindingFilter) => {
      const updatedFilter: IFindingFilter = {
        ...filter,
        selectedValue: filter.type === IFindingFilterType.MULTI_SELECT ? [] : '',
        isVisible: false,
      };
      return updatedFilter;
    });
    setFilters(updatedFilters);
  };

  const extendFilterViewIfNeeded = () => {
    if (visibleFilters.length > filtersVisibleInFirstLine.length) {
      setIsFilterViewExtended(true);
    }
  };

  return (
    <div style={{ width: calculatedWidthToFitFilterBoxes + REDUCED_WIDTH_FROM_FILTERS_LINE }}>
      <div
        className={`${style.container} ${parentStyle.filtersContainer}`}
        style={{ outlineWidth: isFilterViewExtended ? 1 : 0 }}
      >
        <JitButton
          disabled={!shouldAllowExtendFilterView}
          onClick={handleExtendFilterView}
          variant='text'
        >
          <JitIcon centered={false} color={shouldAllowExtendFilterView ? colors.blue02 : colors.lightGray} icon={Filters} />
        </JitButton>

        <FindingsFiltersBoxes
          allowExtendFilterView={shouldAllowExtendFilterView}
          calculatedWidthToFitFilterBoxes={calculatedWidthToFitFilterBoxes}
          height={filterBoxHeight}
          initiallyOpenedFilterOption={initiallyOpenedFilterOption}
          isFilterViewExtended={isFilterViewExtended}
          itemsRef={itemsRef}
          onMenuItemClick={extendFilterViewIfNeeded}
          visibleFilters={visibleFilters}
        />

        <div className={style.filtersContainer}>

          <div className={style.findingsFiltersAddFilterBoxContainer}>
            <FindingsFiltersAddFilterBox
              amountOfPlusFilters={visibleFilters.length - filtersVisibleInFirstLine.length}
              isFiltersViewExtended={isFilterViewExtended}
              onAddFilterVisibility={(addedFilterKey: MenuItemKeyType) => {
                extendFilterViewIfNeeded();
                setInitiallyOpenedFilterOption(addedFilterKey);
              }}
            />
          </div>

          <div className={style.findingsFiltersBoxDivider} />

          <div className={style.findingsFiltersButtonsContainer}>
            <div style={{
              marginLeft: 5,
              display: 'flex',
            }}
            >
              <FindingsFiltersClearButton clearFilters={clearFilters} visibleFilters={visibleFilters} />
            </div>

            <FindingsFiltersSaveButton currentFilters={filters} isReadOnly={isFindingReadOnly} saveFilters={createSavedFilter} />

            <JitButton
              disabled={!shouldAllowExtendFilterView}
              onClick={handleExtendFilterView}
              sx={{
                marginLeft: 'auto',
                marginRight: 0,
                height: '100%',
              }}
              variant='text'
            >
              <JitIcon
                color={shouldAllowExtendFilterView ? colors.blue02 : colors.lightGray}
                icon={isFilterViewExtended ? CloseFilters : ExtendFilters}
                size={11}
              />
            </JitButton>
          </div>
        </div>
      </div>
    </div>
  );
};
