import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import colors from 'themes/colors.module.scss';

export const StyledLinearProgress = styled(LinearProgress)<LinearProgressProps>(({ value = 0 }) => ({
  '.MuiLinearProgress-bar': {
    borderRadius: 8,
    background: `linear-gradient(90deg, ${colors.blue} ${100 - value}%,  ${colors.statusRed} 100%)`,
    transition: 'background 2s ease-out',
  },
}));
