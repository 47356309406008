import { PlanItemConclusion } from 'types/enums';

export enum PlanItemAggregatedStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  ERROR = 'error',
  PENDING = 'pending', // Default status - does not exist in the API
}

export enum PlanItemState {
  ACTIVATED = 'activated',
  INACTIVE = 'inactive',
}

export enum IPlanFilterEntity {
  STATE = 'state',
  STATUS = 'status',
}

export type PlanItemStatus = 'passed' | 'failed' | 'not_applicable';

export interface ItemInput {
  status: PlanItemStatus;
  note?: string;
}

export interface ItemInputWebsocketMessage extends ItemInput {
  item: string;
}

interface ITenantPlanItemDetail {
  control_name: string;
  vendor: string;
  message: string;
  url: string;
  url_text: string;
}

export interface IPlanItemStatusCount {
  count: number;
  has_more: boolean;
  last_pipeline_id?: string;
}

export interface IStatusProperties {
  status: PlanItemAggregatedStatus;
  modified_at: string;
  has_error: boolean;
  findings_count: number;
  success_count: IPlanItemStatusCount;
  failure_count: IPlanItemStatusCount;
  error_count: IPlanItemStatusCount;
}

export interface ITenantPlanItem {
  slug: string;
  name: string;
  layer: string;
  conclusion: PlanItemConclusion;
  status_properties?: IStatusProperties;
  details?: ITenantPlanItemDetail[];
  is_active: boolean;
  updated_at?: string;
  created_at?: string;
  input?: ItemInput;
}
