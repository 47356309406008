import { FC } from 'react';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { getLayerIcon } from 'context/PlanInstanceContext/planUtils';
import colors from 'themes/colors.module.scss';
import { ITableCell } from 'types/interfaces';

export const LayerIconCell: FC<ITableCell> = ({ cell: { value } }) => {
  const icon = getLayerIcon(value);
  return (
    <JitTooltip title={`layer.${value}`}>
      <JitIcon
        color={colors.blue02}
        data-testid='layer-icon'
        icon={icon}
        size={20}
      />
    </JitTooltip>
  );
};
