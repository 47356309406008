import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';
import { findingStatusToTextColor } from 'pages/FindingsPage/constants';
import { FindingStatus } from 'types/enums';
import { ITableCell } from 'types/interfaces';

export const ResolutionCell: FC<ITableCell> = ({ cell: { value } }) => {
  const textColor = findingStatusToTextColor[value as FindingStatus];

  return (
    <JitText bold color={textColor} text={`pages.findings.status.${value?.toLowerCase()}`} />
  );
};

