import { createContext, useContext } from 'react';

import { IExecution } from 'types/interfaces';

interface IExecutionsContext {
  executions: IExecution[];
  getExecutions: (params?: object) => void;
  isLoading: boolean;
  nextPaginationKey?: string;
}

export const ExecutionsContext = createContext<IExecutionsContext>({
  getExecutions: () => undefined,
  executions: [],
  isLoading: false,
});

export const useExecutionsContext = () => useContext(ExecutionsContext);
