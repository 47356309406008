export const removeAllRedundantLinebreaks = (originalText: string): string => {
  /*
      The following regex removes all single linebreaks from a markdown string.
      This is done so that we don't get random linebreaks when they were put mainly to improve readability in GitHub. Double linebreaks are left in.

      More information about this regex can be found here: https://regex101.com/r/wA2rO6/2
  */
  const stringWithoutLineBreaks = originalText.replace(/(\S)[ \t]*(?:\r\n|\n)[ \t]*(\S)/g, '$1 $2');

  /*
      The following regex removes whitespaces from between markdown link elements.

      More information about this regex can be found here: https://regex101.com/r/Tgw9ko/2
  */
  return stringWithoutLineBreaks.replace(/(\[.*?]) (\(.*?\))/g, '$1$2');
};
