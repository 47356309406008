import { FC } from 'react';

import { getAssetTypeDisplayName } from './utils/getAssetTypeDisplayName';
import { getIconByVendor } from './utils/getIconByVendor';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';

interface Props {
  vendor?: string;
  assetName?: string;
}

export const AssetType: FC<Props> = ({ vendor, assetName }) => {
  const text = getAssetTypeDisplayName(assetName || '');
  const icon = getIconByVendor(vendor);

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
    }}
    >
      {icon && (
        <JitIcon
          className='pe-2'
          icon={icon}
        />
      )}

      {assetName && (
      <TooltipOnlyOnOverflow text={text} />
      )}

    </div>
  );
};
