import Box from '@mui/material/Box';
import { Dispatch, FC, SetStateAction, useState } from 'react';

import styles from './PlanCategoryCard.module.scss';

import { ChevronClosed, ChevronOpened, Infrastructure } from 'assets';
import { HoverBox } from 'components/HoverBox/HoverBox';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { ItemSlugOptions, PlanCategoryCardItem } from 'pages/PlanPage/components';
import colors from 'themes/colors.module.scss';
import { IPlanItemDetails } from 'types/interfaces';
import { iconsMap } from 'utils';

interface Props {
  planSlug: string;
  layerKey: string;
  layerTitle: string;
  planItems: IPlanItemDetails[];
  selectedPlanItem: IPlanItemDetails | undefined;
  setDetailedPlanItem: (planItem: IPlanItemDetails | undefined) => void;
  handleConfigure: (planItem: IPlanItemDetails) => void;
  setIntroItem: Dispatch<SetStateAction<ItemSlugOptions | null>>;

}

export const PlanCategoryCard: FC<Props> = ({
  planSlug, layerKey, layerTitle, planItems, selectedPlanItem, setDetailedPlanItem, handleConfigure, setIntroItem,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const handleToggle = () => {
    setIsExpanded((curIsExpanded) => !curIsExpanded);
  };

  return (
    <div style={{ marginBottom: isExpanded ? 12 : 0 }}>
      <Box
        className={styles.box}
      >
        <HoverBox className={styles.hoverBox} color={colors.lightGray} onClick={handleToggle}>
          <Box>
            <JitIcon color={colors.iris} height={21} icon={isExpanded ? ChevronOpened : ChevronClosed} rotateDegrees={180} width={20} />
          </Box>

          <JitIcon
            className={styles.planCategoryIcon}
            color={colors.lightGray}
            height={13}
            icon={iconsMap[layerKey]?.icon || Infrastructure}
            width={13}
          />

          <JitText
            bold
            color={colors.lightGray}
            size='s'
            text={layerTitle}
          />
        </HoverBox>
      </Box>

      {isExpanded && (
        <div style={{
          margin: '0 1rem',
        }}
        >
          {planItems?.map((planItem) => {
            const isSelected = planItem.slug === selectedPlanItem?.slug;
            return (
              <PlanCategoryCardItem
                key={planItem.slug}
                handleConfigure={handleConfigure}
                isSelected={isSelected}
                planItem={planItem}
                planSlug={planSlug}
                setDetailedPlanItem={setDetailedPlanItem}
                setIntroItem={setIntroItem}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
