import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './GithubIntegrationWizard.module.scss';

import { useWizardSteps } from 'components/GithubIntegrationWizard/hooks/useWizardSteps';
import { StepsDialog } from 'components/JitDialogs/StepsDialog/StepsDialog';
import { useTenantContext } from 'context';

export enum IGithubIntegrationWizardStepStatus {
  NOT_STARTED, // the step is not started yet
  ACTIVE, // this is the active current step
  COMPLETE, // the step is complete
}

export interface IGithubIntegrationWizardStep {
  title: string,
  component: FC,
  status: IGithubIntegrationWizardStepStatus,
}

interface Props {
  handleClose: () => void;
  handleInstallClick?: () => void;
}

// ts-prune-ignore-next
export const GithubIntegrationWizard: FC<Props> = ({ handleClose, handleInstallClick }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [didSetInitialStepIndex, setDidSetInitialStepIndex] = useState(false);
  const { hasTriedFetchInstallations } = useTenantContext();

  const incrementStepIndex = useCallback(() => setStepIndex(stepIndex + 1), [stepIndex]);
  const decrementStepIndex = useCallback(() => setStepIndex(stepIndex - 1), [stepIndex]);
  const steps = useWizardSteps(incrementStepIndex, decrementStepIndex, handleClose, handleInstallClick);

  const setInitialStepIndex = () => {
    const currStepIndex = steps?.findIndex((step) => step.status === IGithubIntegrationWizardStepStatus.ACTIVE);
    setStepIndex(currStepIndex);
    setDidSetInitialStepIndex(true);
  };

  useEffect(() => {
    if (!didSetInitialStepIndex && hasTriedFetchInstallations) setInitialStepIndex();
  }, [didSetInitialStepIndex, hasTriedFetchInstallations]); // eslint-disable-line react-hooks/exhaustive-deps

  const StepComponent = useMemo(() => (stepIndex < steps.length ? steps[stepIndex].component : () => null), [stepIndex, steps]);

  const formattedSteps = steps.map((step, index) => ({
    stepName: step.title,
    isSelected: stepIndex === index,
    isVerified: [IGithubIntegrationWizardStepStatus.COMPLETE].includes(step.status),
    isIntro: index === 0, // This is the welcome step
    content: <StepComponent />,
  }));

  return (
    <div className={styles.background} data-testid='github-installation-wizard'>

      {/* When we use the dialog we will handle on close state */}

      <StepsDialog height='m' isOpen onClose={handleClose} setStep={setStepIndex} steps={formattedSteps} title='GithubIntegrationWizard.title' width='xl' withDivider />
    </div>
  );
};
