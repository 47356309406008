import { FC } from 'react';

import { JitLink } from '../../JitLink/JitLink';

import styles from './NoIntegrationButton.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { config } from 'config';
import { useAssetsContext, useFilesContext } from 'context';
import colors from 'themes/colors.module.scss';

export const NoIntegrationButton: FC = () => {
  const { centralizedRepo } = useAssetsContext();
  const { integrationFile } = useFilesContext();

  const repoUrl = centralizedRepo && config.getGithubRepoLink({
    org: centralizedRepo.owner,
    repo: centralizedRepo.asset_name });
  const hoverText = (
    <JitText
      components={{
        linkText: <JitLink
          color={colors.blue02}
          href={integrationFile?.vendorResponse?.htmlUrl || repoUrl}
          rel='noreferrer'
          target='_blank'
          title='integration file'
          underline='hover'
        />,
      }}
      size='s'
      text='tickets.noIntegrationTooltip'
    />
  );
  return (
    <JitTooltip
      childrenWrapperClassName={styles.tooltipChildrenWrapper}
      followCursor={false}
      placement='top'
      title={hoverText}
    >

      <JitButton
        data-testid='create-ticket-button'
        disabled
        noHover
        variant='outlined'
      >
        <JitText
          color={colors.gray}
          data-testid='create-ticket-button-text'
          text='tickets.noIntegration'
        />
      </JitButton>

    </JitTooltip>
  );
};
