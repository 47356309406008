import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { FC, useState } from 'react';

import { DeleteSavedFilterActions } from './components/DeleteSavedFilterActions';

import { RemoveSavedFilterIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { paperStyle } from 'pages/FindingsPage/utils/dialogueStyle';
import colors from 'themes/colors.module.scss';
import { ISavedFilter } from 'types/interfaces/Findings/ISavedFilter';

interface Props {
  savedFilter: ISavedFilter;
  onClose: () => void;
  removeSavedFilter: (savedFilter: ISavedFilter) => void;
}

export const DeleteSavedFilter: FC<Props> = ({
  savedFilter,
  onClose,
  removeSavedFilter,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleDelete = () => {
    removeSavedFilter(savedFilter);
    setOpenDialog(false);
    onClose();
  };

  return (
    <div>
      <MenuItem onClick={() => setOpenDialog(true)}>
        <div style={{ display: 'flex' }}>
          <JitIcon icon={RemoveSavedFilterIcon} />

          <JitText style={{ marginLeft: 7 }} text='dialogs.planItemsCatalog.filter.savedFilters.delete' />
        </div>
      </MenuItem>

      <Dialog
        maxWidth='xl'
        onClose={onClose}
        open={openDialog}
        PaperProps={{ style: paperStyle }}
        scroll='paper'
      >
        <DialogTitle
          data-testid='dialogTitle'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <JitText
            bold
            size='l'
            text='dialogs.planItemsCatalog.filter.savedFilters.removeFromYourLibrary'
          />
        </DialogTitle>

        <Divider />

        <div style={{ padding: 20 }}>
          <div style={{ paddingBottom: 3 }}>
            <JitText bold style={{ paddingBottom: 7 }} text='dialogs.planItemsCatalog.filter.savedFilters.areYouSureDelete' />

            <JitText style={{ color: colors.gray }} text='dialogs.planItemsCatalog.filter.savedFilters.onceYouRemove' />
          </div>

          <DeleteSavedFilterActions handleDelete={handleDelete} />
        </div>

      </Dialog>
    </div>
  );
};
