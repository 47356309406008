import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';

import { SavedFiltersMenuItem } from './components/SavedFilterMenuItem/SavedFilterMenuItem';
import styles from './SavedFiltersDropdown.module.scss';

import { LoadedFilter } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMenu, MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { useFindingsContext } from 'context';
import colors from 'themes/colors.module.scss';

export const SavedFiltersDropdown: FC = () => {
  const { savedFilters, selectedFilter } = useFindingsContext();
  const [displayedSavedFilters, setDisplayedSavedFilters] = useState(savedFilters);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isDisabled = isEmpty(displayedSavedFilters);
  const handleClose = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    setDisplayedSavedFilters(savedFilters);
  }, [savedFilters]);

  const menuItems: MenuItemType[] = displayedSavedFilters.map((savedFilter) => (
    {
      itemKey: savedFilter.name,
      itemContent: <SavedFiltersMenuItem handleClose={handleClose} savedFilter={savedFilter} />,
      searchValue: savedFilter.name,
    }
  ));
  return (
    <div style={{ height: '100%' }}>
      <JitMenu
        className={styles.menu}
        disabled={isDisabled}
        menuItems={menuItems}
        menuStyle={{
          width: 270,
          marginLeft: 2,
        }}
        onMenuClose={handleClose}
        open={isMenuOpen}
        paperProps={{ style: { width: '100%' } }}
        withSearchBox={{}}
      >
        <JitButton
          className={styles.button}
          disabled={isDisabled}
          disableElevation
          disableRipple
          endIcon={<KeyboardArrowDownIcon style={{ marginRight: 10 }} />}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          sx={{
            marginLeft: 2,
            borderRadius: 2,
            height: '100%',
            width: 238,
            backgroundColor: colors.cards,
            '&:hover': {
              backgroundColor: colors.cards,
            },
            '&:disabled': {
              backgroundColor: colors.cards,
              opacity: 'inherit',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          }}
          variant='contained'
        >
          <div className={styles.buttonText}>
            <JitIcon icon={LoadedFilter} />

            <JitText
              className={styles.text}
              color={isDisabled ? colors.gray : colors.white}
              size='m'
              text={selectedFilter ? selectedFilter.name : 'dialogs.planItemsCatalog.filter.savedFilters.saveNewFilter'}
            />
          </div>
        </JitButton>
      </JitMenu>
    </div>
  );
};
