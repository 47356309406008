import { Vendor } from 'types/enums';

export enum IntegrationStatus {
  VALIDATING = 'validating',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum IntegrationStatusReason {
  SUCCESS = 'success',
  INVALID_SCHEMA = 'invalid schema',
  MISSING_SECRET = 'missing secret',
  INCORRECT_AUTH = 'incorrect auth',
  INCORRECT_EMAIL = 'incorrect email',
  PROJECT_NOT_FOUND = 'project id is not found',
  ISSUE_NOT_FOUND = 'issue id is not found',
  NOT_VALID_CUSTOM_FIELDS = 'custom fields are not valid',
}

export interface IIntegration {
  tenantId: string;
  integrationId: string;
  vendor: Vendor;
  status: IntegrationStatus;
  reason?: IntegrationStatusReason;
}
