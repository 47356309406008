import { Chip } from '@mui/material';
import { FC } from 'react';

import styles from './Activities.module.scss';

import colors from 'themes/colors.module.scss';

export interface ICoverageScaleProps {
  activities: string[];
}

export const MAX_ACTIVITIES_TO_DISPLAY = 6;

export const Activities: FC<ICoverageScaleProps> = ({ activities }) => {
  // if we have more than 6 activities, we only show the first 6 and add a +X more
  const hasAdditionalActivities = activities.length > MAX_ACTIVITIES_TO_DISPLAY;
  const additionalActivities = hasAdditionalActivities ? activities.length - MAX_ACTIVITIES_TO_DISPLAY : 0;
  const activitiesToDisplay = hasAdditionalActivities ? activities.slice(0, MAX_ACTIVITIES_TO_DISPLAY) : activities;

  // Add "+X" to the list if there are additional activities
  if (hasAdditionalActivities) {
    activitiesToDisplay.push(`+${additionalActivities}`);
  }
  return (
    <div className={styles.container}>
      {activitiesToDisplay.map((activity) => (
        <Chip
          key={activity}
          classes={{
            label: styles.chipLabel,
          }}
          className={styles.chip}
          data-testid='activity-chip'
          label={activity}
          sx={{
            backgroundColor: colors.activityChipBackground,
            height: '30px',
            borderRadius: '14px',
            border: 'none',
            maxWidth: '210px',
          }}
          variant='outlined'
        />
      ))}
    </div>
  );
};
