
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IGithubIntegrationWizardStep, IGithubIntegrationWizardStepStatus } from 'components/GithubIntegrationWizard/GithubIntegrationWizard';
import { InstallJitApp } from 'components/GithubIntegrationWizard/StepComponents';
import { config } from 'config';
import { useAuthContext } from 'context';
import { constants } from 'globalConstants';
import { createStateToken } from 'services/OauthService/OauthService';
import { Vendor } from 'types/enums';

interface Props {
  incrementStepIndex: () => void;
  isRedirectedBackFromGHInstallation: boolean;
  planSlug?: string;
  handleInstallClick?: () => void;
}

export const useGetInstallJitAppStep = ({
  incrementStepIndex, isRedirectedBackFromGHInstallation, planSlug, handleInstallClick,
}: Props): IGithubIntegrationWizardStep => {
  const [hasClickedContinue, setHasClickedContinue] = useState<boolean>(false);
  const { frontEggUser } = useAuthContext();
  const { planSlug: paramsSlug } = useParams();

  const status = useMemo(() => {
    if (hasClickedContinue) return IGithubIntegrationWizardStepStatus.COMPLETE;
    return IGithubIntegrationWizardStepStatus.ACTIVE;
  }, [hasClickedContinue]);

  const onInstallClick = useCallback(async () => {
    const stateTokenRes = await createStateToken({
      tenant_id: frontEggUser.tenantId,
      vendor: Vendor.Github,
      extra: { initial_plan: planSlug },
    });
    if (paramsSlug) sessionStorage.setItem(constants.GOAL_PLAN_KEY, paramsSlug);
    else handleInstallClick?.();
    window.location.href = config.getInstallGithubUrl(stateTokenRes.token);
  }, [frontEggUser.tenantId, paramsSlug, planSlug, handleInstallClick]);

  const onContinueButtonClick = useCallback(() => {
    setHasClickedContinue(true);
    incrementStepIndex();
  }, [incrementStepIndex]);

  return useMemo(() => ({
    title: 'GithubIntegrationWizard.installJitApp.tabText',
    component: () => (
      <InstallJitApp
        isRedirectedBackFromGHInstallation={isRedirectedBackFromGHInstallation}
        onContinueButtonClick={onContinueButtonClick}
        onInstallClick={onInstallClick}
      />
    ),
    status,
  }), [isRedirectedBackFromGHInstallation, onContinueButtonClick, onInstallClick, status]);
};
