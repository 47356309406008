import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Coverage } from './components/Coverage/Coverage';
import { DevProductivity } from './components/DevProductivity/DevProductivity';
import { SecurityImpactSubtitle } from './components/SecurityPosture/components/SecurityImpactSubtitle/SecurityImpactSubtitle';
import { SecurityPosture } from './components/SecurityPosture/SecurityPosture';
import { useFetchMetrics } from './hooks/useFetchMetrics';
import styles from './SecurityImpact.module.scss';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { SecurityImpactMetricNames } from 'types/enums/MetricNames';

export const SecurityImpact: FC = () => {
  const navigate = useNavigate();

  const { fetchMetrics, fetchDevCalculation, metrics, silentFetchDevProductivity } = useFetchMetrics();

  useEffect(() => {
    fetchMetrics();
  }, [navigate, fetchMetrics, fetchDevCalculation]);

  const cardsContent = (
    <>
      <SecurityPosture
        devProductivityMetric={metrics[SecurityImpactMetricNames.DevProductivity]}
        openFindingsCount={metrics[SecurityImpactMetricNames.OpenFindingsCount]}
        securityImpactMttrMetric={metrics[SecurityImpactMetricNames.SecurityImpactMTTR]}
        totalFindingsGraphMetric={metrics[SecurityImpactMetricNames.TotalFindingsGraph]}
      />

      <DevProductivity
        devProductivityMetric={metrics[SecurityImpactMetricNames.DevProductivity]}
        silentFetchDevProductivity={silentFetchDevProductivity}
      />

      <Coverage planStatusMetric={metrics[SecurityImpactMetricNames.LayersCoverage]} resourcesCoverageMetric={metrics[SecurityImpactMetricNames.ResourcesCoverage]} />
    </>
  );

  const isPageLoading = Object.values(metrics).some((metric) => metric?.isLoading);

  const pageContent = isPageLoading ? <LoadingBar /> : cardsContent;
  return (
    <div className={styles.wrapper} data-testid='securityImpactPage'>
      <div className={styles.title}>
        <PageTitles title='pages.securityImpact.title' />

        <SecurityImpactSubtitle firstDateJitUsage={metrics[SecurityImpactMetricNames.JitUsageFirstDate]} />
      </div>

      {pageContent}

    </div>
  );
};
