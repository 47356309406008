import { FC } from 'react';

import styles from './PageTitles.module.scss';

import { JitText } from 'components/JitText/JitText';

interface TextProps {
  text: string;
  components?: { [tagName: string]: React.ReactNode };
}

interface Props {
  title: string,
  shortTitle?: string,
  subtitle?: TextProps | string,
  description?: string,
}
export const PageTitles: FC<Props> = ({
  title,
  shortTitle,
  subtitle,
  description,
}) => {
  const isSubtitleText = typeof subtitle === 'string';
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <JitText bold data-testid='PageTitles-title' size='xxl' text={title} />

        <JitText data-testid='PageTitles-shortTitle' size='xxl' text={shortTitle} />
      </div>

      <JitText components={isSubtitleText ? undefined : subtitle?.components} data-testid='PageTitles-subtitle' text={isSubtitleText ? subtitle : subtitle?.text} />

      <JitText data-testid='PageTitles-description' muted text={description} />
    </div>
  );
};
