
import { NotificationError, NotificationSuccess } from 'assets';
import { SuccessToastBarContent } from 'components/CreateTicketButton/components/SuccessToastBarContent';
import { OpenTicket } from 'components/CreateTicketButton/VendorConfig';
import { JitText } from 'components/JitText/JitText';
import { useToastsContext } from 'context';
import { logError, logInfo } from 'services/logger/logger';
import colors from 'themes/colors.module.scss';
import { ToastType } from 'types/enums';
import { IFinding } from 'types/interfaces';

const generateTitle = (findings: IFinding[]) => {
  if (findings.length === 1) {
    return findings[0].name;
  }
  return `Jit findings - ${new Date().toLocaleDateString()}`;
};

export const useCreateTicket = () => {
  const { showToast } = useToastsContext();
  // TODO: once the backend will be ready we should handle errors and success in some other way
  // maybe we should check the status code in the response for errors.
  const createTicket = async (findings: IFinding[], integrationId: string, openTicketFunction: OpenTicket, title: string | null = null) => {
    const ticketTitle = title || generateTitle(findings);
    await openTicketFunction(integrationId, ticketTitle, findings).then((response) => {
      logInfo(`Ticket created successfully with id ${response?.data.id}`);
      if (response?.data.link) {
        showToast({
          type: ToastType.Success,
          overrideProps: {
            icon: NotificationSuccess,
            title: 'tickets.successToast.title',
            content: <SuccessToastBarContent link={response.data.link} />,
          },
        });
      }
    }).catch((error) => {
      logError(`Failed to create ticket. Error: ${error}. For findings ${findings.map((finding) => finding.id).join(', ')}`);
      const errorMessage = error?.response?.data?.error;
      showToast({
        type: ToastType.Error,
        overrideProps: {
          icon: NotificationError,
          title: 'tickets.failedToast.title',
          subtitle: 'tickets.failedToast.subtitle',
          content: <JitText color={colors.black} text={errorMessage || 'tickets.failedToast.defaultContent'} />,
        },
      });
    });
  };
  return { createStory: createTicket };
};
