import { FC, useCallback, useMemo } from 'react';

import { CircleFailure, WhiteDotCheck } from 'assets';
import { JitCard } from 'components/JitCard/JitCard';
import { useTenantContext } from 'context';
import { InnerOptions, IntegrationTemplate, IntegrationType, IntegrationVendorType } from 'context/IntegrationsContext/templates/interfaces';
import { i18n } from 'locale/i18n';
import { useAddSecretDialog } from 'pages/IntegrationsPage/hooks/useAddSecretDialog/useAddSecretDialog';
import { getVariantTagArray } from 'pages/IntegrationsPage/utils/variantTags';
import { ISvg } from 'types/interfaces';
import { ActionButton, ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';
import { IIntegration, IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

interface Props {
  icon: FC<ISvg>
  vendor?: string
  title?: string
  description?: string
  variant: IntegrationVendorType
  integrations?: IIntegration[]
  testId?: string
  integrationFileLink?: string
  isLoading?: boolean
  integrationTemplate?: IntegrationTemplate
}

interface IntegrationActionHooks {
  component?: JSX.Element;
  isOpened: boolean;
  action: () => void;
  validation: (x: string | undefined) => IntegrationStatus | undefined;
}

type CustomHook = (integration?: IntegrationTemplate) => IntegrationActionHooks;

interface IntegrationActions {
  onConnect: CustomHook;
  onConfigure: CustomHook;
}

interface IntegrationTypeToActionMap {
  secret: IntegrationActions;
}

const integrationTypeToActionMap: IntegrationTypeToActionMap = {
  secret: {
    onConnect: useAddSecretDialog,
    onConfigure: useAddSecretDialog,
  },
};

export const IntegrationCard: FC<Props> = ({
  integrations, icon, vendor,
  variant, testId, integrationFileLink,
  isLoading, title, description, integrationTemplate }) => {
  const { isGithubIntegrated } = useTenantContext();
  const { validation, isOpened, component, action } = integrationTypeToActionMap[
    integrationTemplate?.integrationType as keyof typeof integrationTypeToActionMap]?.onConnect(integrationTemplate) || {};

  const integrationStatus = useMemo(() => {
    if (validation) {
      return validation((integrationTemplate?.options as InnerOptions).fields?.name?.value);
    }
    const integrationByStatus = integrations?.reduce((acc, integration) => {
      acc[integration.status] = acc[integration.status] ? acc[integration.status] + 1 : 1;
      return acc;
    }, {} as Record<IntegrationStatus, number>);
    if (integrationByStatus?.[IntegrationStatus.SUCCESS]) {
      return IntegrationStatus.SUCCESS;
    }
    if (integrationByStatus?.[IntegrationStatus.VALIDATING]) {
      return IntegrationStatus.VALIDATING;
    }

    if (integrationByStatus?.[IntegrationStatus.FAILURE]) {
      return IntegrationStatus.FAILURE;
    }
    return undefined;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations, validation]);

  const handleButtonClick = useCallback(() => {
    if (action) {
      action();
    } else if (integrationFileLink) {
      window.open(integrationFileLink, '_blank');
    }
  }, [action, integrationFileLink]);

  const integrationFailureReason = useMemo(() => integrations?.find(({ status, reason }) => status === IntegrationStatus.FAILURE && reason), [integrations]);
  const actionButtons: ActionButton[] = useMemo(() => {
    if (integrationTemplate?.integrationType === IntegrationType.securityTool) {
      return [];
    }
    return [{
      label: integrationStatus ? 'cards.buttons.configure' : (integrationTemplate?.options as InnerOptions)?.connect.label || 'cards.buttons.integrateAsCode',
      handleClick: handleButtonClick,
      disabled: (!integrationFileLink && !action) || isLoading || !isGithubIntegrated,
      tooltip: !isGithubIntegrated ? 'cards.tooltip.requiresGithubIntegration' : undefined,
      variant: integrationStatus ? ActionButtonVariant.OUTLINED : ActionButtonVariant.PRIMARY,
      isLoading,
    }];
  }, [action, handleButtonClick, integrationFileLink, integrationStatus, integrationTemplate?.integrationType, integrationTemplate?.options, isGithubIntegrated, isLoading]);
  const statusIcon = integrationStatus === IntegrationStatus.SUCCESS ? WhiteDotCheck : CircleFailure;
  const headerIcons = {
    icons: integrationStatus ? [{ icon }, {
      icon: statusIcon,
      tooltip: integrationFailureReason?.reason,
    }] : [{ icon }],
  };

  const learnMoreLink = useMemo(() => {
    const link = i18n.t(`cards.${vendor}.learnMoreLink`, { defaultValue: '' });
    return integrationTemplate?.link || link ? {
      text: integrationTemplate?.link?.text || 'cards.buttons.learnMore',
      href: integrationTemplate?.link?.href || link,
    } : undefined;
  }, [integrationTemplate?.link, vendor]);

  return (
    <>
      {isOpened && component}

      <JitCard
        actionButtons={actionButtons}
        description={description || `cards.${vendor}.description`}
        headerIcons={headerIcons}
        link={learnMoreLink}
        tags={getVariantTagArray(variant)}
        testid={testId || `cards.${vendor}.title`}
        title={title || `cards.${vendor}.title`}
      />
    </>
  );
};
