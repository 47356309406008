import { FC } from 'react';

import { Header } from '../../AssetManagementTable';

import styles from './AssetsTableHeaders.module.scss';

import { JitText } from 'components/JitText/JitText';

interface Props {
  headers: Header[]
}

export const AssetsTableHeaders: FC<Props> = ({ headers = [] }) => (
  <div className={styles.headersContainer} date-testid='AssetsTableHeaders'>
    {headers.map((header, index) => {
      if (index === 0) {
        return (
          <div key={`assetManagementHeader-${header.label}`} className={styles.header}>
            <JitText className={styles.firstHeader} size='s' text={header.label} />
          </div>
        );
      }
      return (
        <JitText key={`assetManagementHeader-${header.label}`} className={styles.header} size='s' text={header.label} />
      );
    })}
  </div>
);
