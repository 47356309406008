import Breadcrumbs from '@mui/material/Breadcrumbs';
import { InputProps } from '@mui/material/Input';
import { FC } from 'react';

import styles from './JitBreadcrumbs.module.scss';

interface BreadcrumbItem {
  content: JSX.Element
  itemKey: string;
}

interface Props extends Omit<InputProps, 'onChange'> {
  separator: string | JSX.Element;
  items: BreadcrumbItem[];
}

export const JitBreadcrumbs: FC<Props> = ({ separator, items }) => (
  <div data-testid='JitBreadcrumbs'>
    <Breadcrumbs
      aria-label='breadcrumb'
      className={styles.breadcrumbsWrapper}
      separator={separator}
    >
      {items.map((item: BreadcrumbItem, index: number) => (
        <div key={item.itemKey} className={index === items.length - 1 ? styles.breadcrumbsLastItem : undefined}>
          {item.content}
        </div>
      ))}
    </Breadcrumbs>
  </div>
);
