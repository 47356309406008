import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateMockPipeline } from '../../hooks/useCreateMockPipeline';

import { StepGithubCard } from './components/StepGithubCard';
import { AnalyticsEvents, GET_SPECIFIC_PLAN_PAGE_ROUTE } from './constants';

import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitText } from 'components/JitText/JitText';
import { useTenantContext } from 'context';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { useSetCentralizedRepo } from 'context/TenantContext/tenantHooks/useSetCentralizedRepo';
import { useValidateCentralizedRepo } from 'context/TenantContext/tenantHooks/useValidateCentralizedRepo';
import { useNavigatePipelinesDemo } from 'pages/PipelinesPage/hooks/useNavigatePipelinesDemo';
import { CentralizedAssetValidationStatus } from 'types/enums/CentralizedAssetValidationStatus';
import { IAsset } from 'types/interfaces';

interface Props {
  initializedSelectedAsset?: IAsset;
}

export const CreateCentralizedRepo: FC<Props> = ({ initializedSelectedAsset }) => {
  const [selectedAsset, setSelectedAsset] = useState<IAsset | undefined>(initializedSelectedAsset);
  const { handleSetCentralizedRepo, centralizedRepoResponseStatus } = useSetCentralizedRepo();
  const { handleValidateCentralizedRepo, validateCentralizedRepoValidationStatus, setValidateCentralizedRepoValidationStatus } = useValidateCentralizedRepo();
  const { createMockPipeline } = useCreateMockPipeline();
  const { navigatePipelinesDemo } = useNavigatePipelinesDemo();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { githubInstallation } = useTenantContext();
  const navigate = useNavigate();

  const initialPlan = useMemo(() => githubInstallation?.oauth_context?.initial_plan, [githubInstallation]);

  const onContinueButtonClick = useCallback(async () => {
    if (selectedAsset) {
      sendAnalyticsEvent({ action: AnalyticsEvents.CentralizedRepoSetRepoClicked });
      const status = await handleSetCentralizedRepo(selectedAsset);
      if (status === CentralizedAssetValidationStatus.VALID) {
        if (initialPlan) {
          const route = GET_SPECIFIC_PLAN_PAGE_ROUTE(initialPlan);
          navigate(route);
        } else {
          createMockPipeline(); // For the demo
          navigatePipelinesDemo();
        }
      }
    }
  }, [selectedAsset, sendAnalyticsEvent, handleSetCentralizedRepo, initialPlan, navigate, createMockPipeline, navigatePipelinesDemo]);

  const validateSelectedAsset = useCallback(async (asset: IAsset) => {
    const status = await handleValidateCentralizedRepo(asset);
    if (status === CentralizedAssetValidationStatus.INVALID) {
      sendAnalyticsEvent({ action: AnalyticsEvents.CentralizedRepoValidationFailed });
    }
  }, [handleValidateCentralizedRepo, sendAnalyticsEvent]);

  const isPendingSetCentralizedRepo = centralizedRepoResponseStatus === CentralizedAssetValidationStatus.PENDING;

  const continueButtonLabel = initialPlan ? 'gettingStartedWizard.createCentralizedRepo.goToPlan' : 'gettingStartedWizard.createCentralizedRepo.buttonText';

  return (
    <DialogContentWrapper
      actionButtons={[{
        label: continueButtonLabel,
        handleClick: onContinueButtonClick,
        disabled: !(validateCentralizedRepoValidationStatus === CentralizedAssetValidationStatus.VALID) || isPendingSetCentralizedRepo,
        isLoading: isPendingSetCentralizedRepo,
      }]}
    >
      <div>

        <JitText bold text='gettingStartedWizard.createCentralizedRepo.title' />

        <StepGithubCard
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          setValidationStatus={setValidateCentralizedRepoValidationStatus}
          validateSelectedAsset={validateSelectedAsset}
          validationStatus={validateCentralizedRepoValidationStatus}
        />

      </div>

    </DialogContentWrapper>
  );
};

