import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { FILE_ERROR_TOAST_ID } from './constants';
import {
  useHandleFilesMetadataWebSocketNotification,
} from './useHandleFilesWebSocketNotification/useHandleFilesMetadataWebSocketNotification';
import { getFileErrorToasts } from './utils/getFileErrorToast';

import { FilesContext, useToastsContext } from 'context';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { logError, logInfo } from 'services/logger/logger';
import { usePlanService } from 'services/PlanService/usePlanService';
import { WebSocketNotificationTopics } from 'types/enums';
import { FileEntityType } from 'types/enums/FileEntityType';
import { IFileMetadata } from 'types/interfaces/Files/IFileMetadata';

export const FilesProvider: FC = ({ children }) => {
  const [triedToFetchFilesMetadata, setTriedToFetchFilesMetadata] = useState(false);
  const [isLoadingFilesMetadata, setIsLoadingFilesMetadata] = useState(false);
  const [filesMetadata, setFilesMetadata] = useState<undefined | IFileMetadata[]>(undefined);
  const [integrationFile, setIntegrationFile] = useState<undefined | IFileMetadata>(undefined);
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handleFilesMetadataWebSocketNotification } = useHandleFilesMetadataWebSocketNotification({ setFilesMetadata });
  const { showToast, hideToast, activeToasts } = useToastsContext();
  const { getAllFilesMetadata, getIntegrationFile } = usePlanService();

  const fetchFilesMetadata = useCallback(async () => {
    setTriedToFetchFilesMetadata(true);
    setIsLoadingFilesMetadata(true);

    const filesMetadataResponse = await getAllFilesMetadata();
    if (filesMetadataResponse?.status) {
      if (filesMetadataResponse.status <= 299) {
        setFilesMetadata(filesMetadataResponse.data);
      } else if (filesMetadataResponse.status === 404) {
        logInfo('No files file found');
      }
    } else {
      logError('Error while fetching files');
    }
    setIsLoadingFilesMetadata(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filesMetadata !== undefined) {
      websocketSubscribe(WebSocketNotificationTopics.FileMetadata, handleFilesMetadataWebSocketNotification);
    }
  }, [filesMetadata, handleFilesMetadataWebSocketNotification, websocketSubscribe]);

  useEffect(() => {
    fetchFilesMetadata().then(() => {
      logInfo('Files metadata fetched');
    }).catch((error) => {
      logError('Error while fetching files metadata', error);
    });
  }, [fetchFilesMetadata]);

  const fileToasts = useMemo(() => getFileErrorToasts(filesMetadata), [filesMetadata]);
  const activeToastIndex = useMemo(() => activeToasts.findIndex((toast) => toast.toastId === FILE_ERROR_TOAST_ID), [activeToasts]);

  useEffect(() => {
    if (fileToasts?.length) {
      fileToasts.forEach((errorToast) => {
        showToast(errorToast);
      });
    } else {
      hideToast(activeToastIndex);
    }
  }, [activeToastIndex, fileToasts, hideToast, showToast]);

  useEffect(() => {
    const cachedFile = filesMetadata?.find((file) => (file.type === FileEntityType.INTEGRATION_FILE));
    if (cachedFile) {
      setIntegrationFile(cachedFile);
    } else if (!isLoadingFilesMetadata && triedToFetchFilesMetadata) {
      getIntegrationFile().then((response) => {
        logInfo('Integration file fetched');
        if (response?.status && response.status <= 299) {
          setIntegrationFile(response.data);
        } else if (response?.status && response.status === 400) {
          logInfo(`Could not fetch integration file: ${response.data}`);
          setIntegrationFile(undefined);
        } else if (response?.status && response.status === 404) {
          logInfo('No integration file found');
          setIntegrationFile(undefined);
        } else {
          logError('Fetched integration with invalid status code');
        }
      }).catch((error) => {
        logError('Error while fetching integration file', error);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesMetadata, isLoadingFilesMetadata, triedToFetchFilesMetadata]);

  const value = useMemo(() => ({
    fetchFilesMetadata,
    filesMetadata,
    integrationFile,
    isLoadingFilesMetadata,
  }), [fetchFilesMetadata, filesMetadata, integrationFile, isLoadingFilesMetadata]);

  return (
    <FilesContext.Provider value={value}>
      {children}
    </FilesContext.Provider>
  );
};
