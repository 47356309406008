import { AxiosResponse } from 'axios';
import { FC } from 'react';

import { JiraIconOutlined, LinearIconIris, ShortcutIconOutlined } from 'assets';
import { generateADF } from 'pages/FindingsPage/utils/generateADF';
import { generateMarkdown } from 'pages/FindingsPage/utils/generateMarkdown';
import { useJiraService } from 'services/JiraService/useJiraService';
import { useLinearService } from 'services/LinearService/useLinearService';
import { useShortcutService } from 'services/ShortcutService/useShortcutService';
import { Vendor } from 'types/enums';
import { IFinding, ISvg } from 'types/interfaces';
import { Ticket } from 'types/interfaces/TicketManager/Ticket';

export type OpenTicket = (integrationId: string, title: string, findings: IFinding[]) => Promise<AxiosResponse<Ticket> | undefined>;

interface IVendorTicketConfig {
  icon: FC<ISvg>;
  openTicket: OpenTicket;
  ticketName: string;
}

export const useVendorTicketConfig = (): { [key: string]: IVendorTicketConfig } => {
  const { createTicket: createJiraTicket } = useJiraService();
  const { createTicket: createLinearTicket } = useLinearService();
  const { createTicket: createShortcutTicket } = useShortcutService();

  return {
    [Vendor.Jira]: {
      icon: JiraIconOutlined,
      openTicket: (integrationId: string, title: string, findings: IFinding[]) => createJiraTicket(integrationId, title, generateADF(findings)),
      ticketName: 'tickets.jira.ticketName',
    },
    [Vendor.Shortcut]: {
      icon: ShortcutIconOutlined,
      openTicket: (integrationId: string, title: string, findings: IFinding[]) => createShortcutTicket(integrationId, title, generateMarkdown(findings)),
      ticketName: 'tickets.shortcut.ticketName',
    },
    [Vendor.LINEAR]: {
      icon: LinearIconIris,
      openTicket: (integrationId: string, title: string, findings: IFinding[]) => createLinearTicket(integrationId, title, generateMarkdown(findings)),
      ticketName: 'tickets.linear.ticketName',
    },
  };
};
