import { useCallback } from 'react';

import { useClient } from 'services/useClient';
import { IGetIntegrationsResponse } from 'types/interfaces/Integrations';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

const serviceName = 'integration';

export const useIntegrationsService = () => {
  const { client } = useClient();

  const getAllIntegrations = useCallback(async () => {
    const url = `${serviceName}/`;
    const response = await client.get<IGetIntegrationsResponse>({
      url,
      allowedStatuses: [200, 404],
    });

    if (response?.status === 200) {
      response.data = camelizeSnakeCaseKeys(response.data) as IGetIntegrationsResponse;
    }
    return response;
  }, [client]);

  return {
    getAllIntegrations,
  };
};
