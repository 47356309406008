import Markdown from 'markdown-to-jsx';
import { FC, useCallback } from 'react';

import { FindingDetailViewContent } from '..';

import styles from './FindingDetailViewDetailsContext.module.scss';

import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { config } from 'config';
import { useThemeContext } from 'context';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { IFinding } from 'types/interfaces';
import { markdownLinksProps } from 'utils/constants/markdownLinksProps';

interface Props {
  finding: IFinding;
}

export const FindingDetailViewDetailsContext: FC<Props> = ({ finding }) => {
  const { palette } = useThemeContext();
  const { t } = i18n;

  const RefUrl = useCallback(
    () => (
      <>
        <JitText
          bold
          className={styles.sectionTitle}
          data-testid='learnMoreAboutThisIssue'
          size='l'
          text='pages.findings.findingDetails.detailsContext.learnMoreAboutThisIssue'
        />

        {finding.referencesUrls.map((url, index) => (
          <JitTooltip
            key={url}
            title={url}
          >
            <a
              className={styles.referenceLink}
              href={url}
              rel='noreferrer'
              target='_blank'
            >
              <JitText
                className={styles.referenceText}
                color={colors.blue02}
                text={t('pages.findings.findingDetails.detailsContext.linkName', { number: index + 1 }) as string}
              />
            </a>
          </JitTooltip>
        ))}
      </>

    ),
    [finding.referencesUrls, t],
  );

  const FileName = useCallback(
    () => {
      const { codeAttributes, organization, repository, location } = finding;
      const { filename = '', branch = '', lineRange = '' } = codeAttributes || {};
      const [lineStart, lineEnd] = lineRange.split('-');
      const githubFilePathLink = config.getGithubFilePathLink({
        org: organization,
        repo: repository,
        branch,
        path: filename,
        lineStart,
        lineEnd,
      });
      return (
        <div style={{ marginTop: 12 }}>
          <JitText text='pages.findings.findingDetails.detailsContext.fileName' />

          <a data-testid='findingLocation' href={location || githubFilePathLink} rel='noreferrer' target='_blank'>

            <JitText
              color={colors.blue02}
              style={{
                lineHeight: '18px',
                fontWeight: '400',
              }}
              text={finding.codeAttributes?.filename}
            />
          </a>
        </div>
      );
    },
    [finding],
  );

  const LineRange = useCallback(
    () => {
      let keyText = 'Lines: ';
      let lineRange = finding.codeAttributes?.lineRange || '';
      const isSingleLine = lineRange.split('-')[0] === lineRange.split('-')[1];
      if (isSingleLine) {
        [lineRange] = lineRange.split('-');
        keyText = 'Line: ';
      }
      return (
        <div style={{ marginTop: 8 }}>
          <span>{keyText}</span>

          <span style={{
            lineBreak: 'anywhere',
            color: palette.text.primary,
          }}
          >
            {lineRange}
          </span>
        </div>
      );
    },
    [finding, palette],
  );

  const DetailsSection = useCallback(
    () => (
      <div
        data-testid='DetailsSection'
        style={{
          color: palette.text.primary,
          paddingBottom: '8px',
        }}
      >
        <Markdown {...markdownLinksProps}>
          {finding.issueText}
        </Markdown>
      </div>
    ),
    [finding.issueText, palette.text.primary],
  );

  const shouldShowDetailsSection = !finding.appAttributes && !!finding.issueText;

  return (
    <FindingDetailViewContent title='pages.findings.findingDetails.detailsContext.title'>
      { shouldShowDetailsSection && <DetailsSection />}

      {finding.referencesUrls.length > 0 && <RefUrl />}

      {finding.codeAttributes?.filename && <FileName />}

      {finding.codeAttributes?.lineRange && <LineRange />}

    </FindingDetailViewContent>
  );
};
