import { useCallback } from 'react';

import { useConfigurationsContext } from '../ConfigurationsContext';

import { useHasConfigurationsChanged } from 'context/ConfigurationsContext/hooks/useHasConfigurationsChanged/useHasConfigurationsChanged';
import { IActiveToast } from 'context/ToastsContext/interfaces';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { usePlanService } from 'services/PlanService/usePlanService';
import { ToastType } from 'types/enums';

export const useCommitConfigurations = (toastShowOnSuccess?: IActiveToast) => {
  const { configurations, configurationsCommit, setConfigurationsCommit, setFetchedConfigurations, setIsCommittingConfigurations } = useConfigurationsContext();
  const { hasConfigurationsChanged } = useHasConfigurationsChanged();
  const { showToast } = useToastsContext();
  const { updateConfigurations } = usePlanService();

  return useCallback(
    async () => {
      if (hasConfigurationsChanged()) {
        setIsCommittingConfigurations(true);
        await updateConfigurations(configurations, configurationsCommit).then(
          (response) => {
            if (response?.data) {
              setConfigurationsCommit(response.data.content.sha);
              setFetchedConfigurations(configurations);
              setIsCommittingConfigurations(false);
              if (toastShowOnSuccess) {
                showToast(toastShowOnSuccess);
              }
            }
          },
        ).catch(
          (error) => {
            if (error?.response?.status === 409) {
              showToast({
                type: ToastType.Error,
                overrideProps: {
                  title: 'toasts.configurationConflict.title',
                  subtitle: 'toasts.configurationConflict.subtitle',
                },
              });
              setIsCommittingConfigurations(false);
            }
          },
        );
      }
    },
    [configurations, configurationsCommit, hasConfigurationsChanged, setConfigurationsCommit,
      setFetchedConfigurations, setIsCommittingConfigurations, showToast, toastShowOnSuccess, updateConfigurations],
  );
};
