import { Dispatch, FC, SetStateAction } from 'react';

import styles from './GoalsStepContent.module.scss';

import { JitText } from 'components/JitText/JitText';
import { GoalCardsList } from 'pages/OnboardingPage/components/OnboardingWizard/components/GoalsStepContent/components/GoalsList';
import colors from 'themes/colors.module.scss';
import { OnboardingDetailsContent } from 'types/interfaces';

interface Props {
  readonly setOnboardingDetails: Dispatch<SetStateAction<OnboardingDetailsContent>>,
}

export const GoalsStepContent: FC<Props> = ({ setOnboardingDetails }) => (
  <div className={styles.content}>
    <div className={styles.header}>
      <JitText bold color={colors.black} data-testid='goalsStep-title' size='xxxxl' text='pages.onboarding.goalsStep.title' />

      <JitText color={colors.black} data-testid='goalsStep-subtitle' size='l' text='pages.onboarding.goalsStep.subtitle' />
    </div>

    <GoalCardsList setOnboardingDetails={setOnboardingDetails} />
  </div>
);
