export enum WebSocketNotificationTopics {
  Asset = 'asset',
  Plan = 'plan',
  PlanItem = 'plan_item',
  StatusItem = 'status_item',
  Installation = 'installation',
  Pipeline = 'pipeline',
  PrUpdates = 'pr_updates',
  ActionUpdates = 'action_updates',
  ActionFinding = 'action_finding_updates',
  Preferences = 'preferences',
  Deployment = 'deployment',
  Snapshot = 'snapshot',
  Secret = 'secret',
  Integration = 'integration',
  FileMetadata = 'file_metadata',
  GithubStatus = 'github_status',
  SbomStatusChange = 'sbom-status-change',
  SbomFirstReportGenerated = 'sbom-first-org-report-change',

}
