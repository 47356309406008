import { useMemo } from 'react';

import { ScoreCell } from './Cells/ScoreCell/ScoreCell';
import { SortableHeader } from './Headers/SortableHeader';

import { TextCell } from 'components/JitTable/templates';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { i18n } from 'locale/i18n';
import { MembersCell } from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/Cells/MembersCell/MembersCell';
import { NameCell } from 'pages/TeamsPage/SubPages/TeamsPage/components/TeamsTable/Cells/NameCell/NameCell';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';
import { TeamSortBy } from 'types/interfaces/Teams/TeamSorting';
import { TableColumn } from 'types/types';

interface TableColumnsNames {
  name: string;
  members: string;
  resources: string;
  score: string;
}

export const useGetColumns = (): TableColumn<object>[] => {
  const { t } = i18n;
  const { sortedBy, setSortedBy, sortOrder, setSortOrder } = useTeamsContext();
  const columnHeaders: TableColumnsNames = useMemo(() => t('pages.teams.teamsTable.columns', { returnObjects: true }), [t]);

  // @ts-ignore The whole tables related logic uses object as type, this should be fixed
  const columns: TableColumn<object>[] = useMemo(() => [
    {
      id: columnHeaders.name,
      Header: () => (
        <SortableHeader
          columnName={columnHeaders.name}
          setSortedBy={setSortedBy}
          setSortOrder={setSortOrder}
          sortedBy={sortedBy}
          sortOrder={sortOrder}
          sortParameter={TeamSortBy.NAME}
        />
      ),
      accessor: (team: IGetTeamResponseItem) => ({
        name: team.name,
        description: team.description,
      }),
      Cell: NameCell,
      width: '60%',
    },
    {
      id: columnHeaders.score,
      // eslint-disable-next-line react/no-multi-comp
      Header: () => (
        <SortableHeader
          columnName={columnHeaders.score}
          setSortedBy={setSortedBy}
          setSortOrder={setSortOrder}
          sortedBy={sortedBy}
          sortOrder={sortOrder}
          sortParameter={TeamSortBy.SCORE}
        />
      ),
      accessor: (team: IGetTeamResponseItem) => team.score,
      width: '10%',
      Cell: ScoreCell,
    },
    {
      Header: columnHeaders.members,
      accessor: (team: IGetTeamResponseItem) => ({
        members: team.members,
        count: team.membersCount,
      }),
      Cell: MembersCell,
      width: '30%',
    },
    {
      Header: columnHeaders.resources,
      accessor: (team: IGetTeamResponseItem & { assetsCount: number }) => team.assetsCount,
      Cell: TextCell,
      width: '10%',
    },
  ], [columnHeaders.members, columnHeaders.name, columnHeaders.resources, columnHeaders.score, setSortOrder, setSortedBy, sortOrder, sortedBy]);

  return columns;
};
