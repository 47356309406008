import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './TeamDetailsPage.module.scss';

import { JittyTheExplorer } from 'assets';
import { GeneralErrorPage } from 'components/GeneralErrorPage';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { constants } from 'globalConstants';
import { Actions } from 'pages/OverviewPage/components';
import { CollapseSection } from 'pages/OverviewPage/components/CollapseSection/CollapseSection';
import { FindingsCountBanner } from 'pages/TeamsPage/components/FindingsCountBanner/FindingsCountBanner';
import { TeamsBreadcrumbs } from 'pages/TeamsPage/components/TeamsBreadcrumbs/TeamsBreadcrumbs';
import { ChildTeamsList } from 'pages/TeamsPage/SubPages/TeamDetailsPage/components/ChildTeamsList/ChildTeamsList';
import { MembersList } from 'pages/TeamsPage/SubPages/TeamDetailsPage/components/MembersList/MembersList';
import { ResourcesTable } from 'pages/TeamsPage/SubPages/TeamDetailsPage/components/ResourcesTable/ResourcesTable';
import { ScoreBanner } from 'pages/TeamsPage/SubPages/TeamDetailsPage/components/ScoreBanner/ScoreBanner';
import { Seperator } from 'pages/TeamsPage/SubPages/TeamDetailsPage/components/Seperator/Seperator';
import { SetSlackNotification } from 'pages/TeamsPage/SubPages/TeamDetailsPage/components/SetSlackNotification/SetSlackNotification';
import { useFetchActions, useFetchActionsCount } from 'services/ActionService/useFetchActions';
import { fetchFindingsCount } from 'services/FindingsService/FindingsService';
import { IFindingsCount } from 'types/interfaces/Findings/IFindingsCount';

export const TeamDetailsPage: FC = () => {
  const [findingsCount, setFindingsCount] = useState<number>();
  const { getSpecificTeamById, specificTeam } = useTeamsContext();
  const { actions, isLoading: isLoadingActions, getActions } = useFetchActions();
  const { actionsCount, isLoading: isLoadingActionsCount, getActionsCount } = useFetchActionsCount();
  const { teamId } = useParams();
  const navigate = useNavigate();
  const { uiShowActionFilters } = useFlags();

  useEffect(() => {
    if (teamId) {
      getSpecificTeamById(teamId);
    }
  }, [teamId]); // eslint-disable-line react-hooks/exhaustive-deps -- We want to run this only once

  useEffect(() => {
    if (!specificTeam.team) return;
    fetchFindingsCount({
      filters: {
        team: [specificTeam.team.name],
        resolution: ['OPEN'],
        ignored: [false],
      },
    }).then((res) => {
      const response = res as IFindingsCount;
      setFindingsCount(response?.count);
    });
  }, [specificTeam]);

  useEffect(() => {
    getActionsCount();
    getActions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps -- We want to run this only once

  if (specificTeam.isLoadingSpecificTeam || isLoadingActions || isLoadingActionsCount) return <LoadingBar />;

  const handleFindingBannerClick = () => {
    navigate(`/${constants.routes.FINDINGS}?team=${specificTeam.team!.name}&resolution=OPEN&ignored=false`);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.breadcrumbWrapper}>
        <TeamsBreadcrumbs teamId={specificTeam?.team?.id} teamName={specificTeam?.team?.name} />

        {specificTeam.team?.name && <SetSlackNotification teamName={specificTeam.team.name} />}
      </div>

      {specificTeam.team ? (
        <>
          <div className={styles.pageTitleWrapper}>
            <PageTitles subtitle={specificTeam.team.description} title={specificTeam.team.name} />

            <div className={styles.teamStats}>
              <ScoreBanner score={specificTeam.team.score} />

              <FindingsCountBanner findingsCount={findingsCount} onClick={handleFindingBannerClick} />
            </div>
          </div>

          {uiShowActionFilters && (
            <>
              <div className={styles.seperatorWrapper}>
                <Seperator />
              </div>

              <CollapseSection testId='Actions-wrapper' title='actions.title'>
                <Actions actions={actions.data} count={actionsCount || 0} teamName={specificTeam.team.name} />
              </CollapseSection>
            </>
          )}

          <div className={styles.seperatorWrapper}>
            <Seperator />
          </div>

          <div className={styles.content}>

            <ResourcesTable teamAssets={specificTeam.resources.data} teamId={specificTeam.team.id} />

            <MembersList members={specificTeam.members} />

            <ChildTeamsList childTeams={specificTeam.childTeams} />
          </div>
        </>
      ) : (
        <GeneralErrorPage
          backgroundColor='transparent'
          description='pages.teams.teamDetails.notFound.description'
          icon={JittyTheExplorer}
          isFullPage={false}
          title='pages.teams.teamDetails.notFound.title'
        />
      )}

    </div>
  );
};
