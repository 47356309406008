import { FC } from 'react';

import styles from './JitExternalLink.module.scss';

import { OpenInNewTab } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IAnalyticsEvent } from 'types/interfaces';

interface Props {
  href: string;
  text: string;
  'data-testid'?: string
  size?: 's' | 'm' | 'l';
  className?: string;
  wrapperClassName?: string;
  analytics?: IAnalyticsEvent
  textColor?: string;
  onClick?: () => void;
}

export const JitExternalLink: FC<Props> = ({ href, text, size, className, wrapperClassName, analytics, textColor = colors.iris, onClick, ...props }) => (
  <JitLink
    analytics={analytics}
    className={wrapperClassName}
    data-testid={props['data-testid'] || 'jit-external-link'}
    href={href}
    onClick={onClick}
    rel='noreferrer'
    style={{ textDecoration: 'none' }}
    target='_blank'
  >
    <div className={className ? `${styles.linkContent} ${className}` : styles.linkContent}>
      <JitText
        color={textColor}
        data-testid='jit-external-link-text'
        size={size}
        text={text}
      />

      <JitIcon color={textColor} data-testid='jit-external-link-icon' icon={OpenInNewTab} size={13} />
    </div>
  </JitLink>
);
