import { useMemo } from 'react';

import { CreateCentralizedRepo } from '../StepComponents/CreateCentralizedRepo/CreateCentralizedRepo';

import { IGettingStartedWizardStep, IGettingStartedWizardStepStatus } from 'components/GettingStartedWizard/GettingStartedWizard';
import { useAssetsContext } from 'context';

interface Props {
  isPrevStepCompleted: boolean;
}

export const useGetCreateCentralizedRepoStep = ({
  isPrevStepCompleted,
}: Props): IGettingStartedWizardStep => {
  const { centralizedRepo } = useAssetsContext();

  const status = useMemo(() => {
    if (isPrevStepCompleted) {
      if (centralizedRepo) {
        return IGettingStartedWizardStepStatus.COMPLETE;
      }
      return IGettingStartedWizardStepStatus.ACTIVE;
    }
    return IGettingStartedWizardStepStatus.NOT_STARTED;
  }, [centralizedRepo, isPrevStepCompleted]);

  return useMemo(() => ({
    title: 'gettingStartedWizard.createCentralizedRepo.title',
    component: () => (
      <CreateCentralizedRepo />
    ),
    status,
  }), [status]);
};
