import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { FC, useState } from 'react';

import styles from './JitAutoCompleteForm.module.scss';

import { Delete } from 'assets';
import colors from 'themes/colors.module.scss';
import { stopPropagation } from 'utils';

interface IProps<T> {
  placeHolder?: string
  options: T[]
  getOptionLabel?: (v: T) => string
  selected: T[]
  setSelected: (value: T[]) => void
}

/* This wrapper is here to support the border
of the TextField in the dialog */
const JitTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== 'focusColor',
// eslint-disable-next-line @typescript-eslint/no-unused-vars
})((p) => ({
  // input label when focused
  '& label.Mui-focused': {
    color: colors.iris,
    borderWidth: '1px',
  },
  // focused color for input with variant='standard'
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.iris,
    borderWidth: '1px',
  },
  // focused color for input with variant='filled'
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: colors.iris,
    borderWidth: '1px',
  },
  // focused color for input with variant='outlined'
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.iris,
      borderWidth: '1px',
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<T = any> = IProps<T>;

export const JitAutoCompleteForm: FC<Props> = ({ placeHolder, options, getOptionLabel, selected, setSelected }) => {
  const [textInput, setTextInput] = useState('');
  return (
    <FormControl
      className={styles.formControl}
      data-testid='formControl'
      fullWidth
      onClick={stopPropagation}
    >
      <Autocomplete
        autoHighlight
        filterSelectedOptions
        getOptionLabel={getOptionLabel}
        ListboxProps={
          {
            className: styles.listBox,
          }
        }
        multiple
        onChange={(event, value) => setSelected(value)}
        onInputChange={(event, value) => setTextInput(value)}
        options={options}
        renderInput={(params) => (
          <JitTextField
            {...params}
            autoFocus
            data-testid='share-input'
            InputLabelProps={{
              shrink: false,
            }}
            label={
              (textInput || selected.length) ? ' ' : placeHolder
              /* DO NOT CHANGE THE EMPTY PLACEHOLDER */
            }
            size='small'
          />
        )}
        renderOption={(props, option) => (
          <Box
            {...props}
            className={styles.optionBox}
            component='li'
            data-testid='li-box-option'
          >
            {getOptionLabel ? getOptionLabel(option) : option}
          </Box>
        )}
        renderTags={(values, getTagProps) => values.map((value, index) => (
          <Chip
            data-testid='tag-chip'
            {...getTagProps({ index })}
            key={getOptionLabel ? getOptionLabel(value) : value}
            clickable={false}
            deleteIcon={(<Delete />)}
            label={getOptionLabel ? getOptionLabel(value) : value}
            size='small'
            sx={{
              height: '20px',
              borderColor: colors.gray,
              borderWidth: '1px',
              borderRadius: '4px',
            }}
            variant='outlined'
          />
        ))}
      />
    </FormControl>
  );
};
