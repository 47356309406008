import { FC } from 'react';

import styles from './CustomAWSButton.module.scss';

import { Plus } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useConfigurationsContext, useDialogsContext } from 'context';
import colors from 'themes/colors.module.scss';
import { Dialogs, Vendor } from 'types/enums';

interface Props {
  showCreateNewIntegrationButton?: boolean;
}

export const CustomAWSButton: FC<Props> = ({
  showCreateNewIntegrationButton = true,
}) => {
  const { configurations } = useConfigurationsContext();
  const { showDialog, hideDialog } = useDialogsContext();

  const flipDialogs = () => {
    hideDialog(Dialogs.AssetsManagement);
    showDialog({
      type: Dialogs.Integrations,
      props: {
        vendor: Vendor.AWS,
        integration: configurations[Vendor.AWS.toString()],
      },
    });
  };

  return (
    <div>
      {showCreateNewIntegrationButton && (
        <div
          className={styles.addAwsAccountContainer}
          onClick={flipDialogs}
          role='button'
          tabIndex={0}
        >
          <JitIcon
            color={colors.blue02}
            icon={Plus}
            size='0.7em'
            style={{
              marginRight: '0.5rem',
              marginLeft: '0.5rem',
            }}
          />

          <JitText
            color={colors.blue02}
            text='dialogs.integration.aws.details.addAccount'
          />
        </div>
      )}
    </div>

  );
};
