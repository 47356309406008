import { FC, useCallback, useMemo, useState } from 'react';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { ConfirmDialog } from 'components/JitDialogs/ConfirmDialog';
import { useTenantContext } from 'context';
import { useGetPlanItemConfigurationsStatus } from 'context/ConfigurationsContext/hooks';
import { useActivatePlanItem } from 'context/PlansContext/hooks/useActivatePlanItem';
import { ActionButton, ActionButtonVariant, IPlanItemDetails } from 'types/interfaces';

interface ActivateAllButtonProps {
  planItems: IPlanItemDetails[];
}
export const ActivateAllButton: FC<ActivateAllButtonProps> = ({ planItems }) => {
  const { isConfigurationMissing } = useGetPlanItemConfigurationsStatus();
  const { isGithubIntegrated } = useTenantContext();
  const { activatePlanItems } = useActivatePlanItem();
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState<boolean>(false);
  const [isActivateLoading, setIsActivateLoading] = useState<boolean>(false);
  const planItemsToActivate = useMemo(
    () => planItems.filter((planItem) => (!isConfigurationMissing(planItem)) && !planItem.is_active),
    [planItems, isConfigurationMissing],
  );

  const handleActivateAllClick = () => {
    setIsActivateDialogOpen(true);
  };

  const actionButton: ActionButton = {
    label: 'pages.plan.listTitle.activateAll',
    tooltip: isGithubIntegrated ? '' : 'pages.plan.planItem.config.githubIntegrationRequired',
    handleClick: handleActivateAllClick,
    disabled: planItemsToActivate.length === 0 || isActivateLoading || !isGithubIntegrated,
    isLoading: isActivateLoading,
  };

  const handleActivateAllConfirmed = useCallback(async () => {
    setIsActivateLoading(true);
    await activatePlanItems(planItemsToActivate);
    setIsActivateLoading(false);
    setIsActivateDialogOpen(false);
  }, [activatePlanItems, planItemsToActivate]);

  const confirmActivateAllActionButtons: ActionButton[] = useMemo(() => [{
    label: 'dialogs.activateAllPlanItems.actions.no',
    variant: ActionButtonVariant.SECONDARY,
    handleClick: () => { setIsActivateDialogOpen(false); },
  }, {
    label: 'dialogs.activateAllPlanItems.actions.yes',
    variant: ActionButtonVariant.PRIMARY,
    handleClick: handleActivateAllConfirmed,
    analytics: {
      action: 'activate-all-confirmed',
      params: { 'plan-items': planItemsToActivate.map((planItem) => planItem.name) },
    },
    isLoading: isActivateLoading,
  }], [handleActivateAllConfirmed, isActivateLoading, planItemsToActivate]);

  const confirmActivateAllDialogProps = useMemo(() => ({
    title: 'dialogs.activateAllPlanItems.title',
    message: 'dialogs.activateAllPlanItems.message',
    subMessage: 'dialogs.activateAllPlanItems.subMessage',
    actionButtons: confirmActivateAllActionButtons,
    onClose: () => setIsActivateDialogOpen(false),
    isOpen: isActivateDialogOpen,
  }), [confirmActivateAllActionButtons, isActivateDialogOpen]);

  return (
    <>
      <JitActionButton actionButton={actionButton} />

      <ConfirmDialog {...confirmActivateAllDialogProps} />
    </>
  );
};
