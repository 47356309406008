import { FC } from 'react';
import { PolarViewBox } from 'recharts/types/util/types';

import { i18n } from 'locale/i18n';

interface Props {
  totalFindingsCount: number;
  viewBox?: PolarViewBox;
}

export const LabelContent: FC<Props> = ({ totalFindingsCount, viewBox }) => (
  <g data-testid='labelContent'>
    <text
      fill='#fff'
      style={{
        fontSize: '16px',
        fontWeight: 'bold',
      }}
      textAnchor='middle'
      x={viewBox?.cx}
      y={viewBox?.cy}
    >
      {totalFindingsCount}
    </text>

    <text
      dy={20}
      fill='#fff'
      style={{
        fontSize: '10px',
      }}
      textAnchor='middle'
      x={viewBox?.cx}
      y={viewBox?.cy}
    >
      {i18n.t('pages.performance.graphs.pieChartCountList.pieChart.label')}
    </text>
  </g>
);
