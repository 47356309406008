import { FC } from 'react';

import styles from './NavbarBeamer.module.scss';

import { Bell } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';

interface Props {
  color: string;
}

export const NavbarBeamer: FC<Props> = ({ color }) => (
  <JitIcon className={`beamerTrigger ${styles.beamerTrigger}`} color={color} icon={Bell} size={26} />
);
