import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ResourcePlanItemsStatusesTableData } from './ResourcePlanItemsStatusesPage';

import { usePlanTemplatesContext } from 'context';
import { useGetPlanItemsTemplatesBySlugs } from 'context/PlanTemplatesContext/hooks';
import { fetchFindingsCount } from 'services/FindingsService';
import { usePlanService } from 'services/PlanService/usePlanService';
import { IGroupByFindingsCount } from 'types/interfaces/Findings/IFindingsCount';
import { PlanItemAssetStatus, Status } from 'types/interfaces/PlanItemAssetStatus/IPlanItemAssetStatus';

export const useGetTableData = (resourceId: string, resourceName: string) => {
  const [resourcePlanItemsStatuses, setResourcePlanItemsStatuses] = useState<PlanItemAssetStatus[]>([]);
  const [planItemsFindingCount, setPlanItemsFindingCount] = useState<IGroupByFindingsCount[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const { isLoading: isLoadingPlanItemTemplates, fetchPlanItemTemplates, fetchControls, planItemsTemplates } = usePlanTemplatesContext();
  const { getPlanStatusesForAsset } = usePlanService();

  const { getPlanItemsTemplatesBySlugs } = useGetPlanItemsTemplatesBySlugs();

  const fetchPlanItemsFindingsCount = useCallback(async (planItems: string[]) => {
    const params = {
      group_by: 'plan_item',
      filters: {
        plan_item: planItems,
        asset_name: [resourceName],
        resolution: ['OPEN'],
        ignored: [false],
      },
    };

    const response = await fetchFindingsCount(params) as IGroupByFindingsCount[];
    setPlanItemsFindingCount(response || []);
  }, [resourceName]);

  useEffect(() => {
    if (isEmpty(planItemsTemplates) && !isLoadingPlanItemTemplates) {
      fetchPlanItemTemplates();
      fetchControls();
    }
  }, [fetchControls, fetchPlanItemTemplates, isLoadingPlanItemTemplates, planItemsTemplates]);

  const fetchData = useCallback(async () => {
    setIsLoadingData(true);
    const planItems = await getPlanStatusesForAsset(resourceId);
    // if there are no plan items, set empty table data
    if (!planItems) {
      setIsLoadingData(false);
      return;
    }
    setResourcePlanItemsStatuses(planItems);
    // fetch findings count for all the plan items with status FAILURE
    await fetchPlanItemsFindingsCount(
      planItems.filter((planItem) => planItem.status === Status.FAILURE)
        .map((planItem) => planItem.itemSlug),
    );
    setIsLoadingData(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const tableData = useMemo(() => {
    let data: ResourcePlanItemsStatusesTableData[] = [];
    const planItemDisplayNames = getPlanItemsTemplatesBySlugs(resourcePlanItemsStatuses.map((planItem) => planItem.itemSlug));
    if (!isLoadingData) {
      data = resourcePlanItemsStatuses.map((planItem: PlanItemAssetStatus) => {
        const findingsCount = planItemsFindingCount.find((finding) => finding.key === planItem.itemSlug)?.count || 0;
        return ({
          slug: planItem.itemSlug,
          name: planItemDisplayNames.find((planItemDisplayName) => planItemDisplayName.slug === planItem.itemSlug)?.name || '',
          passed: planItem.status === Status.SUCCESS,
          findingsCount,
        });
      });
    }

    return data;
  }, [getPlanItemsTemplatesBySlugs, resourcePlanItemsStatuses, isLoadingData, planItemsFindingCount]);

  const totalFindingsCount = useMemo(() => planItemsFindingCount.reduce((acc, finding) => acc + finding.count, 0), [planItemsFindingCount]);
  const isLoading = useMemo(() => isLoadingData || isLoadingPlanItemTemplates, [isLoadingData, isLoadingPlanItemTemplates]);
  return {
    isLoading,
    tableData,
    totalFindingsCount,
  };
};
