import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { GithubIntegrationWidget } from './components/GithubIntegrationWidget/GithubIntegrationWidget';
import { SecurityPlanTopRow } from './components/SecurityPlanTopRow/SecurityPlanTopRow';
import styles from './PlanPage.module.scss';

import { GithubIntegrationWizard } from 'components/GithubIntegrationWizard/GithubIntegrationWizard';
import { usePlanInstanceContext, useTenantContext } from 'context';
import { useFetchConfigurations } from 'context/ConfigurationsContext/hooks';
import { constants } from 'globalConstants';
import { PlanPageBody } from 'pages/PlanPage/components';
import { IPlanDetails } from 'types/interfaces';

interface Props {
  plan?: IPlanDetails;
}

export const PlanPage: FC<Props> = ({ plan }) => {
  const { setInitialPlan, hasPlanFetched } = usePlanInstanceContext();
  const { isGithubIntegrated } = useTenantContext();
  const { pathname } = useLocation();

  useFetchConfigurations();
  const { uiShowGithubIntegrationWidget } = useFlags();

  const isRedirectedBackFromGHInstallation = pathname === constants.GITHUB_INSTALLATION_REDIRECT_PATH;

  const [isInstallationWizardOpen, setIsInstallationWizardOpen] = useState<boolean>(!!isRedirectedBackFromGHInstallation);

  useEffect(() => {
    if (!hasPlanFetched) {
      setInitialPlan();
    }
  }, [hasPlanFetched, setInitialPlan]);

  if (!plan) {
    return null;
  }
  return (
    <div className={styles.container}>
      <SecurityPlanTopRow plan={plan} />

      {uiShowGithubIntegrationWidget && !isGithubIntegrated && (
      <GithubIntegrationWidget
        handleIntegrate={() => setIsInstallationWizardOpen((prevState) => !prevState)}
      />
      )}

      {uiShowGithubIntegrationWidget && isInstallationWizardOpen
                && <GithubIntegrationWizard handleClose={() => setIsInstallationWizardOpen(false)} />}

      <PlanPageBody planSlug={plan.slug} />
    </div>
  );
};
