import { createContext, useContext } from 'react';

import { AssetType, Vendor } from 'types/enums';
import { IAsset } from 'types/interfaces';

interface IAssetsContext {
  assets: IAsset[];
  centralizedRepo?: IAsset;
  repoAssets: IAsset[];
  awsAccountAssets: IAsset[];
  initAssets: () => void;
  getAssetById: (assetId: string) => IAsset | undefined;
  isLoadingAssets: boolean;
  getAssetsByVendor: (vendor: Vendor) => IAsset[];
  isAssetTypeProperlyIntegrated: (assetType: AssetType) => boolean;
}

export const AssetsContext = createContext<IAssetsContext>({
  initAssets: () => undefined,
  getAssetById: () => undefined,
  assets: [],
  repoAssets: [],
  awsAccountAssets: [],
  isLoadingAssets: false,
  getAssetsByVendor: () => [],
  isAssetTypeProperlyIntegrated: () => true,
});

export const useAssetsContext = () => useContext(AssetsContext);
