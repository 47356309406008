import { FC } from 'react';

import {
  SlackChannelNotificationsItem,
} from 'components/JitDialogs/SlackIntegrationDialog/components/SlackChannelNotificationsItem/SlackChannelNotificationsItem';
import { useTenantContext } from 'context';
import { useGetSlackChannels } from 'context/SlackContext/hooks/useGetSlackChannels';
import { useTenantService } from 'services/TenantService/useTenantService';
import { PreferencesScope } from 'types/enums/PreferencesScope';
import { TenantNotificationsPreferences } from 'types/interfaces';

export const NOTIFICATIONS_PREFERENCES: Record<string, keyof TenantNotificationsPreferences> = {
  HIGH_SEVERITY_FINDINGS: 'high_severity_findings',
  IGNORE_FINDINGS: 'ignore_findings',
  DEPLOYMENT_WITH_VULNERABILITIES: 'deployment_with_vulnerabilities',
  FINDINGS_ON_SAVED_VIEWS: 'findings_on_saved_views',
  NEW_ACTION_CREATED: 'new_action_created',
  OVERVIEW_REPORT: 'period_report',
  SECURITY_PLAN_FAILURES: 'security_plan_failures',
};

export const TenantSlackNotifications: FC = () => {
  const { slackChannels } = useGetSlackChannels();
  const { preferences, setPreferences } = useTenantContext();
  const { patchPreferences } = useTenantService();

  const allPreferences: Record<string, keyof TenantNotificationsPreferences> = NOTIFICATIONS_PREFERENCES;

  const updateNotificationPreferences = async (preferenceKey: string, enabled: boolean, channel?: string) => {
    const notificationsPreferencesParameter = preferenceKey as keyof TenantNotificationsPreferences;
    if (preferences) {
      setPreferences({
        ...preferences,
        notifications: {
          ...preferences?.notifications,
          [notificationsPreferencesParameter]: {
            channel,
            enabled,
          },
        },
      });
    }
    await patchPreferences<TenantNotificationsPreferences>(
      'notifications',
      {
        [notificationsPreferencesParameter]: {
          channel,
          enabled,
        },
      },
      PreferencesScope.TENANT,
    );
  };
  return (
    <div data-testid='slackChannelSection'>
      {Object.values(allPreferences).map((notification) => (
        <SlackChannelNotificationsItem
          key={notification}
          preferenceKey={notification}
          selected={preferences?.notifications[notification]}
          slackChannels={slackChannels || []}
          updateNotificationPreferences={updateNotificationPreferences}
        />
      ))}
    </div>
  );
};

