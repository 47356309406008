import { useCallback } from 'react';

import { useAddItemsToPlan } from './useAddItemsToPlan';

import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { usePlanInstanceContext } from 'context/PlanInstanceContext/PlanInstanceContext';
import { IPlanItemDetails } from 'types/interfaces';

export const useActivatePlanItem = () => {
  const { commitCurrentPlan } = usePlanInstanceContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { addItemsToPlan } = useAddItemsToPlan();

  const activatePlanItem = useCallback((planSlug: string, planItem: IPlanItemDetails) => {
    const updatedPlan = addItemsToPlan([planItem]);
    sendAnalyticsEvent({
      action: 'requirement-activated',
      params: {
        'item-name': [planItem.name],
        'plan-slug': planSlug,
        'is-manual': (planItem.is_manual || false).toString(),
      },
    });
    commitCurrentPlan(updatedPlan);
  }, [addItemsToPlan, commitCurrentPlan, sendAnalyticsEvent]);

  const activatePlanItems = useCallback((planItems: IPlanItemDetails[]) => {
    const updatedPlan = addItemsToPlan(planItems);
    sendAnalyticsEvent({
      action: 'multiple-plan-items-activated',
      params: { 'items-names': planItems.map((planItem) => planItem.name) },
    });
    commitCurrentPlan(updatedPlan);
  }, [addItemsToPlan, commitCurrentPlan, sendAnalyticsEvent]);

  return {
    activatePlanItem,
    activatePlanItems,
  };
};
