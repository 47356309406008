import { useState } from 'react';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

import { LINE_CHART_MARGIN_TOP_MAX, LINE_CHART_MARGIN_TOP_MIN } from './constants';

export const useClosestLineName = (yAxisTicks: number[]) => {
  const [closestLineName, setClosestLineName] = useState<string | null>(null);

  const onMouseMove = (e: CategoricalChartState) => {
    const { isTooltipActive, chartY, activePayload } = e;
    const hoveredPoints = activePayload?.filter((point) => point.value !== null && point.value !== undefined) || [];
    if (!isTooltipActive || hoveredPoints.length === 0 || chartY === undefined) {
      setClosestLineName(null);
      return;
    }
    const minPoint = LINE_CHART_MARGIN_TOP_MIN;
    const maxPoint = LINE_CHART_MARGIN_TOP_MAX;
    const normalizedMousePoint = 1 - (chartY - minPoint) / (maxPoint - minPoint);
    const graphMaxValue = yAxisTicks[yAxisTicks.length - 1];
    const normalizedPointsValues = hoveredPoints.map(({ value }) => value / graphMaxValue);
    const distancesPromPoints = normalizedPointsValues.map((value) => Math.abs(normalizedMousePoint - value));
    const closestPointIndex = distancesPromPoints.indexOf(Math.min(...distancesPromPoints));
    const closestPoint = hoveredPoints[closestPointIndex];
    setClosestLineName(closestPoint.name);
  };

  return { closestLineName,
    onMouseMove };
};
