import { ChangeEvent, FC } from 'react';

import styles from './ConfigurationsZapInputFields.module.scss';

import { FieldsTextInterface } from 'components/Configurations/Zap/components/ConfigurationsZapField';
import { LabelAndTooltip } from 'components/Configurations/Zap/components/LabelAndTooltip';
import { JitPasswordInput } from 'components/JitPasswordInput/JitPasswordInput';
import { i18n } from 'locale/i18n';
import { ZapAuthenticationConfigType } from 'types/interfaces';

interface Props {
  password: string;
  handleChangeInput: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChange: (key: string, value: string) => void;
  inputField: string;
  isMultiLine?: boolean;
  type: ZapAuthenticationConfigType;
  onShowPasswordChange?: (newShowPassword: boolean) => void;
  clearPassword?: () => void;
}

export const ConfigurationsZapWebPassword: FC<Props> = ({
  password, handleChangeInput, inputField, isMultiLine, type, onShowPasswordChange, handleChange, clearPassword,
}) => {
  const fieldsText: FieldsTextInterface = i18n.t(`configurations.zap.${type}.fields.${inputField}`, { returnObjects: true });

  const isAlreadyEncrypted = password?.startsWith('${{');

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isAlreadyEncrypted) {
      return;
    }
    handleChangeInput(event);
  };

  const handleClearPassword = () => {
    if (clearPassword) clearPassword();
    else handleChange(inputField, '');
  };

  return (
    <div className={styles.credentialInputField}>
      <LabelAndTooltip label={fieldsText.label} tooltipText={fieldsText.info} />

      <JitPasswordInput
        clearPassword={handleClearPassword}
        disabled={isAlreadyEncrypted}
        inputProps={{
          className: `mb-2 ${styles.inputFieldBox}`,
          disableUnderline: true,
          fullWidth: true,
          name: 'password',
          dataTestid: inputField,
          onChange: handleValueChange,
          placeholder: fieldsText.placeholder,
          value: password,
        }}
        isMultiLine={isMultiLine}
        onShowPasswordChange={onShowPasswordChange}
        preventShowPassword={isAlreadyEncrypted}
        preventShowPasswordTooltipText='The password is already encrypted, press the "Clear" to change it'
      />
    </div>
  );
};
