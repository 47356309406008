import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DEFAULT_PLAN_SLUG } from './constants';
import { PlanPage } from './PlanPage';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { usePlansContext } from 'context';

interface Props {
  planSlug?: string;
}

export const PlanPageWrapper: FC<Props> = ({ planSlug }) => {
  const { planSlug: paramsSlug } = useParams();
  const selectedPlanSlug = paramsSlug || planSlug;
  const lowerCaseSelectedPlanSlug = selectedPlanSlug?.toLowerCase();
  const [isPlanFound, setIsPlanFound] = useState<boolean | null>(null);
  const { plans, fetchPlanDetails, fetchPlans, hasFetchedPlans } = usePlansContext();

  const shouldUseDefault = !lowerCaseSelectedPlanSlug || isPlanFound === false;

  const fetchDefaultPlan = useCallback(() => {
    if (!plans[DEFAULT_PLAN_SLUG]?.hasFetchedItems) {
      fetchPlanDetails(DEFAULT_PLAN_SLUG);
    }
  }, [fetchPlanDetails, plans]);

  useEffect(() => {
    setIsPlanFound(null);
  }, [lowerCaseSelectedPlanSlug]);

  useEffect(() => {
    if (!hasFetchedPlans) {
      fetchPlans();
      return;
    }
    if (shouldUseDefault) {
      fetchDefaultPlan();
    }
    if (!shouldUseDefault && !isPlanFound && Object.keys(plans).length > 0) {
      if (plans[lowerCaseSelectedPlanSlug]?.hasFetchedItems) {
        setIsPlanFound(true);
      } else {
        fetchPlanDetails(lowerCaseSelectedPlanSlug).then((isFound) => {
          setIsPlanFound(isFound);
        });
      }
    }
    // TODO: restore dependencies once the useClient does not cause re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlanFound, lowerCaseSelectedPlanSlug, plans, shouldUseDefault, hasFetchedPlans]);

  const plan = useMemo(() => (shouldUseDefault ? plans[DEFAULT_PLAN_SLUG] : plans[lowerCaseSelectedPlanSlug]), [lowerCaseSelectedPlanSlug, plans, shouldUseDefault]);

  return (
    plan ? <PlanPage plan={plan} /> : <LoadingBar />
  );
};
