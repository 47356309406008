import { AdminPortal } from '@frontegg/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { NavbarBeamer } from '../components';

import { getNotHiddenTabs } from './utils';

import { ITabItem, Tab, TabItemTypes } from '.';

import { Docs, Link, Settings } from 'assets';
import { config } from 'config';
import { useAuthContext, useDialogsContext, useSecretsContext, useStiggExtendedContext } from 'context';
import { Dialogs } from 'types/enums';
import { hideApiTokensManagement } from 'utils/functions/fronteggUtils';
import { ROLES } from 'wrappers/RBAC/constants';

export const useGetBottomTabItems = () => {
  const { showDialog } = useDialogsContext();
  const navigate = useNavigate();
  const { frontEggUser } = useAuthContext();
  const { setShowSecretsManagement } = useSecretsContext();
  const { setShowStiggPayWall } = useStiggExtendedContext();
  const isAdminOrMember = frontEggUser.roles.some((role) => role.key !== ROLES.DEVELOPER);
  const { uiShowGenerateTokenTab, uiShowPaymentPage, isTenantPaid } = useFlags();

  const settingsItemsTabs: ITabItem[] = useMemo(
    () => [{
      type: TabItemTypes.item,
      onClick: () => { showDialog({ type: Dialogs.AssetsManagement }); },
      name: 'navbar.buttons.manageResources',
    }, {
      type: TabItemTypes.item,
      onClick: () => {
        AdminPortal.show();
        if (!uiShowGenerateTokenTab) {
          hideApiTokensManagement();
        }
        navigate('#/admin-box/users');
      },
      name: 'navbar.buttons.usersAndPermissions',
    }, {
      type: TabItemTypes.item,
      onClick: () => { setShowSecretsManagement(true); },
      name: 'navbar.buttons.secrets',
    }, {
      type: TabItemTypes.item,
      onClick: () => { setShowStiggPayWall(true); },
      name: 'navbar.buttons.subscription',
      shouldHide: isTenantPaid || !uiShowPaymentPage,
    },
    ],

    [
      navigate,
      setShowSecretsManagement,
      setShowStiggPayWall,
      showDialog,
      uiShowGenerateTokenTab,
      uiShowPaymentPage,
      isTenantPaid,
    ],
  );

  const bottomTabs: Tab[] = useMemo(() => [
    {
      type: TabItemTypes.item,
      name: 'navbar.buttons.whatsNew',
      icon: NavbarBeamer,
      // @ts-ignore We should always have the global Beamer instance (imported from the index.html)
      onClick: () => window.Beamer.show(),
    },
    {
      type: TabItemTypes.item,
      onClick: () => { window.open(config.docsUrl, '_blank'); },
      name: 'navbar.buttons.docs',
      icon: Docs,
      rightSideIcon: Link,
    },
    {
      type: TabItemTypes.group,
      name: 'navbar.buttons.settings',
      icon: Settings,
      shouldHide: !isAdminOrMember,
      items: settingsItemsTabs,
    },
  ], [isAdminOrMember, settingsItemsTabs]);

  return {
    bottomTabs: getNotHiddenTabs(bottomTabs),
  };
};
