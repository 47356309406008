import { useCallback, useEffect } from 'react';

import { useSlackContext } from '../SlackContext';

import { getSlackUsers } from 'services/SlackService/SlackService';

export const useGetSlackUsers = () => {
  const { slackUsers, setSlackUsers } = useSlackContext();

  const initSlackUsers = useCallback(async () => {
    const slackUsersResponse = await getSlackUsers();
    setSlackUsers(slackUsersResponse);
  }, [setSlackUsers]);

  useEffect(() => {
    if (!slackUsers?.length) {
      initSlackUsers();
    }
  }, [initSlackUsers, slackUsers]);

  return {
    slackUsers,
  };
};
