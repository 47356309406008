import { FC, useState } from 'react';

import { NoIntegrationButton } from './components/NoIntegrationButton';
import styles from './CreateTicketButton.module.scss';
import { useVendorTicketConfig } from './VendorConfig';

import { useCreateTicket } from 'components/CreateTicketButton/hooks/useCreateTicket';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useAuthContext } from 'context';
import { useGetActiveIntegration } from 'context/IntegrationsContext/hooks/useGetActiveIntegration';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { IFinding } from 'types/interfaces';
import { PERMISSIONS } from 'wrappers/RBAC';

interface Props {
  onClose: () => void;
  findings: IFinding[];
}

export const CreateTicketButton: FC<Props> = ({ onClose, findings }) => {
  const [isInProgress, setIsInProgress] = useState(false);
  const VendorTicketConfig = useVendorTicketConfig();
  const { hasPermission } = useAuthContext();
  const {
    isLoading: isIntegrationsLoading,
    activeIntegration,
  } = useGetActiveIntegration((integration) => integration.vendor in VendorTicketConfig);
  const { createStory } = useCreateTicket();

  const hasWriteTicketPermission = hasPermission(PERMISSIONS.WRITE_TICKETS);

  if (!activeIntegration) return <NoIntegrationButton />;

  const vendorConfig = VendorTicketConfig[activeIntegration.vendor as keyof typeof VendorTicketConfig];

  const handleCreateStory = async () => {
    if (!activeIntegration) return;
    setIsInProgress(true);
    await createStory(findings, activeIntegration.integrationId, vendorConfig.openTicket);
    setIsInProgress(false);
    onClose();
  };

  return (
    <div className={styles.wrapper}>
      <JitButton
        data-testid='create-ticket-button'
        disabled={isInProgress || !activeIntegration || isIntegrationsLoading}
        isLoading={isInProgress}
        isReadOnly={!hasWriteTicketPermission}
        noHover
        onClick={handleCreateStory}
        sx={{
          ':hover': {
            borderColor: colors.iris,
          },
        }}
        variant='outlined'
      >
        <div className={styles.button}>
          <JitIcon color={colors.iris} data-testid='integration-vendor-icon' icon={vendorConfig.icon} size='14px' strokeColor={hasWriteTicketPermission ? colors.iris : colors.lightGray} />

          <JitText
            color={!isInProgress && hasWriteTicketPermission ? colors.iris : colors.grey}
            data-testid='create-ticket-button-text'
            params={{ ticketName: i18n.t(vendorConfig.ticketName).toString() }}
            text='tickets.baseText'
          />
        </div>

      </JitButton>
    </div>
  );
};
