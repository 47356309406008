import { FC } from 'react';

import { AnalyticsEvents } from '../constants';

import styles from './ErrorBanner.module.scss';

import { Alert } from 'assets';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useTenantContext } from 'context';
import colors from 'themes/colors.module.scss';
import { RepositorySelection } from 'types/interfaces';

interface Props {
  title: string;
  onCreateRepoClick: () => void;
}

export const ErrorBanner: FC<Props> = ({ title, onCreateRepoClick }) => {
  const { githubInstallation } = useTenantContext();
  const isInstallationSelectedType = githubInstallation?.vendor_attributes?.repository_selection === RepositorySelection.SELECTED;
  return (
    <div>
      <JitInfoBanner bgColor={colors.cards04} data-testid='error-banner' icon={Alert} iconColor={colors.error}>
        <div className={!isInstallationSelectedType ? styles.errorBannerWrapper : undefined}>
          <JitText
            data-testid='error-banner-title'
            display='inline'
            size='s'
            text={title}
          />

          <JitExternalLink
            analytics={{ action: AnalyticsEvents.CentralizedRepoCreateRepoClicked }}
            className={styles.linkContent}
            href={config.createNewGithubRepo}
            onClick={onCreateRepoClick}
            size='s'
            text='gettingStartedWizard.createCentralizedRepo.card.errorBannerCreateRepo'
          />

          {isInstallationSelectedType && (
            <>
              <JitText display='inline' size='s' text=' and ' />

              <JitExternalLink
                analytics={{ action: AnalyticsEvents.CentralizedRepoEditAccessClicked }}
                className={styles.linkContent}
                href={githubInstallation?.vendor_attributes?.html_url || config.getGithubAppPermissionsUrl(githubInstallation.owner, githubInstallation.installation_id)}
                size='s'
                text='gettingStartedWizard.createCentralizedRepo.card.errorBannerEditPermissions'
              />
            </>
          )}
        </div>
      </JitInfoBanner>
    </div>
  );
};

