import { FC, ReactElement } from 'react';

import styles from './InsightsCard.module.scss';

import { CircleInfo } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';

export type InsightsCardSize = 's' | 'm' | 'l';
export type InsightsCardVariant = 'top' | 'bottom';

interface CardHeader {
  title: string;
  infoTooltip?: string;
}

export interface InsightsCardProps {
  header: CardHeader;
  content: ReactElement;
  footer: ReactElement;
  size?: InsightsCardSize;
  variant?: InsightsCardVariant;
}

const sizeToStyle = {
  s: { minWidth: '246px' },
  m: { minWidth: '260px' },
  l: { minWidth: '300px' },
};

const variantToStyle = {
  top: { borderRadius: '10px 10px 0 0' },
  bottom: { borderRadius: '0 0 10px 10px' },
};

export const InsightsCard: FC<InsightsCardProps> = ({ header, content, footer, size = 's', variant }) => (
  <div
    className={styles.wrapper}
    data-testid='InsightsCard'
    style={{ ...(variant ? variantToStyle[variant] : {}),
    }}
  >
    <div
      className={styles.contentWrapper}
      data-testid='InsightsCard-contentWrapper'
      style={sizeToStyle[size]}
    >
      <div className={styles.header}>
        <JitText bold data-testid='InsightsCard-header' size='l' text={header.title} />

        {header.infoTooltip && (
        <JitTooltip childrenWrapperClassName={styles.infoIcon} data-testid='InsightsCard-tooltip' followCursor={false} placement='top' title={header.infoTooltip}>
          <JitIcon color={colors.white} icon={CircleInfo} size={15} />
        </JitTooltip>
        )}
      </div>

      <div className={styles.content} data-testid='InsightsCard-content'>
        {content}
      </div>

      <div data-testid='InsightsCard-footer'>
        {footer}
      </div>
    </div>
  </div>
);
