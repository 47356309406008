import Chip from '@mui/material/Chip';
import { FC } from 'react';

import { TriangleUp, TriangleDown } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

export interface PercentageChipProperty {
  percentage?: number | null
  bgColor?: string;
  positiveTrendIndicatorDirection: 'up' | 'down'
}

export const PercentageChip: FC<PercentageChipProperty> = ({
  percentage,
  bgColor = colors.cards02,
  positiveTrendIndicatorDirection,
}) => {
  const less = i18n.t('PercentageChip.less');
  const more = i18n.t('PercentageChip.more');

  const positiveColor = colors.pipelineSuccessStatusText;
  const negativeColor = colors.pipelineFailedStatusText;
  const neutralColor = colors.gray;

  let direction = '';
  let title: string;
  let processedPercentage;
  let color = neutralColor;
  let icon;
  if (percentage === undefined || percentage === null) { // percentage is undefined when we don't have data for tenant
    processedPercentage = '--';
    title = i18n.t('PercentageChip.noData');
  } else {
    processedPercentage = percentage.toString().replace('-', '');
    if (percentage > 0) {
      color = positiveTrendIndicatorDirection === 'up' ? positiveColor : negativeColor;
      icon = TriangleUp;
      direction = more;
    } else if (percentage < 0) {
      color = positiveTrendIndicatorDirection === 'down' ? positiveColor : negativeColor;
      icon = TriangleDown;
      direction = less;
    }
    title = i18n.t('PercentageChip.tooltip', {
      percentage: processedPercentage,
      direction,
    });
  }
  const iconObj = icon ? (<JitIcon color={color} icon={icon} size={5} />) : undefined;
  return (
    <JitTooltip followCursor={false} placement='top' sx={{ padding: '3px' }} title={title}>
      <Chip
        icon={iconObj}
        label={`${processedPercentage}%`}
        size='small'
        sx={{
          color,
          backgroundColor: bgColor,
          borderRadius: '4px',
          fontSize: 12,
          minWidth: 37,
          fontWeight: 'bold',
          margin: '0px 11px',
        }}
      />
    </JitTooltip>
  );
};
