import Markdown from 'markdown-to-jsx';
import { FC } from 'react';

import { ItemTypeBadge } from '../../../../../ItemTypeBadge/ItemTypeBadge';
import { UseGetRelevantActivationButton } from '../../hooks/UseGetRelevantActivationButton/UseGetRelevantActivationButton';
import { PlanItemDetailsBodySection } from '../PlanItemDetailsBodySection/PlanItemDetailsBodySection';
import { PlanItemDetailsIntegrations } from '../PlanItemDetailsIntegrations/PlanItemDetailsIntegrations';

import styles from './PlanItemDetailsInfo.module.scss';

import { JitText } from 'components/JitText/JitText';
import { removeAllRedundantLinebreaks } from 'pages/PlanPage/utils/parseMarkdown';
import { Vendor } from 'types/enums';
import { IMouseEvent, IPlanItemDetails } from 'types/interfaces';
import { markdownLinksProps } from 'utils/constants/markdownLinksProps';
import { vendorsWithIntegration } from 'utils/constants/vendorIntegrations';
import { getVendorForAssetType } from 'utils/functions/matchVendorToAssetType';

interface Props {
  planItem: IPlanItemDetails;
  openDeactivateConfirmDialog: () => void;
  onActivate: (e: IMouseEvent) => void;
  isPlanItemActive: boolean;
  onConfigure: () => void;
  isItemConfigurable: boolean;
  onRedirect: () => void;
  isItemRedirecting: boolean,
}

export const PlanItemDetailsInfo: FC<Props> = ({ planItem, openDeactivateConfirmDialog, onActivate, isPlanItemActive, onConfigure, isItemConfigurable, onRedirect, isItemRedirecting }) => {
  const { actionButtons } = UseGetRelevantActivationButton(planItem, openDeactivateConfirmDialog, onActivate, isPlanItemActive, onConfigure, isItemConfigurable, onRedirect, isItemRedirecting);
  const hasIntegrations = planItem.asset_types !== undefined && planItem.asset_types.length > 0;

  const isVendorRequireIntegration = (vendor: Vendor | undefined) => vendor && vendorsWithIntegration.includes(vendor);
  const assetTypesWithIntegration = planItem?.asset_types?.filter((assetType) => isVendorRequireIntegration(getVendorForAssetType(assetType)));

  return (
    <>
      <PlanItemDetailsBodySection buttons={actionButtons} title='pages.plan.itemDetails.info.title'>
        <div className={styles.infoHeaderWrapper}>
          <ItemTypeBadge itemType={planItem.is_manual ? 'manual' : 'auto'} />

          <JitText bold text={planItem.name} />
        </div>
      </PlanItemDetailsBodySection>

      <div className={styles.content}>
        <div className={styles.text}>
          <Markdown {...markdownLinksProps}>
            {removeAllRedundantLinebreaks(planItem.description)}
          </Markdown>
        </div>

        <PlanItemDetailsBodySection title='pages.plan.itemDetails.info.whatWillJitDo'>
          <div className={styles.text}>
            <Markdown {...markdownLinksProps}>
              {removeAllRedundantLinebreaks(planItem.summary)}
            </Markdown>
          </div>
        </PlanItemDetailsBodySection>

        {hasIntegrations && !!assetTypesWithIntegration?.length && (
          <PlanItemDetailsBodySection title='pages.plan.itemDetails.integrations'>
            <div className={styles.bodySectionWrapper}>
              <PlanItemDetailsIntegrations assetTypes={planItem.asset_types || []} />
            </div>
          </PlanItemDetailsBodySection>
        )}
      </div>

    </>
  );
};
