import { client } from 'services/client';
import { ISlackServiceChannelResponse, ISlackUserResponse } from 'types/interfaces/Slack';

const serviceName = 'slack';

export const getSlackUsers = async (): Promise<ISlackUserResponse[] | undefined> => {
  const url = `${serviceName}/users`;
  return client.get<ISlackUserResponse[]>(url);
};

export const getSlackChannels = async (): Promise<ISlackServiceChannelResponse[] | undefined> => {
  const url = `${serviceName}/channels`;
  return client.get<ISlackServiceChannelResponse[]>(url);
};
