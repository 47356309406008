import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IPlan } from '../interfaces/IPlan';

import { usePlansContext } from 'context';
import { constants } from 'globalConstants';

export const useSecurityMapping = (initialState: IPlan[]) => {
  const navigate = useNavigate();
  const [securityPlans, setSecurityPlans] = useState<IPlan[]>(initialState);
  const { plans, fetchPlans, hasFetchedPlans } = usePlansContext();

  useEffect(() => {
    if (!hasFetchedPlans) {
      fetchPlans();
    }
  }, [hasFetchedPlans, fetchPlans]);

  useEffect(() => {
    if (plans) {
      // filter out plans that are not available for this user
      const filteredPlans = initialState.filter((plan) => plan.key in plans);
      setSecurityPlans(filteredPlans);
    }
  }, [plans, initialState]);

  const toggleSecurityPlanSelection = (id: string) => {
    const newSecurityPlans = securityPlans.map((plan) => {
      if (plan.key === id) {
        return {
          ...plan,
          isSelected: !plan.isSelected,
        };
      }
      return plan;
    });
    setSecurityPlans(newSecurityPlans);
  };

  const linkToPlan = (id: string) => {
    const plan = securityPlans.find((securityPlan) => securityPlan.key === id);

    if (plan) {
      navigate(`/${constants.routes.PLANS}/${plan.key}`);
    }
  };

  return {
    toggleSecurityPlanSelection,
    linkToPlan,
    securityPlans,
  };
};
