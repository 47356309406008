import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { InsightsCard, RemainingCard } from '../../baseComponents';
import { PULL_REQUESTS_ROUTE } from '../../constants';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IPullRequest, IPullRequestsAnalysis } from 'types/interfaces/PullRequests';

interface Props {
  prAnalysis: IPullRequestsAnalysis;
}

export const PullRequestsWithFindingsComponent: FC<Props> = ({ prAnalysis }) => {
  const failedPrsCategoriesNames = ['openPrsWithFailedChecks', 'mergedPrsWithFixedChecks', 'mergedPrsWithFailed', 'mergedPrsWithFailedIgnored'] as (keyof IPullRequestsAnalysis)[];
  const failedPrs = failedPrsCategoriesNames.reduce((prs: IPullRequest[], key) => ([...prs, ...(prAnalysis?.[key] || [])]), []);
  const failedPrsAmount = failedPrs.length;
  const navigate = useNavigate();
  const allPrsAmount = prAnalysis?.allPrs?.length || 0;

  return (
    <InsightsCard
      content={(
        <RemainingCard
          count={{ count: failedPrsAmount,
            color: failedPrsAmount === 0 ? colors.white : colors.failRed }}
          total={allPrsAmount}
        />
)}
      footer={(
        <JitText
          color={colors.iris}
          onClick={() => {
            navigate(PULL_REQUESTS_ROUTE);
          }}
          text='pages.overview.insights.viewPullRequests'
        />
)}
      header={{
        title: 'pages.overview.insights.pullRequestsWithFindings.title',
        infoTooltip: 'pages.overview.insights.pullRequestsWithFindings.tooltip',
      }}
      size='s'
      variant='bottom'
    />
  );
};
