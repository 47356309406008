import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';

import { AppLoadingBar } from 'components/AppLoadingBar/AppLoadingBar';
import { GettingStartedWizard } from 'components/GettingStartedWizard/GettingStartedWizard';
import { useTenantContext } from 'context';
import { AllDialogs } from 'wrappers';

export const Wrappers: FC = () => {
  const { hasTriedFetchInstallations } = useTenantContext();
  const { uiShowGithubIntegrationWidget } = useFlags();
  return (
    <>
      {!uiShowGithubIntegrationWidget && <GettingStartedWizard />}

      {(!hasTriedFetchInstallations) && <AppLoadingBar />}

      <AllDialogs />
    </>
  );
};
