import { FC, useCallback, useMemo, useState } from 'react';

import { TeamsContext } from 'context/TeamsContext/TeamsContext';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { useTeamsService } from 'services/TeamsService/useTeamsService';
import { IAsset } from 'types/interfaces/IAsset';
import { initialPaginatedState, IPaginatedState } from 'types/interfaces/IPaginatedState';
import { IGetTeamResponseItem, IMember, ITeam } from 'types/interfaces/Teams/ITeam';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';

export interface ISpecificTeamState {
  team?: ITeam;
  isLoadingSpecificTeam: boolean;
  members: IPaginatedState<IMember>;
  childTeams: IPaginatedState<ITeam>;
  resources: IPaginatedState<IAsset>;
}

export const TeamsProvider: FC = ({ children }) => {
  const [specificTeam, setSpecificTeam] = useState<ISpecificTeamState>({
    team: undefined,
    isLoadingSpecificTeam: false,
    members: initialPaginatedState,
    childTeams: initialPaginatedState,
    resources: initialPaginatedState,
  });
  const [teams, setTeams] = useState<IPaginatedState<IGetTeamResponseItem>>(initialPaginatedState);
  const [isLoadingLeadingTeams, setIsLoadingLeadingTeams] = useState<boolean>(false);
  const [leadingTeams, setLeadingTeams] = useState<IGetTeamResponseItem[]>([]);
  const [sortedBy, setSortedBy] = useState<TeamSortBy>(TeamSortBy.NAME);
  const [sortOrder, setSortOrder] = useState<TeamSortOrder>(TeamSortOrder.ASC);

  const { fetchTeams, fetchTeamById, fetchTeamChildren, fetchMembersById } = useTeamsService();
  const { getAssets } = useAssetService();

  const getSpecificTeamById = useCallback(async (teamId: string) => {
    setSpecificTeam((prevTeam) => ({
      ...prevTeam,
      isLoadingSpecificTeam: true,
      members: {
        ...prevTeam.members,
        isLoading: true,
      },
      childTeams: {
        ...prevTeam.childTeams,
        isLoading: true,
      },
      resources: {
        ...prevTeam.resources,
        isLoading: true,
      },
    }));
    const resolvedRequests = await Promise.all([
      fetchTeamById(teamId),
      fetchTeamChildren(teamId),
      fetchMembersById(teamId),
    ]);
    const [teamRes, childTeamsRes, membersRes] = resolvedRequests;
    setSpecificTeam((prevTeam) => ({
      ...prevTeam,
      team: teamRes,
      members: {
        ...prevTeam.members,
        isLoading: false,
        data: membersRes?.data || [],
        after: membersRes?.metadata?.after,
        hasReachedEnd: !membersRes?.metadata?.after,
      },
      childTeams: {
        ...prevTeam.childTeams,
        isLoading: false,
        data: childTeamsRes?.data || [],
        after: childTeamsRes?.metadata?.after,
        hasReachedEnd: !childTeamsRes?.metadata?.after,
      },
      resources: {
        ...prevTeam.resources,
        isLoading: true,
      },
    }
    ));
    if (teamRes) {
      const assetsForTeamRes = await getAssets(teamRes.name);
      if (assetsForTeamRes?.status === 200) {
        setSpecificTeam((prevTeam) => ({
          ...prevTeam,
          isLoadingSpecificTeam: false,
          resources: {
            ...prevTeam.resources,
            isLoading: false,
            data: assetsForTeamRes.data || [],
            after: undefined,
            hasReachedEnd: true,
          },
        }));
      }
    } else {
      setSpecificTeam((prevTeam) => ({
        ...prevTeam,
        isLoadingSpecificTeam: false,
      }));
    }
  }, [fetchMembersById, fetchTeamById, fetchTeamChildren, getAssets]);

  const getLeadingTeams = useCallback(async () => {
    setIsLoadingLeadingTeams(true);
    const response = await fetchTeams(TeamSortBy.SCORE, TeamSortOrder.DESC, undefined, undefined, 4);
    if (response) {
      setLeadingTeams(response.data);
    }
    setIsLoadingLeadingTeams(false);
  }, [fetchTeams]);

  const getTeams = useCallback(async (shouldResetState: boolean, searchValue?: string) => {
    setTeams((prevTeam) => ({
      ...prevTeam,
      data: shouldResetState ? [] : prevTeam.data,
      isLoading: true,
    }));
    const after = shouldResetState ? undefined : teams.after;
    const response = await fetchTeams(sortedBy, sortOrder, after, searchValue);
    let afterResponse = response?.metadata?.after;
    if (afterResponse === null) {
      afterResponse = undefined; // We don't want to send null to the API
    }
    const data = shouldResetState ? response?.data : [...teams.data, ...(response?.data || [])];
    setTeams((prevTeam) => ({
      ...prevTeam,
      isLoading: false,
      after: afterResponse,
      data: data || [],
      hasReachedEnd: !response?.metadata?.after,
    }));
  }, [teams.after, teams.data, fetchTeams, sortedBy, sortOrder]);

  const getNextChildTeams = useCallback(async () => {
    setSpecificTeam((prevTeam) => ({
      ...prevTeam,
      childTeams: {
        ...prevTeam.childTeams,
        isLoading: true,
      },
    }));
    const response = await fetchTeamChildren(specificTeam.team!.id, specificTeam.childTeams.after);
    setSpecificTeam((prevTeam) => ({
      ...prevTeam,
      childTeams: {
        ...prevTeam.childTeams,
        isLoading: false,
        after: response?.metadata?.after,
        data: [...prevTeam.childTeams.data, ...(response?.data || [])],
        hasReachedEnd: !response?.metadata?.after,
      },
    }));
  }, [fetchTeamChildren, specificTeam.childTeams.after, specificTeam.team]);

  const getNextMembers = useCallback(async () => {
    setSpecificTeam((prevTeam) => ({
      ...prevTeam,
      members: {
        ...prevTeam.members,
        isLoading: true,
      },
    }));
    const response = await fetchMembersById(specificTeam.team!.id, specificTeam.members.after);
    setSpecificTeam((prevTeam) => ({
      ...prevTeam,
      members: {
        ...prevTeam.members,
        isLoading: false,
        after: response?.metadata?.after,
        data: [...prevTeam.members.data, ...(response?.data || [])],
        hasReachedEnd: !response?.metadata?.after,
      },
    }));
  }, [fetchMembersById, specificTeam.members.after, specificTeam.team]);

  const value = useMemo(() => ({
    specificTeam,
    teams,
    leadingTeams,
    isLoadingLeadingTeams,
    getTeams,
    getLeadingTeams,
    getSpecificTeamById,
    getNextChildTeams,
    getNextMembers,
    sortedBy,
    sortOrder,
    setSortedBy,
    setSortOrder,
  }), [specificTeam, teams, leadingTeams, isLoadingLeadingTeams, getTeams, getLeadingTeams, getSpecificTeamById, getNextChildTeams, getNextMembers, sortedBy, sortOrder]);
  return (
    <TeamsContext.Provider value={value}>
      {children}
    </TeamsContext.Provider>
  );
};
