import { CSSProperties } from 'react';

import colors from 'themes/colors.module.scss';

export const loginStyle: CSSProperties = {
  minWidth: '60%',
  height: '100vh',
  position: 'relative',
};

export const jitIconStyle: CSSProperties = {
  position: 'absolute',
  top: 50,
  left: 50,
};

export const centerStyle: CSSProperties = {
  color: colors.white,
  fontFamily: 'Inter',
  padding: '200px 100px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const textStyle: CSSProperties = {
  fontFamily: 'Inter',
  minWidth: '680px',
};

export const frameStyle: CSSProperties = {
  padding: '3px 10px',
  border: '3px solid',
  boxSizing: 'border-box',
  borderRadius: 16,
  fontWeight: '700',
  fontFamily: 'Space Grotesk',
};

export const descriptionStyle: CSSProperties = {
  fontSize: '1.5em',
  marginTop: 50,
  fontFamily: 'Inter',
};
