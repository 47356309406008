import { createContext, useContext } from 'react';

interface IStiggContext {
  showStiggPayWall: boolean;
  setShowStiggPayWall: (show: boolean) => void;
}

export const StiggExtendedContext = createContext<IStiggContext>({
  showStiggPayWall: false,
  setShowStiggPayWall: () => { },
});

export const useStiggExtendedContext = () => useContext(StiggExtendedContext);
