import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { FC, useState } from 'react';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { IMouseEvent } from 'types/interfaces';

interface Props {
  inputProps: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  disabled?: boolean;
  isMultiLine?: boolean;
  onShowPasswordChange?: (newShowPassword: boolean) => void;
  preventShowPassword?: boolean;
  preventShowPasswordTooltipText?: string;
  clearPassword?: () => void;
  inputDataTestId?: string;
}

export const JitPasswordInput: FC<Props> = ({
  inputProps, disabled, isMultiLine, onShowPasswordChange, preventShowPassword, preventShowPasswordTooltipText, inputDataTestId = 'secretValueTextInput', clearPassword,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    if (preventShowPassword) return;
    setShowPassword(!showPassword);
    onShowPasswordChange?.(!showPassword);
  };

  const handleMouseDownPassword = (e: IMouseEvent) => {
    e.preventDefault();
  };
  const value = inputProps.value || '';
  return (
    <Input
      data-testid={inputProps.dataTestid}
      disabled={disabled}
      endAdornment={(
        <>
          {!!value && clearPassword && (
            <InputAdornment position='end'>
              <JitButton disableRipple onClick={clearPassword} variant='text'>
                <JitText color='inherit' text='Clear' />
              </JitButton>
            </InputAdornment>
          )}

          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              edge='end'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              sx={{
                padding: 0,
                '& svg': {
                  fontSize: '0.6em',
                },
                mr: '1px',
              }}
            >
              {showPassword
                ? <Visibility />
                : <JitTooltip title={(preventShowPassword && preventShowPasswordTooltipText) || ''}><VisibilityOff /></JitTooltip>}
            </IconButton>
          </InputAdornment>
        </>
      )}
      inputProps={{
        'data-testid': inputDataTestId,
      }}
      maxRows={3}
      minRows={3}
      multiline={isMultiLine && showPassword}
      sx={{
        padding: '0 0.5rem',
        borderRadius: '0.5rem',
      }}
      type={showPassword ? 'text' : 'password'}
      {...inputProps}
      value={value}
    />
  );
};
