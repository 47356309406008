import { FC } from 'react';

import { IgnoreFilterKeys } from '../../constants';

import styles from './FindingIgnoredStatusCell.module.scss';

import { ChevronClosed } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMenu, MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { FindingsStatisticsTextAndBullet } from 'pages/FindingsPage/components/FindingsStatistics/components';
import colors from 'themes/colors.module.scss';
import { IFinding, IFindingWithSelected, IMouseEvent, ITableCell, MenuItemKeyType } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props extends ITableCell<IFindingWithSelected>{
  updateFindingIgnoredState?: (findingToUpdate: IFinding, selectedIsIgnored: boolean) => void,
  isReadOnly?: boolean,
}

export const FindingIgnoredStatusCell: FC<Props> = ({ cell: { value: isIgnored, row }, updateFindingIgnoredState, isReadOnly }) => {
  const displayAsDisabledIcon = !updateFindingIgnoredState;
  const isValidStatus = typeof isIgnored === 'boolean';
  if (!isValidStatus) {
    return null; // We must pass some DOM element to render
  }
  const backgroundColor = colors.findingStatusIgnoreBG;
  const textColor = colors.findingStatusIgnoreText;
  const menuItems: MenuItemType[] = [{
    itemKey: IgnoreFilterKeys.IGNORED,
    itemContent: <FindingsStatisticsTextAndBullet addUnderlineOnHover={false} text='pages.findings.ignored.true' />,
  }, {
    itemKey: IgnoreFilterKeys.NOT_IGNORED,
    itemContent: <FindingsStatisticsTextAndBullet addUnderlineOnHover={false} text='pages.findings.ignored.false' />,
  }];
  const finding: IFinding = row.original;
  const onMenuItemClick = ({ e, itemKey }: { e: IMouseEvent, itemKey: MenuItemKeyType }) => {
    if (updateFindingIgnoredState) {
      stopPropagation(e);
      updateFindingIgnoredState(finding, itemKey === 'true');
    }
  };
  return (
    <JitMenu
      childrenWrapperStyle={displayAsDisabledIcon ? ({ display: 'flex',
        alignItems: 'center',
        height: '100%' }) : ({
      })}
      disabled={displayAsDisabledIcon}
      isReadOnly={isReadOnly}
      menuItems={menuItems}
      menuItemsWrapperClassName={styles.menuItemsWrapper}
      onMenuItemClick={onMenuItemClick}
      paperProps={{ style: { width: 80 } }}
    >
      <div className={displayAsDisabledIcon ? (styles.cellWrapperIcon) : (styles.cellWrapper)} style={{ backgroundColor }}>
        <JitText bold className={styles.text} color={textColor} text={`pages.findings.ignored.${isIgnored}`} />

        { !displayAsDisabledIcon && <JitIcon color={textColor} icon={ChevronClosed} /> }
      </div>

    </JitMenu>
  );
};
