import { Editor } from '@monaco-editor/react';
import { FC, ReactElement } from 'react';

import { AIGeneratedLabel } from '../../../ActionCardContent/Components/AIGeneratedLabel/AIGeneratedLabel';

import styles from './ActionSuggestedFixDialog.module.scss';
import { getLanguageFromKey } from './utils';

import { JitButton } from 'components/JitButton/JitButton';
import { JitCopyToClipboardBox } from 'components/JitCopyToClipboardBox/JitCopyToClipboardBox';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitTabsMenu } from 'components/JitTabsMenu/JitTabsMenu';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { FixSuggestionSource, IActionFinding } from 'types/interfaces';

interface Props {
  finding: IActionFinding;
  isOpen: boolean;
  onClose: () => void;
}

export const ActionSuggestedFixDialog: FC<Props> = ({ finding, isOpen, onClose }) => {
  const generateDialogTitle = (): string | ReactElement => {
    if (finding.fix_suggestion.source === FixSuggestionSource.ai) {
      return (
        <div className={styles.title}>
          <AIGeneratedLabel />

          <JitText size='l' text='pages.actions.actionCard.nonCodeDialog.title' />
        </div>
      );
    }
    return 'pages.actions.actionCard.nonCodeDialog.title';
  };

  const generateCodeFixTabs = () => (finding.fix_suggestion.code_fixes
    ? Object.entries(finding.fix_suggestion.code_fixes).reduce((tabs, [key, value]) => ({
      ...tabs,
      [key]: {
        title: key,
        component: (
          <div className={styles.editorWrapper} data-testid='editor'>
            <Editor
              height={200}
              language={getLanguageFromKey(key, value)}
              options={{ readOnly: true,
                minimap: { enabled: false },
                lineNumbersMinChars: 2,
                lineDecorationsWidth: 0,
                overviewRulerLanes: 0,
                overviewRulerBorder: false,
                scrollBeyondLastLine: false,
                renderLineHighlight: 'none',
              }}
              theme='vs-dark'
              value={value}
            />

            <div className={styles.copyIconWrapper} data-testid='copyIcon'>
              <JitCopyToClipboardBox displayText='' text={value} variant='minimal' />
            </div>
          </div>
        ),
      },
    }), {})
    : {});

  const codeFixTabs = generateCodeFixTabs();

  return (
    <CustomDialog
      content={(
        <div className={styles.dialogContent}>
          <div className={styles.guidelinesSection}>
            <div className={styles.titleText}>
              <JitText bold color={colors.gray} size='m' text='pages.actions.actionCard.nonCodeDialog.guidelines' />
            </div>

            <JitText className={styles.guidelinesText} color={colors.lightGray} size='l' text={finding.fix_suggestion.guidelines} />
          </div>

          <div className={styles.reasonSection}>
            <div className={styles.titleText}>

              <JitText bold className={styles.titleText} color={colors.gray} size='m' text='pages.actions.actionCard.nonCodeDialog.reason' />
            </div>

            <JitText className={styles.reasonText} color={colors.lightGray} size='l' text={finding.fix_suggestion.reason} />
          </div>

          {Object.keys(codeFixTabs).length > 0 && (
            <JitTabsMenu tabs={codeFixTabs} />
          )}

          <JitButton
            onClick={onClose}
            style={{ marginLeft: 'auto' }}
            variant='contained'
          >
            <JitText text='pages.actions.actionCard.nonCodeDialog.done' />
          </JitButton>
        </div>
      )}
      dataTestId='action-suggested-fix-dialog'
      isOpen={isOpen}
      onClose={onClose}
      title={generateDialogTitle()}
      width='xl'
      withDivider
    />
  );
};
