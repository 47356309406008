import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './OnboardingPage.module.scss';

import { JitFavicon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { i18n } from 'locale/i18n';
import { OnboardingWizard } from 'pages/OnboardingPage/components/OnboardingWizard/OnboardingWizard';
import colors from 'themes/colors.module.scss';

export const OnboardingPage: FC = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const [totalStepsCount, setTotalStepsCount] = useState(0);
  const { t } = i18n;
  const navigate = useNavigate();
  const handleClose = useCallback(
    () => {
      const plan = sessionStorage.getItem(constants.GOAL_PLAN_KEY) || constants.PLAN_SLUG.MVS;
      navigate(`/${constants.routes.PLANS}/${plan}`);
    },
    [navigate],
  );

  return (
    <div className={styles.root} data-testid='onboarding-page'>
      <div className={styles.firstGradient} />

      <div className={styles.secondGradient} />

      <JitIcon
        className={styles.jitIcon}
        color={colors.jitPink}
        icon={JitFavicon}
        size={40}
      />

      <OnboardingWizard
        handleClose={handleClose}
        setStepIndex={setStepIndex}
        setTotalStepsCount={setTotalStepsCount}
        stepIndex={stepIndex}
      />

      <JitText
        color={colors.jitPink}
        text={t('pages.onboarding.stepsProgress', { currentStep: stepIndex + 1,
          totalSteps: totalStepsCount }) as string}
      />
    </div>
  );
};
