import { FC } from 'react';

import { AssetType } from 'components/AssetType/AssetType';
import { IFinding, ITableCell } from 'types/interfaces';

export const AssetTypeCell: FC<ITableCell<IFinding>> = ({ cell }) => {
  const { row: { original } } = cell;
  const { assetType, vendor } = original;

  return (
    <AssetType assetName={assetType} vendor={vendor} />
  );
};
