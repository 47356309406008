
import { FC } from 'react';

import { StiggPaywall } from '.';

import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { useStiggExtendedContext } from 'context';

export const StiggPaywallDialog: FC = () => {
  const { showStiggPayWall, setShowStiggPayWall } = useStiggExtendedContext();

  return (
    <CustomDialog
      content={(<StiggPaywall />)}
      contentPadding={2}
      dataTestId='stiggPayWallDialog'
      height='auto'
      isOpen={showStiggPayWall}
      onClose={() => {
        setShowStiggPayWall(false);
      }}
      title='dialogs.stiggPaywall.title'
      width='xxl'
      withDivider
    />
  );
};
