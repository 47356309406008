import { FC, useMemo } from 'react';

import styles from './PlanItemConfigureButton.module.scss';

import { JitButton, JitButtonVariants } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { usePlanInstanceContext, useTenantContext } from 'context';
import { IPlanItemDetails } from 'types/interfaces';

interface Props {
  planItem: IPlanItemDetails;
  handleConfigure: (planItem: IPlanItemDetails) => void;
  isPlanItemActive: boolean;
  variant?: JitButtonVariants;
  longText?: boolean;
}

export const PlanItemConfigureButton: FC<Props> = ({ planItem, handleConfigure, isPlanItemActive, longText = true, variant = 'contained' }) => {
  const { planItemSlugsInCommit } = usePlanInstanceContext();
  const { isGithubIntegrated } = useTenantContext();

  const handlePlanItemClick = () => {
    handleConfigure(planItem);
  };

  const disabled = planItem.is_enabled === false || planItemSlugsInCommit.some((slug) => slug === planItem.slug) || !isGithubIntegrated;

  const activateText = useMemo(() => {
    if (planItem.is_enabled === false) return 'pages.plan.planItem.config.comingSoon';
    return longText ? 'pages.plan.planItem.activateLong' : 'pages.plan.planItem.activate';
  }, [longText, planItem.is_enabled]);

  const configureText = 'pages.plan.planItem.config.configureItemButton';

  const tooltipDisabledText = useMemo(() => {
    if (!isGithubIntegrated) return 'pages.plan.planItem.config.githubIntegrationRequired';
    return '';
  }, [isGithubIntegrated]);

  return (
    <div className={styles.configureButtonWrapper}>
      <JitTooltip data-testid='setupButtonTooltip' placement='top' title={tooltipDisabledText}>

        <JitButton data-testid='configureButton' disabled={disabled} onClick={handlePlanItemClick} variant={variant}>
          <JitText
            color='inherit'
            text={isPlanItemActive ? configureText : activateText}
          />
        </JitButton>
      </JitTooltip>
    </div>
  );
};
