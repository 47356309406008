import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';

import {
  configurationComponentByItemSlug,
  configurationComponentByItemSlugWithProwler,
} from 'components/Configurations/configurationComponentByItemSlug';
import { configurationComponentBySecret } from 'components/Configurations/configurationComponentBySecret';
import { configurationComponentByVendor } from 'components/Configurations/configurationComponentByVendor';
import { ConfigurationsComponentDetails } from 'components/Configurations/interfaces/configurations';
import { IControlDetails, IPlanItemDetails } from 'types/interfaces';
import { getVendorForAssetType } from 'utils/functions/matchVendorToAssetType';

type PlanItem = Pick<IPlanItemDetails, 'slug' | 'asset_types' | 'name' | 'is_manual'> & {
  controls?: IPlanItemDetails['controls'];
};

const findIntegration = (controls: IControlDetails[] | undefined, isRequired: boolean) => {
  // Find a control that has at least one integration matching the 'isRequired' status.
  const matchingControl = controls?.find((control) => control.integrations?.some((integration) => integration.required === isRequired));
  // Within that control, find and return the specific integration that matches the 'isRequired' status.
  return matchingControl?.integrations?.find((integration) => integration.required === isRequired);
};

export const useGetConfigurationsComponentDetails = () => {
  const { uiDeploymentBasedScanProwler } = useFlags();

  return useCallback((planItem: PlanItem): ConfigurationsComponentDetails | null => {
    const configurationComponents = uiDeploymentBasedScanProwler ? configurationComponentByItemSlugWithProwler : configurationComponentByItemSlug;

    if (planItem.slug in configurationComponents) {
      return configurationComponents[planItem.slug];
    }

    if (planItem.is_manual) return null;

    const assetType = planItem.asset_types?.[0];
    const vendor = assetType ? getVendorForAssetType(assetType) : null;
    if (vendor && vendor in configurationComponentByVendor) {
      return configurationComponentByVendor[vendor];
    }

    // check if there are required integrations, if yes, require the user to configure them
    const requiredIntegration = findIntegration(planItem.controls, true);
    if (requiredIntegration) {
      return configurationComponentBySecret(requiredIntegration.name, planItem.name);
    }

    // if there is no required integration, check if there is an optional integration
    const optionalIntegration = findIntegration(planItem.controls, false);
    if (optionalIntegration) {
      return configurationComponentBySecret(optionalIntegration.name, planItem.name, true);
    }

    return null;
  }, [uiDeploymentBasedScanProwler]);
};
