import { AssetType } from 'types/enums/AssetType';

const assetTypeDisplayNames = {
  [AssetType.AWS_ACCOUNT]: 'Account',
  [AssetType.AZURE_ACCOUNT]: 'Account',
  [AssetType.GCP_ACCOUNT]: 'Account',
  [AssetType.REPO]: 'Repository',
  [AssetType.ORG]: 'Organization',
  [AssetType.WEB]: 'Webapp',
  [AssetType.API]: 'API',
  [AssetType.APPLICATION]: 'Application',
  [AssetType.AWS_ORG]: 'AWS Organization',
};

export const getAssetTypeDisplayName = (assetType: string) => assetTypeDisplayNames[assetType as keyof typeof assetTypeDisplayNames] || assetType;
