import { useCallback, useEffect } from 'react';

import { useSlackContext } from '../SlackContext';

import { getSlackChannels } from 'services/SlackService/SlackService';

export const useGetSlackChannels = () => {
  const { slackChannels, setSlackChannels, setIsSlackChannelsLoading } = useSlackContext();

  const initSlackChannels = useCallback(async () => {
    setIsSlackChannelsLoading(true);
    const slackChannelsResponse = await getSlackChannels();
    setSlackChannels(slackChannelsResponse);
    setIsSlackChannelsLoading(false);
  }, [setIsSlackChannelsLoading, setSlackChannels]);

  useEffect(() => {
    if (!slackChannels?.length) {
      initSlackChannels();
    }
  }, [initSlackChannels, slackChannels]);

  return {
    slackChannels,
  };
};
