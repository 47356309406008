
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, FC, SetStateAction } from 'react';

import { ItemTypeBadge } from '../ItemTypeBadge/ItemTypeBadge';

import { PlanItemActions } from './components/PlanItemActions/PlanItemActions';
import { PlanItemDetailsSummary } from './components/PlanItemDetailsSummary/PlanItemsDetailsSummary';
import { PlanItemSeeFindings } from './components/PlanItemSeeFindings/PlanItemSeeFindings';
import { PlanItemStatusBadge } from './components/PlanItemStatusBadge/PlanItemStatusBadge';
import styles from './PlanCategoryCardItem.module.scss';
import { StyledPlanCategoryCardItem } from './PlanCategoryCardItem.styles';

import { TriangleWarningOutline } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { ItemSlugOptions } from 'pages/PlanPage/components/PlanItemIntroDialog';
import colors from 'themes/colors.module.scss';
import { IPlanItemDetails, PlanItemAggregatedStatus } from 'types/interfaces';

interface Props {
  planSlug: string;
  planItem: IPlanItemDetails;
  setDetailedPlanItem: (planItem: IPlanItemDetails | undefined) => void;
  isSelected: boolean;
  handleConfigure: (planItem: IPlanItemDetails) => void;
  setIntroItem: Dispatch<SetStateAction<ItemSlugOptions | null>>;
}

export const PlanCategoryCardItem: FC<Props> = ({ planSlug, planItem, setDetailedPlanItem, isSelected, handleConfigure, setIntroItem }) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { uiHidePlanItemStatusBadge } = useFlags();

  const planItemStatus = planItem?.status_properties?.status;
  const planItemFindingsCount = planItem?.status_properties?.findings_count;

  const handlePlanItemClick = () => {
    if (isSelected) {
      setDetailedPlanItem(undefined);
    } else {
      sendAnalyticsEvent({
        action: 'requirement-clicked',
        params: { 'item-name': planItem.name },
      });
      setDetailedPlanItem(planItem);
    }
  };

  const shouldShowSeeFindings = planItem.is_active && planItemStatus === PlanItemAggregatedStatus.FAILURE && !planItem.is_manual;

  return (
    <StyledPlanCategoryCardItem
      key={planItem.slug}
      data-testid={`PlanItem-${planItem.slug}`}
      isSelected={isSelected}
      onClick={handlePlanItemClick}
    >
      <div className={styles.idWrapper}>
        <div className={styles.idLabel}>
          <JitText bold noWrap overflowHiddenEllipsis size='m' text={planItem.item_id} />
        </div>

        <div className={styles.itemTypeLabel}>
          <ItemTypeBadge itemType={planItem.is_manual ? 'manual' : 'auto'} />
        </div>
      </div>

      <div id='main' role='button' tabIndex={0}>
        <PlanItemDetailsSummary
          handleConfigure={handleConfigure}
          planItem={planItem}
          setDetailedPlanItem={setDetailedPlanItem}
        />
      </div>

      <div className={styles.itemDetailsWrapper}>

        {planItem.is_active && !uiHidePlanItemStatusBadge && (
        <div className={styles.statusWrapper}>
          <PlanItemStatusBadge status={planItemStatus || PlanItemAggregatedStatus.PENDING} />

          {planItem.status_properties?.has_error && (
          <JitTooltip
            data-testid='status-error-tooltip-info'
            followCursor={false}
            placement='top'
            title='pages.plan.planItem.hasErrors'
          >
            <div className={styles.errorIndicationContainer}>
              <JitIcon
                centered
                color={colors.failRed}
                data-testid='error-icon'
                icon={TriangleWarningOutline}
                size={14}
              />
            </div>
          </JitTooltip>
          )}
        </div>
        )}

        {shouldShowSeeFindings && !!planItemFindingsCount && planItemFindingsCount > 0
          && <PlanItemSeeFindings findingsCount={planItemFindingsCount} planItemSlug={planItem.slug} />}

        <PlanItemActions
          handleConfigure={handleConfigure}
          planItem={planItem}
          planSlug={planSlug}
          setIntroItem={setIntroItem}
        />
      </div>

    </StyledPlanCategoryCardItem>
  );
};
