import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';
import { HeaderGroup } from 'react-table';

import { DefaultTableDesign, TableDesignVariants } from 'components/JitTable/constants';
import colors from 'themes/colors.module.scss';

interface Props {
  headerGroups: HeaderGroup<object>[];
  tableDesign?: TableDesignVariants;
}

export const JitTableHeader: FC<Props> = ({ headerGroups, tableDesign = DefaultTableDesign }) => {
  const borderBottomDesignMap = {
    dark: 0,
    bright: 0,
  };
  return (
    <TableHead>
      {headerGroups?.map((headerGroup) => {
        const headerGroupProps = headerGroup.getHeaderGroupProps();
        const { headers } = headerGroup;
        return (
          <TableRow
            {...headerGroupProps}
            key={headers?.toString()}
            sx={{
              th: {
                borderBottom: borderBottomDesignMap[tableDesign],
                backgroundColor: colors.cards,
              },
            }}
          >
            {headers?.map((column) => {
              const columnProps = column.getHeaderProps();
              return (
                <TableCell
                  {...columnProps}
                  key={column.id}
                  sx={{
                    padding: '16px',
                    minWidth: column.minWidth,
                    width: column.width,
                  }}
                >
                  {column.render('Header')}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableHead>
  );
};
