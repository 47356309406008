import { isEmpty } from 'lodash';
import { FC, useState } from 'react';

import { DataOverviewItem, SecurityImpactCard } from '../SecurityImpactCard/SecurityImpactCard';
import { SecurityImpactCardEmpty } from '../SecurityImpactCard/SecurityImpactCardEmpty';
import { SecurityImpactCardLoading } from '../SecurityImpactCard/SecurityImpactCardLoading';

import { ChangeCalculationDialogContent } from './components/ChangeCalculationDialogContent/ChangeCalculationDialogContent';
import { DevelopersEfficiencyContent } from './components/DevelopersEfficiencyContent/DevelopersEfficiencyContent';
import { DevProductivityDescription } from './components/DevProductivityDescription/DevProductivityDescription';
import { ThreatPreventionContent } from './components/ThreatPreventionContent/ThreatPreventionContent';

import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IDevProductivity } from 'types/interfaces/Metrics/SecurityImpactMetrics/DevProductivity';

interface Props {
  devProductivityMetric: MetricWithLoadingIndication<IDevProductivity>
  silentFetchDevProductivity: () => void
}

export const DevProductivity: FC<Props> = ({ devProductivityMetric, silentFetchDevProductivity }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isCalcDialogOpen, setIsCalcDialogOpen] = useState<boolean>(false);

  if (devProductivityMetric.isLoading) {
    return (
      <SecurityImpactCardLoading />
    );
  }

  if (isEmpty(devProductivityMetric.metric) || !devProductivityMetric.metric) return <SecurityImpactCardEmpty />;

  const { metric: { developersCount, hoursSaved, moneySaved, preProdResolvedFindings, totalResolvedFindings } } = devProductivityMetric;

  const dataOverviewItems: DataOverviewItem[] = [{
    content: <ThreatPreventionContent preProdResolvedFindings={preProdResolvedFindings} totalResolvedFindings={totalResolvedFindings} />,
    description: 'pages.securityImpact.devProductivity.threatPrevention.description',
    descriptionTestID: 'threat-prevention-description',
    title: 'pages.securityImpact.devProductivity.threatPrevention.title',
    titleTestID: 'threat-prevention-title',
  }, {
    content: <DevelopersEfficiencyContent developersCount={developersCount} hoursSaved={hoursSaved} moneySaved={moneySaved} />,
    description: 'pages.securityImpact.devProductivity.developersEfficiency.description',
    descriptionTestID: 'developers-efficiency-description',
    title: 'pages.securityImpact.devProductivity.developersEfficiency.title',
    titleTestID: 'developers-efficiency-title',
    actionItemText: 'pages.securityImpact.calculator.editCalculation',
    actionItemOnClick: () => { setIsCalcDialogOpen(true); },
  }];

  return (
    <div>
      <SecurityImpactCard
        dataOverviewItems={dataOverviewItems}
        description={(
          <DevProductivityDescription
            developersCount={developersCount}
            hoursSaved={hoursSaved}
            preProdResolvedFindings={preProdResolvedFindings}
          />
      )}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        title='pages.securityImpact.devProductivity.title'
      />

      <CustomDialog
        content={(
          <ChangeCalculationDialogContent
            devProductivityMetric={devProductivityMetric.metric}
            setIsCalcDialogOpen={setIsCalcDialogOpen}
            silentFetchDevProductivity={silentFetchDevProductivity}
          />
)}
        dataTestId='share-snapshot-dialog'
        isOpen={isCalcDialogOpen}
        onClose={() => { setIsCalcDialogOpen(false); }}
        title='pages.securityImpact.calculator.title'
        width='m'
        withDivider
      />
    </div>
  );
};
