import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';

interface Props {
  keyText: string;
  valueText: string | string[];
}

export const IntegrationDetailsExistingBox: FC<Props> = ({ keyText, valueText }) => {
  const valueTextSplitted = String(valueText).split(',').join(', ');
  const showBullet = keyText === 'account_id';

  return (
    <div
      key={keyText}
      className='ps-1'
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <JitText
        bold
        className='align-self-start'
        {...(showBullet && { style: { display: 'list-item' } })}
        text={`dialogs.integration.aws.fields.${keyText}.label`}
      />

      <JitText
        text={`: ${valueTextSplitted}`}
      />
    </div>
  );
};
