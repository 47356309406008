import { useState } from 'react';

import { useToastsContext } from 'context';
import { constants } from 'globalConstants';
import { usePlanService } from 'services/PlanService/usePlanService';
import { ToastType } from 'types/enums';
import { ActionButton, ActionButtonVariant } from 'types/interfaces';

interface Props {
  itemSlug: string;
  onTriggerSend: () => void;
  variant?: ActionButtonVariant;
  isLoadingOverride?: boolean;
}

export const useGetRerunButton = ({ itemSlug, onTriggerSend, variant, isLoadingOverride }: Props): ActionButton => {
  const { showToast } = useToastsContext();
  const { triggerRerun } = usePlanService();
  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    setIsLoading(true);
    const result = await triggerRerun(constants.PLAN_SLUG.MY_PLAN, itemSlug);
    onTriggerSend();
    if (result?.status === 201) {
      showToast({
        type: ToastType.Success,
        overrideProps: {
          title: 'pages.plan.planItem.rerun.success',
          secondsToClose: 3,
        },
      });
    }
    setIsLoading(false);
  };

  const finalIsLoading = isLoadingOverride !== undefined ? isLoadingOverride : isLoading;
  return {
    label: 'pages.plan.itemDetails.results.rerun',
    handleClick: onClick,
    disabled: finalIsLoading,
    isLoading: finalIsLoading,
    variant: variant || ActionButtonVariant.OUTLINED,
    analytics: {
      action: 'rerun-item',
      params: { 'plan-items': itemSlug },
    },
  };
};
