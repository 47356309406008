import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { FC, useState } from 'react';

import { EditSavedFilterActions } from './components/EditSavedFilterActions';

import { EditSavedFilterIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { inputDefaultProps, paperStyle } from 'pages/FindingsPage/utils/dialogueStyle';
import { ISavedFilter } from 'types/interfaces/Findings/ISavedFilter';

interface Props {
  savedFilter: ISavedFilter;
  onClose: () => void;
  editSavedFilter: (savedFilter: ISavedFilter) => void;
}

export const EditSavedFilter: FC<Props> = ({
  savedFilter,
  onClose,
  editSavedFilter,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [filterName, setFilterName] = useState(savedFilter.name);
  const [filterDescription, setFilterDescription] = useState(savedFilter.description);
  const [filterShouldNotify, setFilterShouldNotify] = useState(savedFilter.shouldNotify);

  const handleSave = () => {
    editSavedFilter({
      ...savedFilter,
      name: filterName,
      description: filterDescription,
      shouldNotify: filterShouldNotify,
    });
    setOpenDialog(false);
    onClose();
  };

  return (
    <div>
      <MenuItem onClick={() => setOpenDialog(true)}>
        <div style={{ display: 'flex' }}>
          <JitIcon icon={EditSavedFilterIcon} />

          <JitText style={{ marginLeft: 7 }} text='dialogs.planItemsCatalog.filter.savedFilters.edit' />
        </div>
      </MenuItem>

      <Dialog
        maxWidth='xl'
        onClose={onClose}
        open={openDialog}
        PaperProps={{ style: paperStyle }}
        scroll='paper'
      >
        <DialogTitle
          data-testid='dialogTitle'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <JitText
            bold
            size='l'
            text='dialogs.planItemsCatalog.filter.savedFilters.editCustomFilter'
          />
        </DialogTitle>

        <Divider />

        <div style={{ padding: 20 }}>
          <div>
            <JitText
              style={{
                paddingBottom: 5,
                paddingLeft: 8,
              }}
              text='dialogs.planItemsCatalog.filter.savedFilters.name'
            />

            <JitTextInput
              {...inputDefaultProps}
              defaultValue={filterName}
              isRequired
              onChange={(e) => setFilterName(e)}
            />
          </div>

          <div style={{ marginTop: 20 }}>
            <JitText
              style={{
                paddingBottom: 5,
                paddingLeft: 8,
              }}
              text='dialogs.planItemsCatalog.filter.savedFilters.description'
            />

            <JitTextInput
              {...inputDefaultProps}
              defaultValue={filterDescription}
              maxRows={4}
              multiline
              onChange={(e) => setFilterDescription(e)}
              rows={4}
            />
          </div>

          <EditSavedFilterActions
            filterName={filterName}
            filterShouldNotify={filterShouldNotify}
            handleSave={handleSave}
            onClose={onClose}
            setFilterShouldNotify={setFilterShouldNotify}
          />
        </div>

      </Dialog>
    </div>
  );
};
