import { FC, useMemo } from 'react';

import styles from './DevelopersEfficiency.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { formatHoursToDisplayText } from 'utils';
import { formatMoneyToDisplayText } from 'utils/functions/formatMoney';

interface Props {
  hoursSaved: number;
  moneySaved: number;
  developersCount?: number;
}

export const DevelopersEfficiencyContent: FC<Props> = ({ hoursSaved, moneySaved, developersCount }) => {
  const hoursSavedDisplayText = useMemo(() => formatHoursToDisplayText(hoursSaved), [hoursSaved]);
  const moneySavedDisplayText = useMemo(() => formatMoneyToDisplayText(moneySaved), [moneySaved]);
  const content = useMemo(() => (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper} data-testid='developers-efficiency-hours-and-money-saved'>
        <JitText bold size='huge' text={hoursSavedDisplayText} />

        <JitText bold color={colors.performanceGreen} size='huge' text='pages.securityImpact.devProductivity.developersEfficiency.equalSign' />

        <JitText
          bold
          color={colors.performanceGreen}
          params={{
            moneySaved: moneySavedDisplayText,
          }}
          size='huge'
          text='pages.securityImpact.devProductivity.developersEfficiency.moneySaved'
        />
      </div>

      { developersCount !== undefined && (
      <JitText
        bold
        data-testid='developers-efficiency-developers-count'
        params={{
          developersCount,
        }}
        text='pages.securityImpact.devProductivity.developersCount'
      />
      )}
    </div>
  ), [developersCount, hoursSavedDisplayText, moneySavedDisplayText]);

  return content;
};
