import { t } from 'i18next';
import { useMemo } from 'react';

import { usePlanInstanceContext, useTenantContext } from 'context';
import { useGetPlanItemConfigurationsStatus, useHasConfigurationsChanged } from 'context/ConfigurationsContext/hooks';
import { useGetPlanItemVendors } from 'context/PlansContext/hooks/useGetPlanItemVendors';
import { ActionButton, ActionButtonVariant, IMouseEvent, IPlanItemDetails } from 'types/interfaces';

export const UseGetRelevantActivationButton = (
  planItem: IPlanItemDetails,
  openDeactivateConfirmDialog: () => void,
  onActivate: (e: IMouseEvent) => void,
  isPlanItemActive: boolean,
  onConfigure: () => void,
  isItemConfigurable: boolean,
  onRedirect: () => void,
  isItemRedirecting: boolean,
) => {
  const { planItemSlugsInCommit } = usePlanInstanceContext();
  const { hasConfigurationsChanged } = useHasConfigurationsChanged();
  const { isIntegrated, isConfigurationMissing } = useGetPlanItemConfigurationsStatus();
  const { isGithubIntegrated } = useTenantContext();
  const { getPlanItemVendors } = useGetPlanItemVendors();
  const planItemVendorIntegrations = getPlanItemVendors(planItem);

  const isItemConfigurationMissing = useMemo(() => isConfigurationMissing(planItem), [isConfigurationMissing, planItem]);
  const isItemIntegrated = useMemo(() => isIntegrated(planItem), [isIntegrated, planItem]);

  const isLoading = planItemSlugsInCommit.filter((slug) => slug === planItem.slug).length > 0;

  const activateButtonTooltipDisabledText = useMemo(() => {
    if (!isItemIntegrated) return t('pages.plan.planItem.config.integrationRequired', { planItemVendorIntegrations });
    if (hasConfigurationsChanged(planItem.slug)) return 'pages.plan.planItem.config.applyChangesFirst';

    return ''; // We should never get here
  }, [hasConfigurationsChanged, isItemIntegrated, planItem, planItemVendorIntegrations]);

  const activateButtonText = useMemo(() => {
    if (planItem.is_enabled === false) return 'pages.plan.planItem.config.comingSoon';
    return 'pages.plan.itemDetails.info.activateButton';
  }, [planItem.is_enabled]);

  const deactivateButton: ActionButton = useMemo(() => ({
    label: 'pages.plan.itemDetails.info.deactivateButton',
    handleClick: openDeactivateConfirmDialog,
    isLoading,
    variant: ActionButtonVariant.DANGER,
  }), [isLoading, openDeactivateConfirmDialog]);

  const disabledTooltip = useMemo(() => {
    if (!isGithubIntegrated) return t('pages.plan.planItem.config.githubIntegrationRequired');
    if (isItemConfigurationMissing) return activateButtonTooltipDisabledText;
    return undefined;
  }, [activateButtonTooltipDisabledText, isGithubIntegrated, isItemConfigurationMissing]);

  const activateButton: ActionButton = useMemo(() => ({
    label: activateButtonText,
    handleClick: onActivate,
    isLoading,
    tooltip: disabledTooltip,
    disabled: !!disabledTooltip,
  }), [disabledTooltip, activateButtonText, isLoading, onActivate]);

  const isConfiguredDisabled = planItem.is_enabled === false || planItemSlugsInCommit.some((slug) => slug === planItem.slug);
  const activateText = useMemo(() => {
    if (planItem.is_enabled === false) return 'pages.plan.planItem.config.comingSoon';
    return 'pages.plan.planItem.activate';
  }, [planItem.is_enabled]);

  const configureText = 'pages.plan.planItem.config.configureItemButton';

  const configureButton: ActionButton = useMemo(() => ({
    label: isPlanItemActive ? configureText : activateText,
    handleClick: onConfigure,
    isLoading,
    variant: isPlanItemActive ? ActionButtonVariant.OUTLINED : ActionButtonVariant.PRIMARY,
    tooltip: disabledTooltip,
    disabled: isConfiguredDisabled || !!disabledTooltip,
  }), [activateText, disabledTooltip, isConfiguredDisabled, isLoading, isPlanItemActive, onConfigure]);

  const viewReportText = 'pages.plan.planItem.viewReport.viewReportButton';

  const viewReportButton: ActionButton = useMemo(() => ({
    label: viewReportText,
    handleClick: onRedirect,
    variant: isPlanItemActive ? ActionButtonVariant.OUTLINED : ActionButtonVariant.PRIMARY,
  }), [isPlanItemActive, onRedirect]);

  const actionButtons = useMemo(() => {
    if (isItemConfigurable) return isPlanItemActive ? [configureButton, deactivateButton] : [configureButton];
    if (isItemRedirecting) return isPlanItemActive ? [viewReportButton, deactivateButton] : [activateButton];
    if (isPlanItemActive) return [deactivateButton];
    return [activateButton];
  }, [activateButton, configureButton, deactivateButton, viewReportButton, isItemConfigurable, isPlanItemActive, isItemRedirecting]);

  return { actionButtons };
};
