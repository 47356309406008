import { useMemo } from 'react';

import { TextCell } from 'components/JitTable/templates';
import { Score } from 'components/Score/Score';
import { i18n } from 'locale/i18n';
import { IAsset, ITableCell } from 'types/interfaces';
import { TableColumn } from 'types/types';

export const useGetColumns = (): TableColumn<object>[] => {
  const { t } = i18n;

  // @ts-ignore The whole tables related logic uses object as type, this should be fixed
  const columns: TableColumn<object>[] = useMemo(() => [
    {
      Header: t('pages.teams.teamDetails.resourceTable.columns.name'),
      accessor: (asset: IAsset) => asset.asset_name,
      Cell: TextCell,
      width: '80%',
    },
    {
      Header: t('pages.teams.teamDetails.resourceTable.columns.score'),
      accessor: (asset: IAsset) => asset.score,
      Cell: ({ cell: { value } }: ITableCell) => <Score score={value || 0} />,
      width: '20%',
    },
  ], [t]);

  return columns;
};
