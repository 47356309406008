import { useMemo } from 'react';

import integrationGroups from './integrationGroups.json';
import integrationTemplates from './integrations.json';
import { InnerOptions, IntegrationTemplate, IntegrationTemplatesGroup, IntegrationType, IntegrationVendorType } from './interfaces';

import { getIconByVendor } from 'components/AssetType/utils/getIconByVendor';

const secretIntegrationDefaultOptions: InnerOptions = {
  connect: {
    label: 'Connect',
  },
  fields: {
    name: {
      label: 'Name',
      value: 'default-value',
      placeholder: 'Enter the key name',
    },
    secret: {
      label: 'Token',
      placeholder: 'Enter the token',
    },
  },
};

const getIntegrationOptions = (integrationTemplate: IntegrationTemplate): InnerOptions | undefined => {
  if (integrationTemplate.integrationType !== IntegrationType.secret) return undefined;
  const options = integrationTemplate.options as InnerOptions;
  return {
    ...secretIntegrationDefaultOptions,
    ...options, // Merge with existing options without overriding them
    fields: {
      name: {
        ...secretIntegrationDefaultOptions.fields.name,
        ...options.fields?.name,
      },
      secret: {
        ...secretIntegrationDefaultOptions.fields.secret,
        ...options.fields?.secret,
      },
      ...(options.fields?.secretLink && {
        secretLink: {
          ...secretIntegrationDefaultOptions.fields.secretLink,
          ...options.fields.secretLink,
        },
      }
      ),
    },
  };
};

export const useIntegrationsTemplate = () => {
  const templates: IntegrationTemplate[] = useMemo(() => integrationTemplates.items.map((item) => ({
    ...item,
    vendorType: IntegrationVendorType[item.vendorType as keyof typeof IntegrationVendorType],
    options: getIntegrationOptions(item as IntegrationTemplate),
    icon: getIconByVendor(item.vendor, undefined, true),
  })), []);

  const getTemplateByVendorKey = (vendorKey: string): IntegrationTemplate | undefined => templates.find((template) => template.key === vendorKey);

  const groupedTemplates = useMemo(
    () => {
      const groups: IntegrationTemplatesGroup[] = [];

      integrationGroups.groups.forEach((group) => {
        const groupTemplates = templates.filter((template) => group.templates.includes(template.key));

        if (groupTemplates.length) {
          groups.push({
            key: group.key,
            title: group.title,
            templates: groupTemplates,
          });
        }
      });

      return groups;
    },
    [templates],
  );

  return {
    templates,
    groupedTemplates,
    getTemplateByVendorKey,
  };
};
