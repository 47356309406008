import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { FindingsTableGeneralHeader, FindingsTableSelectHeader } from '../components';
import { FindingIgnoredStatusCell } from '../components/FindingIgnoredCell/FindingIgnoredStatusCell';

import { AssetTypeCell, IsSelectedCheckboxCell, LayerIconCell, SeverityCell, TextCell } from 'components/JitTable/templates';
import { ControlNameCell } from 'components/JitTable/templates/ControlNameCell/ControlNameCell';
import { ResolutionCell } from 'components/JitTable/templates/ResolutionCell/ResolutionCell';
import { i18n } from 'locale/i18n';
import { SortDirections } from 'types/enums';
import { IDictionary, IFinding, IFindingWithSelected, ISortColumn, ITableCell, ServerFindingKeys } from 'types/interfaces';
import { TableColumns } from 'types/types/TableColumns';

interface Props {
  currentSortColumn: ISortColumn | undefined,
  setCurrentSortColumn: Dispatch<SetStateAction<ISortColumn | undefined>>,
  isPageSelected: boolean,
  setPageSelected: (isPageSelected: boolean) => void,
  isFindingDetailsTabOpen: boolean,
  updateFindingIgnoredState: (findingToUpdate: IFinding, selectedIsIgnored: boolean) => void,
  isIgnoreRulesReadOnly: boolean,
}

export const useGetTableColumns = ({
  currentSortColumn, setCurrentSortColumn, isPageSelected, setPageSelected, isFindingDetailsTabOpen, updateFindingIgnoredState,
  isIgnoreRulesReadOnly }: Props): TableColumns<IFindingWithSelected> => {
  const { t } = i18n;

  const columnHeaders: IDictionary<string> = useMemo(() => t('pages.findings.findingsTable.columnHeaders', { returnObjects: true }), [t]);
  const onChangeSortOrder = useCallback((columnKey: string, columnName: string) => {
    setCurrentSortColumn({
      sortKey: columnKey,
      columnName,
      direction: currentSortColumn?.direction === SortDirections.ASC ? SortDirections.DESC : SortDirections.ASC,
    });
  }, [currentSortColumn?.direction, setCurrentSortColumn]);

  const columnsData = useMemo(() => {
    let columns = [
      {
        Header: <FindingsTableSelectHeader isPageSelected={isPageSelected} setPageSelected={setPageSelected} />,
        accessor: 'isSelectedDetails',
        width: '5%',
        Cell: IsSelectedCheckboxCell,
      },
      {
        Header: <FindingsTableGeneralHeader
          columnKey={ServerFindingKeys.created_at}
          columnName={columnHeaders.firstDetected}
          onChangeSortOrder={onChangeSortOrder}
          sortDirection={currentSortColumn?.direction}
        />,
        accessor: 'createdAt',
        width: '13%',
        Cell: TextCell,
      },
      {
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.status} columnName={columnHeaders.status} />,
        accessor: 'resolution',
        width: '6%',
        Cell: ResolutionCell,
      },
      {
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.plan_layer} columnName={columnHeaders.planLayer} />,
        accessor: 'planLayer',
        width: '5%',
        Cell: LayerIconCell,
      },
      {
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.vulnerability_type} columnName={columnHeaders.vulnerabilityType} />,
        accessor: 'vulnerabilityType',
        width: '12%',
        Cell: TextCell,
      },
      {
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.test_name} columnName={columnHeaders.name} />,
        accessor: 'name',
        width: '15%',
        Cell: TextCell,
      },
      {
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.issue_severity} columnName={columnHeaders.severity} />,
        accessor: 'severity',
        width: '6%',
        Cell: SeverityCell,
      },
      {
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.control_name} columnName={columnHeaders.securityTool} />,
        accessor: 'controlName',
        width: '10%',
        Cell: ControlNameCell,
      },
      {
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.asset_type} columnName={columnHeaders.assetType} />,
        accessor: 'assetType',
        width: '10%',
        Cell: AssetTypeCell,
      },
      {
        Header: <FindingsTableGeneralHeader columnKey={ServerFindingKeys.location_text} columnName={columnHeaders.location} />,
        accessor: 'locationText',
        width: '8%',
        Cell: TextCell,
      },
      {
        Header: <FindingsTableGeneralHeader
          columnKey={ServerFindingKeys.ignored}
          columnName={columnHeaders.ignored}
        />,
        accessor: 'ignored',
        width: '8%',
        Cell: (props: ITableCell<IFindingWithSelected>) => <FindingIgnoredStatusCell {...props} isReadOnly={isIgnoreRulesReadOnly} updateFindingIgnoredState={updateFindingIgnoredState} />,
      },
    ];

    if (isFindingDetailsTabOpen) {
      const columnsToHideOnTabOpen = [ServerFindingKeys.ignored, ServerFindingKeys.vulnerability_type];
      columns = columns.filter((column) => !columnsToHideOnTabOpen.includes(column.accessor as ServerFindingKeys));
    }

    return { columns };
  }, [columnHeaders.assetType, columnHeaders.firstDetected, columnHeaders.ignored, columnHeaders.location, columnHeaders.name, columnHeaders.planLayer,
    columnHeaders.securityTool, columnHeaders.severity, columnHeaders.status, columnHeaders.vulnerabilityType, currentSortColumn?.direction,
    isFindingDetailsTabOpen, isIgnoreRulesReadOnly, isPageSelected, onChangeSortOrder, setPageSelected, updateFindingIgnoredState]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return columnsData as TableColumns<any>;
};
