import Markdown from 'markdown-to-jsx';
import { FC, MouseEvent, ReactElement, useState } from 'react';

import styles from './ExtendedActionActions.module.scss';

import { ArrowRight } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMultiActionButton } from 'components/JitMultiActionButton/JitMultiActionButton';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import colors from 'themes/colors.module.scss';
import { IActionOption, IMouseEvent } from 'types/interfaces';
import { markdownLinksProps } from 'utils/constants/markdownLinksProps';

interface ExtendedCodeActionsProps {
  disableActions: boolean
  remediateFindingsAction?: (event: IMouseEvent) => void
  handleOpenIgnoreDialog: (event: IMouseEvent) => void
  handleCreateStory: (event: IMouseEvent) => void
  fixButtonText?: string
  guidelines: string
  isOpenPRLoading: boolean
  isCreateStoryLoading: boolean
  isCreateStoryDisabled: boolean
  actionId: string,
  actionName: string,
  ignoreButtonText: string,
  createTicketText: string,
}

export const ExtendedActionActions: FC<ExtendedCodeActionsProps> = ({
  disableActions, remediateFindingsAction, handleOpenIgnoreDialog, guidelines, isOpenPRLoading, actionId, actionName, fixButtonText,
  handleCreateStory, ignoreButtonText, createTicketText, isCreateStoryLoading, isCreateStoryDisabled,
}) => {
  const [isGuidelineOpen, setIsGuidelineOpen] = useState(false);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const createStoryWrapper = (wrapped: ReactElement) => (
    <JitTooltip
      followCursor={false}
      offset={[0, 10]}
      placement='left'
      title='tickets.noIntegrationActionTooltip'
    >
      {wrapped}
    </JitTooltip>
  );

  const isCreateStoryCurrentlyDisabled = disableActions || isCreateStoryDisabled || isCreateStoryLoading;

  const buttonsOptions: IActionOption[] = [
    {
      handleClick: handleOpenIgnoreDialog,
      text: ignoreButtonText,
      disabled: disableActions,
    },
    {
      handleClick: handleCreateStory,
      text: createTicketText,
      disabled: isCreateStoryCurrentlyDisabled,
      wrapper: isCreateStoryCurrentlyDisabled ? createStoryWrapper : undefined,
    },
  ];

  if (remediateFindingsAction && fixButtonText) {
    // Since we don't always have a primary fix action, we'll add it only if it's available.
    // For example, non-code controls don't have a "fix" action.
    buttonsOptions.unshift({
      handleClick: remediateFindingsAction,
      text: fixButtonText,
      disabled: disableActions || isOpenPRLoading,
    });
  }

  const handleOpenCloseGuideline = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    setIsGuidelineOpen((prev: boolean) => !prev);
    sendAnalyticsEvent({
      action: 'guidelines-clicked',
      params: {
        actionId,
        actionName,
      },
    });
  };

  return (
    <div className={styles.wrapper} data-testid='actionCardActions'>
      <div className={styles.cardActions}>

        <div className={styles.actionGuidelinesWrapper} onClick={handleOpenCloseGuideline} role='button' tabIndex={0}>

          <JitIcon color={colors.iris} icon={ArrowRight} rotateDegrees={!isGuidelineOpen ? 0 : 90} />

          <JitText
            bold
            color={colors.iris}
            data-testid='actionCardGuidelines'
            display='inline'
            size='m'
            sx={{
              '&:hover': {
                textDecoration: 'underline',
                textDecorationColor: colors.iris,
                textUnderlineOffset: '3px',
              },
            }}
            text='pages.actions.actionCard.actions.guidelines'
          />
        </div>

        <JitMultiActionButton
          data-testid='multiActionButton'
          disableActions={disableActions}
          isLoading={isOpenPRLoading || isCreateStoryLoading}
          options={buttonsOptions}
        />

      </div>

      {isGuidelineOpen && (
        <Markdown className={styles.markdownText} data-testid='markdown' style={{ color: colors.lightGray }} {...markdownLinksProps}>
          {guidelines}
        </Markdown>
      )}

    </div>
  );
};
