import { Diff } from 'deep-diff';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { initialPlan } from './constants';

import { IPlanInstance, ITenantPlanItem } from 'types/interfaces';

interface IPlanInstanceContext {
  plan: IPlanInstance;
  fetchedPlan: IPlanInstance;
  setPlan: Dispatch<SetStateAction<IPlanInstance>>;
  setFetchedPlan: Dispatch<SetStateAction<IPlanInstance>>;
  isPlanActivated: boolean;
  isPlanExist: boolean;
  planUncommitedChanges?: Diff<IPlanInstance, IPlanInstance>[];
  commitCurrentPlan: (updatedPlan: IPlanInstance) => void;
  isPlanItemActive: (planItemSlug: string) => boolean;
  handleCodeChange: (newValue: string) => void;
  isPlanValid?: boolean;
  planItemSlugsInCommit: string[];
  getActivePlanItemsFromItemNames?: (planItemsNames: string[]) => string[];
  isLoadingPlan: boolean;
  setIsLoadingPlan: (isLoading: boolean) => void;
  setPlanItemSlugsInCommit: Dispatch<SetStateAction<string[]>>;
  setInitialPlan: () => void;
  hasPlanFetched: boolean;
  planItems?: ITenantPlanItem[];
  setPlanItems: Dispatch<SetStateAction<ITenantPlanItem[] | undefined>>;
  initPlanItems: () => void;
  getPlanItemBySlug: (slug: string) => ITenantPlanItem | undefined;
  updatePlanItem: (planItem: ITenantPlanItem) => void;
}

export const PlanInstanceContext = createContext<IPlanInstanceContext>({
  planItemSlugsInCommit: [],
  commitCurrentPlan: () => {},
  isPlanItemActive: () => false,
  handleCodeChange: () => undefined,
  setPlan: () => undefined,
  setFetchedPlan: () => undefined,
  plan: initialPlan,
  fetchedPlan: initialPlan,
  isLoadingPlan: false,
  setIsLoadingPlan: () => {},
  isPlanActivated: false,
  isPlanExist: false,
  setPlanItemSlugsInCommit: () => [],
  setInitialPlan: () => {},
  hasPlanFetched: false,
  planItems: undefined,
  setPlanItems: () => {},
  initPlanItems: () => {},
  getPlanItemBySlug: () => undefined,
  updatePlanItem: () => {},
});

export const usePlanInstanceContext = () => useContext(PlanInstanceContext);
