/* eslint-disable react/no-multi-comp */
import { Dispatch, SetStateAction } from 'react';

import { ConfigurationTrigger } from './ConfigurationTrigger/ConfigurationTrigger';
import { AwsIntegration } from './Integrations/AwsIntegration/AwsIntegration';
import { IntegrationProps, ConfigurationButton, ConfigurationDetailsStep, ConfigurationsComponentDetails, SlackNotificationsProps, ZapProps } from './interfaces/configurations';
import { SlackNotifications } from './Notifications/SlackNotifications';
import { zapValidator } from './utils/zapValidator';
import { ConfigurationsZap } from './Zap/ConfigurationsZap';

import { OpenInNewTab, Zap } from 'assets';
import { constants } from 'globalConstants';
import { i18n } from 'locale/i18n';
import { AssetType, Vendor } from 'types/enums';
import { ApplicationTypes } from 'types/interfaces';

interface Props {
  setIsDoneStep: Dispatch<SetStateAction<boolean>>;
}

const awsIntegrationStep = (button?: ConfigurationButton): ConfigurationDetailsStep => ({
  stepDisplayText: 'pages.plan.planItemsCategory.steps.awsIntegration',
  ConfigurationComponent: ({ setIsDoneStep, setShouldHideStepsDialog }: IntegrationProps) => (
    <AwsIntegration
      dialogCardWidth='100%'
      setIsDoneStep={setIsDoneStep}
      setShouldHideStepsDialog={setShouldHideStepsDialog}
    />
  ),
  button,
  title: 'pages.plan.configurationDialog.awsIntegration.title',
  subtitle: 'pages.plan.configurationDialog.awsIntegration.subtitle',
});

const awsConfigurationComponent: ConfigurationsComponentDetails = ({
  steps: [
    awsIntegrationStep({
      text: 'dialogs.buttons.activate',
      disabledTooltip: 'pages.plan.configurationDialog.awsIntegration.disabledTooltip',
    }),
  ],
});

export const configurationComponentByVendor: { [index: string]: ConfigurationsComponentDetails } = {
  [Vendor.AWS]: awsConfigurationComponent,
  [Vendor.ZAP_API]: {
    steps: [{
      stepDisplayText: 'pages.plan.planItemsCategory.steps.targetApplication',
      ConfigurationComponent: (
        { setIsDoneStep, onConfigurationChange }: ZapProps,
      ) => <ConfigurationsZap onChangeConfiguration={onConfigurationChange} planItemSlug={constants.PLAN_ITEMS_SLUG.ZAP_API} setIsDoneStep={setIsDoneStep} type={AssetType.API} />,
      button: {
        text: 'dialogs.buttons.next',
        disabledTooltip: 'pages.plan.configurationsDialog.missingField',
      },
      title: 'configurations.zap.api.title',
      subtitle: 'configurations.zap.api.subtitle',
      subtitleLink: {
        href: i18n.t('configurations.zap.api.linkHref'),
        text: 'configurations.zap.api.linkText',
        icon: OpenInNewTab,
      },
      validation: zapValidator(AssetType.API),
    },
    {
      stepDisplayText: 'pages.plan.planItemsCategory.steps.trigger',
      ConfigurationComponent: ({ setIsDoneStep }: Props) => (
        <ConfigurationTrigger
          applicationType={ApplicationTypes.ZAP_API}
          planItemSlug={constants.PLAN_ITEMS_SLUG.ZAP_API}
          setIsDoneStep={setIsDoneStep}
        />
      ),
      button: {
        text: 'dialogs.buttons.next',
        disabledTooltip: 'pages.plan.configurationDialog.trigger.disabledTooltip',
        commitOnCLick: true,
      },
      title: 'pages.plan.configurationDialog.trigger.title',
      subtitle: 'configurations.zap.triggerSubtitle',
    },
    {
      stepDisplayText: 'pages.plan.planItemsCategory.steps.notifications',
      // eslint-disable-next-line react/no-multi-comp
      ConfigurationComponent: ({ setIsDoneStep, stepIndex }: SlackNotificationsProps) => (
        <SlackNotifications applicationType={ApplicationTypes.ZAP_API} planItemSlug={constants.PLAN_ITEMS_SLUG.ZAP_API} setIsDoneStep={setIsDoneStep} stepIndex={stepIndex} />
      ),
      button: {
        text: 'dialogs.buttons.activate',
        disabledTooltip: 'pages.plan.configurationsDialog.missingField',
      },
      title: 'configurations.notifications.title',
      subtitle: 'configurations.notifications.subtitle',
    }],
    icon: Zap,
    analyticsEvent: { action: 'Zap-Configure-Complete' },
    requiredAssetType: AssetType.API,
  },
  [Vendor.ZAP_WEB]: {
    steps: [{
      stepDisplayText: 'pages.plan.planItemsCategory.steps.targetApplication',
      ConfigurationComponent: (
        { setIsDoneStep, onConfigurationChange }: ZapProps,
      ) => <ConfigurationsZap onChangeConfiguration={onConfigurationChange} planItemSlug={constants.PLAN_ITEMS_SLUG.ZAP_WEB} setIsDoneStep={setIsDoneStep} type={AssetType.WEB} />,
      button: {
        text: 'dialogs.buttons.next',
        disabledTooltip: 'pages.plan.configurationsDialog.missingField',
      },
      title: 'configurations.zap.web.title',
      subtitle: 'configurations.zap.web.subtitle',
      subtitleLink: {
        href: i18n.t('configurations.zap.web.linkHref'),
        text: 'configurations.zap.web.linkText',
        icon: OpenInNewTab,
      },
      validation: zapValidator(AssetType.WEB),
    },
    {
      stepDisplayText: 'pages.plan.planItemsCategory.steps.trigger',
      ConfigurationComponent: ({ setIsDoneStep }: Props) => (
        <ConfigurationTrigger
          applicationType={ApplicationTypes.ZAP_WEB}
          planItemSlug={constants.PLAN_ITEMS_SLUG.ZAP_WEB}
          setIsDoneStep={setIsDoneStep}
        />
      ),
      button: {
        text: 'dialogs.buttons.next',
        disabledTooltip: 'pages.plan.configurationDialog.trigger.disabledTooltip',
        commitOnCLick: true,
      },
      title: 'pages.plan.configurationDialog.trigger.title',
      subtitle: 'configurations.zap.triggerSubtitle',
    },
    {
      stepDisplayText: 'pages.plan.planItemsCategory.steps.notifications',
      // eslint-disable-next-line react/no-multi-comp
      ConfigurationComponent: ({ setIsDoneStep, stepIndex }: SlackNotificationsProps) => (
        <SlackNotifications applicationType={ApplicationTypes.ZAP_WEB} planItemSlug={constants.PLAN_ITEMS_SLUG.ZAP_WEB} setIsDoneStep={setIsDoneStep} stepIndex={stepIndex} />
      ),
      button: {
        text: 'dialogs.buttons.activate',
        disabledTooltip: 'pages.plan.configurationsDialog.missingField',
      },
      subtitle: 'configurations.notifications.subtitle',
      title: 'configurations.notifications.title',
    }],
    icon: Zap,
    analyticsEvent: { action: 'Zap-Configure-Complete' },
    requiredAssetType: AssetType.WEB,
  },
};
