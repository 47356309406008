import { FC, useEffect } from 'react';

import { NewPipelinesPage } from './NewPipelinesPage';

import { usePipelineContextNew } from 'context';

export const NewPipelinesPageWrapper: FC = () => {
  const { fetchPipelines, filters } = usePipelineContextNew();
  useEffect(() => {
    fetchPipelines(filters);
  }, [fetchPipelines]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <NewPipelinesPage />
  );
};
