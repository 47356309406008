import { FC, useState } from 'react';

import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { useAuthContext } from 'context';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import {
  DialogContent,
} from 'pages/InsightsPage/SubPages/PerformancePage/components/ShareSnapshotDialog/components/DialogContent';
import {
  useSubscribeToSnapshotWebsocket,
} from 'pages/InsightsPage/SubPages/PerformancePage/components/ShareSnapshotDialog/hooks/useSubscribeToSnapshotWebsocket';
import { logInfo } from 'services/logger/logger';
import { createSnapshot as createSnapshotRequest } from 'services/MetricService/MetricService';
import { TimeAgo } from 'types/enums';
import { Snapshot } from 'types/interfaces/Metrics/Snapshots';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  period: TimeAgo;
}

export const ShareSnapshotDialog: FC<Props> = ({ isOpen, onClose, period }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createdSnapshot, setCreatedSnapshot] = useState<Snapshot | undefined>(undefined);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  useSubscribeToSnapshotWebsocket(createdSnapshot, setCreatedSnapshot);
  const { frontEggUser } = useAuthContext();

  const createSnapshot = async (snapshotName: string, snapshotExpiration: TimeAgo) => {
    sendAnalyticsEvent({ action: 'create-snapshot-clicked' });
    logInfo('Creating snapshot', {
      snapshotName,
      snapshotExpiration,
    });
    setIsLoading(true);
    const isPublic = false; // TODO: this should be a state in the future
    const snapshot = await createSnapshotRequest(snapshotName, period, snapshotExpiration, isPublic, frontEggUser.name);
    logInfo('Created snapshot', { snapshot });
    setCreatedSnapshot(snapshot);
  };

  return (
    <CustomDialog
      content={(<DialogContent closeDialog={onClose} createdSnapshot={createdSnapshot} createSnapshot={createSnapshot} isLoading={isLoading} />)}
      dataTestId='share-snapshot-dialog'
      isOpen={isOpen}
      onClose={onClose}
      title='pages.performance.shareSnapshot.dialogTitle'
      width='l'
      withDivider
    />
  );
};
