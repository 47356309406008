import { FC, ReactNode } from 'react';

import styles from './StatisticsWidget.module.scss';

import { CircularPercentage } from 'components/CircularPercentage/CircularPercentage';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { PercentageChip } from 'components/PercentageChip/PercentageChip';
import { StatisticsRow } from 'pages/InsightsPage/components/StatisticsWidget/components/StatisticsRow/StatisticsRow';
import colors from 'themes/colors.module.scss';

interface StatisticsWidgetProps {
  totalAmount?: number;
  failedTooltipTitle?: string;
  passedTooltipTitle?: string;
  totalTooltipTitle?: string;
  passedTitle?: string;
  failedTitle?: string;
  failedAmount?: number;
  passedAmount?: number;
  failedPercentage?: number;
  passedPercentage?: number;
  header?: ReactNode
}

export const StatisticsWidget: FC<StatisticsWidgetProps> = ({
  totalTooltipTitle = '',
  failedTooltipTitle,
  passedTooltipTitle,
  passedTitle = '',
  failedTitle = '',
  totalAmount,
  failedAmount,
  passedAmount,
  failedPercentage,
  passedPercentage,
  header }) => {
  const circularPercentageContent = (
    <JitTooltip
      followCursor={false}
      placement='left'
      title={totalTooltipTitle}
    >
      <JitText align='center' bold size='xl' text={totalAmount ?? '-'} />
    </JitTooltip>
  );

  const getCircularPercentageValue = () => (failedAmount && totalAmount ? (failedAmount / totalAmount) * 100 : 0);

  return (
    <div className={styles.wrapper}>
      {header}

      <div className={styles.statisticSection}>

        <CircularPercentage
          backgroundPartTooltipText={passedTooltipTitle}
          color={colors.failedStatistics}
          content={circularPercentageContent}
          size={65}
          value={getCircularPercentageValue()}
          valuePartTooltipText={failedTooltipTitle}
        />

        <div className={styles.rowsWrapper}>
          <div className={styles.row}>
            <StatisticsRow
              bulletColor={colors.failedStatistics}
              number={failedAmount}
              text={failedTitle}
            />

            {failedPercentage && <PercentageChip percentage={failedPercentage} positiveTrendIndicatorDirection='down' />}
          </div>

          <div className={styles.row}>
            <StatisticsRow
              bulletColor={colors.gray}
              number={passedAmount}
              text={passedTitle}
            />

            {passedPercentage && <PercentageChip percentage={passedPercentage} positiveTrendIndicatorDirection='up' />}
          </div>
        </div>
      </div>
    </div>
  );
};

