import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { CreateSecretDialogContent } from './Components/CreateSecretDialogContent';
import { DeleteSecretDialogContent } from './Components/DeleteSecretDialogContent';
import { InitialSecretMessage } from './Components/InitialSecretMessage';
import { SecretRow } from './Components/SecretRow';
import styles from './SecretsManagementDialog.module.scss';

import { Plus } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { useSecretsContext } from 'context';
import { useSecretService } from 'services/SecretsService/useSecretService';
import colors from 'themes/colors.module.scss';
import { ITenantSecret } from 'types/interfaces/Secrets/ITenantSecret';

interface Props {}

export const SecretsManagementDialog: FC<Props> = () => {
  const { tenantSecrets, showSecretsManagement, setShowSecretsManagement, fetchTenantSecrets, isLoading, setTenantSecrets } = useSecretsContext();
  const [selectedSecret, setSelectedSecret] = useState<ITenantSecret | undefined>(undefined);
  const [showCreateSecretDialog, setShowCreateSecretDialog] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [isDeletingSecret, setIsDeletingSecret] = useState(false);
  const { saveTenantSecret, deleteTenantSecret } = useSecretService();

  const resetState = useCallback(() => {
    setShowCreateSecretDialog(false);
    setShowDeleteConfirmDialog(false);
    setIsDeletingSecret(false);
    setSelectedSecret(undefined);
  }, []);

  useEffect(() => {
    if (showSecretsManagement && tenantSecrets === undefined) {
      fetchTenantSecrets();
    }
  }, [fetchTenantSecrets, showSecretsManagement, tenantSecrets]);

  const editSecret = useCallback((tenantSecret: ITenantSecret) => {
    setShowCreateSecretDialog(true);
    setSelectedSecret(tenantSecret);
  }, []);

  const saveSecret = useCallback(async (secretName, secretValue) => {
    await saveTenantSecret({ secretName,
      secretValue });
  }, [saveTenantSecret]);

  const deleteSecret = useCallback(async (tenantSecret: ITenantSecret) => {
    setIsDeletingSecret(true);
    await deleteTenantSecret(tenantSecret.name);
    resetState();
    setTenantSecrets(tenantSecrets ? tenantSecrets?.filter((secret) => secret.name !== tenantSecret.name) : []);
  }, [deleteTenantSecret, resetState, setTenantSecrets, tenantSecrets]);

  const handleDeleteSecret = useCallback((tenantSecret: ITenantSecret) => {
    setSelectedSecret(tenantSecret);
    setShowDeleteConfirmDialog(true);
  }, []);

  const deleteConfirmDialog = useMemo(() => (
    selectedSecret && (
    <DeleteSecretDialogContent
      isLoading={isDeletingSecret}
      isOpen={showDeleteConfirmDialog}
      onClose={() => setShowDeleteConfirmDialog(false)}
      onDelete={() => selectedSecret && deleteSecret(selectedSecret)}
      selectedSecret={selectedSecret}
    />
    )
  ), [deleteSecret, isDeletingSecret, selectedSecret, showDeleteConfirmDialog]);

  const createSecretDialogContent = useMemo(() => (
    <CreateSecretDialogContent
      existingSecret={selectedSecret}
      onClose={() => {
        setShowCreateSecretDialog(false);
        resetState();
      }}
      onSave={saveSecret}
    />
  ), [resetState, saveSecret, selectedSecret]);

  const secretListDialogContent = useMemo(() => (
    <div className={styles.contentWrapper}>
      {deleteConfirmDialog}

      <JitText
        className={styles.subtitle}
        muted
        text='dialogs.secretsManagement.initialScreen.subtitle'
      />

      <div className={styles.tenantSecretsWrapper}>
        {tenantSecrets?.sort((a, b) => a.name.localeCompare(b.name)).map((secret) => (
          <SecretRow
            key={`${secret.name}-${secret.modifiedAt}`}
            onDelete={handleDeleteSecret}
            onEdit={editSecret}
            tenantSecret={secret}
          />
        ))}
      </div>

      <div className={styles.lowerPart}>
        <div className={styles.buttons}>
          <JitButton
            className={styles.createNewSecret}
            noHover
            onClick={() => setShowCreateSecretDialog(true)}
            showChildrenWhileLoading
            sx={{
              padding: 0,
            }}
          >
            <JitIcon color={colors.iris} icon={Plus} size={12} />

            <JitText color={colors.iris} noWrap text='dialogs.secretsManagement.createSecretButton' />
          </JitButton>

          <JitButton
            data-testid='doneButton'
            onClick={() => {
              resetState();
              setShowSecretsManagement(false);
            }}
            showChildrenWhileLoading
            variant='contained'
          >
            <JitText text='dialogs.secretsManagement.doneButton' />
          </JitButton>
        </div>
      </div>
    </div>
  ), [deleteConfirmDialog, editSecret, handleDeleteSecret, resetState, setShowSecretsManagement, tenantSecrets]);

  const dialogContent = useMemo(() => {
    if (isLoading) {
      return (
        <div>
          <LoadingBar />
        </div>
      );
    }
    if (showCreateSecretDialog) {
      return createSecretDialogContent;
    }
    if (!tenantSecrets?.length) {
      return (<InitialSecretMessage onCreate={() => setShowCreateSecretDialog(true)} />);
    }

    return secretListDialogContent;
  }, [createSecretDialogContent, isLoading, secretListDialogContent, showCreateSecretDialog, tenantSecrets?.length]);

  return (
    <CustomDialog
      content={(
        <div className={styles.wrapper}>
          {dialogContent}
        </div>
)}
      dataTestId='secretsManagementDialog'
      height='s'
      isOpen={showSecretsManagement}
      onClose={() => {
        resetState();
        setShowSecretsManagement(false);
      }}
      title='dialogs.secretsManagement.title'
      width='l'
      withDivider
    />

  );
};
