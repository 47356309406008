import { useMemo } from 'react';

import { constants } from 'globalConstants';

export interface InsightsOption {
  key: string;
  displayText?: string;
  shouldHide?: boolean;
  isMenuOption: boolean;
}

export const useGetInsightsOptions = () => {
  const { insights: { DEPLOYMENTS, PULL_REQUESTS, PERFORMANCE, SNAPSHOT_NOT_FOUND, SECURITY_IMPACT } } = constants.routes;

  const insightsOptions: InsightsOption[] = useMemo(() => [{
    key: SECURITY_IMPACT,
    displayText: 'pages.securityImpact.title',
    isMenuOption: true,
  }, {
    key: PERFORMANCE,
    displayText: 'pages.performance.pageName',
    isMenuOption: true,
  }, {
    key: PULL_REQUESTS,
    displayText: 'pages.pullRequests.title',
    isMenuOption: true,
  }, {
    key: DEPLOYMENTS,
    displayText: 'pages.deployments.title',
    isMenuOption: true,
  }, {
    key: SNAPSHOT_NOT_FOUND,
    isMenuOption: false,
  }], [DEPLOYMENTS, PERFORMANCE, PULL_REQUESTS, SECURITY_IMPACT, SNAPSHOT_NOT_FOUND]);

  return insightsOptions;
};
