import { ISegmentColors } from 'pages/SecurityMappingPage/interfaces/ISegmentsMapping';

export enum SegmentId {
  Code = 'code',
  Build = 'build',
  Test = 'test',
  Release = 'release',
  Deploy = 'deploy',
  Operate = 'operate',
  Monitor = 'monitor',
  Plan = 'plan',
}

export interface IGradientAttributes {
  coordinates: {
    x1: string;
    x2: string;
    y1: string;
    y2: string;
  }
}

export interface ITooltipAttributes {
  width: string;
  height: string;
  position: {
    x: string;
    y: string;
  },
}

export interface ISegmentSettings {
  gradient: IGradientAttributes;
  tooltip: ITooltipAttributes;
}

export interface ISegmentWrapper {
  colors: ISegmentColors;
  isTheLoopHovered: boolean;
  activities?: string[];
  onSegmentClick?: (e: React.MouseEvent) => void;
  onSegmentMouseEnter: (e: React.MouseEvent) => void;
  onSegmentMouseLeave: (e: React.MouseEvent) => void;
}

export interface ISegment extends ISegmentWrapper {
  id: SegmentId;
  path: string;
  textPath: string;
  settings: ISegmentSettings;
}

