import { differenceWith, isEqual } from 'lodash';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { useAssetsManagementTabDetailsByTab } from '../useAssetsManagementTabDetailsByTab/useAssetsManagementTabDetailsByTab';

import { TABS } from 'components/JitDialogs/AssetsManagementDialog/constants';
import { useAssetsContext, useDialogsContext } from 'context';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { Dialogs } from 'types/enums';
import { IAsset, IChangeInputEvent } from 'types/interfaces';

export const useAssetsDialogManagement = (
  defaultTab: TABS,
  onClose?: () => void,
  tagAllAsCheckedForVendors?: string[], // Vendors for which all assets should be checked (checkbox ticked) at opening by default
) => {
  const { assets, initAssets, centralizedRepo } = useAssetsContext();
  const { hideDialog } = useDialogsContext();
  const { updateMultipleAssets } = useAssetService();

  const [searchedAsset, setSearchedAsset] = useState<string>('');
  const [dialogAssets, setDialogAssets] = useState<IAsset[]>([]);
  const [selectedTab, setSelectedTab] = useState<TABS>(defaultTab);

  useEffect(() => {
    if (assets) {
      if (tagAllAsCheckedForVendors) {
        setDialogAssets(assets.map((asset) => (!asset.is_covered && tagAllAsCheckedForVendors.includes(asset.vendor) ? ({
          ...asset,
          is_covered: true,
        }) : asset)));
      } else {
        setDialogAssets(assets);
      }
    }
  }, [assets, tagAllAsCheckedForVendors]);

  const tabDetails = useAssetsManagementTabDetailsByTab(selectedTab);
  const { shouldAppearInCurrentSelectedTab } = tabDetails;

  const handleTabChange = (event: SyntheticEvent, newValue: TABS) => {
    setSelectedTab(newValue);
  };

  const checkAsset = (assetId: string, checked: boolean) => {
    setDialogAssets((prevDialogAssets) => prevDialogAssets.map((asset) => (assetId === asset.asset_id ? ({
      ...asset,
      is_covered: checked,
    }) : asset)));
  };

  const checkMultipleAssetsByOwner = (owner: string, checked: boolean) => {
    setDialogAssets((prevDialogAssets) => prevDialogAssets.map((dialogAsset) => {
      const isCentralizedRepo = dialogAsset.asset_id === centralizedRepo?.asset_id;
      if (owner === dialogAsset.owner && !isCentralizedRepo) {
        return {
          ...dialogAsset,
          is_covered: checked,
        };
      }
      return dialogAsset;
    }));
  };

  const handleSyncUpdatedAssets = async () => {
    const assetsToSync = differenceWith(dialogAssets, assets || [], isEqual);
    if (assetsToSync.length) {
      const res = await updateMultipleAssets(assetsToSync);
      if (!res?.data) return;
      initAssets();
    }
  };

  const handleToggleSelectAllAssets = (e: IChangeInputEvent, checked: boolean) => {
    setDialogAssets((prevDialogAssets) => prevDialogAssets.map((dialogAsset) => {
      const isCentralizedRepo = dialogAsset.asset_id === centralizedRepo?.asset_id;
      if (shouldAppearInCurrentSelectedTab(dialogAsset) && !isCentralizedRepo) {
        return {
          ...dialogAsset,
          is_covered: checked,
        };
      }

      return dialogAsset;
    }));
  };

  const handleSearchAsset = (e: IChangeInputEvent) => setSearchedAsset(e.target.value);
  const searchedAssets = useMemo(
    () => dialogAssets.filter((asset) => asset.asset_name?.toLocaleLowerCase()?.includes(searchedAsset?.toLocaleLowerCase())),
    [dialogAssets, searchedAsset],
  );

  const isAllAssetsSelectedTabCovered = useMemo(
    () => dialogAssets.filter((asset) => shouldAppearInCurrentSelectedTab(asset)).every((asset) => asset.is_covered),
    [dialogAssets, shouldAppearInCurrentSelectedTab],
  );

  const displayedAssets = useMemo(
    () => searchedAssets?.filter((asset) => shouldAppearInCurrentSelectedTab(asset)),
    [searchedAssets, shouldAppearInCurrentSelectedTab],
  );

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      hideDialog(Dialogs.AssetsManagement);
    }
  };

  const handleSave = () => {
    handleClose();
    handleSyncUpdatedAssets();
  };

  const ownerDisplayNames = dialogAssets.reduce((acc, asset) => {
    // With AWS assets, each asset have a specific sub-account (which mean a specific environment).
    // All of these accounts, which are actually relate to the same account, will have the same 'owner' value which
    // is the aws account value of the main account.
    // This is why for each one of these assets we look back to the full list of assets and search for the main
    // account asset, and then bring the asset_name from there. Then the mapping is created correctly.
    //
    // If selectedTab is not AWS, the ownerDisplayNames keys will be the same as the values for each item.

    const displayName = selectedTab === TABS.AWS ? dialogAssets.find((currAsset) => currAsset.aws_account_id === asset.owner)?.asset_name : asset.owner;
    return {
      ...acc,
      [asset.owner]: displayName,
    };
  }, {});

  return {
    isAllAssetsSelectedTabCovered,
    handleSave,
    handleClose,
    handleToggleSelectAllAssets,
    handleSearchAsset,
    checkMultipleAssetsByOwner,
    checkAsset,
    handleTabChange,
    selectedTab,
    displayedAssets,
    ownerDisplayNames,
    tabDetails,
  };
};
