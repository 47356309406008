
export enum ActionType {
  PageViewed = 'page-viewed',
}

export enum PageName {
  Home = 'Home',
  SecurityPlans = 'Security Plans',
  Findings = 'Findings',
  Matrix = 'Matrix',
  Integrations = 'Integrations',
  Pipelines = 'Pipelines',
  SecurityImpact = 'Security Impact',
  PullRequests = 'Pull Requests',
  Deployments = 'Deployments',
  Performance = 'Performance',
  Teams = 'Teams',
  Actions = 'Actions',
  Overview = 'Overview',
  Logout = 'Logout',
  Onboarding = 'Onboarding',
  Sbom = 'Sbom',
  SecurityMapping = 'Security Mapping',
}
