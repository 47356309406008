import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './SelectPageDropdown.module.scss';

import { ExpandArrow } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMenu } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { InsightsOption } from 'pages/InsightsPage/hooks/useGetInsightsOptions';
import colors from 'themes/colors.module.scss';
import { MenuItemKeyType } from 'types/interfaces';

interface Props {
  insightsOptions: InsightsOption[];
  selectedInsightPage: string;
}

export const SelectPageDropdown: FC<Props> = ({
  insightsOptions,
  selectedInsightPage,
}) => {
  const { insights: { BASE_ROUTE: BASE_INSIGHTS_ROUTE } } = constants.routes;

  const navigate = useNavigate();

  const selectedInsight = useMemo(
    () => insightsOptions.find((insight) => insight.key === selectedInsightPage),
    [insightsOptions, selectedInsightPage],
  );

  const displayedOptions = insightsOptions.filter((insight) => insight.isMenuOption);

  const menuItems = displayedOptions.map((insight) => ({
    itemKey: insight.key,
    itemContent: <JitText text={insight.displayText} />,
    isSelected: insight === selectedInsight,
  }));

  const handleSetSelectedInsight = (itemKey: MenuItemKeyType) => {
    navigate(`/${BASE_INSIGHTS_ROUTE}/${itemKey}`);
  };
  return (
    <div className={styles.wrapper}>
      <JitText data-testid='insightsMenuTitle' size='l' text='pages.insights.insightsMenuTitle' />

      <JitMenu
        data-testid='insightsMenu'
        menuItems={menuItems}
        menuSelectedDesign='fillRow'
        onMenuItemClick={(item) => handleSetSelectedInsight(item.itemKey)}
        paperProps={{ style: { width: 140 } }}
        shouldCloseOnItemClick
      >
        <div className={styles.menuDisplay}>
          <JitText bold color={colors.iris} data-testid='selectInsightPage' size='l' text={selectedInsight!.displayText || 'pages.insights.insightsMenuPlaceholder'} />

          <JitIcon color={colors.iris} data-testid='filterExpandIcon' icon={ExpandArrow} size={7} />
        </div>

      </JitMenu>
    </div>
  );
};

