
import { CommitPlanRequired, NoFindings } from './components';

import { MissingGithubIntegration } from 'components/JitTable/components';
import { AppliedFiltersHasNoData } from 'components/JitTable/components/AppliedFiltersHasNoData/AppliedFiltersHasNoData';
import { useTenantContext } from 'context';
import { useClearFilters } from 'context/FindingsContext/hooks/useClearFilters';
import { useHasVisibleFilters } from 'context/FindingsContext/hooks/useHasVisibleFiltersCheck';
import { usePlanInstanceContext } from 'context/PlanInstanceContext/PlanInstanceContext';

export const EmptyFindingsTable = () => {
  const hasVisibleFilters = useHasVisibleFilters();
  const { isPlanActivated } = usePlanInstanceContext();
  const clearFilters = useClearFilters();
  const { isGithubIntegrated } = useTenantContext();
  if (!isGithubIntegrated) {
    return <MissingGithubIntegration subtitle='pages.findings.emptyFindingsTable.missingGithubIntegration' />;
  }
  if (!isPlanActivated) {
    return <CommitPlanRequired />;
  }
  if (hasVisibleFilters()) {
    return <AppliedFiltersHasNoData clearFilters={clearFilters} entityName='findings' />;
  }
  return <NoFindings />;
};
