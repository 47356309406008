import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useHandleAssetsWebSocketNotification } from './assetHooks/useHandleAssetsWebSocketNotification';

import { AssetsContext, useTenantContext } from 'context';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { logError, logInfo } from 'services/logger/logger';
import { AssetType, Vendor, WebSocketNotificationTopics } from 'types/enums';
import { IAsset } from 'types/interfaces';
import { vendorsThatDontRequireIntegration, vendorsWithIntegration } from 'utils/constants/vendorIntegrations';
import { getVendorForAssetType } from 'utils/functions/matchVendorToAssetType';

export const AssetsProvider: FC = ({ children }) => {
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);
  const [isInitializeAssets, setIsInitializeAssets] = useState(false);
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { githubInstallation, isLoadingInstallation } = useTenantContext();
  const { getAssets } = useAssetService();
  const { handleAssetsWebSocketNotification } = useHandleAssetsWebSocketNotification({ setAssets });

  const initAssets = useCallback(async () => {
    setIsLoadingAssets(true);
    const res = await getAssets();
    if (!res) {
      console.error('Error getting assets');
    } else if (res?.status === 200) {
      setAssets(res.data);
    }
    setIsLoadingAssets(false);
    setIsInitializeAssets(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initAssets();
  }, [initAssets]);

  const centralizedRepo = useMemo(() => {
    const centralizedRepoAsset = assets.find((asset) => asset?.asset_id === githubInstallation?.centralized_repo_asset_id);
    if (!isLoadingInstallation && !isLoadingAssets && isInitializeAssets && !centralizedRepoAsset && githubInstallation) {
      logError(`Centralized repo asset not found for installation ${githubInstallation?.installation_id}`);
    } else if (centralizedRepoAsset) {
      logInfo(`Centralized repo asset ${centralizedRepoAsset.asset_id} found for installation ${githubInstallation?.installation_id}`);
    }
    return centralizedRepoAsset;
  }, [assets, githubInstallation, isLoadingAssets, isLoadingInstallation, isInitializeAssets]);

  const repoAssets = useMemo(() => assets.filter((asset) => asset.asset_type === AssetType.REPO && asset.asset_id !== centralizedRepo?.asset_id), [assets, centralizedRepo?.asset_id]);

  const awsAccountAssets = useMemo(() => assets.filter((asset) => asset.asset_type === AssetType.AWS_ACCOUNT), [assets]);

  const getAssetById = useCallback((assetId: string) => assets.find((asset) => asset.asset_id === assetId), [assets]);

  const getAssetsByVendor = useCallback((vendor: Vendor) => assets.filter((asset) => asset.vendor === vendor.toString()), [assets]);

  const isAssetTypeProperlyIntegrated = useCallback((assetType: AssetType) => {
    const vendor = getVendorForAssetType(assetType);
    if (!!vendor && vendorsWithIntegration.includes(vendor) && !vendorsThatDontRequireIntegration.includes(vendor)) {
      const assetsByVendor = getAssetsByVendor(vendor);
      return !!assetsByVendor?.length;
    }

    return true;
  }, [getAssetsByVendor]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Asset, handleAssetsWebSocketNotification);
  }, [handleAssetsWebSocketNotification, websocketSubscribe]);

  const value = useMemo(() => ({
    assets,
    centralizedRepo,
    repoAssets,
    awsAccountAssets,
    initAssets,
    getAssetById,
    isLoadingAssets,
    getAssetsByVendor,
    isAssetTypeProperlyIntegrated,
  }), [assets, centralizedRepo, repoAssets, awsAccountAssets, initAssets, getAssetById, isLoadingAssets, getAssetsByVendor, isAssetTypeProperlyIntegrated]);

  return (
    <AssetsContext.Provider value={value}>
      {children}
    </AssetsContext.Provider>
  );
};
