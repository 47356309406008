import { kebabCase } from 'lodash';
import { useCallback } from 'react';

import { ActionType } from './constants';

import { analyticsEvents, useAnalyticsContext } from 'context/AnalyticsContext';
import { useAuthContext } from 'context/AuthContext';
import { IAnalyticsEvent, IDictionary, IFronteggUser, IPlanInstance } from 'types/interfaces';
import { getPlansDiff } from 'utils/functions/getPlansDiff/getPlansDiff';

export const useSendAnalyticsEvent = () => {
  const { analytics } = useAnalyticsContext();
  const { frontEggUser } = useAuthContext();

  const sendAnalyticsEvent = useCallback((analyticEvent: IAnalyticsEvent) => {
    const { action = '', params: inputParams = {} } = analyticEvent;

    const userParams: IDictionary<string> = {};

    if (frontEggUser) {
      userParams.userId = frontEggUser.id;
      userParams.email = frontEggUser.email;
      userParams.userName = frontEggUser.name;
      userParams.tenantId = frontEggUser.tenantId;
    }

    const params: IDictionary<string> = {
      ...inputParams,
      ...userParams,
      isLoggedIn: (!!frontEggUser?.id).toString(),
    };
    const analyticsActionKebab = kebabCase(action);
    const actionString = analyticsEvents[analyticsActionKebab] || analyticsActionKebab;
    try {
      if (action === ActionType.PageViewed) {
        analytics?.page(params.page, params);
      } else {
        analytics?.track(actionString, params);
      }
    } catch (e) {
      console.error('Error sending analytics event');
    }
  }, [frontEggUser, analytics]);

  const sendAnalyticsAuthEvent = useCallback((user: IFronteggUser) => {
    analytics?.identify(user.id, user);
    sendAnalyticsEvent({ action: 'login' });
  }, [analytics, sendAnalyticsEvent]);

  const sendAnalyticsPlanDiffEvent = useCallback((plan: IPlanInstance, originalPlan: IPlanInstance) => {
    const { addedItems, removedItems } = getPlansDiff(originalPlan, plan);
    if (addedItems.length) {
      sendAnalyticsEvent({
        action: 'items-added',
        params: {
          items_added: addedItems.map(({ name }) => name),
          items_delta: addedItems.length.toString(),
        },
      });
    }
    if (removedItems.length) {
      sendAnalyticsEvent({
        action: 'items-removed',
        params: {
          items_added: removedItems.map(({ name }) => name),
          items_delta: removedItems.length.toString(),
        },
      });
    }
  }, [sendAnalyticsEvent]);

  return {
    sendAnalyticsEvent,
    sendAnalyticsAuthEvent,
    sendAnalyticsPlanDiffEvent,
  };
};
