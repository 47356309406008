import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useTenantContext } from 'context';
import { usePageViewedEvent } from 'context/AnalyticsContext/hooks/usePageViewedEvent';
import { constants } from 'globalConstants';
import { OnboardingPage } from 'pages/OnboardingPage/OnboardingPage';
import { ThirdPartyAuthentication } from 'pages/ThirdPartyAuthentication/ThirdPartyAuthentication';
import { IOnboardingState, OnboardingStatus } from 'types/interfaces';
import { useOnboardingState } from 'utils/hooks/useOnboardingState';
import { Layout } from 'wrappers/Layout/Layout';
import { useRoutesOfUser } from 'wrappers/RBAC/hooks/useRoutesOfUser';

export const Router: FC = () => {
  usePageViewedEvent();
  const { routesToRender } = useRoutesOfUser();
  const { getOnboardingState } = useOnboardingState();
  const { uiShowOnboardingForm } = useFlags();
  const { isGithubIntegrated } = useTenantContext();
  const { THIRD_PARTY_AUTHENTICATION, ONBOARDING } = constants.routes;
  const onboardingState: IOnboardingState = getOnboardingState();

  const shouldDisplayOnboardingPage = useMemo(
    () => uiShowOnboardingForm && onboardingState?.status === OnboardingStatus.IN_PROGRESS && !isGithubIntegrated,
    [onboardingState?.status, uiShowOnboardingForm, isGithubIntegrated],
  );

  return (
    <Routes>
      {/* The ThirdPartyAuthentication is not in the Layout scope since the navbar is not wanted here */}

      <Route key={THIRD_PARTY_AUTHENTICATION} element={<ThirdPartyAuthentication />} path={`/${THIRD_PARTY_AUTHENTICATION}`} />

      {/* Display only the onboarding page if the user didn't finish the onboarding */}

      {shouldDisplayOnboardingPage ? (
        <>
          <Route element={<OnboardingPage />} path={`/${ONBOARDING}`} />

          {/* Redirect to the onboarding page by default */}

          <Route element={<Navigate replace to={`/${ONBOARDING}`} />} path='*' />
        </>
      ) : (
        <Route element={<Layout />}>
          {routesToRender}
        </Route>
      )}

    </Routes>
  );
};
