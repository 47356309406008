import { FC, useEffect, useMemo } from 'react';

import { Actions, Coverage, Insights, SecurityPosture } from '..';
import { useFetchMetrics } from '../../hooks/useFetchMetrics';
import { CollapseSection } from '../CollapseSection/CollapseSection';
import { SecurityPlans } from '../SecurityPlans/SecurityPlans';
import { TeamsLeaderboard } from '../TeamsLeaderboard/TeamsLeaderboard';

import styles from './OverviewBody.module.scss';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { usePlansContext } from 'context';
import { usePullRequestsContext } from 'context/PullRequestsContext/PullRequestsContext';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { useFetchActions, useFetchActionsCount } from 'services/ActionService/useFetchActions';
import { OverviewMetricNames } from 'types/enums/MetricNames';

export const OverviewBody: FC = () => {
  const { prAnalysis, isLoading: prLoading, getPullRequestsAnalysis } = usePullRequestsContext();
  const { actions, isLoading: isActionLoading, getActions } = useFetchActions();
  const { isLoading: isLoadingActionsCount, actionsCount, getActionsCount } = useFetchActionsCount();
  const { metrics, fetchMetrics, silentFetchDevProductivity } = useFetchMetrics();
  const { fetchPlans, hasFetchedPlans, plans } = usePlansContext();
  const { leadingTeams, getLeadingTeams, isLoadingLeadingTeams } = useTeamsContext();

  useEffect(() => {
    if (!hasFetchedPlans) fetchPlans();
  }, [fetchPlans, hasFetchedPlans]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  useEffect(() => {
    getLeadingTeams();
  }, [getLeadingTeams]);

  useEffect(() => {
    if (!prLoading && !prAnalysis) {
      getPullRequestsAnalysis();
    }
  }, [getPullRequestsAnalysis, prAnalysis, prLoading]);

  useEffect(() => {
    getActions();
  // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want it to run once
  }, []);

  useEffect(() => {
    getActionsCount();
  }, [getActionsCount]);

  const resourceCoverageMetric = metrics?.[OverviewMetricNames.ResourcesCoverage];
  const planStatusMetric = metrics?.[OverviewMetricNames.LayersCoverage];
  const planItemFindingsMetric = metrics?.[OverviewMetricNames.PlanItemFindings];

  const goals = useMemo(() => {
    if (!plans) return [];
    return Object.values(plans).filter((plan) => plan.is_goal);
  }, [plans]);

  const {
    [OverviewMetricNames.TrendingDevProductivity]: devProductivityMetric,
    [OverviewMetricNames.MTTR]: mttrMetric,
  } = metrics;
  if (devProductivityMetric.isLoading || mttrMetric.isLoading || prLoading
        || isActionLoading || !actions || isLoadingActionsCount || isLoadingLeadingTeams) {
    return <LoadingBar />;
  }

  return (
    <div className={styles.bodyContent}>
      <div className={styles.insightsAndLeaderboardContainer}>
        <div className={styles.insights}>
          <Insights
            devProductivityMetric={devProductivityMetric.metric}
            mttrMetric={mttrMetric.metric}
            prAnalysis={prAnalysis}
            silentFetchDevProductivity={silentFetchDevProductivity}
          />
        </div>

        <div className={styles.leaderboard}>
          <TeamsLeaderboard teams={leadingTeams} />
        </div>
      </div>

      <CollapseSection title='pages.overview.securityPlans.title'>
        <SecurityPlans goals={goals} />
      </CollapseSection>

      <CollapseSection testId='Actions-wrapper' title='actions.title'>
        <Actions actions={actions.data} count={actionsCount || 0} />
      </CollapseSection>

      <div className={styles.coverageSecurityPosture}>
        <Coverage planStatusMetric={planStatusMetric} resourcesCoverageMetric={resourceCoverageMetric} />

        <SecurityPosture planItemFindingsMetric={planItemFindingsMetric} />
      </div>
    </div>
  );
};
