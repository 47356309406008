import { isEmpty } from 'lodash';
import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';

import { useConfigurationsContext } from 'context/ConfigurationsContext/ConfigurationsContext';
import { Application, IConfigurations } from 'types/interfaces';

export const upsertApp = (currentApplications: Application[], updatedApplication: Application): Application[] => {
  const currentApp = currentApplications?.find((app) => app.type === updatedApplication.type);
  if (currentApp) {
    return currentApplications?.map((item: Application) => {
      if (item.type === updatedApplication.type) {
        return updatedApplication;
      }
      return item;
    });
  }

  return [...currentApplications, updatedApplication];
};

export const useConfigurationStateManager = (initialState: Application, onChangeConfiguration?: Dispatch<SetStateAction<IConfigurations>>) => {
  const { setConfigurations, configurations } = useConfigurationsContext();

  const setConfigurationWithCallback: typeof setConfigurations = (newConfigurations) => {
    setConfigurations(newConfigurations);
    if (onChangeConfiguration) {
      onChangeConfiguration(newConfigurations);
    }
  };

  const getCurrentConfigurations = useCallback((currentConfigurations = configurations) => {
    const configuration = currentConfigurations.applications?.find(
      (app: Application) => app.type === initialState.type,
    );
    return isEmpty(configuration) ? initialState : configuration;
  }, [configurations, initialState]);

  const handleChange = (name: string, value: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    setConfigurationWithCallback((prevConfigurations: IConfigurations) => {
      const currentConfigurations = getCurrentConfigurations(prevConfigurations);
      const newConfiguration = {
        ...currentConfigurations,
        [name]: value,
      };
      return ({
        ...prevConfigurations,
        applications: upsertApp(prevConfigurations.applications || [], newConfiguration),
      });
    });
  };

  const deleteConfigurationKey = (keyName: string) => {
    setConfigurationWithCallback((prevConfigurations: IConfigurations) => {
      const newConfiguration = { ...getCurrentConfigurations(prevConfigurations) };
      delete newConfiguration[keyName];
      return ({
        ...prevConfigurations,
        applications: upsertApp(prevConfigurations.applications || [], newConfiguration),
      });
    });
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.name, event.target.value);
  };

  return {
    getCurrentConfigurations,
    handleChangeInput,
    handleChange,
    deleteConfigurationKey,
  };
};
