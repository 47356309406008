// eslint-disable-next-line import/no-extraneous-dependencies
import { useEffect, useMemo } from 'react';

import { constants } from 'globalConstants';

const POLLING_GRACE_PERIOD = 1000 * 15; // 15 seconds

export const useManageSignup = (
  setIsWaitingForSwitchTenant: (isWaitingForRoleUpdate: boolean) => void,
  setIsWaitingForRoleUpdate: (isWaitingForRoleUpdate: boolean) => void,
  isAuthorized: boolean,
) => {
  const shouldSignup = useMemo(() => sessionStorage.getItem(constants.SHOULD_SIGNUP_KEY), []);

  useEffect(() => {
    if (!isAuthorized) {
      setTimeout(() => {
        setIsWaitingForRoleUpdate(false);
      }, POLLING_GRACE_PERIOD);
    }
  }, [isAuthorized, setIsWaitingForRoleUpdate, shouldSignup]);
};
