// eslint-disable-next-line import/no-extraneous-dependencies
import { IRole } from '@frontegg/rest-api/roles/interfaces';
import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';

import { useAuthContext } from 'context';

export const hasRoles = (roles: IRole[]) => !isEmpty(roles);

export const useIsAuthorized = () => {
  const { frontEggUser } = useAuthContext();

  const calcIsAuthorized = useCallback(hasRoles, []);
  const isAuthorized = calcIsAuthorized(frontEggUser.roles);

  return { isAuthorized };
};
