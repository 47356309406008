import { useCallback, useMemo, useState } from 'react';

import { constants } from 'globalConstants';
import { GoalsStepContent } from 'pages/OnboardingPage/components/OnboardingWizard/components/GoalsStepContent/GoalsStepContent';
import {
  IOnboardingWizardStep,
  IOnboardingWizardStepName,
  IOnboardingWizardStepStatus,
} from 'pages/OnboardingPage/components/OnboardingWizard/OnboardingWizard';
import { ActionButtonVariant, OnboardingDetailsContent } from 'types/interfaces';

interface Props {
  isPrevStepCompleted: boolean;
  handleBackClick: (stepName: string) => void;
  handleNextClick: (stepName: string, onboardingDetails: OnboardingDetailsContent) => Promise<void>;
  isLoading: boolean;
}

export const useGoalStep = ({
  isPrevStepCompleted,
  handleBackClick,
  handleNextClick,
  isLoading,
}: Props): IOnboardingWizardStep => {
  const [onboardingDetails, setOnboardingDetails] = useState<OnboardingDetailsContent>({});

  const status = useMemo(() => {
    if (isPrevStepCompleted) {
      return IOnboardingWizardStepStatus.ACTIVE;
    }
    return IOnboardingWizardStepStatus.NOT_STARTED;
  }, [isPrevStepCompleted]);

  const setDefaultGoalPlan = useCallback(() => {
    sessionStorage.setItem(constants.GOAL_PLAN_KEY, constants.PLAN_SLUG.MVS);
  }, []);

  const onNextClick = useCallback(() => {
    if (!onboardingDetails.plan) setDefaultGoalPlan();
    handleNextClick(IOnboardingWizardStepName.GOALS, onboardingDetails);
  }, [onboardingDetails, handleNextClick, setDefaultGoalPlan]);

  const actionButtons = useMemo(() => [
    {
      label: 'pages.onboarding.backButtonText',
      handleClick: () => handleBackClick(IOnboardingWizardStepName.GOALS),
      variant: ActionButtonVariant.JIT_OUTLINED,
    },
    {
      label: 'pages.onboarding.nextButtonText',
      handleClick: onNextClick,
      isLoading,
      variant: ActionButtonVariant.JIT_PRIMARY,
    },
  ], [handleBackClick, onNextClick, isLoading]);

  const component = useCallback(() => (
    <GoalsStepContent setOnboardingDetails={setOnboardingDetails} />
  ), []);

  return useMemo(() => ({
    component,
    status,
    actionButtons,
  }), [actionButtons, component, status]);
};
