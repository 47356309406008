import { FC, useState } from 'react';

import { FindingsFiltersSaveDialog } from './components/SaveFindingFilterDialog/SaveFindingFilterDialog';
import styles from './FindingsFiltersSave.module.scss';

import { SaveFilter } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { IFindingFilter } from 'types/interfaces';
import { ISavedFilter } from 'types/interfaces/Findings/ISavedFilter';

interface Props {
  currentFilters: IFindingFilter[];
  saveFilters: (currentFilters: ISavedFilter) => void;
  isReadOnly?: boolean;
}

export const FindingsFiltersSaveButton: FC<Props> = ({
  currentFilters,
  saveFilters,
  isReadOnly,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterDescription, setFilterDescription] = useState('');
  const [filterShouldNotify, setFilterShouldNotify] = useState(false);

  const handleClick = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setFilterName('');
    setFilterDescription('');
    setFilterShouldNotify(false);
    setIsDialogOpen(false);
  };

  const handleSave = () => {
    const filter: ISavedFilter = {
      name: filterName,
      description: filterDescription,
      isDefault: false,
      filters: currentFilters,
      shouldNotify: filterShouldNotify,
    };
    saveFilters(filter);
    handleClose();
  };

  return (
    <div style={{
      marginLeft: 2,
      display: 'flex',
      alignItems: 'center',
    }}
    >
      <JitButton
        disableRipple
        isReadOnly={isReadOnly}
        noHover
        onClick={handleClick}
      >
        <JitIcon
          icon={SaveFilter}
          strokeColor={isReadOnly ? colors.lightGray : colors.iris}
          style={{ marginRight: 5 }}
        />

        <JitText
          className={styles.text}
          color={isReadOnly ? colors.lightGray : colors.blue02}
          size='l'
          text='pages.findings.filtersSection.saveFilters'
        />
      </JitButton>

      <FindingsFiltersSaveDialog
        filterDescription={filterDescription}
        filterName={filterName}
        filterShouldNotify={filterShouldNotify}
        handleClose={handleClose}
        handleSave={handleSave}
        open={isDialogOpen}
        setFilterDescription={setFilterDescription}
        setFilterName={setFilterName}
        setFilterShouldNotify={setFilterShouldNotify}
      />
    </div>
  );
};
