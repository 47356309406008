import { useCallback } from 'react';

import { useConfigurationsContext } from '../ConfigurationsContext';

import { useGetConfigurationsComponentDetails } from './useGetConfigurationsComponentDetails';
import { useHasConfigurationsChanged } from './useHasConfigurationsChanged/useHasConfigurationsChanged';
import { useValidateSecretByIntegrationKey } from './useValidateSecretByIntegrationKey';

import { useAssetsContext } from 'context/AssetsContext';
import { AssetType } from 'types/enums';
import { IPlanItemDetails } from 'types/interfaces';
import { getVendorForAssetType, isVendorCloudProvider } from 'utils/functions/matchVendorToAssetType';

export const useGetPlanItemConfigurationsStatus = () => {
  const { isProperlyConfigured, isAssetTypeProperlyIntegratedInConfig } = useConfigurationsContext();
  const { isAssetTypeProperlyIntegrated } = useAssetsContext();
  const getConfigurationsComponentDetails = useGetConfigurationsComponentDetails();
  const isSecretValidForIntegration = useValidateSecretByIntegrationKey();

  const isConfigured = useCallback((planItem: IPlanItemDetails, assetType?: AssetType) => isProperlyConfigured(planItem.slug, assetType), [isProperlyConfigured]);
  const isIntegrated = useCallback((planItem: IPlanItemDetails) => !planItem.asset_types || planItem.asset_types.every((assetType) => {
    const allIntegrationsConfigured = planItem.controls.every((control) => control.integrations?.every((integration) => isSecretValidForIntegration(integration.name)));
    const vendor = getVendorForAssetType(assetType);
    return (isVendorCloudProvider(vendor))
      ? isAssetTypeProperlyIntegrated(assetType) && allIntegrationsConfigured
      : isAssetTypeProperlyIntegratedInConfig(assetType) && allIntegrationsConfigured;
  }), [isAssetTypeProperlyIntegrated, isAssetTypeProperlyIntegratedInConfig, isSecretValidForIntegration]);

  const { hasConfigurationsChanged } = useHasConfigurationsChanged();

  const isConfigurationMissing = useCallback(
    (planItem: IPlanItemDetails) => !isIntegrated(planItem)
    || !isConfigured(planItem, planItem.asset_types?.[0]) || hasConfigurationsChanged(planItem.slug) || !!planItem.is_manual || planItem.is_enabled === false,
    [isIntegrated, isConfigured, hasConfigurationsChanged],
  );

  const isConfigurable = useCallback(
    (planItem: IPlanItemDetails) => !!(getConfigurationsComponentDetails(planItem) || planItem.is_manual),
    [getConfigurationsComponentDetails],
  );

  return {
    isConfigured,
    isIntegrated,
    isConfigurationMissing,
    isConfigurable,
  };
};
