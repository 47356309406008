import { FC, useMemo } from 'react';

import styles from './ThreatPrevention.module.scss';

import { JitText } from 'components/JitText/JitText';
import { LineProgress } from 'components/LineProgress/LineProgress';
import colors from 'themes/colors.module.scss';

interface Props {
  preProdResolvedFindings: number;
  totalResolvedFindings: number;
}

export const ThreatPreventionContent: FC<Props> = ({ preProdResolvedFindings, totalResolvedFindings }) => {
  const percentage = (preProdResolvedFindings / totalResolvedFindings) * 100;
  const color = percentage > 60 ? colors.performanceGreen : colors.alert;
  const content = useMemo(() => (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper} data-testid='threatPreventionData'>
        <JitText bold data-testid='threat-prevention-pre-prod-resolved-findings' size='huge' text={preProdResolvedFindings} />

        <JitText bold color={colors.gray} data-testid='threat-prevention-slash' size='l' text='/' />

        <JitText bold color={colors.gray} data-testid='threat-prevention-total-resolved-findings' size='l' text={totalResolvedFindings} />

      </div>

      <LineProgress color={color} percentage={percentage} />
    </div>
  ), [color, percentage, preProdResolvedFindings, totalResolvedFindings]);

  return content;
};
