import { FC, useEffect, useState } from 'react';

import styles from './SlackChannelNotificationsItem.module.scss';

import { CircleInfo } from 'assets';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useSlackContext, useTenantContext } from 'context';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { Vendor } from 'types/enums';
import {
  MenuItemKeyType,
  TenantNotificationsPreferences,
  NotificationPreference,
  SpecificTeamsNotificationPreference,
} from 'types/interfaces';
import { ISlackServiceChannelResponse } from 'types/interfaces/Slack';

const SKIDDING_OFFSET = 0;
const DISTANCE_OFFSET = 6;

const tooltipPopperProps = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [SKIDDING_OFFSET, DISTANCE_OFFSET],
      },
    },
  ],
};

interface Props {
  preferenceKey: keyof TenantNotificationsPreferences | keyof SpecificTeamsNotificationPreference;
  slackChannels: ISlackServiceChannelResponse[],
  updateNotificationPreferences: (preferenceKey: string, enabled: boolean, channel?: string) => void
  selected?: NotificationPreference
}

export const SlackChannelNotificationsItem: FC<Props> = ({
  preferenceKey,
  slackChannels,
  updateNotificationPreferences,
  selected,
}) => {
  const { isPreferencesLoading, installations } = useTenantContext();
  const { isSlackChannelsLoading } = useSlackContext();
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(selected?.enabled);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const isLoading = isPreferencesLoading
    || isSlackChannelsLoading
    || installations.filter((installation) => installation.vendor === Vendor.SLACK).length === 0;

  const isDisabled = isLoading || !slackChannels?.length;

  useEffect(() => {
    setIsNotificationEnabled(selected?.enabled);
  }, [selected?.enabled]);

  const slackChannelMenuItems: Partial<MenuItemType>[] = slackChannels.map((channel) => ({
    itemKey: channel.name,
    isSelected: channel.name === selected?.channel,
  })).sort((a, b) => a.itemKey.localeCompare(b.itemKey));

  const onMenuItemClick = ({ itemKey }: { itemKey: MenuItemKeyType }) => {
    updateNotificationPreferences(preferenceKey, true, itemKey as string);
    sendAnalyticsEvent({ action: 'slack-channel-selected' });
  };

  const onCheckBoxClick = () => {
    const newState = !isNotificationEnabled;
    setIsNotificationEnabled(newState);
    updateNotificationPreferences(preferenceKey, newState, selected?.channel);
    const analyticsEvent = newState ? 'slack-channel-enabled' : 'slack-channel-disabled';
    sendAnalyticsEvent({
      action: analyticsEvent,
      params: { notification_type: preferenceKey.toString() },
    });
  };
  const tooltipText = i18n.t(`dialogs.integration.slack.channels.preferencesTooltips.${preferenceKey}`, { defaultValue: '' });

  return (
    <div className={styles.inLine} data-testid='slackChannelNotifications'>
      <JitCheckbox
        key={isNotificationEnabled?.toString()} // had to do this hack in because the check icon wasn't refreshing
        checked={isNotificationEnabled}
        className={styles.notificationCheckBox}
        data-testid='isEnabledCheckBox'
        disabled={isDisabled}
        onClick={onCheckBoxClick}
      />

      <JitText text={`dialogs.integration.slack.channels.preferencesDescriptions.${preferenceKey}`} />

      {tooltipText && (
        <JitTooltip
          className={styles.infoTooltip}
          followCursor={false}
          placement='top'
          PopperProps={tooltipPopperProps}
          title={tooltipText}
          withHighlightShadow
        >
          <JitIcon
            data-testid='tooltipCircleIcon'
            icon={CircleInfo}
            iconClassName={styles.infoTooltipIcon}
            size={15}
          />
        </JitTooltip>
      )}

      <div className={`${styles.inLine} ${styles.stickToTheRight}`}>
        <div className={styles.hashTagSign}>
          <JitText color={isNotificationEnabled ? colors.white : colors.darkGray} size='l' text='#' />
        </div>

        <JitDropdownNew
          defaultDisplayText='dialogs.integration.slack.channels.defaultDropDown'
          displayText={selected?.channel}
          isEnabled={isNotificationEnabled && !isDisabled}
          isLoading={isLoading}
          menuItems={slackChannelMenuItems}
          onMenuItemClick={onMenuItemClick}
          width='200px'
        />
      </div>

      <div />
    </div>
  );
};
