import { FC } from 'react';

import styles from './ProdOpenFindingsCountOverview.module.scss';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IOpenFindingsCount } from 'types/interfaces/Metrics/SecurityImpactMetrics/OpenFindingsCount';

interface Props {
  openFindingsCountMetric: MetricWithLoadingIndication<IOpenFindingsCount>
}

export const ProdOpenFindingsCountOverview: FC<Props> = ({ openFindingsCountMetric }) => {
  if (openFindingsCountMetric.isLoading) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <JitText className={styles.subtitle} size='l' text='pages.securityImpact.securityPosture.detectedFindings.last90Days' />

      <div className={styles.mainItem}>
        <div className={styles.parameter}>
          <JitText bold size='huge' text={openFindingsCountMetric.metric?.lastPeriodOpenFindingsCount} />
        </div>

      </div>

      <div className={styles.subItem}>
        <div className={styles.parameter}>
          <JitText bold color={colors.failRed} size='m' text={openFindingsCountMetric.metric?.totalOpenFindingsCount} />
        </div>

        <JitText className={styles.descriptor} color={colors.gray} size='m' text='pages.securityImpact.securityPosture.detectedFindings.totalOpenFindings' />
      </div>
    </div>
  );
};
