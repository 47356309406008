import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeEvent, FC } from 'react';

import styles from './ConfigurationsZapInputFields.module.scss';

import { ErrorIcon } from 'assets';
import { LabelAndTooltip } from 'components/Configurations/Zap/components/LabelAndTooltip';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { i18n } from 'locale/i18n';
import { ZapAuthenticationConfigType } from 'types/interfaces/Configurations/IApplicationConfiguration';

interface Props {
  // inputField has to be in snake_case
  inputField: string;
  inputValue: string;
  handleChangeInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  isMultiLine?: boolean;
  disabled?: boolean;
  type: ZapAuthenticationConfigType;
  isValid?: boolean;
  dataTestid?: string,
}

export interface FieldsTextInterface {
  label: string,
  subLabel?: string,
  info: string,
  invalidToolTipTitle?: string,
  placeholder: string,
  header?: string,
  accept?: string,
}

export const ConfigurationsZapField: FC<Props> = ({
  inputField, inputValue, handleChangeInput, isMultiLine, disabled, type, isValid, dataTestid,
}) => {
  const fieldsText: FieldsTextInterface = i18n.t(`configurations.zap.${type}.fields.${inputField}`, { returnObjects: true });

  let inputStyle = `mb-2 ${styles.inputFieldBox}`;
  const shouldShowError = isValid !== undefined && !isValid;
  if (shouldShowError) {
    inputStyle = `${inputStyle} ${styles.inputFieldBoxFail}`;
  }
  const testId = dataTestid || inputField || 'web-url-input2';

  return (
    <div className={styles.inputFieldContainer}>

      <LabelAndTooltip label={fieldsText.label} subLabel={fieldsText.subLabel} tooltipPlacement='left' tooltipText={fieldsText.info} />

      <Input
        className={inputStyle}
        data-testid={testId}
        disabled={disabled}
        disableUnderline
        endAdornment={shouldShowError && (
          <InputAdornment position='end'>
            <JitTooltip followCursor={false} placement='left' title={`configurations.zap.invalidToolTipTitle.${inputField}`}>
              <JitIcon
                icon={ErrorIcon}
                size={16}
                style={{
                  marginLeft: '0.5rem',
                }}
              />
            </JitTooltip>
          </InputAdornment>
        )}
        fullWidth
        margin='dense'
        maxRows={3}
        minRows={3}
        multiline={isMultiLine}
        name={inputField}
        onChange={handleChangeInput}
        placeholder={fieldsText.placeholder}
        style={{
          padding: '0 0.5rem',
          fontSize: 14,
          borderRadius: '0.5rem',
        }}
        value={inputValue}
      />
    </div>
  );
};
