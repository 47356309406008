import { FC, useState } from 'react';

import styles from './InputErrorIndicator.module.scss';
import { JitTextInputVariants } from './JitTextInput';

import { ErrorIcon } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitPopper, JitPopperVariants } from 'components/JitPopper/JitPopper';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  variant: JitTextInputVariants;
  errorMessage?: string;
}

const variantColors: Record<JitTextInputVariants, { backgroundColor: string, textColor: string }> = {
  default: {
    backgroundColor: colors.cards,
    textColor: colors.white,
  },
  light: {
    backgroundColor: colors.cards,
    textColor: colors.white,
  },
  dark: {
    backgroundColor: colors.cards,
    textColor: colors.white,
  },
  bright: {
    backgroundColor: colors.white,
    textColor: colors.black,
  },
};

export const InputErrorIndicator: FC<Props> = ({ variant, errorMessage }) => {
  const [isHovered, setIsHovered] = useState(false);

  const icon = (
    <JitIcon
      className={errorMessage ? styles.icon : ''}
      data-testid='input-error-indicator-icon'
      icon={ErrorIcon}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      size={18}
    />
  );

  if (!errorMessage) return icon;

  return (
    <JitPopper
      arrowPlacement='bottom'
      content={(
        <div
          style={{
            backgroundColor: variantColors[variant].backgroundColor,
            padding: 10,
            borderRadius: 8,
          }}
        >
          <JitText color={variantColors[variant].textColor} text={errorMessage} />
        </div>
          )}
      isWithArrow
      open={isHovered}
      placement='top'
      shadowVariant='dark'
      variant={variant as JitPopperVariants}
    >

      {icon}

    </JitPopper>
  );
};
