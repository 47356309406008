import { isEqual, pick } from 'lodash';

import { authModeToExtraFieldsInitialState } from '../Zap/constants';
import { validateZapLogin } from '../Zap/ZapLoginValidator';

import { logInfo } from 'services/logger/logger';
import { validateUrlExistence } from 'services/ValidationService/ValidationService';
import { AssetType } from 'types/enums';
import { IConfigurations, ZapApplication, ZapApplicationFormBasedAuth, ZapAuthMode, ZapAuthenticationConfigType } from 'types/interfaces';

export const zapValidator = (zapType: ZapAuthenticationConfigType) => async (configurations: IConfigurations, fetchedConfigurations: IConfigurations) => {
  const isFormBasedAuth = (tested?: ZapApplication): tested is ZapApplicationFormBasedAuth => tested?.authentication_mode === ZapAuthMode.FormBased;

  const isFormBasedAuthExtraFieldChanged = (tested?: ZapApplication): tested is ZapApplicationFormBasedAuth => {
    const AuthExtraFields = Object.keys(authModeToExtraFieldsInitialState[ZapAuthMode.FormBased]);
    const fetchedApplication = fetchedConfigurations?.applications?.find((app) => app.type === zapType) as ZapApplication | undefined;

    const applicationAuthExtraFields = pick(tested, AuthExtraFields);
    const fetchedApplicationAuthExtraFields = pick(fetchedApplication, AuthExtraFields);
    return !isEqual(applicationAuthExtraFields, fetchedApplicationAuthExtraFields);
  };
  const application = configurations.applications?.find((app) => app.type === zapType) as ZapApplication | undefined;

  const websiteExists = application?.target_url && (zapType === AssetType.WEB) && (await validateUrlExistence(application?.target_url));

  if (!isFormBasedAuth(application) || !isFormBasedAuthExtraFieldChanged(application)) {
    if (websiteExists || zapType === AssetType.API) {
      return {
        isValid: true,
        reason: 'configurations.zap.validation.noValidationRequired',
      };
    }
    return {
      isValid: false,
      reason: 'configurations.zap.validation.websiteDoesNotExist',
    };
  }

  const { success, message } = await validateZapLogin(application);
  logInfo('Zap configurations validated', { application,
    success,
    message });
  return {
    isValid: success,
    reason: message,
  };
};
