import { styled } from '@mui/material/styles';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import { CSSProperties } from 'react';

import { rowBackgroundColorVariantMap, borderColorVarianMap, hoveredBorderColorVarianMap } from './utils/constants';

import { CellVerticalAlign, DefaultCellVerticalAlign, DefaultTableDesign, TableDesignVariants } from 'components/JitTable/constants';
import colors from 'themes/colors.module.scss';

interface RowProps extends TableRowProps {
  isselected?: string;
  style?: CSSProperties;
  hoverStyle?: CSSProperties;
  verticalalign?: CellVerticalAlign;
  tabledesign?: TableDesignVariants;
  isClickable: boolean;
}

export const StyledTableRow = styled(TableRow)<RowProps>(({ isselected, style, verticalalign = DefaultCellVerticalAlign, tabledesign = DefaultTableDesign, hoverStyle, isClickable }) => ({
  backgroundColor: rowBackgroundColorVariantMap[tabledesign],
  height: 70,
  borderColor: borderColorVarianMap[tabledesign],
  borderWidth: isClickable ? '1px 0' : '0px 0px',
  position: 'relative',

  ...(isselected && {
    borderColor: colors.blue02,
  }),
  '&:hover': {
    borderColor: hoveredBorderColorVarianMap[tabledesign],
    ...hoverStyle,
  },
  ...(style || {}),

  td: {
    borderWidth: 'inherit',
    borderColor: 'inherit',
    verticalAlign: verticalalign,

    '&:first-of-type': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      borderRightWidth: 0,
      borderLeftWidth: isClickable && 1,
    },

    '&:last-of-type': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderRightWidth: isClickable && 1,
      borderLeftWidth: 0,
    },
  },
}));
