import { FC, useCallback, useMemo, useRef, useState } from 'react';

import { FindingsContext } from 'context';
import { useSavedFilters } from 'context/FindingsContext/hooks/useSavedFilters';
import { buildGetFindingsRequestParams } from 'context/FindingsContext/utils/buildGetFindingsRequestParams';
import {
  createIgnoreRule,
  deleteIgnoreRule,
  fetchFindings,
  fetchFindingsCount,
  fetchFindingsCsvLink,
  fetchFindingsStatistics,
  fetchFullFindingsByIds,
} from 'services/FindingsService';
import {
  CreateIgnoreRuleRequest,
  IBaseIgnoreRule,
  IFinding,
  IFindingFilter,
  IFindingServer,
  IFindingsStatistics,
  ISortColumn,
} from 'types/interfaces';
import { IFindingsCount } from 'types/interfaces/Findings/IFindingsCount';

export const FindingsProvider: FC = ({ children }) => {
  const lastGetFindingsRequestId = useRef<number>(0);
  const lastTotalFindingsAmountRequestId = useRef<number>(0);

  const [findings, setFindings] = useState<IFinding[]>([]);
  const [totalFindingsAmount, setTotalFindingsAmount] = useState<number>();
  const [findingsStatistics, setFindingsStatistics] = useState<IFindingsStatistics | undefined>();
  const [isFetchingCSV, setIsFetchingCSV] = useState(false);
  const [isLoadingStatistics, setIsLoadingStatistics] = useState(false);
  const [filtersVisibleInFirstLine, setFiltersVisibleInFirstLine] = useState<IFindingFilter[]>([]);

  const [filters, setFilters] = useState<IFindingFilter[]>([]);
  const [currentSortColumn, setCurrentSortColumn] = useState<ISortColumn | undefined>();
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const {
    editSavedFilter,
    removeSavedFilter,
    setSavedFilterAsDefault,
    createSavedFilter,
    getSavedFilters,
    savedFilters,
    selectSavedFilter,
    selectedSavedFilter,
  } = useSavedFilters(filters, setFilters);

  const getFindingsStatistics = useCallback(async (isSilentFetch: boolean = false, requestFilters: IFindingFilter[] = []) => {
    if (!isSilentFetch) {
      setIsLoadingStatistics(true);
    }
    const formattedFilterParams = buildGetFindingsRequestParams({ filters: requestFilters });
    const statisticsResponse = await fetchFindingsStatistics(formattedFilterParams);

    if (!statisticsResponse) {
      console.error('Error while getting findings statistics');
      setIsLoadingStatistics(false);
      return;
    }
    setFindingsStatistics(statisticsResponse);
    setIsLoadingStatistics(false);
  }, []);

  const getFindings = useCallback(async (paginationKey: string | undefined): Promise<string | undefined> => {
    const requestId = Date.now();
    lastGetFindingsRequestId.current = requestId;
    const formattedFilterParams = buildGetFindingsRequestParams({
      filters,
      currentSortColumn,
      nextPageKey: paginationKey,
    });
    const { data, metadata: { nextKey } } = await fetchFindings(formattedFilterParams, paginationKey);
    if (lastGetFindingsRequestId.current !== requestId) return undefined; // Ignore outdated request

    setFindings(data);

    return nextKey;
  }, [currentSortColumn, filters]);

  const getTotalFindingsAmount = useCallback(async () => {
    const requestId = Date.now();
    lastTotalFindingsAmountRequestId.current = requestId;
    const formattedFilterParams = buildGetFindingsRequestParams({ filters });
    const response = await fetchFindingsCount(formattedFilterParams) || {};
    const { count } = response as IFindingsCount;
    if (lastTotalFindingsAmountRequestId.current !== requestId) return; // Ignore outdated request
    setTotalFindingsAmount(count);
  }, [filters]);

  const getCsvFile = useCallback(async (requestedFilters: IFindingFilter[], sortColumn: ISortColumn | undefined): Promise<string | undefined> => {
    setIsFetchingCSV(true);
    try {
      const formattedFilterParams = buildGetFindingsRequestParams({
        filters: requestedFilters,
        currentSortColumn: sortColumn,
      });
      const csvDownloadUrl = await fetchFindingsCsvLink(formattedFilterParams);
      setIsFetchingCSV(false);
      return csvDownloadUrl;
    } catch (error) {
      setIsFetchingCSV(false);
      console.error(`Failed to get download URL for CSV file. The following error was received: ${error}`);
    }
    return undefined;
  }, []);

  const fetchedFindings = useCallback((findingsIds: string[]): Promise<IFindingServer[] | undefined> => fetchFullFindingsByIds(findingsIds), []);

  const ignoreFindings = useCallback(async (ignoreRulesToCreate: CreateIgnoreRuleRequest[]): Promise<IBaseIgnoreRule[]> => {
    const responses: Promise<IBaseIgnoreRule | undefined>[] = ignoreRulesToCreate.map((ignoreRulePayload) => createIgnoreRule(ignoreRulePayload));
    const findingsResponses: (IBaseIgnoreRule | undefined)[] = await Promise.all(responses);
    return findingsResponses.filter((findingsResponse) => findingsResponse !== undefined) as IBaseIgnoreRule[];
  }, []);

  const undoIgnoreFindings = useCallback(async (findingsToUndoIgnore: IFinding[]) => {
    const ignoreRulesToUndo = findingsToUndoIgnore.map((finding) => finding.ignoreRulesIds)
      .filter((item) => item !== undefined).flat() as string[];
    const responses: Promise<undefined>[] = ignoreRulesToUndo.map((ignoreRuleId) => deleteIgnoreRule(ignoreRuleId));
    await Promise.all(responses);
  }, []);

  const value = useMemo(() => ({
    findings,
    getFindings,
    isFetchingCSV,
    setFindings,
    ignoreFindings,
    getSavedFilters,
    undoIgnoreFindings,
    findingsStatistics,
    isLoadingStatistics,
    getFindingsStatistics,
    getCsvFile,
    fetchedFindings,
    isLoadingFilters,
    savedFilters,
    editSavedFilter,
    createSavedFilter,
    removeSavedFilter,
    setSavedFilterAsDefault,
    selectSavedFilter,
    selectedFilter: selectedSavedFilter,
    filters,
    setFilters,
    filtersVisibleInFirstLine,
    setFiltersVisibleInFirstLine,
    setIsLoadingFilters,
    currentSortColumn,
    setCurrentSortColumn,
    totalFindingsAmount,
    setTotalFindingsAmount,
    getTotalFindingsAmount,
  }), [
    getSavedFilters,
    getFindings,
    findings,
    isFetchingCSV,
    ignoreFindings,
    undoIgnoreFindings,
    findingsStatistics,
    isLoadingStatistics,
    getFindingsStatistics,
    getCsvFile,
    fetchedFindings,
    isLoadingFilters,
    filters,
    setFilters,
    savedFilters,
    editSavedFilter,
    createSavedFilter,
    removeSavedFilter,
    setSavedFilterAsDefault,
    selectSavedFilter,
    selectedSavedFilter,
    filtersVisibleInFirstLine,
    setFiltersVisibleInFirstLine,
    setIsLoadingFilters,
    currentSortColumn,
    setCurrentSortColumn,
    totalFindingsAmount,
    setTotalFindingsAmount,
    getTotalFindingsAmount,
  ]);

  return (
    <FindingsContext.Provider value={value}>
      {children}
    </FindingsContext.Provider>
  );
};
