import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';

import colors from 'themes/colors.module.scss';

export const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  fontSize: theme.typography.fontSize,
  lineHeight: 'none',
  paddingTop: 0,
  borderRadius: 8,
  backgroundColor: colors.black,
  paddingBottom: 2,
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#555555 !important',
    marginTop: 3,
    borderRadius: 8,
  },
  '.MuiSelect-iconOutlined': {
    marginBottom: 3,
    '&.MuiSelect-iconOpen': {
      paddingBottom: 0,
    },
  },
  '.MuiOutlinedInput-input': {
    padding: '5px 10px',
  },
  '.Mui-disabled': {
    webkitTextFillColor: colors.white,
    opacity: 0.5,
  },
  legend: {
    display: 'none',
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  '&.Mui-selected': {
    backgroundColor: 'inherit',
  },
  padding: '8px 6px',
}));

export const StyledCheckbox = styled(Checkbox)(() => ({
  padding: 3,
  '.MuiSvgIcon-root': {
    height: 20,
  },
}));
