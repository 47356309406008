import saveAs from 'file-saver';
import { FC } from 'react';

import styles from './DownloadButton.module.scss';

import { Download } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';

interface Props {
  downloadUrl: string;
}

export const DownloadButton: FC<Props> = ({ downloadUrl }) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  return (
    <div className={styles.buttonContainer}>
      <JitButton
        className={styles.downloadButton}
        data-testid='download-button'
        onClick={() => {
          sendAnalyticsEvent(
            {
              action: 'Pipelines.Artifacts.Download',
              params: {
                downloadUrl,
              },
            },
          );
          saveAs(downloadUrl);
        }}
        variant='outlined'
      >
        <JitIcon
          color='currentColor'
          data-testid='download-icon'
          height='18px'
          icon={Download}
          iconClassName={styles.downloadIcon}
          width='18px'
        />

        <JitText
          color='inherit'
          horizontalAlign='center'
          text='pages.pipelineDetails.header.downloadArtifacts'
        />
      </JitButton>
    </div>
  );
};
