import { FC, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ProtectedContextProviders } from './AuthContext/ProtectedContextProviders';

import { config } from 'config';
import {
  AuthProviderWrapper,
  FeatureFlagProvider,
  JitThemeProvider,
  StiggExtendedProvider,
} from 'context';

export const ContextProviders: FC = ({ children }) => {
  const [tenantId, setTenantId] = useState('');
  const [isWaitingForSwitchTenant, setIsWaitingForSwitchTenant] = useState(true);

  if (config.isTest) {
    return (
      <BrowserRouter>
        {children}
      </BrowserRouter>
    );
  }

  return (
    <JitThemeProvider>
      <FeatureFlagProvider tenantId={tenantId}>
        <AuthProviderWrapper isWaitingForSwitchTenant={isWaitingForSwitchTenant} setIsWaitingForSwitchTenant={setIsWaitingForSwitchTenant} setTenantId={setTenantId}>
          <StiggExtendedProvider apiKey={config.stiggKey}>
            <ProtectedContextProviders>
              {children}
            </ProtectedContextProviders>
          </StiggExtendedProvider>
        </AuthProviderWrapper>
      </FeatureFlagProvider>
    </JitThemeProvider>
  );
};
