import { useCallback } from 'react';

import { GET_CHILD_TEAMS_LIMIT, GET_MEMBERS_LIMIT, GET_TEAMS_PAGE_LIMIT } from 'services/TeamsService/constants';
import { useClient } from 'services/useClient';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { IGetTeamResponseItem, IMember, ITeam } from 'types/interfaces/Teams/ITeam';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';
import { parseObjectToQueryParams } from 'utils';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

const serviceName = 'teams';

type FetchTeamsParams = {
  limit?: number;
  after?: string;
  sort_by?: TeamSortBy;
  sort_order?: TeamSortOrder;
  search_value?: string;
  display_metadata?: boolean;
};

export const useTeamsService = () => {
  const { client } = useClient();

  const fetchTeams = useCallback(async (
    sort_by: TeamSortBy,
    sort_order: TeamSortOrder,
    after?: string,
    searchValue?: string,
    limit?: number,
  ): Promise<IPaginatedResponse<IGetTeamResponseItem> | undefined> => {
    const fetchLimit = limit || GET_TEAMS_PAGE_LIMIT;
    const params: FetchTeamsParams = {
      limit: fetchLimit,
      display_metadata: true,
      after,
      sort_by,
      sort_order,
    };
    if (searchValue) {
      params.search_value = searchValue;
    }
    const stringParams = parseObjectToQueryParams(params);
    const url = `${serviceName}/?${stringParams}`;
    const response = await client.get<IPaginatedResponse<IGetTeamResponseItem>>({
      url,
      allowedStatuses: [200],
    });
    if (response?.status === 200) {
      return {
        data: camelizeSnakeCaseKeys(response!.data.data) as IGetTeamResponseItem[],
        metadata: response!.data.metadata,
      };
    }
    return undefined;
  }, [client]);

  const fetchTeamById = useCallback(async (teamId: string): Promise<ITeam | undefined> => {
    const url = `${serviceName}/${teamId}`;
    const response = await client.get<ITeam>({
      url,
      allowedStatuses: [200],
    });
    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response!.data) as ITeam;
    }
    return undefined;
  }, [client]);

  const fetchMembersById = useCallback(async (teamId: string, after?: string): Promise<IPaginatedResponse<IMember> | undefined> => {
    const params = {
      limit: GET_MEMBERS_LIMIT,
      after,
    };
    const stringParams = parseObjectToQueryParams(params);
    const url = `${serviceName}/${teamId}/members?${stringParams}`;
    const response = await client.get<IPaginatedResponse<IMember>>({
      url,
      allowedStatuses: [200],
    });
    if (response?.status === 200) {
      return {
        data: camelizeSnakeCaseKeys(response!.data.data) as IMember[],
        metadata: response!.data.metadata,
      };
    }
    return undefined;
  }, [client]);

  const fetchTeamChildren = useCallback(async (teamId: string, after?: string): Promise<IPaginatedResponse<ITeam> | undefined> => {
    const params = {
      limit: GET_CHILD_TEAMS_LIMIT,
      after,
    };
    const stringParams = parseObjectToQueryParams(params);
    const url = `${serviceName}/${teamId}/children?${stringParams}`;
    const response = await client.get<IPaginatedResponse<ITeam>>({
      url,
      allowedStatuses: [200],
    });
    if (response?.status === 200) {
      return {
        data: camelizeSnakeCaseKeys(response!.data.data) as ITeam[],
        metadata: response!.data.metadata,
      };
    }
    return undefined;
  }, [client]);

  return {
    fetchTeams,
    fetchTeamById,
    fetchMembersById,
    fetchTeamChildren,
  };
};

