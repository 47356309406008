import { Dispatch, FC, SetStateAction, useMemo } from 'react';

import { JitButton, JitButtonVariants } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { usePlanInstanceContext, useTenantContext } from 'context';
import { useGetPlanItemConfigurationsStatus, useHasConfigurationsChanged } from 'context/ConfigurationsContext/hooks';
import { useActivatePlanItem } from 'context/PlansContext/hooks/useActivatePlanItem';
import { useGetPlanItemVendors } from 'context/PlansContext/hooks/useGetPlanItemVendors';
import { i18n } from 'locale/i18n';
import { isIntroItemSlug, ItemSlugOptions } from 'pages/PlanPage/components/PlanItemIntroDialog';
import { IMouseEvent, IPlanItemDetails } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface ActivatePlanItemButtonProps {
  planSlug: string;
  planItem: IPlanItemDetails;
  variant: JitButtonVariants;
  setIntroItem: Dispatch<SetStateAction<ItemSlugOptions | null>>
  longText?: boolean;
}

export const ActivatePlanItemButton: FC<ActivatePlanItemButtonProps> = ({
  planSlug, planItem, variant, setIntroItem, longText = false,
}) => {
  const { activatePlanItem } = useActivatePlanItem();
  const { planItemSlugsInCommit } = usePlanInstanceContext();
  const { t } = i18n;

  const { getPlanItemVendors } = useGetPlanItemVendors();
  const planItemVendorIntegrations = getPlanItemVendors(planItem);

  const { hasConfigurationsChanged } = useHasConfigurationsChanged();
  const { isIntegrated, isConfigurationMissing } = useGetPlanItemConfigurationsStatus();
  const { isGithubIntegrated } = useTenantContext();

  const isItemConfigurationMissing = useMemo(() => isConfigurationMissing(planItem) || !isGithubIntegrated, [isConfigurationMissing, planItem, isGithubIntegrated]);
  const isItemIntegrated = useMemo(() => isIntegrated(planItem), [isIntegrated, planItem]);

  const tooltipDisabledText = useMemo(() => {
    if (!isGithubIntegrated) return t('pages.plan.planItem.config.githubIntegrationRequired');
    if (!isItemIntegrated) return t('pages.plan.planItem.config.integrationRequired', { planItemVendorIntegrations });
    if (hasConfigurationsChanged(planItem.slug)) return 'pages.plan.planItem.config.applyChangesFirst';

    return ''; // We should never get here
  }, [isGithubIntegrated, hasConfigurationsChanged, isItemIntegrated, planItem.slug, planItemVendorIntegrations, t]);

  const buttonText = useMemo(() => {
    if (planItem.is_enabled === false) return 'pages.plan.planItem.config.comingSoon';
    return longText ? 'pages.plan.planItem.activateLong' : 'pages.plan.planItem.activate';
  }, [longText, planItem.is_enabled]);

  const onButtonClick = (e: IMouseEvent) => {
    activatePlanItem(planSlug, planItem);
    if (isIntroItemSlug(planItem.slug)) {
      setIntroItem(planItem.slug);
    }
    stopPropagation(e);
  };

  const isLoading = planItemSlugsInCommit.filter((slug) => slug === planItem.slug).length > 0;
  return (
    <JitTooltip data-testid='setupButtonTooltip' placement='top' title={tooltipDisabledText}>
      <JitButton
        data-testid='setupButton'
        disabled={isItemConfigurationMissing}
        isLoading={isLoading}
        onClick={onButtonClick}
        variant={variant}
        width='fit-content'
      >
        <JitText color='inherit' data-testid='setupButtonText' noWrap text={buttonText} />
      </JitButton>
    </JitTooltip>
  );
};
