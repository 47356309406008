import Checkbox from '@mui/material/Checkbox';
import { FC } from 'react';

interface Props {
  isPageSelected: boolean;
  setPageSelected: (isPageSelected: boolean) => void;
}

export const FindingsTableSelectHeader: FC<Props> = ({
  isPageSelected,
  setPageSelected,
}) => (
  <Checkbox
    checked={isPageSelected}
    onChange={() => setPageSelected(!isPageSelected)}
    sx={{
      padding: 0,
      marginLeft: '26px',
    }}
  />
);

