import IconButton from '@mui/material/IconButton';
import { FC } from 'react';

import { CopyFindingsButton } from '../../../CopyFindingsButton/CopyFindingsButton';

import { Cross } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { SeverityBox } from 'components/SeverityBox/SeverityBox';
import { FindingIgnoredStatusDropdown } from 'pages/FindingsPage/components/FindingStatusDropdown/FindingIgnoredStatusDropdown';
import colors from 'themes/colors.module.scss';
import { IFinding } from 'types/interfaces';

interface Props {
  finding: IFinding;
  onClose: () => void;
  onSetIgnoredIndication: (findingToUpdate: IFinding, ignored: boolean) => void;
  shouldDisablePopper: boolean;
  isIgnoreRulesReadOnly?: boolean;
}

export const FindingDetailViewHeader: FC<Props> = ({
  finding,
  onClose,
  onSetIgnoredIndication,
  shouldDisablePopper,
  isIgnoreRulesReadOnly,
}) => (
  <div>
    <div style={{
      display: 'flex',
      alignItems: 'center',
    }}
    >

      <JitText bold text={finding.name} variant='h6' />

      <IconButton
        className='btn p-0 wp-20'
        onClick={onClose}
        style={{ marginLeft: 'auto' }}
      >
        <JitIcon icon={Cross} size={25} />
      </IconButton>

    </div>

    <div
      className='justify-content-start'
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 26,
        padding: '30px 0px',
      }}
    >
      <SeverityBox severity={finding.severity} />

      <div>
        <JitText bold color={colors.lightGray} size='m' text='pages.findings.findingDetails.headerContext.firstDetection' />

        <JitText color={colors.lightGray} size='m' text={finding.createdAt} />
      </div>

      <div>
        <JitText bold color={colors.lightGray} size='m' text='pages.findings.findingDetails.headerContext.lastDetection' />

        <JitText color={colors.lightGray} size='m' text={finding.lastDetectedDateTime} />
      </div>
    </div>

    <div style={{ display: 'flex' }}>
      <FindingIgnoredStatusDropdown
        initialStatus={finding.ignored}
        isReadOnly={isIgnoreRulesReadOnly}
        onSetIgnoredIndication={(ignored: boolean) => onSetIgnoredIndication(finding, ignored)}
      />

      <CopyFindingsButton
        findings={[finding]}
        shouldDisablePopper={shouldDisablePopper}
        style={{
          marginLeft: 'auto',
          marginTop: -2,
        }}
      />
    </div>

  </div>

);
