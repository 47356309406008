import DialogActions from '@mui/material/DialogActions';
import { FC } from 'react';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';

interface Props {
  handleDelete: () => void;
}

export const DeleteSavedFilterActions: FC<Props> = ({
  handleDelete,
}) => (
  <DialogActions>
    <JitButton
      onClick={handleDelete}
      style={{
        marginRight: '1.5rem',
        marginLeft: '1.5rem',
      }}
      variant='contained'
    >
      <JitText text='dialogs.planItemsCatalog.filter.savedFilters.remove' />
    </JitButton>
  </DialogActions>
);
