import { useCallback, useEffect } from 'react';

import { useConfigurationsContext } from '../ConfigurationsContext';

import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { usePlanService } from 'services/PlanService/usePlanService';

export const useFetchConfigurations = () => {
  const { centralizedRepo } = useAssetsContext();
  const { setConfigurations, setConfigurationsCommit, setFetchedConfigurations, setIsLoadingConfigurations, fetchedConfigurations } = useConfigurationsContext();
  const { getConfigurations } = usePlanService();

  const fetchConfigurations = useCallback(async () => {
    if (!centralizedRepo || fetchedConfigurations) return;

    setIsLoadingConfigurations(true);
    const defaultConfigurations = {
      content: {},
      sha: '',
    };
    const configurationsRes = await getConfigurations();
    setConfigurations(configurationsRes?.data?.content || defaultConfigurations.content);
    setFetchedConfigurations(configurationsRes?.data?.content || defaultConfigurations.content);
    setConfigurationsCommit(configurationsRes?.data?.sha || defaultConfigurations.sha);

    setIsLoadingConfigurations(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoadingConfigurations, centralizedRepo, setConfigurations, setFetchedConfigurations, setConfigurationsCommit]);

  useEffect(() => {
    fetchConfigurations();
  }, [fetchConfigurations]);
};
