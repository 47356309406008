import { FC } from 'react';

import styles from './InitialSecretMessage.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';

interface Prop {
  onCreate: () => void;
}

export const InitialSecretMessage: FC<Prop> = ({ onCreate }) => (
  <div className={styles.wrapper}>
    <div className={styles.textWrapper}>
      <JitText bold text='dialogs.secretsManagement.initialScreen.title' />

      <JitText className={styles.subtitle} text='dialogs.secretsManagement.initialScreen.subtitle' />

      <JitButton className={styles.createButton} onClick={onCreate} variant='contained'>
        <JitText text='dialogs.secretsManagement.initialScreen.createSecretButton' />
      </JitButton>
    </div>
  </div>
);
