import { Palette, ThemeProvider } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { darkTheme, lightTheme } from 'themes';

interface IThemeContext {
  toggleTheme: () => void;
  mode: string;
  isLight: boolean;
  theme: Theme;
  palette: Palette;
}

export const themes = {
  light: lightTheme,
  dark: darkTheme,
};

export const ThemeContext = createContext<IThemeContext>({
  toggleTheme: () => undefined,
  mode: '',
  isLight: false,
  theme: darkTheme,
  palette: darkTheme.palette,
});

export const useThemeContext = () => useContext(ThemeContext);

export const JitThemeProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState(themes.dark);
  const htmlElement = document.getElementsByTagName('html')[0];

  useEffect(() => {
    htmlElement.style.backgroundColor = theme.palette.background.default;
  }, [htmlElement.style, theme.palette.background.default]);

  const toggleTheme = useCallback(() => {
    setTheme((curTheme) => {
      const newTheme = curTheme.palette.mode === 'light' ? themes.dark : themes.light;
      htmlElement.style.backgroundColor = newTheme.palette.background.default;
      return (newTheme);
    });
  }, [htmlElement.style]);

  const value = useMemo(() => ({
    toggleTheme,
    mode: theme.palette.mode,
    isLight: theme.palette.mode === 'light',
    palette: theme.palette,
    theme,
  }), [toggleTheme, theme]);

  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={value}>
        <div style={{
          backgroundColor: theme.palette.background.default,
          height: '100%',
        }}
        >
          {children}
        </div>
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};
