import { useCallback, useState } from 'react';

export const useHttpStream = () => {
  const [data, setData] = useState('');
  const [error, setError] = useState(null);
  const [isPolling, setIsPolling] = useState(false);

  const sendRequest = useCallback((url, method, body) => {
    const fetchData = async () => {
      try {
        setIsPolling(true);
        const response = await fetch(url, {
          method,
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body?.getReader();
        if (!reader) {
          throw new Error('No reader available for the response body.');
        }

        // eslint-disable-next-line no-new
        new ReadableStream({
          start: async (controller) => {
            // eslint-disable-next-line no-constant-condition
            while (true) {
              // eslint-disable-next-line no-await-in-loop
              const { done, value } = await reader.read();

              if (done) {
                setIsPolling(false);
                setData('');
                controller.close();
                break;
              }

              const chunk = new TextDecoder().decode(value);
              setData(chunk);
              controller.enqueue(value);
            }
          },
        });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setIsPolling(false);
        setError(err.message);
      }
    };

    fetchData();
  }, []);

  return {
    sendRequest,
    data,
    error,
    isPolling,
  };
};
