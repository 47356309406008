import React from 'react';

import styles from './ChatHeader.module.scss';

import { MagicSquare } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';

interface ChatHeaderProps {
  onReset: () => void;
  isPolling: boolean;
}

export const ChatHeader: React.FC<ChatHeaderProps> = ({ onReset, isPolling }) => (
  <div className={styles.root}>
    <div className={styles.chatItem}>
      <span className={styles.botIcon}><JitIcon icon={MagicSquare} size='2rem' /></span>

      <JitText size='l' text='chat.welcomeMsg' />
    </div>

    <JitButton disabled={isPolling} onClick={onReset} variant='outlined'>
      <JitText bold color='light' text='chat.resetBtn' />
    </JitButton>
  </div>
);
