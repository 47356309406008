import { isEmpty } from 'lodash';
import { FC, useState } from 'react';

import { DataOverviewItem, SecurityImpactCard } from '../SecurityImpactCard/SecurityImpactCard';
import { SecurityImpactCardEmpty } from '../SecurityImpactCard/SecurityImpactCardEmpty';
import { SecurityImpactCardLoading } from '../SecurityImpactCard/SecurityImpactCardLoading';

import { MTTROverview } from './components/MTTROverview/MTTROverview';
import { ProdOpenFindingsCountOverview } from './components/ProdOpenFindingsCountOverview/ProdOpenFindingsCountOverview';
import { SecurityPostureDescription } from './components/SecurityPostureDescription/SecurityPostureDescription';
import { SecurityPostureExpandedContent } from './components/SecurityPostureExpandedContent/SecurityPostureExpandedContent';
import { convertHoursToTimeFormat } from './utils';

import { MetricWithLoadingIndication } from 'types/interfaces/Metrics/Metric';
import { IDevProductivity } from 'types/interfaces/Metrics/SecurityImpactMetrics/DevProductivity';
import { IOpenFindingsCount } from 'types/interfaces/Metrics/SecurityImpactMetrics/OpenFindingsCount';
import { ISecurityImpactMTTR } from 'types/interfaces/Metrics/SecurityImpactMetrics/SecurityImpactMTTR';
import { ITotalFindingsGraph } from 'types/interfaces/Metrics/SecurityImpactMetrics/TotalFindingsGraph';

export interface Props {
  securityImpactMttrMetric: MetricWithLoadingIndication<ISecurityImpactMTTR>
  devProductivityMetric: MetricWithLoadingIndication<IDevProductivity>
  totalFindingsGraphMetric: MetricWithLoadingIndication<ITotalFindingsGraph>
  openFindingsCount: MetricWithLoadingIndication<IOpenFindingsCount>
}

export const SecurityPosture: FC<Props> = ({ securityImpactMttrMetric, devProductivityMetric, totalFindingsGraphMetric, openFindingsCount }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  if (securityImpactMttrMetric.isLoading || devProductivityMetric.isLoading || totalFindingsGraphMetric.isLoading || openFindingsCount.isLoading) {
    return <SecurityImpactCardLoading />;
  }

  if ((isEmpty(totalFindingsGraphMetric.metric) || !totalFindingsGraphMetric.metric)
      || (isEmpty(securityImpactMttrMetric.metric) || !securityImpactMttrMetric.metric)
      || (isEmpty(devProductivityMetric.metric) || !devProductivityMetric.metric)
      || (isEmpty(openFindingsCount.metric))) return <SecurityImpactCardEmpty />;

  const { currentPeriodMttr } = securityImpactMttrMetric.metric;
  const convertedMttr = convertHoursToTimeFormat(currentPeriodMttr);
  const averageMttrString = `${convertedMttr.value} ${convertedMttr.unit}`;
  const dataOverviewItems: DataOverviewItem[] = [{
    content: <ProdOpenFindingsCountOverview openFindingsCountMetric={openFindingsCount} />,
    title: 'pages.securityImpact.securityPosture.newOpenFindingsTitle',
    titleTestID: 'detected-findings-overview-title',
  }, {
    content: <MTTROverview devProductivityMetric={devProductivityMetric} isExpanded={isExpanded} securityImpactMTTRMetric={securityImpactMttrMetric} />,
    title: 'pages.securityImpact.securityPosture.mttrTitle',
    titleTestID: 'mttr-overview-title',
  }];

  const totalOpenFindings = openFindingsCount.metric?.totalOpenFindingsCount || 0;
  const openFindingsFromCurrentPeriod = openFindingsCount.metric?.lastPeriodOpenFindingsCount || 0;
  const prodResolvedFindings = devProductivityMetric.metric.totalResolvedFindings - devProductivityMetric.metric.preProdResolvedFindings;

  return (
    <SecurityImpactCard
      dataOverviewItems={dataOverviewItems}
      description={(
        <SecurityPostureDescription
          averageMttrString={averageMttrString}
          openFindingsCount={totalOpenFindings}
          openFindingsFromCurrentPeriodCount={openFindingsFromCurrentPeriod}
          resolvedFindingsCount={prodResolvedFindings}
        />
      )}
      expandedContent={<SecurityPostureExpandedContent totalFindingsGraphMetric={totalFindingsGraphMetric} />}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      title='pages.securityImpact.securityPosture.title'
    />
  );
};
