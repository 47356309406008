import Checkbox from '@mui/material/Checkbox';
import { FC } from 'react';

import { ITableCell } from 'types/interfaces';

export const IsSelectedCheckboxCell: FC<ITableCell> = ({ cell }) => {
  const { value: isSelectedDetails } = cell;
  const { isSelected, onChangeSelection, findingId } = isSelectedDetails;
  return (
    <Checkbox
      checked={isSelected}
      id={findingId}
      onChange={() => onChangeSelection(findingId)}
      onClick={(e) => e.stopPropagation()}
    />
  );
};
