import { createContext, useContext } from 'react';

import { ISpecificTeamState } from 'context/TeamsContext/TeamsProvider';
import { initialPaginatedState, IPaginatedState } from 'types/interfaces/IPaginatedState';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';

interface ITeamsContext {
  teams: IPaginatedState<IGetTeamResponseItem>;
  specificTeam: ISpecificTeamState;
  leadingTeams: IGetTeamResponseItem[];
  isLoadingLeadingTeams: boolean;
  getLeadingTeams: () => Promise<void>;
  getTeams: (shouldResetState: boolean, searchValue?: string) => Promise<void>;
  getSpecificTeamById: (teamId: string) => Promise<void>;
  getNextChildTeams: () => Promise<void>;
  getNextMembers: () => Promise<void>;
  sortedBy: TeamSortBy;
  setSortedBy: (sortBy: TeamSortBy) => void;
  sortOrder: TeamSortOrder;
  setSortOrder: (sortOrder: TeamSortOrder) => void;
}

export const TeamsContext = createContext<ITeamsContext>({
  getNextMembers: () => Promise.resolve(),
  getNextChildTeams: () => Promise.resolve(),
  getTeams: () => Promise.resolve(),
  getSpecificTeamById: () => Promise.resolve(),
  getLeadingTeams: () => Promise.resolve(),
  isLoadingLeadingTeams: false,
  leadingTeams: [],
  teams: initialPaginatedState,
  sortedBy: TeamSortBy.NAME,
  setSortedBy: () => {},
  sortOrder: TeamSortOrder.ASC,
  setSortOrder: () => {},
  specificTeam: {
    team: undefined,
    isLoadingSpecificTeam: false,
    members: initialPaginatedState,
    childTeams: initialPaginatedState,
    resources: initialPaginatedState,
  },
});

export const useTeamsContext = () => useContext(TeamsContext);
