import { useCallback } from 'react';

import { usePipelineContextNew } from 'context';
import { constants } from 'globalConstants';
import {
  createMockPipelineNew,
  createPlaceholderMockPipelineNew,
  runPipelineDemoNew,
} from 'pages/PipelinesPage/utils/MockPipelineNew/mockPipelineNew';
import { IMockPipelineNew } from 'types/interfaces';
import { PipelineDemoCommitType } from 'types/interfaces/Ci';

export const useCreateMockPipeline = () => {
  const { setMockPipeline, setShowActivationPopper } = usePipelineContextNew();
  const ACTIVATION_PLAN_ITEM_SLUG = constants.PLAN_ITEMS_SLUG.SECRETS;

  const setActivationState = useCallback((mockPipeline: IMockPipelineNew, showActivationPopper: boolean) => {
    if (setMockPipeline) {
      setMockPipeline(mockPipeline);
    }
    setShowActivationPopper(showActivationPopper);
  }, [setMockPipeline, setShowActivationPopper]);

  const createMockPipeline = useCallback(() => {
    createPlaceholderMockPipelineNew(ACTIVATION_PLAN_ITEM_SLUG, PipelineDemoCommitType.CLEAN, setActivationState);
    runPipelineDemoNew(ACTIVATION_PLAN_ITEM_SLUG, PipelineDemoCommitType.CLEAN).then(
      (pipelineDemoResponse) => {
        if (pipelineDemoResponse) {
          createMockPipelineNew(
            pipelineDemoResponse.base.repo.name,
            pipelineDemoResponse.number,
            ACTIVATION_PLAN_ITEM_SLUG,
            PipelineDemoCommitType.CLEAN,
            pipelineDemoResponse.base.repo.owner.login,
            setMockPipeline,
          );
        }
      },
    );
  }, [ACTIVATION_PLAN_ITEM_SLUG, setActivationState, setMockPipeline]);

  return { createMockPipeline };
};
