import axios, { AxiosResponse, Method } from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo } from 'react';

import { config } from 'config';
import { useAuthContext, useToastsContext } from 'context';
import { constants } from 'globalConstants';
import { logError } from 'services/logger/logger';
import { ToastType } from 'types/enums';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logRequestFailure = (error: any) => { // This 'any' will be address once we'll refactor our responses handling
  const extra = {
    message: error?.message,
    method: error?.config?.method,
    url: error?.config?.url,
    responseStatusCode: error?.response?.status || '',
    responseData: error?.response?.data || '',
  };
  const message = `API Request Failed - ${extra.responseStatusCode || extra.message}`;
  logError(message, extra);
};

const createClient = () => {
  const instance = axios.create({
    baseURL: config.apiBaseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401 || (error.response?.status === 403 && error.response?.data?.Message === constants.UNAUTHORIZED_MESSAGE)) {
        // eslint-disable-next-line no-param-reassign
        error.response.isLogoutResponse = true;
        window.location.href = constants.LOGOUT_PATH;
      }
      return error.response;
    },
  );
  return instance;
};

export const client = createClient();

export const setClientsHeaders = (headers: Record<string, string>) => {
  client.defaults.headers.common = {
    ...client.defaults.headers.common,
    ...headers,
  };
};

export const useClient = () => {
  const { showToast } = useToastsContext();
  const { uiShowRequestFailedIndication } = useFlags();
  const { frontEggUser } = useAuthContext();

  // User with no roles is not authorized.
  const isAuthorized = useMemo(() => {
    if (frontEggUser && frontEggUser.roles) {
      return config.isTest || frontEggUser.roles.length > 0;
    }
    return config.isTest;
  }, [frontEggUser]);

  type MakeRequestParams = {
    url: string;
    method: Method;
    allowedStatuses: number[];
    requestConfig?: Record<string, unknown>;
  };
  const makeRequest = useCallback(async <T>({
    url, method, requestConfig, allowedStatuses,
  }: MakeRequestParams): Promise<AxiosResponse<T> | undefined> => {
    if (!isAuthorized) {
      // Avoid making requests if user is not authorized.
      return undefined;
    }
    const result = await client.request<T>({
      url,
      method,
      ...requestConfig,
    });
    if (!result) {
      if (uiShowRequestFailedIndication) {
        showToast({
          type: ToastType.Error,
          overrideProps: {
            title: 'toasts.networkError.title',
            subtitle: 'toasts.networkError.subtitle',
          },
        });
      }
      // @ts-ignore
    } else if (result.isLogoutResponse) {
      return result;
    } else if (result.status === 403) {
      showToast({
        type: ToastType.Error,
        overrideProps: {
          title: 'toasts.noPermissions.title',
          subtitle: 'toasts.noPermissions.subtitle',
        },
      });
    } else if (!allowedStatuses.includes(result.status)) {
      logRequestFailure(result);
      if (uiShowRequestFailedIndication) {
        showToast({
          type: ToastType.Error,
          overrideProps: {
            title: 'toasts.failedApiRequest.title',
            subtitle: 'toasts.failedApiRequest.subtitle',
          },
        });
      }
    }

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- No need to re-calculate this function on changes in showToast
  }, [isAuthorized, uiShowRequestFailedIndication]);

  const methods = useMemo(() => ['post', 'get', 'put', 'patch', 'delete'] as Method[], []);
  type RequestFunction = <T = never>(params: Omit<MakeRequestParams, 'method'>) => Promise<AxiosResponse<T> | undefined>;

  const requests = useMemo(() => methods.reduce((acc, method) => {
    acc[method] = async <T = unknown>({ url, allowedStatuses, requestConfig }: Omit<MakeRequestParams, 'method'>) => makeRequest<T>({
      url,
      method,
      allowedStatuses,
      requestConfig,
    });
    return acc;
  }, {} as Record<string, RequestFunction>), [makeRequest, methods]);

  return useMemo(() => ({
    client: requests,
  }), [requests]);
};
