import { PageName } from '../hooks/constants';

import { constants } from 'globalConstants';
import { IDictionary } from 'types/interfaces';

export const pageNames: IDictionary<string> = {
  '/': PageName.Home,
  [`/${constants.routes.PLANS}`]: PageName.SecurityPlans,
  [`/${constants.routes.FINDINGS}`]: PageName.Findings,
  [`/${constants.routes.MATRIX}`]: PageName.Matrix,
  [`/${constants.routes.INTEGRATIONS}`]: PageName.Integrations,
  [`/${constants.routes.PIPELINES}`]: PageName.Pipelines,
  [`/${constants.routes.insights.BASE_ROUTE}/${constants.routes.insights.SECURITY_IMPACT}`]: PageName.SecurityImpact,
  [`/${constants.routes.insights.BASE_ROUTE}/${constants.routes.insights.PULL_REQUESTS}`]: PageName.PullRequests,
  [`/${constants.routes.insights.BASE_ROUTE}/${constants.routes.insights.DEPLOYMENTS}`]: PageName.Deployments,
  [`/${constants.routes.insights.BASE_ROUTE}/${constants.routes.insights.PERFORMANCE}`]: PageName.Performance,
  [`/${constants.routes.TEAMS}`]: PageName.Teams,
  [`/${constants.routes.ACTIONS}`]: PageName.Actions,
  [`/${constants.routes.OVERVIEW}`]: PageName.Overview,
  [`/${constants.routes.LOGOUT}`]: PageName.Logout,
  [`/${constants.routes.ONBOARDING}`]: PageName.Onboarding,
  [`/${constants.routes.SBOM}`]: PageName.Sbom,
  [`/${constants.routes.SECURITY_MAPPING}`]: PageName.SecurityMapping,
};

export const getPagesNames = (pathname: string): string => {
  const pageName = pageNames[pathname];
  if (pageName) {
    return pageName;
  }
  const pathnameParts = pathname.split('/');
  pathnameParts.pop();
  if (pathnameParts.length === 0) {
    return 'Unknown';
  }
  return getPagesNames(pathnameParts.join('/'));
};

export const getPlanName = (pathname: string): string | null => {
  if (PageName.SecurityPlans === getPagesNames(pathname)) {
    const pathnameParts = pathname.split('/');
    const planNameIndex = pathnameParts.indexOf(constants.routes.PLANS) + 1;
    if (planNameIndex < pathnameParts.length) {
      return pathnameParts[planNameIndex];
    }
  }
  return null;
};

